<template>
  <div class="main">
    <h2 class="subtitle">Selecciona un centro para continuar:</h2>
    <div class="center-wrapper">
      <div class="center" v-for="(center, idx) in getCentersList" :key="idx">
        <router-link :to="{ name: 'groups', params: { id: center.id } }">
        </router-link>
        {{ center.name }}
      </div>
      <div class="center new-center" v-if="getUserInfo.isSuperAdmin || !getUserInfo.isSupervisor">
        <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M30.7758 19.504H19.6398V30.384H11.3838V19.504H0.31175V11.696H11.3838V0.815998H19.6398V11.696H30.7758V19.504Z" fill="#00ADCE"/>
        </svg>
        <router-link :to="{ name: 'new-center' }">
        </router-link>
          Añadir nuevo
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CentersList',
  data() {
    return {
      isLoading: true,
      centers: []
    }
  },
  computed: {
    ...mapGetters('Admin', ['getCentersList', 'getUserInfo'])
  },
}
</script>
<style lang="scss" scoped>
.center-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
  .center {
    position: relative;
    width: 80%;
    min-height: 160px;
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 18px;
    background-color: rgba($newGrey, 0.5);
    font-weight: 700;
    cursor: pointer;
    padding: 20px;
    border: 1px solid $newGrey;
    a {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 5;
    }
    &:hover {
      transition: all .3s linear;
      color: $newGreen;
      border: 1px solid $newGreen;
    }
    &.new-center {
      background-color: rgba($newLightBlue, 0.4);
      border: 1px solid $newBlue;
      color: $newBlue;
      flex-direction: column;
      text-shadow:  1px 1px rgba(lighten($newBlue, 50), 0.9);
      svg {
        margin-bottom: 10px;
        path {
          fill: $newBlue;
        }
      }
      &:hover {
        border: 1px solid $newBlue;
      }
    }
  }
  @include tablet {
    flex-direction: row;
    .center {
      width: 180px;
    }
  }
}
</style>
