<template>
  <main class="main enrollment">
    <form action class="form admin__form" @submit.prevent="newEnrollmentSubmit">
      <div class="data-row space-between">
        <h2 class="subtitle">Nueva matrícula:</h2>
        <button type="submit" class="btn btn-save">Guardar</button>
      </div>
      <div class="form__field-row half">
        <div class="form__field-wrapper">
          <div class="form__field">
            <label class="form__field-label" for="#student">Alumn@ *</label>
            <Select2
              class="enrollment__select"
              v-model="v$.form.student.$model"
              :options="formStudents"
              :settings="{  placeholder: 'Selecciona un alumno', width: '100%' }">
            </Select2>
            <div class="errors errors-input" v-show="errors.hasOwnProperty('student')">
              <p v-for="(error, key) in errors.student" :key="key">{{ $t(error) }}</p>
            </div>
          </div>
        </div>

        <div class="form__field-wrapper">
          <div class="form__field">
            <label class="form__field-label" for="#group">Grupos *</label>
            <select
              v-model="v$.form.group.$model"
              class="form__field-input"
              name="group"
              id="group"
              @click="removeErrors"
              required
            >
              <option v-for="(group,idx) in formGroups" :key="idx" :value="group.value">{{group.label}}</option>
            </select>
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('group')">
            <p v-for="(error, key) in errors.group" :key="key">{{ $t(error) }}</p>
          </div>
        </div>
      </div>

      <div class="form__field-row half">
        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.price.$invalid && v$.form.price.$dirty">
              Por favor, introduce un precio
            </p>
            <label class="form__field-label" for="#price">Mensualidad *</label>
            <input
              v-model="v$.form.price.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.price.$invalid && v$.form.price.$dirty}"
              type="number"
              step="any"
              min="0"
              id="price"
              required
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('priceFee')">
            <p v-for="(error, key) in errors.priceFee" :key="key">{{ $t(error) }}</p>
          </div>
        </div>

        <div class="form__field-wrapper">
          <div class="form__field">
            <label class="form__field-label" for="#price">Fecha de comienzo *</label>
            <datepicker
              v-model="form.picked"
              inputFormat="dd/MM/yyyy"
              :lower-limit="form.lowerLimit"
              :style="useInputStyles"
              :locale="dateLocale"
            />
          </div>
        </div>
      </div>

      <div class="form__field-row half">
        <div class="form__field-wrapper">
          <div class="form__field">
            <label class="form__field-label" for="#priceEnrollment">Coste Matrícula</label>
            <input
              v-model="form.priceEnrollment"
              class="form__field-input"
              type="number"
              step="any"
              min="0"
              id="priceEnrollment"
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('frequency')">
            <p v-for="(error, key) in errors.frequency" :key="key">{{ $t(error) }}</p>
          </div>
        </div>
        <div class="form__field-wrapper">
          <div class="form__field">
            <label class="form__field-label" for="#subjectDefault">Concepto</label>
            <input
              v-model="form.subjectDefault"
              class="form__field-input"
              type="text"
              id="subjectDefault"
              @click="removeErrors"
            >
          </div>
        </div>
      </div>

      <div class="form__field-row half">
        <div class="form__field-wrapper">
          <div class="form__field">
            <label class="form__field-label" for="#typeOfPayment">Método de pago *</label>
            <div class="form__field-input enrollment__payments">
              <div
                  v-for="(type, idx) in paymentMethodsAvailable"
                  :key="type.id">
                <label class="form__field-label" for="#card">{{type.label}}</label>
                <input
                    type="radio"
                    id="isCardPayment"
                    :value="type.id"
                    name="typeOfPayment"
                    :checked="idx === 0"
                    @change="selectedTypeOfPayment($event)">
              </div>
            </div>
          </div>
        </div>
      </div>


    </form>
  </main>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Datepicker from 'vue3-datepicker'
import { ref } from 'vue'
import { utcToTimestamp } from '@/commonMethods/methods'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import es from 'date-fns/locale/es'
import { typeOfPayments } from '@/commonMethods/constants'
import Select2 from 'vue3-select2-component'

const useInputStyles = {
    padding: "12px 20px",
    border: "1px solid #00ADCE",
    borderRadius: "0",
    marginRight: "30px",
    width: "calc(100% - 30px)"
  }

export default {
  name: 'FormLeads',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {
    Datepicker,
    Select2
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      form: {
        student: '',
        group: '',
        price: null,
        picked: ref(new Date()),
        lowerLimit: ref(new Date()),
        frequency: 0,
        priceEnrollment: null,
        subjectDefault: '',
        utcToTimestamp
      },
      errors: {},
      useInputStyles,
      dateLocale: es,
      typesOfPayments: typeOfPayments,
      paymentMethod: 0
    }
  },
  validations() {
    return {
      form: {
        student: {
          required
        },
        group: {
          required
        },
        price: {
          required
        },
        frequency: {
          required
        },
      }
    }
  },
  computed:{
    ...mapGetters('Admin', {
      center: 'getActiveCenter',
      students: 'getStudentsList',
      groups: 'getGroupLists'
    }),
    activeCenter() {
      return this.center(this.$route.params.id)
    },
    paymentMethodsAvailable() {
      return this.typesOfPayments.filter(type => this.activeCenter.paymentMethods.includes(type.id))
    },
    formStudents() {
      return this.students.map(student => ({
        id: student.id,
        text: `${student.name} ${student.surnames}`
      }))
    },
    formGroups() {
      return this.groups.map(group => ({
        value: group.id,
        label: group.name
      }))
    },
  },
  methods: {
    ...mapActions('Admin', ['fetchGroupList', 'createEnrollment', 'fetchStudentsList']),
    newEnrollmentSubmit() {
      const newEnrollment = {
        student: parseInt(this.form.student),
        group: this.form.group,
        priceFee: this.form.price,
        dateStart: utcToTimestamp(this.form.picked),
        frequency: this.form.frequency,
        priceEnrollment: this.form.priceEnrollment,
        subjectDefault: this.form.subjectDefault,
        paymentMethod: parseInt(this.paymentMethod)
      }
      if(!this.v$.$invalid) {
        this.createEnrollment({centerId: this.id, newEnrollment})
        .then(() => {
          this.$toast.success('Datos guardados correctamente');
          setTimeout(() => {
            this.$router.push({ name: 'enrollments', params: { id: this.id } })
          }, 3000)
        })
        .catch(errors => {
          if(errors.length) {
            this.errors = {
              group: errors
            }
          } else {
            this.errors = errors
          }
        })
      }
    },
    removeErrors() {
      this.errors = {}
    },
    selectedTypeOfPayment(event) {
      this.paymentMethod = event.target.value
    }
  },
  mounted() {
    this.fetchStudentsList()
    this.fetchGroupList({ centerId: this.id })
  }
}
</script>

<style scoped lang="scss">
.form__field-wrapper {
  @include tablet {
    flex-direction: row;
    .admin__form__element {
      width: 50%;
      &:nth-child(odd) {
        .form__field-input, .form__field-label {
          margin-right: 30px;
        }
        .v3dp__datepicker {
          width: calc(100% - 41px);
          margin-right: 30px;
        }
      }
    }
  }
}
.v3dp__datepicker {
  text-align: left;
  width: calc(100% - 12px);
}
.enrollment {
  &__payments {
    text-align: start;
  }

  &__select {
    width: 100%;

    :deep {

      .select2-container--default {
        color: red;
        .select2-selection--single {
          border: 1px solid $newBlue;
          border-radius: 0;
          height: 41px;
          padding: 8px 12px;
          text-align: left;

          .select2-selection__arrow {
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}
</style>
