<template>
  <div class="main">
    <h2 class="subtitle">Campamentos / Talleres:</h2>
    <p class="text" v-if="isLoading">
      ¡Cargando datos!
    </p>
    <div v-for="(student, idx) in students" :key="idx">
      <h3 v-if="student.campHirings.length != 0">{{student.name}}</h3>
      <table class="admin__table" v-if="student.campHirings.length != 0">
        <thead class="admin__table__head">
          <tr>
            <th class="admin__table__head__cell">Nombre</th>
            <th class="admin__table__head__cell">Desde</th>
            <th class="admin__table__head__cell">Hasta</th>
            <th class="admin__table__head__cell">Servicio</th>
          </tr>
        </thead>
        <tbody class="admin__table__body" v-for="(camp, index) in student.campHirings" :key="index">
          <tr>
            <td class="admin__table__body__cell"><span>Nombre</span><p>{{camp.campPeriod.camp.name}}</p></td>  
            <td class="admin__table__body__cell"><span>Desde</span><p>{{formatDateShort(camp.campPeriod.dateStart)}}</p></td> 
            <td class="admin__table__body__cell"><span>Hasta</span><p>{{formatDateShort(camp.campPeriod.dateEnd)}}</p></td>
            <td class="admin__table__body__cell"><span>Servicio</span><p>{{camp.campService.name}}</p></td>
          </tr>
        </tbody>
      </table>
    </div>


    <div class="search-camps">
      <h3>Apunta a tus alumn@s a un nuevo campamento / taller</h3>
      <router-link :to="{ name: 'new-camp' }">
        <button class="btn">Buscar campamento / taller</button>
      </router-link>
    </div>

  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { formatDateShort } from '../commonMethods/methods'

export default {
  name: 'TutorCamps',
  data() {
    return {
      formatDateShort,
      students: [],
      campHirings: []
    }
  },
  methods: {
    ...mapActions('TutorCamps', ['fetchTutorStudents'])
  },
  mounted() {
    this.fetchTutorStudents()
    .then(response => {
      this.students = response
      this.isLoading = false
    })
    .catch(err => {
      console.log('error', err)
    })
  }
}
</script>

<style scoped lang="scss">
.search-camps {
  margin-top: 48px;
  margin-bottom: 48px;
  padding: 1em 2em;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  background-color: $lightBgBlue;
  border-radius: 22px;
  padding: 1em 2em;
  margin-bottom: 3em;
  h3 {
    text-align: center;
    color: $newDark;
  }
  a {
    margin: 0 auto;
  }
  @include tablet {
    flex-direction: row;
    justify-content: space-between;
    h3 {
      margin-right: 10px;
    }
    a {
      margin: 0;
      display: flex;
      align-items: center;
      text-decoration: none;
    }
  }
}
.admin__table__body__cell {
  cursor: auto;
  @include tablet {
    span {
      display: none;
    }
  }
}
.admin__table {
  margin-bottom: 30px;
}
</style>
