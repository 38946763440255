<template>
  <div class="loading">
    <img class="menu__logo" alt="Vue logo" src="../assets/logo.png">
    <p>!Cargando datos...!</p>
  </div>
</template>

<script>
export default {
  name: 'Loading'
}
</script>

<style lang="scss" scoped>
.loading {
  width: 200px;
  margin: 100px auto;
  img {
    width: 100%;
  }
  p {
    text-align: center;
    font-weight: 700;
  }
}
</style>
