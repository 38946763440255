<template>
  <div class="main">
    <form action class="form admin__form" @submit.prevent="updateTutorSubmit">
      <div class="btn-wrapper">
        <h2 class="subtitle">Editar tutor:</h2>
        <button type="submit" class="btn btn-save">Guardar</button>
      </div>
      <div class="form__field-row">
        <div class="form__field-wrapper wide">
          <div class="form__field center">
            <label class="form__field-label" for="#center">Centro *</label>
            <select
                v-model="v$.form.center.$model"
                class="form__field-input"
                name="center"
                id="center"
                @click="removeErrors"
                style="width: 300px"
            >
              <option v-for="(center,idx) in centers" :key="idx" :value="center.id">{{ center.name }}</option>
            </select>
          </div>
          <p class="input-errors" v-if="v$.form.center.$invalid && v$.form.center.$dirty">
            Debes elegir uno de nuestros centros
          </p>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('center')">
            <p v-for="(error, key) in errors.center" :key="key">{{ $t(error) }}</p>
          </div>
        </div>
      </div>
      <div class="form__field-row half">
        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.name.$invalid && v$.form.name.$dirty && !errors.name">
              Por favor, introduce un nombre
            </p>
            <label class="form__field-label" for="#name">Nombre *</label>
            <input
              v-model="v$.form.name.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.name.$invalid && v$.form.name.$dirty}"
              type="text"
              id="name"
              @click="removeErrors"
              required
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('name')">
            <p v-for="(error, key) in errors.name" :key="key">{{ $t(error) }}</p>
          </div>
        </div>

        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.surnames.$invalid && v$.form.surnames.$dirty && !errors.surnames">
              Por favor, introduce los apellidos
            </p>
            <label class="form__field-label" for="#surnames">Apellidos *</label>
            <input
              v-model="v$.form.surnames.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.surnames.$invalid && v$.form.surnames.$dirty}"
              type="text"
              id="surnames"
              @click="removeErrors"
              required
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('surnames')">
            <p v-for="(error, key) in errors.surnames" :key="key">{{ $t(error) }}</p>
          </div>
        </div>
      </div>

      <div class="form__field-row half">
        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.email.$invalid && v$.form.email.$dirty && !errors.email">
              Por favor, introduce un email válido
            </p>
            <label class="form__field-label" for="#email">Email *</label>
            <input
              v-model="form.email"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.email.$invalid && v$.form.email.$dirty}"
              type="text"
              id="email"
              required
              disabled
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('email')">
            <p v-for="(error, key) in errors.email" :key="key">{{ $t(error) }}</p>
          </div>
        </div>

        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.emailSecondary.$invalid && v$.form.emailSecondary.$dirty">
              Por favor, introduzca un email válido
            </p>
            <label class="form__field-label" for="#emailSecondary">Segundo email</label>
            <input
              v-model="v$.form.emailSecondary.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.emailSecondary.$invalid && v$.form.emailSecondary.$dirty}"
              type="email"
              id="emailSecondary"
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('emailSecondary')">
            <p v-for="(error, key) in errors.emailSecondary" :key="key">{{ $t(error) }}</p>
          </div>
        </div>
      </div>

      <div class="form__field-row third">
        <div class="form__field-wrapper">
          <p class="input-errors" v-if="v$.form.phone.$invalid && v$.form.phone.$dirty">
            Por favor, introduce un teléfono válido
          </p>
          <div class="form__field">
            <label class="form__field-label" for="#phone">Teléfono *</label>
            <input
              v-model="v$.form.phone.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.phone.$invalid && v$.form.phone.$dirty}"
              type="text"
              id="phone"
              @click="removeErrors"
              required
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('phone')">
            <p v-for="(error, key) in errors.phone" :key="key">{{ $t(error) }}</p>
          </div>
        </div>
         <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.phoneSecondary.$invalid && v$.form.phoneSecondary.$dirty">
              Por favor, introduce un teléfono válido
            </p>
            <label class="form__field-label" for="#phoneSecondary">Segundo teléfono</label>
            <input
              v-model="v$.form.phoneSecondary.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.phoneSecondary.$invalid && v$.form.phoneSecondary.$dirty}"
              type="number"
              id="phoneSecondary"
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('phoneSecondary')">
            <p v-for="(error, key) in errors.phoneSecondary" :key="key">{{ $t(error) }}</p>
          </div>
        </div>
        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.dni.$invalid && v$.form.dni.$dirty">
              Por favor, introduce un DNI válido
            </p>
            <label class="form__field-label" for="#dni">DNI</label>
            <input
                v-model="v$.form.dni.$model"
                class="form__field-input"
                :class="{'form__input--invalid': v$.form.dni.$invalid && v$.form.dni.$dirty}"
                type="text"
                id="dni"
                @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('dni')">
            <p v-for="(error, key) in errors.dni" :key="key">{{ $t(error) }}</p>
          </div>
        </div>
      </div>
    </form>

    <div v-if="getTutor?.students?.length">
      <h3>Estudiantes:</h3>
      <form
          class="form admin__form alias__form"
          v-for="(student, idx) in getTutor.students"
          :key="`alias--${idx}`">

        <div class="form__field-row half">
          <div class="form__field-wrapper">
            <div class="form__field">
              <label class="form__field-label" for="#name">Nombre</label>
              <input v-model="student.name" class="form__field-input" type="text" disabled>
            </div>
          </div>
          <div class="form__field-wrapper">
            <div class="form__field">
              <label class="form__field-label" for="#name">Apellidos</label>
              <input v-model="student.surnames" class="form__field-input" type="text" disabled>
            </div>
          </div>
        </div>

        <div class="form__field-row half">
          <div class="form__field-wrapper">
            <div class="form__field">
              <label class="form__field-label" for="#name">Fecha de nacimiento</label>
              <input :value="`${student.dayOfBirth}/${student.monthOfBirth}/${student.year}`" class="form__field-input" type="text" disabled>
            </div>
          </div>
          <div class="form__field-wrapper">
            <div class="form__field">
              <label class="form__field-label" for="#name">Alergias</label>
              <input :value="student.allergies.map(allergy => allergy.name).join(', ')" class="form__field-input" type="text" disabled>
            </div>
          </div>
        </div>

        <div class="form__field-row half">
          <div class="form__field-wrapper">
            <div class="form__field">
              <label class="form__field-label" for="#name">Autoriza imágenes</label>
              <input :value="student.imageAuthorized ? 'Sí' : 'No'" class="form__field-input" type="text" disabled>
            </div>
          </div>
        </div>
      </form>
    </div>

    <template v-if="getTutor?.tutorAliases?.length">
      <h3>Otros tutores asociados:</h3>
      <TutorAliasInfo :tutorAlias="getTutor.tutorAliases"/>
    </template>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required, email, numeric, minLength } from '@vuelidate/validators'
import TutorAliasInfo from "@/components/Partials/TutorAliasInfo";

export default {
  name: 'TutorDetail',
  props: {
    id: {
      type: String,
      required: true
    },
    tutorId: {
      type: String,
      required: true
    }
  },
  components: {
    TutorAliasInfo,
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      form: {
        name: '',
        surnames: '',
        email: '',
        emailSecondary: '',
        dni: null,
        phone: '',
        phoneSecondary: '',
        center: null
      },
      errors: {}
    }
  },
  validations() {
    return {
      form: {
        name: {
          required
        },
        surnames: {
          required
        },
        dni: {
          minLength: minLength(9)
        },
        email: {
          required,
          email
        },
        center: {
          required
        },
        emailSecondary: {
          email
        },
        phone: {
          required,
          numeric
        },
        phoneSecondary: {
          numeric
        }
      }
    }
  },
  watch: {
    getTutor: {
      immediate: true,
      handler(newTutor) {
        if(newTutor) {
          this.form = newTutor
        }
      }
    }
  },
  methods: {
    ...mapActions('Tutors', ['fetchTutor', 'updateTutor']),
    updateTutorSubmit() {
      const infoUpdateTutor = {
        name: this.form.name,
        surnames: this.form.surnames,
        phone: this.form.phone,
        dni: this.form.dni,
        center: this.form.center,
        phoneSecondary: this.form.phoneSecondary,
        emailSecondary: this.form.emailSecondary
      }
      this.updateTutor({centerId: this.id, tutorId: this.tutorId, infoUpdateTutor: infoUpdateTutor})
      .then(() => {
        this.$toast.success('Datos guardados correctamente');
        setTimeout(() => {
          this.$router.push({ name: 'tutors' })
        }, 3000)
      })
      .catch(errors => {
        this.errors = errors
      })
    },
    removeErrors() {
      this.errors = {}
    },
  },
  computed: {
    ...mapGetters('Tutors', ['getTutor']),
    ...mapState('Admin', ['centers']),
  },
  async mounted() {
    await this.fetchTutor({centerId: this.id, tutorId: this.tutorId})
  }
}
</script>

<style scoped lang="scss">
.alias__form {
  border-bottom: 1px solid $newBlue;
  margin: 32px 0;

  &:last-child {
    border: none;
  }
}
</style>
