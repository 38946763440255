<template>
  <main class="main">
    <form action class="form admin__form" @submit.prevent="updateCampSubmit">
      <div class="data-row space-between">
        <h2 class="subtitle">Editar campamento / taller:</h2>
        <button type="submit" class="btn btn-save">Guardar</button>
      </div>
      <div class="form__field-row half">
        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.name.$invalid && v$.form.name.$dirty">
              Por favor, introduce un nombre para el campamento / taller
            </p>
            <label class="form__field-label" for="#name">Nombre campamento / taller *</label>
            <input
              v-model="v$.form.name.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.name.$invalid && v$.form.name.$dirty}"
              type="text"
              id="name"
              required
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('name')">
            <p v-for="(error, key) in errors.name" :key="key">{{ $t(error) }}</p>
          </div>
        </div>
      </div>

      <div class="form__field-row half">
        <div class="form__field-wrapper">
          <div class="form__field">
            <label class="form__field-label" for="#staffMember">Profesor</label>
            <select
              v-model="form.staffMember"
              class="form__field-input"
              name="staffMember"
              id="staffMember"
              @click="removeErrors"
            >
              <option v-for="(staffMember,idx) in formTeachers" :key="idx" :value="staffMember.value">{{staffMember.label}}</option>
            </select>
            <div class="errors errors-input" v-show="errors.hasOwnProperty('staffMember')">
              <p v-for="(error, key) in errors.staffMember" :key="key">{{ $t(error) }}</p>
            </div>
          </div>

        </div>

        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.description.$invalid && v$.form.description.$dirty">
              Por favor, introduce una descripción del campamento / taller
            </p>
            <label class="form__field-label" for="#description">Descripción *</label>
            <input
              v-model="v$.form.description.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.description.$invalid && v$.form.description.$dirty}"
              type="text"
              id="description"
              required
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('description')">
            <p v-for="(error, key) in errors.description" :key="key">{{ $t(error) }}</p>
          </div>
        </div>

      </div>

      <div class="form__field-row half">
        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.yearStart.$invalid && v$.form.yearStart.$dirty">
              Por favor, introduce un año válido
            </p>
            <label class="form__field-label" for="#yearStart">Año mínimo de nacimiento *</label>
            <input
              v-model="v$.form.yearStart.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.yearStart.$invalid && v$.form.yearStart.$dirty}"
              type="text"
              id="yearStart"
              required
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('yearStart')">
            <p v-for="(error, key) in errors.yearStart" :key="key">{{ $t(error) }}</p>
          </div>
        </div>

        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.yearEnd.$invalid && v$.form.yearEnd.$dirty">
              Por favor, introduce un año válido
            </p>
            <label class="form__field-label" for="#yearEnd">Año máximo de nacimiento *</label>
            <input
              v-model="v$.form.yearEnd.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.yearEnd.$invalid && v$.form.yearEnd.$dirty}"
              type="text"
              id="yearEnd"
              required
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('yearEnd')">
            <p v-for="(error, key) in errors.yearEnd" :key="key">{{ $t(error) }}</p>
          </div>
        </div>

      </div>

      <p
        v-if="parseInt(form.yearStart) > parseInt(form.yearEnd) && form.yearEnd.length == 4"
        class="warning">
        Atención, el Año mínimo de nacimiento tiene que ser menor al Año máximo de nacimiento
      </p>

      <div class="form__field-row half">

        <div class="form__field-wrapper">
          <div class="form__field">
            <label class="form__field-label" for="#price">Contratable hasta *</label>
            <datepicker
                v-model="v$.form.hireableUntilAt.$model"
                inputFormat="dd/MM/yyyy"
                :lower-limit="form.lowerLimit"
                :style="useInputStyles"
                :locale="dateLocale"
            />
          </div>
        </div>

        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.firstPaymentPercentage.$invalid && v$.form.firstPaymentPercentage.$dirty">
              Por favor, introduce un valor para el primer pago
            </p>
            <label class="form__field-label" for="#firstPaymentPercentage">Primer pago (%) *</label>
            <input
              v-model="v$.form.firstPaymentPercentage.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.firstPaymentPercentage.$invalid && v$.form.firstPaymentPercentage.$dirty}"
              type="number"
              step="any"
              min="0"
              id="firstPaymentPercentage"
              required
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('firstPaymentPercentage')">
            <p v-for="(error, key) in errors.firstPaymentPercentage" :key="key">{{ $t(error) }}</p>
          </div>
        </div>


      </div>

      <div class="form__field-row half">

        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.discountPercentagePerStudent.$invalid && v$.form.discountPercentagePerStudent.$dirty">
              Por favor, introduce un valor para el descuento por hermano
            </p>
            <label class="form__field-label" for="#discountPercentagePerStudent">Descuento por hermano (%) *</label>
            <input
              v-model="v$.form.discountPercentagePerStudent.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.discountPercentagePerStudent.$invalid && v$.form.discountPercentagePerStudent.$dirty}"
              type="number"
              step="any"
              min="0"
              id="discountPercentagePerStudent"
              required
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('discountPercentagePerStudent')">
            <p v-for="(error, key) in errors.discountPercentagePerStudent" :key="key">{{ $t(error) }}</p>
          </div>
        </div>

        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.discountPercentagePerSession.$invalid && v$.form.discountPercentagePerSession.$dirty">
              Por favor, introduce un valor para el descuento por días contratados
            </p>
            <label class="form__field-label" for="#discountPercentagePerSession">Descuento por días contratados (%) *</label>
            <input
              v-model="v$.form.discountPercentagePerSession.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.discountPercentagePerSession.$invalid && v$.form.discountPercentagePerSession.$dirty}"
              type="number"
              step="any"
              min="0"
              id="discountPercentagePerSession"
              required
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('discountPercentagePerSession')">
            <p v-for="(error, key) in errors.discountPercentagePerSession" :key="key">{{ $t(error) }}</p>
          </div>
        </div>
      </div>

      <div class="form__field-row half">
        <div class="form__field-wrapper discount">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.discountPercentagePerGroup.$invalid && v$.form.discountPercentagePerGroup.$dirty">
              Por favor, introduce un valor para el descuento por alumno de La Casita
            </p>
            <label class="form__field-label" for="#discountPercentagePerSession">Descuento por alumno de La Casita (%) *</label>
            <input
                v-model="v$.form.discountPercentagePerGroup.$model"
                class="form__field-input"
                :class="{'form__input--invalid': v$.form.discountPercentagePerGroup.$invalid && v$.form.discountPercentagePerGroup.$dirty}"
                type="number"
                step="any"
                min="0"
                id="discountPercentagePerSession"
                required
                @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('discountPercentagePerGroup')">
            <p v-for="(error, key) in errors.discountPercentagePerGroup" :key="key">{{ $t(error) }}</p>
          </div>
        </div>
        <div class="form__field-wrapper">
          <div class="form__field">
            <label class="form__field-label" for="#">Permisos (escribe uno por cada línea)</label>
            <textarea name="" id="" cols="30" rows="10" v-model="form.questionsOptions"></textarea>
          </div>
        </div>
    </div>
      <h3 class="subtitle">Selecciona uno o varios periodos para el campamento / taller:</h3>

      <div class="form__field-row period-camps" v-for="(period, index) in campPeriods" :key="index">
        <div class="form__field-wrapper form__field-date-picker">
          <div class="form__field">
            <label class="form__field-label" for="#price">Desde *</label>
            <datepicker
                v-model="period.dateStart"
                inputFormat="dd/MM/yyyy"
                :lower-limit="form.lowerLimit"
                :style="useInputStyles"
                required
                :locale="dateLocale"
            />
          </div>
        </div>

        <div class="form__field-wrapper form__field-date-picker">
          <div class="form__field">
            <label class="form__field-label" for="#price">Hasta *</label>
            <datepicker
                v-model="period.dateEnd"
                inputFormat="dd/MM/yyyy"
                :lower-limit="form.lowerLimit"
                :style="useInputStyles"
                required
                :locale="dateLocale"
            />
          </div>
        </div>

        <div class="form__field-wrapper">
          <div class="form__field">
            <label class="form__field-label" for="#price">Plazas *</label>
            <input
              v-model="period.places"
              class="form__field-input"
              type="number"
              id="places"
              required
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('places')">
            <p v-for="(error, key) in errors.places" :key="key">{{ $t(error) }}</p>
          </div>
        </div>
        <button @click="deletePeriod(index, period.canDelete)" class="btn btn-add delete" :class="{'disabled': !period.canDelete}">Borrar</button>
      </div>


      <div class="tutor new-element" @click="addPeriod">
        <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M30.7758 19.504H19.6398V30.384H11.3838V19.504H0.31175V11.696H11.3838V0.815998H19.6398V11.696H30.7758V19.504Z" fill="#00ADCE"/>
        </svg>
          Añadir nuevo periodo
      </div>


      <h3 class="subtitle">Selecciona uno o varios servicios para el campamento / taller:</h3>

      <div class="form__field-row half services-camps" v-for="(service, index) in campServices" :key="index">
        <div class="form__field-wrapper service">
          <div class="form__field">

            <label class="form__field-label" for="#services">Servicio *</label>
            <input
              v-model="service.name"
              class="form__field-input"
              type="text"
              id="services"
              required
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('services')">
            <p v-for="(error, key) in errors.services" :key="key">{{ $t(error) }}</p>
          </div>
        </div>

        <div class="form__field-wrapper price">
          <div class="form__field">
            <label class="form__field-label" for="#prices">Precio *</label>
            <input
              v-model="service.price"
              class="form__field-input"
              type="text"
              id="prices"
              required
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('prices')">
            <p v-for="(error, key) in errors.prices" :key="key">{{ $t(error) }}</p>
          </div>
        </div>
        <button class="btn btn-add delete" :class="{'disabled': !service.canDelete}" @click="deleteService(index, service.canDelete)">Borrar</button>
      </div>

      <div class="tutor new-element" @click="addService">
        <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M30.7758 19.504H19.6398V30.384H11.3838V19.504H0.31175V11.696H11.3838V0.815998H19.6398V11.696H30.7758V19.504Z" fill="#00ADCE"/>
        </svg>
          Añadir nuevo servicio
      </div>
    </form>
  </main>

</template>

<script>
import Datepicker from 'vue3-datepicker'
import es from 'date-fns/locale/es'
import { useVuelidate } from '@vuelidate/core'
import { required, numeric, maxLength, minLength } from '@vuelidate/validators'
import { mapActions, mapGetters } from 'vuex'
import { utcToTimestamp } from '@/commonMethods/methods'
import { ref } from 'vue'

const useInputStyles = {
  padding: "12px 20px",
  border: "1px solid #00ADCE",
  borderRadius: "0",
  marginRight: "30px",
  width: "calc(100% - 30px)"
}

export default {
  name: 'CampCreate',
  components: {
    Datepicker,
  },
  props: {
    id: {
      type: String,
      required: true
    },
     campId: {
      type: String,
      required: true
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      form: {
        staffMember: '',
        name: '',
        description: '',
        hireableUntilAt: ref(new Date()),
        lowerLimit: ref(new Date()),
        yearStart: '',
        yearEnd: '',
        firstPaymentPercentage: null,
        discountPercentagePerStudent: null,
        discountPercentagePerSession: null,
        discountPercentagePerGroup: null,
        questionsOptions: ''
      },
      errors: {},
      useInputStyles,
      campPeriods: [],
      campServices: [],
      utcToTimestamp,
      dateLocale: es,
    }
  },
  validations() {
    return {
      form: {
        name: {
          required
        },
        description: {
          required
        },
        hireableUntilAt: {
          required
        },
        yearStart: {
          required,
          numeric,
          maxLength: maxLength(4),
          minLength: minLength(4)
        },
        yearEnd: {
          required,
          numeric,
          maxLength: maxLength(4),
          minLength: minLength(4)
        },
        firstPaymentPercentage: {
          required,
          numeric
        },
        discountPercentagePerStudent: {
          required,
          numeric
        },
        discountPercentagePerSession: {
          required,
          numeric
        },
        discountPercentagePerGroup: {
          required,
          numeric
        },
      }
    }
  },
  watch: {
    activeCamp: {
      immediate: true,
      handler(newCamp) {
        if(newCamp) {
          this.form = {
            ...newCamp,
            questionsOptions: newCamp.questionOptions.join('\n'),
            hireableUntilAt: newCamp.hireableUntilAt ? new Date(newCamp.hireableUntilAt * 1000) : ref(new Date()),
            staffMember: newCamp.staffMember ? newCamp.staffMember.id : '',
          }
          this.campPeriods = []
          newCamp.campPeriods.forEach(period => {
            this.campPeriods.push({
              dateStart: new Date(period.dateStart * 1000),
              dateEnd: new Date(period.dateEnd * 1000),
              places: period.places,
              canDelete: period.canDelete
            })
          })
          this.campServices = []
          newCamp.campServices.forEach(period => {
            this.campServices.push({
              name: period.name,
              price: period.price,
              canDelete: period.canDelete
            })
          })
        }
      }
    }
  },
  computed: {
    ...mapGetters('Admin', {
      staffMembers: 'getStaffMembers'
    }),
    ...mapGetters('AdminCamps', {
      getActiveCamp: 'getActiveCamp'
    }),
    activeCamp() {
      return this.getActiveCamp(this.campId)
    },
    formTeachers() {
      return this.staffMembers.map(teacher => ({
        value: teacher.id,
        label: teacher.name
      }))
    },
  },
  methods: {
    ...mapActions('AdminCamps', ['fetchCamps', 'campUpdate']),
    ...mapActions('Admin', ['fetchStaffMembers']),
    removeErrors() {
      this.errors = {}
    },
    addPeriod() {
      this.campPeriods.push({
        dateStart: ref(new Date()),
        dateEnd: ref(new Date()),
        places: 0,
        canDelete: true
      })
    },
    addService() {
      this.campServices.push({
        name: '',
        price: 0,
        canDelete: true
      })
    },
    deletePeriod(idx, canDelete) {
      if(canDelete) {
        this.campPeriods.splice(idx, 1)
      } else {
        this.$toast.error('No se puede borrar este periodo')
      }
    },
    deleteService(idx, canDelete) {
      if(canDelete) {
        this.campServices.splice(idx, 1)
      } else {
        this.$toast.error('No se puede borrar este servicio')
      }
    },
    updateCampSubmit() {
      const questionsOptionsArray = this.form.questionsOptions.split('\n').filter(item => item.length > 0)
      let periodsToSend = []
      for(let i=0; i<this.campPeriods.length; i++) {
        if(typeof this.campPeriods[i].dateStart === 'object') {
          periodsToSend.push({
            dateStart: utcToTimestamp(this.campPeriods[i].dateStart),
            dateEnd: utcToTimestamp(this.campPeriods[i].dateEnd),
            places: parseInt(this.campPeriods[i].places)
          })
        } else {
          periodsToSend.push({
            dateStart: this.campPeriods[i].dateStart / 1000,
            dateEnd: this.campPeriods[i].dateEnd / 1000,
            places: parseInt(this.campPeriods[i].places)
          })
        }
      }
      let servicesToSend = []
      for(let i=0; i<this.campServices.length; i++) {
        servicesToSend.push({
          name: this.campServices[i].name,
          price: this.campServices[i].price
        })
      }

      let infoCampUpdate = {
        staffMember: this.form.staffMember,
        name: this.form.name,
        description: this.form.description,
        yearStart: parseInt(this.form.yearStart),
        yearEnd: parseInt(this.form.yearEnd),
        hireableUntilAt: utcToTimestamp(this.form.hireableUntilAt),
        firstPaymentPercentage: parseInt(this.form.firstPaymentPercentage),
        discountPercentagePerStudent: parseFloat(this.form.discountPercentagePerStudent),
        discountPercentagePerSession: parseFloat(this.form.discountPercentagePerSession),
        discountPercentagePerGroup: parseFloat(this.form.discountPercentagePerGroup),
        campPeriods: periodsToSend,
        campServices: servicesToSend,
        questionOptions: questionsOptionsArray
      }
      if(!this.v$.$invalid) {
        this.campUpdate([ this.id, this.campId, infoCampUpdate ])
        .then(() => {
          this.$toast.success('Campamento / taller actualizado correctamente')
          this.$router.push({ name: 'camps' })
        })
        .catch (errors => {
          if(errors.campServices && errors.campServices.length > 0) {
            if(errors.campServices.includes('error.not_null')) {
              this.$toast.error('Los campamentos / talleres tienen que tener al menos un servicio')
            }
          }
          if(errors.campPeriods && errors.campPeriods.length > 0) {
            if(errors.campPeriods.includes('error.not_null')) {
              this.$toast.error('Los campamentos / talleres tienen que tener al menos un periodo')
            }
          }
          this.errors = errors
        })
      }
    }
  },
  async mounted() {
    this.isLoading = true
    try {
      await this.fetchStaffMembers(this.id)
      await this.fetchCamps(this.id)
    } catch (error) {
      console.log('error', error)
    } finally {
      this.isLoading = false
    }
  }
}
</script>

<style scoped lang="scss">
.form {
  &__field {
    margin-bottom: 20px;
  }
  @include desktop {
    margin-top: 50px;
    .form__field-row {
      width: 100%;
      &.half {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
          gap: 20px;
        .form__field-wrapper {
          display: inline-block;
          vertical-align: top;
          width: 100%;
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
      }
    }
  }
  textarea {
    border-color: #45B8AC;
    font-family: $font__main;
    font-size: 14px;
    height: 80px;
    padding: 4px;
    resize: none;
  }
}
.form__field-row {
  &.period-camps, &.services-camps {
    margin-bottom: 40px;
    align-items: center;
    .form__field-wrapper {
      margin-bottom: 0;
    }
  }
  &.period-camps {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: unset;
    gap: 12px;
    @include desktop {
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
      .form__field-wrapper {
        width: 100%;
      }
    }
  }
  &.services-camps {
    .form__field-wrapper {
      max-width: unset;
      .form__field {
        width: 100%;
        input {
          width: calc(100% - 42px);
        }
      }
      &.form__field-date-picker {
        max-width: 96%;
      }
      .btn {
        margin-bottom: 0;
      }
    }
    @include tablet {
      flex-direction: row;
      .form__field-wrapper {
        width: calc(50% - 20px);
        &.price {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          .form__field {
            width: calc(50% - 20px);
            justify-content: space-between;
            flex-grow: 1;
          }
        }
        .form__field {
          input {
            width: unset;
          }
        }
      }
    }
  }

}
.warning {
  color: $newHighlight;
  font-size: 12px;
  margin-top: -24px;
  margin-bottom: 32px;
  max-width: 40%;
  text-align: left;
}
.btn {
  &.delete {
    margin: 0 auto;
    min-width: 200px;
  }
  &.disabled {
    background-color: $grey;
    border: 1px solid $grey;
    pointer-events: none;
    &:hover {
      color: $white;
      cursor: not-allowed;
    }
  }
}
</style>

<style lang="scss">
  .v3dp__input_wrapper {
    input {
      box-sizing: border-box;
      width: 100% !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
</style>
