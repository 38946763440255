<template>
  <div class="login-wrapper box">

    <div class="login-box">
      <h2 class="subtitle">Bienvenido de nuevo</h2>
      <p class="text">Introduce tus datos para continuar:</p>
      <form action class="form" @submit.prevent="login">
        <div class="form-wrapper">
          <div class="form__field-wrapper">
            <p class="input-errors" v-if="v$.form.email.$invalid && v$.form.email.$dirty">
              Por favor, introduce un correo válido
            </p>
            <div class="form__field">
              <label class="form__field-label" for="#email">Email *</label>
              <input
                v-model="v$.form.email.$model"
                class="form__field-input"
                :class="{'form__input--invalid': v$.form.email.$invalid && v$.form.email.$dirty}"
                id="email"
                required
                @click="removeErrors"
              >
            </div>
          </div>
          <div class="form__field-wrapper">
            <p class="input-errors" v-if="v$.form.password.$invalid && v$.form.password.$dirty">
              Tu contraseña debe tener al menos 8 caracteres
            </p>
            <div class="form__field">
              <label class="form__field-label" for="#password">Contraseña *</label>
              <input
                v-model="v$.form.password.$model"
                class="form__field-input"
                :class="{'form__input--invalid': v$.form.password.$invalid && v$.form.password.$dirty}"
                type="password"
                required
                @click="removeErrors"
              >
            </div>
          </div>
        </div>
        <button class="btn">Entrar</button>
        <div class="errors" v-if="errors.user != '' ">
          <p>{{ errors.user }}</p>
        </div>
      </form>
      <router-link class="forgot-password" :to="{ name: 'recover' }">
        ¿Has olvidado tu contraseña?
      </router-link>
    </div>

    <div class="login-box">
      <h2 class="subtitle">¿Aún no eres cliente?</h2>
      <p class="text">Crea tu cuenta en tan sólo dos minutos y matricula a los miembros de tu familia en alguno de los grupos disponibles.</p>

      <router-link :to="{ name: 'register-main' }">
          <button class="btn">Crear una cuenta</button>
      </router-link>
    </div>

  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email, minLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'


export default {
  name: 'Register',
  components: {
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      errors: {
        user: ''
      },
      err: {}
    }
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(8)
      }
    }
  },
  methods: {
    ...mapActions('Auth', {
      actionLogin: 'login',
      actionRefresh: 'refresh'
    }),
    removeErrors() {
      this.errors.user = ''
    },
    redirect(response) {
      if(response.isStaffMember) {
        this.$router.push({ name: 'admin-main-screen' })
      } else {
        this.$router.push({ name: 'parents-main-screen' })
      }
    },
    login() {
      if(!this.v$.$invalid) {
        this.actionLogin(this.form)
        .then((response) => {
          this.redirect(response)
        })
        .catch(err => {
          this.err = err
          this.errors.user = 'Credenciales incorrectas';
        })
      }
    }
  },
  mounted() {
    this.actionRefresh()
    .then((response) => {
      this.redirect(response)
    })
  }
}
</script>

<style scoped lang="scss">
.login-wrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  .text {
    max-width: 100%;
    margin: 0 auto 30px;
  }
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0 20px;
    &-wrapper {
      width: 80%;
      display: flex;
      flex-direction: column;
    }
  }
  .btn {
    min-width: 220px;
    &__separator {
      margin-bottom: 2em;
    }
  }
  .forgot-password {
    color: $bg-blue;
    display: block;
    margin-top: 1em;
    margin-bottom: 3em;
    text-decoration: none;
    font-size: 14px;
    &:hover {
      color: $link-hover;
    }
  }
  @include tablet {
    flex-direction: row;
    align-items: center;
    .text {
      max-width: 60%;
    }
    .form {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .form-wrapper {
        width: 100%;
        .form__field-wrapper {
          width: 68%;
          margin: 0 auto 40px;
        }
      }
    }
  }
}
.login-box {
  margin-bottom: 40px;
  .text {
    padding: 0 20px;
  }
  @include tablet {
    width: 50%;
    margin-bottom: 0;
    &:first-child {
      position: relative;
      &::after {
        position: absolute;
        top: 50%;
        margin-top: -140px;
        left: 100%;
        content: " ";
        height: 280px;
        width: 1px;
        background: $newPink;
      }
    }
    &:last-child {
      .subtitle {
        margin-top: 0;
      }
    }
  }
}
</style>
