<template>
  <div class="register-confirmation-wrapper box">

    <h2 class="subtitle">Confirma tu cuenta</h2>
    <p class="text">Revisa tu correo electrónico, te hemos enviado un código para confirmar la cuenta.</p>
    <form action class="confirmation__form" @submit.prevent="registerPasswordCode">
      <div class="form__field-wrapper">
        <div class="form__field confirmation__form__container">
          <input
              class="form__field-input confirmation__form__input"
              type="number"
              id="code"
              v-model="v$.code.$model"
              minlength="6"
              maxlength="6"
              required>
        </div>
        <div class="errors" v-for="(error, key) in errors" :key="key">
          <p>{{ $t(error) }}</p>
        </div>
      </div>
      <button class="btn">Enviar</button>
    </form> 
    
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'

export default {
  name: 'RegisterConfirmation',
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      code: '',
      errors: []
    }
  },
  validations() {
    return {
      code: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(6)
      }
    }
  },
  methods: {
    ...mapActions('Auth', {
      sendCode: 'registerCode'
    }),
    async registerPasswordCode() {
      if(!this.v$.$invalid) {
        try {
          await this.sendCode(parseInt(this.code))
          this.$router.push({ name: 'thanks' })
        } catch(errors) {
          this.errors = ['Lo sentimos, no hemos podido completar el registro, inténtelo de nuevo']
        }
      } else {
        this.errors = ['El código es incorrecto, debe tener 6 caracteres']
      }
    }
  },
}
</script>

<style scoped lang="scss">
.register-confirmation-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;

  p {
    max-width: 100%;
    margin: 0 auto 2em;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  .confirmation {

    &__form {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 24px;

      &__container {
        align-items: center;
      }

      &__input {
        color: $bg-blue;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 3px;
        text-align: center;
        width: 200px;
      }

      .errors {
        margin: 24px auto 0;
      }

    }
  }
  
  @include tablet {
    p {
      max-width: 60%;
    }
  }
  @include desktop {
    p {
      max-width: 40%;
    }
  }
}
</style>
