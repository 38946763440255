export const CARD_METHOD = 0
export const CASH_METHOD = 1
export const SEPA_METHOD = 2

export const typeOfPayments = [
    {
        id: CARD_METHOD,
        label: 'Tarjeta',
        name: 'card',
        isDefault: true
    },
    {
        id: CASH_METHOD,
        label: 'Efectivo',
        name: 'cash'
    },
    {
        id: SEPA_METHOD,
        label: 'SEPA',
        name: 'sepa'
    }
]

export const paymentStatus = [
    {
        label: 'No pagado',
        name: 'unPaid'
    },
    {
        label: 'Pagado',
        name: 'paid'
    },
    {
        label: 'Error',
        name: 'paidError'
    }
]

export const aspectsToEvaluate = [
    {
        id: 1,
        label: 'Pronunciation'
    },
    {
        id: 2,
        label: 'Listening Comprehension'
    },
    {
        id: 3,
        label: 'Vocabulary'
    },
    {
        id: 4,
        label: 'Fluency'
    },
    {
        id: 5,
        label: 'Participation'
    }
]

export const evaluationLevels = [
    {
        id: 0,
        value: 1
    },
    {
        id: 1,
        value: 2
    },
    {
        id: 2,
        value: 3
    }
]

export const classReports = [
    {
        id: 1,
        label: 'WildCats'
    },
    {
        id: 2,
        label: 'Hunny Bunnies'
    },
    {
        id: 3,
        label: 'Cool Koalas'
    },
    {
        id: 4,
        label: 'Bumblebees'
    },
    {
        id: 5,
        label: 'Blue Jays'
    }
]