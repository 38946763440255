<template>
  <div class="thanks-wrapper box">
    <div class="thanks-box">
      <h2 class="subtitle">Muchas gracias</h2>
      <p class="text">Muchas gracias por confiar en nosotros.</p>
      <router-link :to="{ name: 'login' }">
        <button class="btn">Entrar</button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Thanks',
}
</script>

<style scoped lang="scss">
.thanks-wrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  .text {
    max-width: 100%;
    margin: 0 auto 3em;
  }
  .btn {
    margin-bottom: 3em;
    min-width: 220px;
  }
  @include tablet {
    .text {
      max-width: 60%;
    }
  }
  @include desktop {
    .text {
      max-width: 40%;
    }
  }
}
.thanks-box {
  margin-bottom: 40px;
  .text {
    padding: 0 20px;
  }
}

</style>
