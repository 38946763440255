<template>
  <form class="form admin__form">
    <ul class="form__field-wrapper">
      <li class="form__field admin__form__element">
        <label
          for="name"
          class="form__field-label">Nombre *
        </label>
        <input
          class="form__field-input"
          :class="{'form__input--invalid': v$.form.name.$invalid && v$.form.name.$dirty}"
          type="text"
          id="name"
          v-model="v$.form.name.$model">
          <p class="input-errors" v-if="v$.form.name.$invalid && v$.form.name.$dirty && !errors.name">
            Por favor, introduce una alergia
          </p>
      </li>
      <div class="errors errors-input" v-show="errors.hasOwnProperty('name')">
        <p v-for="(error, key) in errors.name" :key="key">{{ $t(error) }}</p>
      </div>
    </ul>
    <button
        type="button"
        class="btn btn-save"
        @click="submitForm">Guardar
    </button>
  </form>
</template>

<script>
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

export default {
  name: 'AllergyForm.vue',
  setup () {
    return { v$: useVuelidate() }
  },
  props: {
    allergy: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    allergy: {
      immediate: true,
      handler(newAllergy) {
        if (newAllergy) {
          this.form = { ...newAllergy }
        }
      }
    }
  },
  data() {
    return {
      form: {
        name: ''
      }
    }
  },
  validations() {
    return {
      form: {
        name: {
          required
        }
      }
    }
  },
  methods: {
    submitForm() {
      if (!this.v$.$invalid) {
        this.$emit('submit', this.form)
      }
    }
  }
}
</script>

<style scoped>

</style>
