<template>
  <div class="main">
    <div class="header">
      <h2 class="subtitle">Certificados anuales</h2>
      <div class="form__field-wrapper">
        <div class="form__field">
          <label class="form__field-label" for="#year">Año :</label>
          <select
              v-model="year"
              class="form__field-input"
              name="year"
              id="year"
          >
            <option v-for="(year,idx) in [2023,2022]" :key="idx" :value="year">{{ year }}</option>
          </select>
        </div>
      </div>
    </div>
    <p class="text" v-if="returnYearlyPaymentList == null">No tienes pagos para el año {{ year }}</p>
    <Loading v-if="isLoading"/>
    <p class="text" v-if="!hasDni">Para generar los certificados, necesitamos tu DNI. Edítalo haciendo click <a href="#" @click="isModalOpen = true">aqui</a>
    </p>
    <table class="admin__table" v-if="returnYearlyPaymentList != null && returnYearlyPaymentList.length && !isLoading">
      <thead class="admin__table__head">
      <tr>
        <th class="admin__table__head__cell">Alumno</th>
        <!--        <th class="admin__table__head__cell">Año Nacimiento</th>-->
        <th class="admin__table__head__cell">Centro</th>
        <th class="admin__table__head__cell">Importe</th>
        <th class="admin__table__head__cell" v-if="hasDni">&nbsp;</th>
      </tr>
      </thead>
      <tbody class="admin__table__body">
      <tr v-for="(payment, idx) in returnYearlyPaymentList" :key="idx">
        <td class="admin__table__body__cell"><p>{{ payment.name }} {{ payment.surnames }}</p></td>
        <!--        <td class="admin__table__body__cell"><p>{{ payment.year }}</p></td>-->
        <td class="admin__table__body__cell"><p>{{ payment.center }}</p></td>
        <td class="admin__table__body__cell"><p>{{ payment.total }}</p></td>
        <td class="admin__table__body__cell" v-if="hasDni">
          <div class="btn" @click="downloadCertificate(payment.id, payment.centerId)">Descargar Certificado</div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <Popup :valid="isValid" :modalTitle="'Introduzca su DNI'" :compact="true" v-if="isModalOpen" @onClose="closeModal" @onAccept="saveDNI" >
    <div class="form__field-row no-margin">
      <div class="form__field-wrapper no-margin">
        <div class="form__field">
          <p class="input-errors" v-if="v$.form.dni.$invalid && v$.form.dni.$dirty">
            DNI no valido
          </p>
          <label class="form__field-label" for="#dni">DNI *</label>
          <input
              v-model="v$.form.dni.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.dni.$invalid && v$.form.dni.$dirty}"
              type="text"
              id="dni"
              required
              placeholder=""
          >
        </div>
        <div class="errors" v-show="errors.hasOwnProperty('dni')">
          <p v-for="(error, key) in errors.dni" :key="key">{{ $t(error) }}</p>
        </div>
      </div>
    </div>
  </Popup>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Loading from "@/components/Loading.vue";
import Popup from "@/components/Helpers/Popup.vue";
import { useVuelidate } from "@vuelidate/core";
import { minLength, required } from "@vuelidate/validators";

export default {
  name: 'Yearly',
  setup() {
    return { v$: useVuelidate() }
  },
  components: { Popup, Loading },
  data() {
    return {
      year: 2023,
      yearlyPaymentList: [],
      isLoading: true,
      isModalOpen: false,
      form: {
        dni: null
      },
      errors: {}
    }
  },
  async mounted() {
    await this.updateData()
  },
  validations(){
    return {
      form: {
        dni: {
          required,
          minLength: minLength(9)
        }
      }
    }
  },
  watch: {
    year: {
      immediate: true,
      async handler() {
        await this.updateData()
      }
    }
  },
  computed: {
    ...mapGetters('User', {
      getUser: 'getUser',
      returnYearlyPaymentList: 'getYearlyPaymentList'
    }),
    isValid() {
      return !this.v$.form.dni.$invalid;
    },
    hasDni() {
      return this.getUser.dni;
    }
  },
  methods: {
    ...mapActions('User', ['tutorYearlyPaymentList', 'updateUserInfo']),
    async updateData() {
      this.isLoading = true;
      try {
        await this.tutorYearlyPaymentList(this.year);
      } catch (error) {
        this.errors = error
      } finally {
        this.isLoading = false
      }
    },
    closeModal(){
      this.form.dni = null;
      this.isModalOpen = false;
    },
    saveDNI(){
      if(!this.v$.$invalid) {
        const info = {
          dni: this.form.dni
        }
        this.updateUserInfo(info)
            .then(() => {
              this.$toast.success('Datos guardados correctamente');
            })
            .catch(() => {
              this.$toast.error('Ha ocurido un error, inténtalo de nuevo.');
            })
      }
      this.closeModal()
    },
    async downloadCertificate(studentId, centerId) {
      const accessToken = localStorage.getItem('accessToken');
      const url = `${process.env.VUE_APP_API_BASE_URI}/tutor/payments/${this.year}/certificate/${studentId}/${centerId}`
      const options = {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${accessToken}`
        },
      };
      fetch(url, options)
          .then(response => {
            if (!response.ok) {
              throw new Error('Lo sentimos, no hemos podido descargar el PDF, inténtelo de nuevo');
            }
            return response.blob();
          })
          .then(blob => {
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `certificado_${this.year}_${studentId}_${centerId}.pdf`;
            document.body.appendChild(link);
            link.click();
            URL.revokeObjectURL(url);
          })
          .catch(error => {
            // Manejar errores
            console.error('Error al descargar el archivo:', error);
          });

    }
  }
}

</script>


<style scoped lang="scss">
.header {
  display: flex;
  gap: 20px;
  justify-content: space-between;

  .form__field-wrapper {
    margin-bottom: 0;
    .form__field {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      .form__field-input {
        min-width: 100px;
      }
    }
  }
}
</style>