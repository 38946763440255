<template>
  <div class="main">
    <Loading v-if="isLoading" />
    <template v-else>
        <h2 class="subtitle">Nuevo lead:</h2>
        <form class="form admin__form">
        <ul class="form__field-wrapper">
          <li class="form__field admin__form__element">
            <label
              for="nameTutor"
              class="form__field-label">Nombre y apellido del tutor legal
            </label>
            <input
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.nameTutor.$invalid && v$.form.nameTutor.$dirty}"
              type="text"
              id="nameTutor"
              required
              v-model="v$.form.nameTutor.$model"
              @click="removeErrors('nameTutor')">
              <div class="errors errors-input" v-if="errors.hasOwnProperty('nameTutor')">
                <p>{{ $t(errors['nameTutor'][0]) }}</p>
              </div>
          </li>
          <li class="form__field admin__form__element">
            <label
              for="phone"
              class="form__field-label">Móvil *
            </label>
            <input
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.phone.$invalid && v$.form.phone.$dirty}"
              type="text"
              id="phone"
              required
              v-model="v$.form.phone.$model"
              @click="removeErrors('phone')">
              <div class="errors errors-input" v-if="errors.hasOwnProperty('phone')">
                <p>{{ $t(errors['phone'][0]) }}</p>
              </div>
          </li>
          <li class="form__field admin__form__element">
            <label
              for="phoneSecondary"
              class="form__field-label">Móvil secundario
            </label>
            <input
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.phoneSecondary.$invalid && v$.form.phoneSecondary.$dirty}"
              type="text"
              id="phoneSecondary"
              required
              v-model="v$.form.phoneSecondary.$model"
            >
              <p class="input-errors" v-if="v$.form.phoneSecondary.$invalid && v$.form.phoneSecondary.$dirty">
                Por favor, introduce un móvil válido
              </p>
              <div class="errors errors-input" v-if="errors.hasOwnProperty('phoneSecondary')">
                <p>{{ $t(errors['phoneSecondary']) }}</p>
              </div>
          </li>
          <li class="form__field admin__form__element">
            <label
              for="email"
              class="form__field-label">Email *
            </label>
            <input
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.email.$invalid && v$.form.email.$dirty}"
              type="email"
              id="email"
              required
              v-model="v$.form.email.$model"
              @click="removeErrors('email')">
              <p class="input-errors" v-if="v$.form.email.$invalid && v$.form.email.$dirty">
                Por favor, introduce un email válido
              </p>
              <div class="errors errors-input" v-if="errors.hasOwnProperty('email')">
                <p>{{ $t(errors['email'][0]) }}</p>
              </div>
          </li>
          <li class="form__field admin__form__element">
            <label
              for="emailSecondary"
              class="form__field-label">Email secundario
            </label>
            <input
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.emailSecondary.$invalid && v$.form.emailSecondary.$dirty}"
              type="email"
              id="emailSecondary"
              required
              v-model="v$.form.emailSecondary.$model"
            >
              <p class="input-errors" v-if="v$.form.emailSecondary.$invalid && v$.form.emailSecondary.$dirty">
                Por favor, introduce un email válido
              </p>
              <div class="errors errors-input" v-if="errors.hasOwnProperty('emailSecondary')">
                <p>{{ $t(errors['emailSecondary']) }}</p>
              </div>
          </li>
          <li class="form__field admin__form__element">
            <label
              for="nameStudent"
              class="form__field-label">Nombre del alumn@
            </label>
            <input
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.nameStudent.$invalid && v$.form.nameStudent.$dirty}"
              type="text"
              id="nameStudent"
              required
              v-model="v$.form.nameStudent.$model"
              @click="removeErrors('nameStudent')">
              <p class="input-errors" v-if="v$.form.nameStudent.$invalid && v$.form.nameStudent.$dirty">
                Por favor, introduce el nombre del alumn@
              </p>
              <div class="errors errors-input" v-if="errors.hasOwnProperty('nameStudent')">
                <p>{{ $t(errors['nameStudent'][0]) }}</p>
              </div>
          </li>
          <li class="form__field admin__form__element">
            <label
              for="year"
              class="form__field-label">Año de nacimiento alumn@ *
            </label>
            <input
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.year.$invalid && v$.form.year.$dirty}"
              type="text"
              id="year"
              required
              v-model="v$.form.year.$model"
              @click="removeErrors('year')">
              <p class="input-errors" v-if="v$.form.year.$invalid && v$.form.year.$dirty">
                Por favor, introduce el año de nacimiento
              </p>
              <div class="errors errors-input" v-if="errors.hasOwnProperty('year')">
                <p>{{ $t(errors['year'][0]) }}</p>
              </div>
          </li>
          <li class="form__field admin__form__element">
            <label
              for="service"
              class="form__field-label">Servicio *
            </label>
            <select
              v-model="v$.form.service.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.service.$invalid && v$.form.service.$dirty}"
              name="service"
              id="service"
              required
              @click="removeErrors('service')"
            >
              <option v-for="service in servicesList" :key="service.id" :value="service.id">{{service.name}}</option>
            </select>
            <div class="errors errors-input" v-if="errors.hasOwnProperty('service')">
              <p>Debes elegir un servicio</p>
            </div>
          </li>
          <li class="form__field admin__form__element">
            <label
              for="text"
              class="form__field-label">Mensaje *
            </label>
            <textarea
              class="form__field-input"
              type="text"
              id="text"
              required
              v-model="v$.form.text.$model"
              @click="removeErrors('text')">
            </textarea>
            <div class="errors errors-input" v-if="errors.hasOwnProperty('text')">
              <p>{{ $t(errors['text'][0]) }}</p>
            </div>
          </li>
        </ul>
        <button
          type="button"
          class="btn btn-save"
          @click="submitForm">Guardar
        </button>
      </form>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { required, numeric, maxLength, minLength } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { apiClient } from '../../services/ApiClient'
import Loading from '../Loading.vue'

export default {
  name: 'CreateLead',
  props: {
    id: {
      type: String,
      dafault: '',
      required: true
    },
  },
  components: {
    Loading,
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      form: {
        nameTutor: '',
        nameStudent: '',
        service: 0,
        text: '',
        email: '',
        emailSecondary: '',
        phone: '',
        phoneSecondary: '',
        year: '',
        status: null,
        message: ''
      },
      errors: {},
      isLoading: false
    }
  },
  validations() {
    return {
      form: {
        nameTutor: {
          required: false
        },
        nameStudent: {
          required: false
        },
        service: {
          required
        },
        text: {
          required
        },
        email: {
          required
        },
        emailSecondary: {
          required: false
        },
        year: {
          required,
          numeric,
          maxLength: maxLength(4),
          minLength: minLength(4)
        },
        phone: {
          required,
          numeric,
          maxLength: maxLength(9),
          minLength: minLength(9)
        },
        phoneSecondary: {
          numeric,
          maxLength: maxLength(9),
          minLength: minLength(9)
        },
      }
    }
  },
  computed: {
    ...mapGetters('Admin', {
      services: 'getServicesList',
      staffMembers: 'getStaffMembers'
    }),
    servicesList() {
      return this.services.map(service => ({
        id: service.id,
        name: service.name
      }))
    }
  },
  methods: {
    ...mapActions('Admin', ['fetchServices']),
    async submitForm() {
      this.isLoading = true
      try {
        await apiClient.post(`/admin/centers/${this.id}/leads`, this.form)
        this.$toast.success('Datos enviados correctamente')
        this.$router.push({ name: 'leads' })
      } catch (errors) {
        const { data } = errors?.response
        this.errors = data.errors
      }
      finally {
        this.isLoading = false
      }
    },
    removeErrors(key) {
      delete this.errors[key]
    }
  },
  async mounted() {
    await this.fetchServices()
  }
}
</script>

<style scoped lang="scss">
.form__field-wrapper {
  @include tablet {
    flex-direction: row;
    .admin__form__element {
      width: 50%;
      &:nth-child(odd) {
        .form__field-input {
          margin-right: 30px;
        }
        .v3dp__datepicker {
          width: calc(100% - 41px);
          margin-right: 30px;
        }
      }
      &:last-child {
        width: 100%;
      }
      textarea {
        resize: none;
        margin: 0 !important;
      }
    }
  }
}
.v3dp__datepicker {
  text-align: left;
  width: calc(100% - 12px);
}
</style>
