<template>
  <div class="main">
    <Loading v-if="isLoading" />
    <div v-else>
      <div class="csv__header">
        <h2 class="subtitle">Cambio de curso escolar:</h2>
      </div>
      <h2 v-if="academyYearChanged">Resumen:</h2>
      <div
        v-if="!academyYearChanged"
        class="form__field-row half payments__filters__dates">
        <div class="form__field-wrapper">
          <div class="form__field form__field--datepicker">
            <label class="form__field-label" for="#price">Fecha inicio</label>
            <datepicker
              v-model="startDate"
              ref="startDatepicker"
              inputFormat="dd/MM/yyyy"
              :style="useInputStyles"
              :locale="dateLocale"
              :clearable="true"
              :upper-limit="endDate"
              @update:model-value="handleDate($event, 'startDate')"
            />
            <p class="description">Indica cuando comienzan las nuevas matrículas y actualiza el comienzo de los grupos afectados.</p>
          </div>
        </div>
        <div class="form__field-wrapper">
          <div class="form__field form__field--datepicker">
            <label class="form__field-label" for="#price">Fecha fin</label>
            <datepicker
              v-model="endDate"
              ref="endDatepicker"
              inputFormat="dd/MM/yyyy"
              :style="useInputStyles"
              :locale="dateLocale"
              :clearable="true"
              :lower-limit="startDate"
              @update:model-value="handleDate($event, 'endDate')"
            />
            <p class="description">Indica la fecha cuando finaliza el grupo.</p>
          </div>
        </div>
      </div>
      <div class="camps-list">
        <template v-if="enrollments.length">
        <table class="admin__table">
          <thead class="admin__table__head">
            <tr>
              <th class="admin__table__head__cell">Nombre alumn@</th>
              <th class="admin__table__head__cell">Precio</th>
              <th class="admin__table__head__cell">Grupo</th>
              <th class="admin__table__head__cell">Próximo curso</th>
            </tr>
          </thead>
          <tbody class="admin__table__body">
            <tr v-for="(enrollment, idx) in enrollments" :key="idx">
              <td class="admin__table__body__cell camps__table__cell cell-name"><span class="admin__table__body__cell__header">Nombre alumn@</span><span class="cell-content">{{enrollment.student.name}} {{enrollment.student.surnames}}</span></td>
              <td class="admin__table__body__cell camps__table__cell"><span class="admin__table__body__cell__header">Precio</span>{{formatPrice(enrollment.priceEnrollment)}}</td>
              <td class="admin__table__body__cell camps__table__cell"><span class="admin__table__body__cell__header">Grupo</span>{{enrollment.group.name}}</td>
              <td class="admin__table__body__cell camps__table__cell"><span class="admin__table__body__cell__header">Próxima matrícula</span> <div class="wrapper-switch"><Switch label="" :checked="true" class="container" @update:checked="handleEnrollment($event, enrollment.id)" /></div> </td>
            </tr>
          </tbody>
        </table>

          <div class="btn-wrapper">
            <button class="btn" @click="newAcademyYearInfo">Enviar</button>
          </div>
        </template>
        <div v-else-if="!enrollments.length && !academyYearChanged" class="message__noData">
          <div class="message__noData-icon">
            <svg width="13" height="36" viewBox="0 0 13 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.880469 -2.38419e-06H12.1805L10.2805 22.25H2.73047L0.880469 -2.38419e-06ZM6.53047 35.45C4.8638 35.45 3.49714 34.95 2.43047 33.95C1.3638 32.9167 0.830469 31.6667 0.830469 30.2C0.830469 28.7 1.3638 27.4667 2.43047 26.5C3.49714 25.5333 4.8638 25.05 6.53047 25.05C8.19714 25.05 9.54714 25.5333 10.5805 26.5C11.6471 27.4667 12.1805 28.7 12.1805 30.2C12.1805 31.6667 11.6471 32.9167 10.5805 33.95C9.5138 34.95 8.1638 35.45 6.53047 35.45Z" fill="white"/>
            </svg>
          </div>
          <div class="message__noData-text">
            <p>No hay matrículas activas de grupos que hayan terminado</p>
          </div>
        </div>
        <table v-else class="admin__table">
          <thead class="admin__table__head">
            <tr>
              <th class="admin__table__head__cell">Matrículas no seleccionadas</th>
              <th class="admin__table__head__cell">Matrículas generadas efectivo/SEPA</th>
              <th class="admin__table__head__cell">Matrículas generadas con tarjetas de crédito</th>
              <th class="admin__table__head__cell">Tut@r sin tarjeta de crédito</th>
            </tr>
          </thead>
          <tbody class="admin__table__body">
            <tr>
              <td class="admin__table__body__cell camps__table__cell cell-name"><span class="admin__table__body__cell__header">Matrículas no seleccionadas</span><span>{{summary.unselectedEnrollments}}</span></td>
              <td class="admin__table__body__cell camps__table__cell"><span class="admin__table__body__cell__header">Matrículas generadas efectivo/SEPA</span><span>{{summary.cashSepaEnrollments}}</span></td>
              <td class="admin__table__body__cell camps__table__cell"><span class="admin__table__body__cell__header">Matrículas generadas con tarjetas de crédito</span><span>{{ summary.creditCardEnrolments }}</span></td>
              <td class="admin__table__body__cell camps__table__cell"><span class="admin__table__body__cell__header">Tut@r sin tarjeta de crédito</span><span>{{summary.tutorWithoutCreditCard}}</span></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vue3-datepicker'
import Loading from '../Loading.vue'
import Switch from '../../components/Switch.vue'
import es from 'date-fns/locale/es'
import { apiClient } from '@/services/ApiClient'
import { formatPrice } from '@/commonMethods/methods'

export default {
  name: 'Camps',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {
    Loading,
    Datepicker,
    Switch
  },
  data() {
    return {
      isLoading: false,
      academyYearChanged: false,
      enrollmentChecked: true,
      // startDate: new Date().getTime() - (24*60*60*1000 * 180),
      startDate: null,
      endDate: null,
      dateLocale: es,
      enrollments: [],
      enrollmentsId: [],
      summary: {
        nextYearEnrollments: null,
        successfulPayments: null,
        failedPayments: null,
        tutorWithoutCreditCard: null
      },
      formatPrice,
      useInputStyles: {
        padding: "12px 20px",
        border: "1px solid #45B8AC",
        borderRadius: "0",
        marginRight: "30px",
        width: "calc(100% - 30px)"
      }
    }
  },
  methods: {
    handleDate(date, type) {
      if (type === 'startDate') {
        this.startDate = date.getTime()
      } else {
        this.endDate = date.getTime()
      }
    },
    handleEnrollment(checked, id) {
      if (checked) {
        this.enrollmentsId.push(id)
      } else {
        this.enrollmentsId = this.enrollmentsId.filter(enrollmentId => enrollmentId !== id)
      }
    },
    async newAcademyYearInfo() {
      this.isLoading = true

      const newInfo = {
        dateStart: this.startDate !== null ? this.startDate / 1000 : null,
        dateEnd: this.endDate !== null ? this.endDate / 1000 : null,
        enrollments: this.enrollmentsId
      }

      try {
        const summary = await apiClient.post(`/admin/centers/${this.id}/enrollments/academic-year-change`, newInfo)
        this.summary = { ...summary.data }

        const enrollments = await apiClient.get(`/admin/centers/${this.id}/enrollments/academic-year-change`)
        this.enrollments = enrollments.data

        this.$toast.success('Cambio de curso realizado correctamente.')

        this.academyYearChanged = true
        this.isLoading = false
      } catch (error) {
        const errors = { error }
        const { response } = errors.error
        const newError = response.data.errors

        if (newError?.dateStart || newError?.dateEnd) {
          this.$toast.error('Asegúrate de tener fechas seleccionadas.')
        } else if (newError?.enrollments) {
          this.$toast.error('Debes seleccionar al menos una matrícula.')
        } else {
          this.$toast.error('Ha ocurido un error, inténtalo de nuevo.')
        }

        this.isLoading = false
      }
    }
  },
  async mounted() {
    this.isLoading = true
    try {
      const { data } = await apiClient.get(`/admin/centers/${this.id}/enrollments/academic-year-change`)
      this.enrollments = data
      data.forEach(data => this.enrollmentsId.push(data.id))
      this.isLoading = false
    } catch (error) {
      this.enrollments = []
      this.isLoading = false
    }
  }
}
</script>


<style scoped lang="scss">

  table > tbody > tr > td {
    cursor: auto;
  }

  .description {
    font-size: 14px;
    margin: 0;
    margin-top: 6px;
    color: #949494;
  }

  .wrapper-switch {
    display: flex;
    justify-content: center;
  }

  .container {
    justify-content: center;
    margin: 0;
    margin-right: 4px;

    @include tablet {
      margin-right: 0;
    }
  }
  .btn-wrapper {
    display: flex;
    flex-direction: column;
    padding: 20px 10px;

    .btn {
      padding: 12px 30px;
    }
  }

</style>
