<template>
  <div class="main">
    <Loading v-if="isLoading" />
    <div v-else>
      <div class="csv__header">
        <h2 class="subtitle">Matrículas con error en el último pago:</h2>
      </div>
      <div class="enrollments__filters">
        <input
          type="text"
            placeholder="Buscar alumno por nombre"
            v-model="student"
            class="enrollments__filters__text">
        <transition name="showFilter">
          <ul v-if="showFilters"
              class="enrollments__filters__container">
            <li class="enrollments__filters__item">
              <input type="checkbox" id="dateEnd" value="dateEnd" v-model="isDateEnd" >
              <label for="dateEnd">Baja</label>
            </li>
            <li class="enrollments__filters__item">
              <input type="checkbox" id="unDateEnd" value="unDateEnd" v-model="isUnDateEnd" >
              <label for="unDateEnd">No baja</label>
            </li>
          </ul>
        </transition>
      </div>
      <div
        class="form__field-row half payments__filters__dates">
        <div class="form__field-wrapper">
          <div class="form__field form__field--datepicker">
            <label class="form__field-label" for="#price">Fecha inicio</label>
            <datepicker
              v-model="dateFilters.startDate"
              ref="startDatepicker"
              inputFormat="dd/MM/yyyy"
              :style="useInputStyles"
              :locale="dateLocale"
              :clearable="true"
              :upper-limit="dateFilters.endDate"
            />
          </div>
        </div>
        <div class="form__field-wrapper">
          <div class="form__field form__field--datepicker">
            <label class="form__field-label" for="#price">Fecha fin</label>
            <datepicker
              v-model="dateFilters.endDate"
              ref="endDatepicker"
              inputFormat="dd/MM/yyyy"
              :style="useInputStyles"
              :locale="dateLocale"
              :clearable="true"
              :lower-limit="dateFilters.startDate"
            />
          </div>
        </div>
      </div>
      <div class="enrollments-list">
        <table class="admin__table" v-if="enrollmentsSearch.length">
          <thead class="admin__table__head">
            <tr>
              <th class="admin__table__head__cell">Alumn@</th>
              <th class="admin__table__head__cell">Curso y nivel</th>
              <th class="admin__table__head__cell">Activa desde</th>
              <th class="admin__table__head__cell">Método de pago</th>
              <th class="admin__table__head__cell">Último pago</th>
              <th class="admin__table__head__cell">Precio</th>
              <th class="admin__table__head__cell">Error</th>
              <th class="admin__table__head__cell"></th>
            </tr>
          </thead>
          <tbody class="admin__table__body">
            <tr v-for="(enrollment, idx) in enrollmentsSearch" :key="idx" class="error-payment">
              <td class="admin__table__body__cell enrollments__table__cell"><span class="admin__table__body__cell__header">Nombre alumn@</span>{{ enrollment.student.name }} {{ enrollment.student.surnames }}</td>
              <td class="admin__table__body__cell enrollments__table__cell"><span class="admin__table__body__cell__header">Curso y nivel</span>{{ enrollment.group.service.name }}</td>
              <td class="admin__table__body__cell enrollments__table__cell"><span class="admin__table__body__cell__header">Activa desde</span>{{ formatDateShort(enrollment.dateStart, false) }}</td>
              <td class="admin__table__body__cell enrollments__table__cell"><span class="admin__table__body__cell__header">Método de pago</span>{{ getPaymentMethod(enrollment.paymentMethod) }}</td>
              <td class="admin__table__body__cell enrollments__table__cell"><span class="admin__table__body__cell__header">Último pago</span>{{formatDateShort(enrollment.lastPayment.orderAt)}} </td>
              <td class="admin__table__body__cell enrollments__table__cell"><span class="admin__table__body__cell__header">Precio</span>{{formatPrice(enrollment.lastPayment.price) }}</td>
              <td class="admin__table__body__cell enrollments__table__cell"><span class="admin__table__body__cell__header">Error</span>{{enrollment.lastPayment.error}}</td>
              <td class="admin__table__body__cell enrollments__table__cell"><span class="admin__table__body__cell__header"></span>
                <button v-if="enrollment.lastPayment.tutor.hasPaymentMethod" class="btn" @click="retryPay(enrollment.lastPayment.id)">Repetir pago</button>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else class="message__noData">
          <div class="message__noData-icon">
            <svg width="13" height="36" viewBox="0 0 13 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.880469 -2.38419e-06H12.1805L10.2805 22.25H2.73047L0.880469 -2.38419e-06ZM6.53047 35.45C4.8638 35.45 3.49714 34.95 2.43047 33.95C1.3638 32.9167 0.830469 31.6667 0.830469 30.2C0.830469 28.7 1.3638 27.4667 2.43047 26.5C3.49714 25.5333 4.8638 25.05 6.53047 25.05C8.19714 25.05 9.54714 25.5333 10.5805 26.5C11.6471 27.4667 12.1805 28.7 12.1805 30.2C12.1805 31.6667 11.6471 32.9167 10.5805 33.95C9.5138 34.95 8.1638 35.45 6.53047 35.45Z" fill="white"/>
            </svg>
          </div>
          <div class="message__noData-text">
            <p>No hay matrículas para mostrar</p>
            <p>Prueba seleccionando otro centro</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from '../../Loading.vue'
import { mapActions } from 'vuex'
import { apiClient } from '@/services/ApiClient'
import {
  formatDateShort,
  formatPrice,
  getEndDateTimestamp,
  getStartDateTimestamp,
  normalizeText
} from '@/commonMethods/methods'
import { typeOfPayments } from '@/commonMethods/constants'
import Datepicker from 'vue3-datepicker'
import es from 'date-fns/locale/es'

const useInputStyles = {
  padding: "12px 20px",
  border: "1px solid #00ADCE",
  borderRadius: "0",
  marginRight: "30px",
  width: "calc(100% - 30px)"
}


export default {
  name: 'Tutors',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {
    Loading,
    Datepicker
  },
  data() {
    return {
      isLoading: false,
      formatDateShort,
      formatPrice,
      student: '',
      errors: {},
      showFilters: false,
      isDateEnd: true,
      isUnDateEnd: true,
      typeOfPayments,
      dateFilters: {
        // startDate: new Date().getTime() - (24*60*60*1000 * 180),
        startDate: null,
        endDate: null
      },
      useInputStyles,
      dateLocale: es,
      enronllmentsPaymentError: []
    }
  },
  computed: {
    enrollmentsSearch() {
      return this.enronllmentsPaymentError.filter(_enrollment => (
          normalizeText(_enrollment.studentName).includes(normalizeText(this.student)) ||
          _enrollment.group.service.name.toUpperCase().includes(this.student.toUpperCase())) &&
          ((_enrollment.dateEnd && this.isDateEnd) ||
          (!_enrollment.dateEnd && this.isUnDateEnd)) &&
          (
            this.dateFilters.startDate
              ? (_enrollment.dateStart * 1000) >= getStartDateTimestamp(this.dateFilters.startDate)
              : true
          ) &&
          (
            this.dateFilters.endDate
              ? (_enrollment.dateStart * 1000) <= getEndDateTimestamp(this.dateFilters.endDate)
              : true
          )
      )
    }
  },
  methods: {
    ...mapActions('Admin', ['fetchEnrollments', 'updateEnrollmentsFinish', 'retryPayment', 'fetchCSVToken']),
    getPaymentMethod(type) {
      return this.typeOfPayments.find(_type => _type.id === type).label
    },
    async retryPay(paymentId) {
      if (confirm('¿Estás seguro de que quieres reintentar el pago?')) {
        this.isLoading = true
        try {
          await this.retryPayment({ centerId: this.$route.params.id, paymentId })
          const { data } = await apiClient.get(`/admin/centers/${this.id}/enrollments/with-payment-error`)
          this.enronllmentsPaymentError = data.map(enrollment => ({
            ...enrollment,
            studentName: `${enrollment.student.name} ${enrollment.student.surnames}`
          }))
          this.$toast.success('Pago realizado correctamente')
        } catch (error) {
          console.log('error', error)
          this.$toast.error('No se ha podido realizar el pago')
        } finally {
          this.isLoading = false
        }
      }
    },
  },
  async mounted() {
    this.isLoading = true
    try {
      const { data } = await apiClient.get(`/admin/centers/${this.id}/enrollments/with-payment-error`)
      this.enronllmentsPaymentError = data.map(enrollment => ({
        ...enrollment,
        studentName: `${enrollment.student.name} ${enrollment.student.surnames}`
      }))
    } catch (error) {
      this.errorLabel = 'Ha ocurrido un error, inténtalo de nuevo'
    }
    finally {
      this.isLoading = false
    }
  }
}
</script>


<style scoped lang="scss">
  .enrollments {
    &__filters {
      align-items: center;
      display: flex;
      margin-bottom: 16px;
      position: relative;
      &__text {
        border: 2px solid $newPink;
        border-radius: 16px;
        flex-grow: 1;
        font-size: 12px;
        margin-right: 16px;
        max-width: 400px;
        padding: 8px;

        @include desktop {
          margin-bottom: 0;
          width: 40%;
        }
      }
    }
  }
  table {
    tr {
      td {
        cursor: auto;
      }
    }
  }
  .error-payment {
    background-color: lighten(desaturate($newSalmon, 10), 15);
  }
</style>
