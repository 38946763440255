<template>
  <div class="main">
    <form action class="form" @submit.prevent="sendFormLeads">

      <div class="form__field-row half">
        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.nameTutor.$invalid && v$.form.nameTutor.$dirty">
              Por favor, introduce un nombre
            </p>
            <label class="form__field-label" for="#nameTutor">Nombre tutor legal *</label>
            <input
              v-model="v$.form.nameTutor.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.nameTutor.$invalid && v$.form.nameTutor.$dirty}"
              type="text"
              id="nameTutor"
              required
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('nameTutor')">
            <p v-for="(error, key) in errors.nameTutor" :key="key">{{ $t(error) }}</p>
          </div>
        </div>

        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.surnameTutor.$invalid && v$.form.surnameTutor.$dirty">
              Por favor, introduce los apellidos
            </p>
            <label class="form__field-label" for="#surnameTutor">Apellidos tutor legal *</label>
            <input
              v-model="v$.form.surnameTutor.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.surnameTutor.$invalid && v$.form.surnameTutor.$dirty}"
              type="text"
              id="surnameTutor"
              required
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('surnameTutor')">
            <p v-for="(error, key) in errors.surnameTutor" :key="key">{{ $t(error) }}</p>
          </div>
        </div>
      </div>

      <div class="form__field-row half">

        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.phone.$invalid && v$.form.phone.$dirty">
              Por favor, introduce un teléfono válido
            </p>
            <label class="form__field-label" for="#phone">Móvil *</label>
            <input
              v-model="v$.form.phone.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.phone.$invalid && v$.form.phone.$dirty}"
              type="number"
              id="phone"
              required
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('phone')">
            <p v-for="(error, key) in errors.phone" :key="key">{{ $t(error) }}</p>
          </div>
        </div>

        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.phoneSecondary.$invalid && v$.form.phoneSecondary.$dirty">
              Por favor, introduce un teléfono válido
            </p>
            <label class="form__field-label" for="#phoneSecondary">Segundo teléfono</label>
            <input
              v-model="v$.form.phoneSecondary.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.phoneSecondary.$invalid && v$.form.phoneSecondary.$dirty}"
              type="number"
              id="phoneSecondary"
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('phoneSecondary')">
            <p v-for="(error, key) in errors.phoneSecondary" :key="key">{{ $t(error) }}</p>
          </div>
        </div>

      </div>

      <div class="form__field-row half">
        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.email.$invalid && v$.form.email.$dirty">
              Por favor, introduzca un email válido
            </p>
            <label class="form__field-label" for="#email">Email *</label>
            <input
              v-model="v$.form.email.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.email.$invalid && v$.form.email.$dirty}"
              type="email"
              id="email"
              required
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('email')">
            <p v-for="(error, key) in errors.email" :key="key">{{ $t(error) }}</p>
          </div>
        </div>

        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.emailSecondary.$invalid && v$.form.emailSecondary.$dirty">
              Por favor, introduzca un email válido
            </p>
            <label class="form__field-label" for="#emailSecondary">Segundo email</label>
            <input
              v-model="v$.form.emailSecondary.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.emailSecondary.$invalid && v$.form.emailSecondary.$dirty}"
              type="email"
              id="emailSecondary"
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('emailSecondary')">
            <p v-for="(error, key) in errors.emailSecondary" :key="key">{{ $t(error) }}</p>
          </div>
        </div>
      </div>

      <div class="form__field-row half">

        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.nameStudent.$invalid && v$.form.nameStudent.$dirty">
              Por favor, introduce un nombre de alumn@
            </p>
            <label class="form__field-label" for="#nameStudent">Nombre del alumn@ *</label>
            <input
              v-model="v$.form.nameStudent.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.nameStudent.$invalid && v$.form.nameStudent.$dirty}"
              type="text"
              id="nameStudent"
              required
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('nameStudent')">
            <p v-for="(error, key) in errors.nameStudent" :key="key">{{ $t(error) }}</p>
          </div>
        </div>

        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.year.$invalid && v$.form.year.$dirty">
              Año no válido. Longitud del campo 4 números
            </p>
            <label class="form__field-label" for="#year">Año de nacimiento alumn@ *</label>
            <input
              v-model="v$.form.year.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.year.$invalid && v$.form.year.$dirty}"
              type="number"
              id="year"
              required
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('year')">
            <p v-for="(error, key) in errors.year" :key="key">{{ $t(error) }}</p>
          </div>
        </div>

      </div>

      <div class="form__field-row half">

        <div class="form__field-wrapper">
          <div class="form__field">
            <label class="form__field-label" for="#service">Interesado en: *</label>
            <select
              v-model="v$.form.service.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.service.$invalid && v$.form.service.$dirty}"
              name="service"
              id="service"
              required
            >
              <option v-for="(service,idx) in formServices" :key="idx" :value="service.value">{{service.label}}</option>
            </select>
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('service')">
            <p v-for="(error, key) in errors.service" :key="key">{{ $t(error) }}</p>
          </div>
        </div>

        <div class="form__field-wrapper">
          <div class="form__field">
            <label class="form__field-label" for="#center">Casita de Inglés de... *</label>
            <select
              v-model="v$.form.center.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.center.$invalid && v$.form.center.$dirty}"
              id="center"
              required
            >
              <option v-for="(center,idx) in formCenters" :key="idx" :value="center.value">{{center.label}}</option>
            </select>

          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('center')">
            <p v-for="(error, key) in errors.center" :key="key">{{ $t(error) }}</p>
          </div>
        </div>

      </div>

      <div class="form__field-row">

        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.text.$invalid && v$.form.text.$dirty">
              Por favor, introduce un mensaje
            </p>
            <label class="form__field-label" for="#text">Mensaje *</label>
            <textarea
              v-model="v$.form.text.$model"
              :class="{'form__input--invalid': v$.form.text.$invalid && v$.form.text.$dirty}"
              class="form__field-input"
              required
            >
            </textarea>
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('text')">
            <p v-for="(error, key) in errors.text" :key="key">{{ $t(error) }}</p>
          </div>
        </div>

      </div>

      <button class="btn">Enviar</button>
    </form>
    <div class="errors" v-for="(error, key) in errors" :key="key">
      <p>{{ $t(error) }}</p>
    </div>

  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, numeric, maxLength, minLength, email } from '@vuelidate/validators'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'FormLeads',
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      form: {
        nameTutor: '',
        surnameTutor: '',
        nameStudent:'',
        phone: '',
        phoneSecondary: '',
        email: '',
        emailSecondary: '',
        year: '',
        text: '',
        service: '',
        center: ''
      },
      errors: {},
    }
  },
  validations() {
    return {
      form: {
        nameTutor: {
          required
        },
        surnameTutor: { 
          required
        },
        nameStudent: { 
          required
        },
        phone: { 
          required,
          numeric
        },
        phoneSecondary: { 
          numeric
        },
        email: {
          required,
          email
        },
        emailSecondary: {
          email
        },
        year: {
          required,
          numeric,
          maxLength: maxLength(4),
          minLength: minLength(4)
        },
        text: {
          required
        },
        service: {
          required
        },
        center: {
          required
        }
      }
    }
  },
  computed: {
    ...mapGetters('Admin', {
      centers: 'getCentersList',
      services: 'getServicesList'
    }),
    formCenters() {
      return this.centers.map(center => ({
        value: center.id,
        label: center.name
      }))
    },
    formServices() {
      return this.services.map(service => ({
        value: service.id,
        label: service.name
      }))
    }
  },
  methods: {
    ...mapActions('Admin', ['fetchCenters', 'fetchServices', 'sendForm']),
    sendFormLeads() {
      if(!this.v$.$invalid) {
        this.sendForm(this.form)
        .then(() => {
          this.$toast.success('Formulario enviado correctamente');
        }) 
        .catch (errors => {
          this.errors = errors
        }) 
      }
    },
    removeErrors() {
      this.errors = ''
    },
  },
  async mounted() {
    await this.fetchCenters()
    await this.fetchServices()
  }
  
}
</script>

<style scoped lang="scss">
.main {
  max-width: 1400px;
  padding: 20px;
  background-color: white;
  .form {
    margin-top: 30px;
    .form__field-row {
      width: 90%;
      margin: 0 auto;
    }
    .btn {
      margin: 30px auto;
      display: block;
    }
  }
  @include tablet {
    .form {
      margin-top: 50px;
      .form__field-row {
        width: 80%;
        &.half {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .form__field-wrapper {
            display: inline-block;
            vertical-align: top;
            width: calc(50% - 20px);
            &:nth-child(2n-1) {
              margin-right: 10px;
            }
            &:nth-child(2n) {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}

</style>
