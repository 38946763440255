<template>
  <div class="main">
    <Loading v-if="isLoading" />
    <div v-else>
      <div class="csv__header">
        <h2 class="subtitle">Profesores:</h2>
        <button class="new-element compact" @click="signUpStaffMember">
          <svg viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M30.7758 19.504H19.6398V30.384H11.3838V19.504H0.31175V11.696H11.3838V0.815998H19.6398V11.696H30.7758V19.504Z"
                fill="#00ADCE"/>
          </svg>
          Añadir nuevo profesor/a
        </button>
      </div>
      <table v-if="teachers.length" class="admin__table">
        <thead class="admin__table__head">
        <tr>
          <th v-for="(column, idx) in tableConfig"
              :key="`columnHeader--${idx}`"
              class="admin__table__head__cell"
          >{{column.label}}</th>
        </tr>
        </thead>
        <tbody class="admin__table__body">
        <tr
            v-for="(element, idx) in teachers"
            :key="`payment--${idx}`">
          <td v-for="(column, idx) in tableConfig" :key="`columnHeader--${idx}`"
              class="admin__table__body__cell teachers__table__cell"
              @click="showTeacherDetail(element.id)">
            <ul
              v-if="column.isMultiple"
              class="teachers__table__sublist">
              <li
                v-for="(item, idx2) in element[column.key]"
                :key="`multipleCell-${idx}-${idx2}`"
                class="teachers__table__subelement"
                ><span
                  class="admin__table__body__cell__header"
                  :class="{'teachers__table--disabled': idx2 > 0 }">
                  {{ column.label }}
                </span>
                <span class="teachers__table__info addresses">{{item}}</span>
              </li>
            </ul>
            <template v-else>
              <span class="admin__table__body__cell__header">{{ column.label }}</span>
              <template
                  v-if="column.isIcon">
                <img
                    v-if="element[column.key]"
                    src="../../assets/attent.svg" alt="check"
                    class="teachers__table__icon teachers__table__icon--checked">
                <img
                    v-else
                    src="../../assets/missed.svg" alt="check"
                    class="teachers__table__icon teachers__table__icon--unchecked">
              </template>
              <template v-else-if="column.delete">
                <span class="admin__table__body__cell__header"></span>
                <button @click.stop="deleteTeacher(element.id)" class="btn">Borrar</button>
              </template>
              <template v-else>
                {{ element[column.key] }}
              </template>
            </template>
          </td>
        </tr>
        </tbody>
      </table>
      <div v-else class="message__noData">
        <div class="message__noData-icon">
          <svg width="13" height="36" viewBox="0 0 13 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.880469 -2.38419e-06H12.1805L10.2805 22.25H2.73047L0.880469 -2.38419e-06ZM6.53047 35.45C4.8638 35.45 3.49714 34.95 2.43047 33.95C1.3638 32.9167 0.830469 31.6667 0.830469 30.2C0.830469 28.7 1.3638 27.4667 2.43047 26.5C3.49714 25.5333 4.8638 25.05 6.53047 25.05C8.19714 25.05 9.54714 25.5333 10.5805 26.5C11.6471 27.4667 12.1805 28.7 12.1805 30.2C12.1805 31.6667 11.6471 32.9167 10.5805 33.95C9.5138 34.95 8.1638 35.45 6.53047 35.45Z" fill="white"/>
          </svg>
        </div>
        <div class="message__noData-text">
          <p>No hay profesores para mostrar</p>
          <p>Prueba seleccionando otro centro</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Loading from '../Loading.vue'

export default {
  name: 'Teachers',
  props: {
    id: {
      type: String,
      dafault: '',
      required: true
    },
  },
  components: {
    Loading
  },
  data() {
    return {
      tableConfig: [
        {
          label: 'Nombre',
          key: 'name',
        },
        {
          label: 'Apellidos',
          key: 'surnames',
        },
        {
          label: 'Centros',
          key: 'centers',
          isMultiple: true,
        },
        {
          label: 'Supervisor',
          key: 'isSupervisor',
          isIcon: true
        },
        {
          label: '',
          key: '',
          delete: true
        },
      ],
      isLoading: false
    }
  },
  computed: {
    ...mapGetters('Admin', {
      teachers: 'getStaffMembers'
    })
  },
  methods: {
    ...mapActions('Admin', ['fetchStaffMembers', 'deleteStaffMember']),
    showTeacherDetail(teacherId) {
      this.$router.push({ name: 'teacher-detail', params: { id: this.$route.params.id, teacherId } })
    },
    signUpStaffMember() {
      this.$router.push({ name: 'teachers-signup', params: { id: this.$route.params.id } })
    },
    async deleteTeacher(teacherId) {
      if (confirm("¿Estás seguro que quieres borrar este profesor?")) {
        try {
          await this.deleteStaffMember({centerId: this.id, teacherId})
          this.$toast.success('Profesor borrado correctamente.')
        } catch (error) {
          this.$toast.error('Este profesor no se ha podido borrar.')
        }
      }
    }
  },
  async mounted() {
    this.isLoading = true
    await this.fetchStaffMembers(this.id)
    .then(() => {
      this.isLoading = false
    })
    .catch(() => {
      this.isLoading = true
    })
  }
}
</script>
<style lang="scss" scoped>

  .teachers {
    &__table {
      &__cell {
        &:hover {
          cursor: pointer;
        }
      }

      &__sublist {
        align-items: initial;
        display: flex;
        flex-direction: column;
        width: 100%;

        @include desktop {
          align-items: center;
        }
      }

      &__subelement {
        display: flex;
        justify-content: space-between;
        margin: 0;
      }

      &--disabled {
        display: none;
      }

      &__info {
        font-weight: 400;
        text-align: end;
        width: 100%;
      }

      &__icon {
        width: 24px;

        &--checked {
          filter: sepia(1) saturate(25) hue-rotate(47deg) brightness(0.8) contrast(1);
        }

        &--unchecked {
          filter: sepia(1) saturate(25) hue-rotate(290deg) brightness(0.8) contrast(2);
        }
      }
    }

    &__button {
      margin-top: 24px;
    }

    &__message {
      background-color: $newLightBlue;
      border: 1px solid $newBlue;
      border-radius: 16px;
      font-size: 14px;
      margin-top: 32px;
      padding: 12px;
      text-align: center;
    }
  }

  .addresses {
    padding: 0;
    margin: 0;
  }
</style>
