<template>
  <main class="main reports">
    <div class="container-header">
      <div class="title">
        <div class="info">
          <h2>Monitor: <span>{{detailedReport.staffMember.name}} {{detailedReport.staffMember.surnames}}</span></h2>
        </div>
        <div class="info">
          <h2>Alumno: <span>{{detailedReport.student.name}} {{detailedReport.student.surnames}}</span></h2>
        </div>
      </div>
      <div class="explanation">
        <div class="emojis-text">
          <span>Working towards</span>
          <span>Achieved</span>
          <span>Exceeds expectations</span>
        </div>
        <div class="emojis">
          <span>😊</span>
          <span>😊😊</span>
          <span>😊😊😊</span>
        </div>
      </div>
    </div>
    <div class="img-container">
      <img class="level-title" :src="detailedReport.reportLevel.title" alt="legend-image">
    </div>
      <div class="box-container">
        <ol>
          <li v-for="(legends, legendsIndex) in detailedReport.reportLevel.legends" :key="legends.id" class="legends-list">
            <p id="target" @click="goToDescription(legendsIndex)">{{legendLabel(legends.score_title)}}</p>
            <span>{{emojiLabel(legends.score_title, true, true, detailedReport)}}</span>
          </li>
        </ol>
        <div class="icon-container">
          <img :src="detailedReport.reportLevel.image" alt="animal" class="level-icon" />
        </div>
    </div>
    <h4 class="comment-title">Observaciones</h4>
    <div class="comments">
      <span v-for="(comment, i) in commentSplitted" :key="i">
        {{ comment }} <br v-if="!comment.length"/>
      </span>
    </div>
    <div class="legends-wrapper">
      <div v-for="(legends, legendIndex) in detailedReport.reportLevel.legends" :key="legends.id" class="description" :class="{ 'last-child': legendIndex === 4 }">
        <span class="score-title">{{legendLabel(legends.score_title)}}</span>
        <span class="legend-text">
          <p>{{legends.score_content_es}}</p>
          <br>
          <p>{{legends.score_content_en}}</p>
        </span>
      </div>
    </div>
    <div @click="backToList" class="back">
    <span>Volver</span>
    </div>
  </main>
</template>

<script>
import { mapState } from 'vuex'
import { emojiLabel, legendLabel, formatDateShort } from '@/commonMethods/methods'

export default {
  name: 'Reports',
  props: {
    id: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      tableConfig: [
         {
          label: 'Level',
          key: 'level'
        },
        {
          label: 'Valoración',
          key: 'value',
        },
      ],
      arrayOfIndex: [],
      isValueHidden: false,
      currentSection: 0,
      emojiLabel,
      legendLabel,
      formatDateShort,
      commentSplitted: []
    }
  },
  computed: {
    ...mapState('Students', ['detailedReport']),
  },
  methods: {
    backToList() {
      this.$emit('back-to-list', true)
    },
    goToDescription(index) {
      let legendsSection = document.getElementsByClassName('legend-text')
      legendsSection.forEach((legend, i) => {
        if (i === index) {
          window.scrollTo({
            top: legend.offsetTop - 450,
            behavior: 'smooth'
          })
        }
      })
    },
  },
  mounted() {
    this.commentSplitted = this.detailedReport.comment.split('\n')
    this.arrayOfIndex = Array.from(Array(this.detailedReport.reportLevel.legends.length).keys())
    this.tableConfig[0].label = this.detailedReport.reportLevel.title
  }
}
</script>

<style scoped lang="scss">
.reports {
  .header {
    display: flex;
    align-items: center;
    padding: 0;
    .title {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      gap: 0;
      .info {
        display: flex;
        align-items: center;
        gap: 10px;
        h2 {
          color: $black;
          font-size: 20px;
          margin: 10px 0;
        }
      }
    }
  }
  .admin {
    &__table {
      margin-top: 30px;
    }
  }
  .legends-wrapper {
    display: flex;
    flex-direction: column;
    background-color: #f2faf9;
    border-radius: 16px;
    border: 1px solid $newBlue;
    margin-top: 30px;
    width: 100%;
    .description {
      display: flex;
      align-items: center;
      border-bottom: 1px solid $newBlue;
      gap: 30px;
      padding: 0px 30px;
      span:first-child {
        color: $newHighlight;
        margin-right: 36px;
        min-width: 20%;
      }
      .legend-text {
        border-left: 1px solid  $newBlue;
        font-size: 14px;
        margin: 0;
        padding: 8px 0;
        padding-left: 10px;
        width: 500px;
        p {
          margin: 0;
        }
        p:last-child {
          font-style: italic;
        }
      }
    }
    .last-child {
      border-bottom: 0;
    }
  }
  .comment-title {
    color: $newHighlight;
    padding: 10px 0;
    padding-bottom: 0;
    margin-bottom: 10px;
  }
  .comments {
    background-color: #f2faf9;
    border: 1px solid $newBlue;
    border-radius: 16px;
    cursor: text;
    font-size: 14px;
    font-family: $font__main;
    padding: 10px;
    resize: none;
    width: 98%;
  }
  &__search {
    border: 2px solid $newPink;
    border-radius: 16px;
    flex-grow: 1;
    font-size: 12px;
    margin-right: 16px;
    max-width: 500px;
    padding: 8px;

    @include desktop {
      margin-bottom: 32px;
      width: 45%;
    }
  }
  .img-container {
    display: flex;
    justify-content: center;
    .level-title {
      margin-top: 30px;
      width: 200px;
    }
  }
  .box-container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    padding: 0;
    margin: 0;
    margin-top: 20px;
    ol {
      border: 1px solid  $newHighlight;
      border-radius: 16px;
      margin: 0;
      padding: 0;
      width: 100%;
    }
    .legends-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $newHighlight;
      padding: 0 6px;
      margin: 0;
      max-width: 97%;
      p {
        cursor: pointer;
        padding-left: 6px;
      }
      span {
        border-left: 1px solid  $newHighlight;
        padding: 15px;
        text-align: center;
        width: 50px;
      }
    }
    li:last-child {
      border: 0;
    }
    .level-icon {
      margin-left: -40px;
      width: 200px;
      height: 200px;
    }
  }
  .back {
    color: #db4a78;
    cursor: pointer;
    font-family: "Londrina Solid", Arial, Helvetica, "Nimbus Sans L", sans-serif;
    font-size: 28px;
    text-decoration: underline;
    text-align: center;
    margin-top: 30px;
  }
   @media screen and (min-width: 1250px) {
    .img-container {
      display: block;
      .level-title {
        margin-top: 30px;
        width: 200px;
      }
    }
    .box-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      ol {
        width: 500px;
      }
      .legend-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 8px;
      }
      .icon-container {
        display: flex;
        justify-content: center;
        width: 50%;
      }
      .level-icon {
        margin-left: -8 0px;
        width: 200px;
        height: 200px;
      }
    }
  }
}
</style>
