import { createWebHistory, createRouter } from "vue-router";
import Parents from "@/views/Parents.vue";
import RegisterLayout from "@/views/RegisterLayout.vue";
import FormLeads from "@/views/FormLeads.vue";
import FormLeadsIframe from "@/views/FormLeadsIframe.vue";
import Login from "@/components/Login.vue";
import Register from "@/components/Register.vue";
import RegisterConfirmation from "@/components/RegisterConfirmation.vue";
import RecoverPassword from "@/components/RecoverPassword.vue";
import RecoverPasswordCode from "@/components/RecoverPasswordCode.vue";
import ActivateAccountByEmail from "@/components/ActivateAccountByEmail.vue";
import ParentMainScreen from "@/components/ParentMainScreen.vue";
import PaymentHistory from "@/components/PaymentHistory.vue";
import EditProfile from "@/components/EditProfile.vue";
import PaymentMethodsByTutor from "@/components/PaymentMethodsByTutor.vue";
import ContactUs from "@/components/ContactUs.vue";
import Enroll from "@/components/Enroll.vue";
import EnrollmentConfirmation from "@/components/EnrollmentConfirmation.vue";
import EnrollmentConfirmationRetry from "@/components/EnrollmentConfirmationRetry.vue";
import EnrollmentConfirmationOK from "@/components/EnrollmentConfirmationOK.vue";
import RecoverClass from "@/components/RecoverClass.vue";
import ReservedClasses from "@/components/ReservedClasses.vue";
import RecoverClassSession from "@/components/RecoverClassSession.vue";
import StudentData from "@/components/StudentData.vue";
import StudentReport from "@/components/StudentReport.vue";
import NewStudent from "@/components/NewStudent.vue";
import NewTutor from "@/components/NewTutor.vue";
import LegalTutors from "@/components/LegalTutors.vue";
import NewGroup from "@/components/NewGroup.vue";
import NewGroupByCenter from "@/components/NewGroupByCenter.vue";
import Thanks from "@/components/Thanks.vue";
import TutorCamps from "@/components/TutorCamps.vue";
import NewCamp from "@/components/NewCamp.vue";
import NewCampByCenter from "@/components/NewCampByCenter.vue";
import NewCampByStudent from "@/components/NewCampByStudent.vue";
import NewCampOrderSummary from "@/components/NewCampOrderSummary.vue";
import NewCampConfirmationOK from "@/components/NewCampConfirmationOK.vue";
import Admin from "@/views/Admin.vue";
import CentersList from "@/components/Admin/CentersList.vue";
import Groups from "@/components/Admin/Groups.vue";
import GroupCreate from "@/components/Admin/GroupCreate.vue";
import GroupDetail from "@/components/Admin/GroupDetail";
import Sessions from "@/components/Admin/Sessions";
import Centers from "@/components/Admin/Centers.vue";
import Services from "@/components/Admin/Services.vue";
import Allergies from "@/components/Admin/Allergies.vue";
import Holidays from "@/components/Admin/Holidays.vue";
import StudentsWithoutCenter from '@/components/Admin/StudentsWithoutCenter'
import StudentWithoutCenterDetail from '@/components/Admin/StudentWithoutCenterDetail'
import AllergyDetail from "@/components/Admin/AllergyDetail";
import AllergyCreate from "@/components/Admin/AllergyCreate";
import HolidayForm from "@/components/Admin/HolidayForm";
import Students from "@/components/Admin/Students.vue";
import StudentCreate from "@/components/Admin/StudentCreate.vue";
import StudentDetail from "@/components/Admin/StudentDetail.vue";
import Payments from "@/components/Admin/Payments.vue";
import PaymentDetail from "@/components/Admin/PaymentDetail.vue";
import PaymentExtra from "@/components/Admin/PaymentExtra.vue";
import Teachers from "@/components/Admin/Teachers.vue";
import TeacherDetail from "@/components/Admin/TeacherDetail";
import TeachersSignUp from "@/components/Admin/TeachersSignUp.vue";
import Leads from "@/components/Admin/Leads.vue";
import CreateLead from "@/components/Admin/CreateLead.vue";
import LeadDetail from "@/components/Admin/LeadDetail.vue";
import CenterCreate from "@/components/Admin/CenterCreate.vue";
import CenterDetail from "@/components/Admin/CenterDetail.vue";
import ServiceCreate from "@/components/Admin/ServiceCreate.vue";
import ServiceDetail from "@/components/Admin/ServiceDetail.vue";
import StudentDetailRecoverClass from "@/components/Admin/StudentDetailRecoverClass.vue";
import Tutors from "@/components/Admin/Tutors/Tutors.vue";
import TutorDetail from "@/components/Admin/Tutors/TutorDetail.vue";
import TutorCreate from "@/components/Admin/Tutors/TutorCreate.vue";
import Enrollments from "@/components/Admin/Enrollments/Enrollments.vue";
import EnrollmentsPaymentError from "@/components/Admin/Enrollments/EnrollmentsPaymentError.vue";
import EnrollmentCreate from "@/components/Admin/Enrollments/EnrollmentCreate.vue";
import EnrollmentDetail from "@/components/Admin/Enrollments/EnrollmentDetail.vue";
import EnrollmentChangeGroup from '@/components/Admin/Enrollments/EnrollmentChangeGroup'
import PaymentError from "@/components/PaymentError.vue";
import Camps from "@/components/Admin/Camps/Camps.vue";
import CampCreate from "@/components/Admin/Camps/CampCreate.vue";
import CampDetail from "@/components/Admin/Camps/CampDetail.vue";
import CampsStudentsList from "@/components/Admin/Camps/CampsStudentsList.vue";
import SavePaymentOk from '@/components/SavePaymentOk.vue'
import PaymentMethods from "@/components/Admin/PaymentMethods";
import Reports from '@/components/Admin/Reports'
import AcademicYearChange from '@/components/Admin/AcademicYearChange'
import ReportCreate from '@/components/Admin/ReportCreate'
import Yearly from "@/components/Yearly.vue";

const routes = [
  {
    path: "/registro",
    name: "register",
    component: RegisterLayout,
    children: [
      {
        path: '',
        name: 'register-main',
        component: Register,
      },
      {
        path: "confirmacion",
        name: "register-confirmation",
        component: RegisterConfirmation,
      },
      {
        path: "/",
        name: "login",
        component: Login,
      },
      {
        path: "/recuperar",
        name: "recover",
        component: RecoverPassword,
      },
      {
        path: "/recuperar/confirmacion",
        name: "recover/confirmation",
        component: RecoverPasswordCode,
      },
      {
        path: "/activar-cuenta/confirmacion-email",
        name: "activate-account-email",
        props: true,
        component: ActivateAccountByEmail,
      },
      {
        path: "/gracias",
        name: "thanks",
        component: Thanks
      },
      {
        path: ":centerId/matriculas/:studentId/confirmacion/:groupId",
        name: "enrollment-confirmation",
        props: true,
        component: EnrollmentConfirmation,
      },
      {
        path: "matriculas/pago/:paymentId/reintento",
        name: "enrollment-confirmation-retry",
        props: true,
        component: EnrollmentConfirmationRetry,
      },
      {
        path: "/matriculas/pago-exitoso/:id",
        name: "enrollment-confirmation-ok",
        props: true,
        component: EnrollmentConfirmationOK,
      },
      {
        path: "/matriculas/pago-erroneo",
        name: "payment-error",
        props: true,
        component: PaymentError,
      },
      {
        path: "/nuevo-campamento/:idCenter/pago/:campId",
        name: "new-camp-order-summary",
        props: true,
        component: NewCampOrderSummary,
      },
      {
        path: "/nuevo-campamento/pago-exitoso/:id",
        name: "new-camp-confirmation-ok",
        props: true,
        component: NewCampConfirmationOK,
      },
      {
        path: "/metodo-pago/exitoso",
        name: "save-payment-ok",
        props: true,
        component: SavePaymentOk,
      },
    ]
  },
  {
    path: "/padres",
    name: "parents",
    component: Parents,
    children: [
      {
        path: '',
        name: 'parents-main-screen',
        component: ParentMainScreen
      },
      {
        path: "matriculas/:id",
        name: "enroll",
        props: true,
        component: Enroll,
      },
      {
        path: ":id/nuevo-grupo",
        name: "new-group",
        props: true,
        component: NewGroup,
      },
      {
        path: ":id/nuevo-grupo-horario/:idCenter",
        name: "new-group-center",
        props: true,
        component: NewGroupByCenter,
      },
      {
        path: "recuperacion-clases/:id",
        name: "recover-class",
        props: true,
        component: RecoverClass,
      },
      {
        path: "clases-reservadas/:id",
        name: "reserved-classes",
        props: true,
        component: ReservedClasses,
      },
      {
        path: ":id/recuperacion-clases/:idMissedSession",
        name: "recover-class-session",
        props: true,
        component: RecoverClassSession,
      },
      {
        path: "datos-alumno/:id",
        name: "student-data",
        props: true,
        component: StudentData,
      },
      {
        path: "reportes-alumno/:id",
        name: "student-report",
        props: true,
        component: StudentReport,
      },
      {
        path: "nuevo-alumno",
        name: "new-student",
        component: NewStudent,
      },
      {
        path: "nuevo-tutor",
        name: "new-tutor",
        component: NewTutor,
      },
      {
        path: 'tutores-legales',
        name: 'legal-tutors',
        component: LegalTutors
      },
      {
        path: 'historial-pagos',
        name: 'payment-history',
        component: PaymentHistory
      },
      {
        path: 'certificados-anuales',
        name: 'yearly',
        component: Yearly
      },
      {
        path: 'editar-perfil',
        name: 'edit-profile',
        component: EditProfile
      },
      {
        path: 'contacto',
        name: 'contact',
        component: ContactUs
      },
      {
        path: "campamentos/",
        name: "tutor-camps",
        component: TutorCamps,
      },
      {
        path: "nuevo-campamento",
        name: "new-camp",
        component: NewCamp,
      },
      {
        path: "nuevo-campamento/:idCenter",
        name: "new-camp-center",
        props: true,
        component: NewCampByCenter,
      },
      {
        path: "nuevo-campamento/:idCenter/estudiante/:campId",
        name: "new-camp-student",
        props: true,
        component: NewCampByStudent,
      },
      {
        path: "metodos-de-pago",
        name: "paymentMethodsByUser",
        props: true,
        component: PaymentMethodsByTutor,
      }
    ]
  },
  {
    path: "/administrador",
    name: "admin",
    component: Admin,
    children: [
      {
        path: '',
        name: 'admin-main-screen',
        component: CentersList
      },
      {
        path: ':id/grupos',
        name: 'groups',
        props: true,
        component: Groups
      },
      {
        path: ':id/grupos/crear',
        name: 'group-create',
        props: true,
        component: GroupCreate
      },
      {
        path: ':id/grupos/:groupId',
        name: 'group-detail',
        props: true,
        component: GroupDetail
      },
      {
        path: ':id/grupos/centros',
        name: 'sessions',
        props: true,
        component: Sessions
      },
      {
        path: ':id/centro/alumnos/',
        name: 'students',
        props: true,
        component: Students
      },
      {
        path: ':id/centro/alumnos/crear',
        name: 'student-create',
        props: true,
        component: StudentCreate
      },
      {
        path: ':id/centro/alumnos/:studentId',
        name: 'student-detail',
        props: true,
        component: StudentDetail
      },
      {
        path: ':id/centro/alumnos/:studentId/recuperacion-clases/:missedSessionId',
        name: 'student-detail-recover-class',
        props: true,
        component: StudentDetailRecoverClass
      },
      {
        path: ':id/centro/historial-pagos',
        name: 'payments',
        props: true,
        component: Payments
      },
      {
        path: ':id/centro/historial-pagos/:paymentId',
        name: 'payment-detail',
        component: PaymentDetail
      },
      {
        path: ':id/centro/pago-manual',
        name: 'payment-extra',
        component: PaymentExtra
      },
      {
        path: ':id/centro/profesores',
        name: 'teachers',
        props: true,
        component: Teachers
      },
      {
        path: ':id/centro/profesores/:teacherId',
        name: 'teacher-detail',
        component: TeacherDetail
      },
      {
        path: ':id/centro/profesores/registro',
        name: 'teachers-signup',
        props: true,
        component: TeachersSignUp
      },
      {
        path: ':id/centro/leads',
        name: 'leads',
        props: true,
        component: Leads,
      },
      {
        path: ':id/centro/leads/:leadId',
        name: 'lead-detail',
        props: true,
        component: LeadDetail
      },
      {
        path: ':id/centro/leads/crear',
        name: 'create-lead',
        props: true,
        component: CreateLead
      },
      {
        path: 'centros',
        name: 'centers',
        component: Centers
      },
      {
        path: ':id/centro/editar',
        name: 'center-edit',
        props: true,
        component: CenterDetail
      },
      {
        path: 'centros/crear',
        name: 'new-center',
        component: CenterCreate
      },
      {
        path: 'servicios',
        name: 'services-edit',
        component: Services
      },
      {
        path: 'servicios/crear',
        name: 'new-service',
        component: ServiceCreate
      },
      {
        path: 'servicios/editar/:serviceId',
        name: 'service-detail',
        props: true,
        component: ServiceDetail
      },
      {
        path: 'alergias',
        name: 'allergies',
        component: Allergies
      },
      {
        path: 'alergias/editar/:allergyId',
        name: 'allergy-detail',
        component: AllergyDetail
      },
      {
        path: 'alergias/crear',
        name: 'allergy-create',
        component: AllergyCreate
      },
      {
        path: 'festivos',
        name: 'holidays',
        component: Holidays
      },
      {
        path: 'festivos/crear',
        name: 'create-holiday',
        component: HolidayForm
      },
      {
        path: ':id/centro/tutores',
        name: 'tutors',
        props: true,
        component: Tutors
      },
      {
        path: ':id/centro/tutores/:tutorId',
        name: 'tutor-detail',
        props: true,
        component: TutorDetail
      },
      {
        path: ':id/centro/tutores/crear',
        name: 'tutor-create',
        props: true,
        component: TutorCreate
      },
      {
        path: ':id/centro/matriculas',
        name: 'enrollments',
        props: true,
        component: Enrollments
      },
      {
        path: ':id/centro/matriculas/crear',
        name: 'enrollment-create',
        props: true,
        component: EnrollmentCreate
      },
      {
        path: ':id/centro/matriculas/:enrollmentId',
        name: 'enrollment-detail',
        props: true,
        component: EnrollmentDetail
      },
      {
        path: ':id/centro/matriculas/:enrollmentId/cambio-grupo/:studentId',
        name: 'enrollment-change-group',
        component: EnrollmentChangeGroup
      },
      {
        path: ':id/centro/matriculas-error-pago',
        name: 'enrollments-payment-error',
        props: true,
        component: EnrollmentsPaymentError
      },
      {
        path: ':id/centro/campamentos',
        name: 'camps',
        props: true,
        component: Camps
      },
      {
        path: ':id/centro/campamentos/crear',
        name: 'camp-create',
        props: true,
        component: CampCreate
      },
      {
        path: ':id/centro/campamentos/:campId',
        name: 'camp-detail',
        props: true,
        component: CampDetail
      },
      {
        path: ':id/centro/campamento-alumnos/:campId',
        name: 'camps-students',
        props: true,
        component: CampsStudentsList
      },
      {
        path: ':id/centro/metodos-pago',
        name: 'payment-methods',
        props: true,
        component: PaymentMethods
      },
      {
        path: ':id/centro/informes',
        name: 'reports',
        component: Reports
      },
      {
        path: ':id/centro/informes/crear',
        name: 'reports-create',
        component: ReportCreate,
        props: true
      },
      {
        path: ':id/centro/cambio-curso',
        name: 'academic-year-change',
        component: AcademicYearChange,
        props: true
      },
      {
        path: 'estudientes/sin-centro',
        name: 'students-without-center',
        component: StudentsWithoutCenter
      },
      {
        path: 'estudientes/sin-centro/:studentId',
        name: 'student-without-center-detail',
        component: StudentWithoutCenterDetail
      },
    ]
  },
  {
    path: '/formulario',
    name: 'formLeads',
    component: FormLeads,
  },
  {
    path: '/embed',
    name: 'formLeadsIframe',
    component: FormLeadsIframe,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }

    return {
      top: 0,
      behavior: 'smooth'
    }
  }
});

export default router;
