import { apiClient } from '@/services/ApiClient'

const state = {
  user: {
  },
  paymentCheck: {},
  paymentList: [],
  yearlyPaymentList: [],
  activeStudentId: null,
  paymentPrice: null,
  activePayment: null,
  changingPaymentMethodModal: false,
  paymentMethodSelected: null
}

const actions = {
  async updateUserInfo({ commit }, newUserInfo) {
    await apiClient.patch('/tutor', newUserInfo)
    .then(() => {
      commit('UPDATE_USER', newUserInfo)
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },
  async fetchProfile({ commit }) {
    return new Promise((resolve, reject) => {
      apiClient.get('/tutor')
      .then(response => {
        commit('SET_USER_INFO', response.data)
        commit('Students/INITIAL_STUDENT_INFO', response.data.students, {root: true})
        resolve(response.data)
      })
      .catch(err => {
        apiClient.refreshToken()
        reject(err.response.data.errors)
      })
    })
  },
  async activateAccountByEmail({ commit }, infoUserAccount) {
    return new Promise((resolve, reject) => {
      apiClient.post('/signup/confirmation-admin', infoUserAccount)
      .then(response => {
        commit('Auth/DO_LOGIN', response.data, {root: true})
        resolve(response.data)
      })
      .catch(err => {
        console.log('error en store', err);
        reject(err.response.data.errors)
      })
    })
  },
  async tutorSendContactEmail(commit, infoContact) {
    await apiClient.post('/tutor/send-contact-email', infoContact)
    .then(() => {
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },
  async paymentCheck({ commit } , paymentsId) {
    try {
      const { data } = await apiClient.get(`/tutor/payments/${paymentsId}/check`)
      commit('SET_PAYMENT_INFO', data)
    } catch (err) {
      await apiClient.refreshToken()
      throw err.response.data.errors
    }
  },
  async tutorPaymentList({ commit }) {
    try {
      const { data } = await apiClient.get('/tutor/payments')
      commit('SET_PAYMENT_LIST', data)
    } catch (err) {
      throw err.response.data.errors
    }
  },
  async tutorYearlyPaymentList({ commit }, year) {
    try {
      const { data } = await apiClient.get(`/tutor/payments/${year}`)
      commit('SET_YEARLY_PAYMENT_LIST', data)
    } catch (err) {
      throw err.response.data.errors
    }
  },
  async tutorPaymentRetry(commit, {paymentId, infoPayment}) {
    try {
      const { data } = await apiClient.post(`/tutor/payments/${paymentId}/retry`, infoPayment)
      return data
    } catch (err) {
      throw err.response.data.errors
    }
  },
  async handlerPaymentMethod({ commit }, obj) {
    commit('HANDLER_PAYMENT_METHOD', obj.value)
    commit('SET_PAYMENT_METHOD', obj.payment)
  }
}

const mutations = {
  UPDATE_USER (state, userInfo) {
    const userObject = Object.keys(userInfo)
    userObject.forEach(item => {
      state.user[item] = userInfo[item]
    })
  },
  SET_USER_INFO(state, info) {
    state.user = info
  },
  SET_PAYMENT_INFO(state, checkInfo) {
    state.paymentCheck = checkInfo
  },
  SET_PAYMENT_LIST(state, paymentList) {
    state.paymentList = paymentList
  },
  SET_YEARLY_PAYMENT_LIST(state, paymentList) {
    state.yearlyPaymentList = paymentList
  },
  SET_PAYMENT_PRICE_RETRY(state, price) {
    state.paymentPrice = price
  },
  SET_ACTIVE_PAYMENT(state, paymentInfo) {
    state.activePayment = paymentInfo
  },
  HANDLER_PAYMENT_METHOD(state, value) {
    state.changingPaymentMethodModal = value
  },
  SET_PAYMENT_METHOD(state, payment) {
    state.paymentMethodSelected = payment
  }
}

const getters = {
  getUser(state) {
    return state.user
  },
  getPaymentList: (state) => state.paymentList,
  getYearlyPaymentList: (state) => state.yearlyPaymentList,
  getActiveStudent: (state) => (studentId) => {
    return state.user.students.find(student => student.id === studentId)
  },
  getPaymentCheckInfo: (state) => state.paymentCheck,
  getPaymentPrice: (state) => state.paymentPrice,
  getActivePayment: (state) => state.activePayment,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
