<template>
  <div class="main leads">
    <Loading v-if="isLoading" />
    <div v-else>
      <div class="csv__header">
        <h2 class="subtitle flex-grow">Leads:</h2>
        <button class="new-element compact" v-if="userInfo.isSuperAdmin || userInfo.isSupervisor">
          <svg viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.7758 19.504H19.6398V30.384H11.3838V19.504H0.31175V11.696H11.3838V0.815998H19.6398V11.696H30.7758V19.504Z" fill="#00ADCE"/>
          </svg>
          <router-link :to="{ name: 'create-lead' }"></router-link>
          Añadir nuevo lead
        </button>
        <button
          class="btn csv__button"
          type="button"
          @click="downloadCSV">
          <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
            <path d="M22 24h-20v-24h14l6 6v18zm-7-23h-12v22h18v-16h-6v-6zm3 15v1h-12v-1h12zm0-3v1h-12v-1h12zm0-3v1h-12v-1h12zm-2-4h4.586l-4.586-4.586v4.586z"/>
          </svg>
          <span class="csv__button__text">Descargar CSV</span>
        </button>
      </div>
      <div class="form__field-row leads__filters">
        <div class="leads__filters__dates">
          <div class="form__field-wrapper leads__filters__date">
            <div class="form__field form__field--datepicker">
              <label class="form__field-label" for="#price">Fecha inicio</label>
              <datepicker
                v-model="dateFilters.startDate"
                ref="startDatepicker"
                inputFormat="dd/MM/yyyy"
                :style="useInputStyles"
                :locale="dateLocale"
                :clearable="true"
                :upper-limit="dateFilters.endDate"
              />
            </div>
          </div>
          <div class="form__field-wrapper leads__filters__date">
            <div class="form__field form__field--datepicker">
              <label class="form__field-label" for="#price">Fecha fin</label>
              <datepicker
                v-model="dateFilters.endDate"
                ref="endDatepicker"
                inputFormat="dd/MM/yyyy"
                :style="useInputStyles"
                :locale="dateLocale"
                :clearable="true"
                :lower-limit="dateFilters.startDate"
              />
            </div>
          </div>
        </div>
        <button
            class="filters__button btn"
            :class="{
              'filters__button--open': showFilters
            }"
            type="button"
            @click="toogleFilters">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" >
            <path class="filters__button__icon" d="M22.906 2.841c1.104-2.412-7.833-2.841-10.907-2.841-2.934 0-12.01.429-10.906 2.841.508 1.11 8.907 12.916 8.907 12.916v5.246l4 2.997v-8.243s8.398-11.806 8.906-12.916zm-10.901-.902c4.243 0 8.144.575 8.144 1.226s-3.9 1.18-8.144 1.18-8.042-.528-8.042-1.18 3.799-1.226 8.042-1.226z"/>
          </svg>
        </button>
        <transition name="showFilter">
          <div v-if="showFilters" class="filters__container">
            <div class="filters__status">
              <span class="filters__title">Estado</span>
              <ul>
                <li
                  v-for="(status, idx) in statusList"
                  :key="`filter-leads--${idx}`"
                  class="filters__item">
                  <input
                    type="checkbox"
                    :id="status.key"
                    :value="status.value"
                    v-model="statusListFilters">
                  <label :for="status.key">{{status.key}}</label>
                </li>
              </ul>
            </div>
          </div>
        </transition>
      </div>
      <div class="leads-list">
        <table v-if="leads.length" class="admin__table">
          <thead class="admin__table__head">
            <tr>
              <th v-for="(column, idx) in tableConfig"
                :key="`columnHeader--${idx}`"
                class="admin__table__head__cell"
              >{{column.label}}</th>
            </tr>
          </thead>
          <tbody class="admin__table__body">
          <tr
            v-for="(lead, idx) in leads"
            :key="`payment--${idx}`">
            <td v-for="(column, idx) in tableConfig" :key="`columnHeader--${idx}`"
              class="admin__table__body__cell cell-leads"
              @click="showLeadDetail(lead.id)">
              <span class="admin__table__body__cell__header">{{ column.label }}</span>
              <span class="cell-content">{{ column.key === 'status' ? getStatusLabel(lead[column.key]) : lead[column.key] }}</span>
              <span v-if="column.delete" class="icon" >
                <span class="admin__table__body__cell__header"></span>
                <button @click.stop="deleteLead(lead.id)" class="btn">Borrar</button>
              </span>
            </td>
          </tr>
          </tbody>
        </table>
        <div v-else class="message__noData">
          <div class="message__noData-icon">
            <svg width="13" height="36" viewBox="0 0 13 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.880469 -2.38419e-06H12.1805L10.2805 22.25H2.73047L0.880469 -2.38419e-06ZM6.53047 35.45C4.8638 35.45 3.49714 34.95 2.43047 33.95C1.3638 32.9167 0.830469 31.6667 0.830469 30.2C0.830469 28.7 1.3638 27.4667 2.43047 26.5C3.49714 25.5333 4.8638 25.05 6.53047 25.05C8.19714 25.05 9.54714 25.5333 10.5805 26.5C11.6471 27.4667 12.1805 28.7 12.1805 30.2C12.1805 31.6667 11.6471 32.9167 10.5805 33.95C9.5138 34.95 8.1638 35.45 6.53047 35.45Z" fill="white"/>
            </svg>
          </div>
          <div class="message__noData-text">
            <p>No hay elementos para mostrar</p>
            <p>Prueba seleccionando otro centro</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { formatDateShort, getEndDateTimestamp, getStartDateTimestamp } from '@/commonMethods/methods'
import Loading from '../Loading.vue'
import Datepicker from 'vue3-datepicker'
import es from 'date-fns/locale/es'

const useInputStyles = {
  padding: "12px 20px",
  border: "1px solid #00ADCE",
  borderRadius: "0",
  marginRight: "30px",
  width: "calc(100% - 30px)"
}

export default {
  name: 'Leads',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {
    Loading,
    Datepicker
  },
  data() {
    return {
      errors: {},
      tableConfig: [
        {
          label: 'Tutor legal',
          key: 'nameTutor'
        },
        {
          label: 'Nombre alumn@',
          key: 'nameStudent'
        },
        {
          label: 'Servicio',
          key: 'service'
        },
        {
          label: 'Estado',
          key: 'status'
        },
        {
          label: 'Fecha',
          key: 'createdAt'
        },
        {
          label: '',
          key: '',
          delete: true
        },
      ],
      isLoading: false,
      dateFilters: {
        startDate: null,
        endDate: null
      },
      statusListFilters: [],
      useInputStyles,
      dateLocale: es,
      showFilters: false
    }
  },
  computed: {
    ...mapGetters('Leads', ['getLeadsList', 'getStatus']),
    ...mapGetters('Admin', {
      userInfo: 'getUserInfo'
    }),
    statusList() {
      return Object.keys(this.getStatus).map(key => ({
        key,
        value: this.getStatus[key]
      }))
    },
    leads() {
      return this.getLeadsList.filter(lead =>
        (
            (lead.status === null && this.statusListFilters.includes(null)) ||
            (lead.status === 0 && this.statusListFilters.includes(0)) ||
            (lead.status === 1 && this.statusListFilters.includes(1)) ||
            (lead.status === 2 && this.statusListFilters.includes(2)) ||
            (lead.status === 3 && this.statusListFilters.includes(3))
        ) &&
        (
          this.dateFilters.startDate
              ? (lead.createdAt * 1000) >= getStartDateTimestamp(this.dateFilters.startDate)
              : true
        ) &&
        (
          this.dateFilters.endDate
              ? (lead.createdAt * 1000) <= getEndDateTimestamp(this.dateFilters.endDate)
              : true
        )
      ).map(lead => ({
        ...lead,
        createdAt: formatDateShort(lead.createdAt)
      }))
    }
  },
  watch: {
    getStatus: {
      immediate: true,
      handler (newStatusList) {
        this.statusListFilters = Object.values(newStatusList)
      }
    }
  },
  methods: {
    ...mapActions('Leads', ['fetchLeads', 'delLead', 'updateLead', 'leadStatus']),
    ...mapActions('Admin', ['fetchCSVToken']),
    toogleFilters () {
      this.showFilters = !this.showFilters
    },
    showLeadDetail(leadId) {
      this.$router.push({ name: 'lead-detail', params: { leadId: leadId } })
    },
    deleteLead(leadId) {
      if (confirm('¿Estás seguro que quieres borrar este lead?')) {
        const infoDelLead = {
          centerId: this.id,
          leadId: leadId
        }
        this.delLead(infoDelLead)
        .then(() => this.$toast.success('Lead eliminado correctamente.'))
        .catch(errors => {
          this.errors = errors
          this.$toast.error('No se ha podido eliminar el lead.')
        })
      }
    },
    getStatusLabel(statusValue) {
      return this.statusList.find(status => status.value === statusValue).key
    },
    async downloadCSV() {
      try {
        const response = await this.fetchCSVToken()
        const csv = `${process.env.VUE_APP_API_BASE_URI}/admin/centers/${this.$route.params.id}/leads.csv?token=${response.data.token}`
        window.open(csv)
      } catch (error) {
        this.$toast.error('Lo sentimos, no hemos podido descargar el CSV, inténtelo de nuevo');
      }
    }
  },
  mounted() {
    this.isLoading = true
    this.fetchLeads(this.id)
    this.leadStatus()
    .then(() => {
      this.isLoading = false
    })
    .catch(() => {
      this.isLoading = true
    })
  }
}
</script>

<style lang="scss" scoped>

.leads {
  &__filters {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 16px;
    position: relative;

    @include desktop {
      flex-direction: row;
    }

    &__dates {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
      width: 100%;

      @include desktop {
        flex-direction: row;
        margin-bottom: 0;
      }
    }

    &__date {
      margin-bottom: 16px;

      @include desktop {
        margin-bottom: 0;
        min-width: 350px;

        &:first-child {
          margin-right: 12px;
        }
      }
    }
  }
}
.icon {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
}
@include desktop {
  .cell-leads:first-child {
    width: 20%;
  }
}

</style>
