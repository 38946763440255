<template>
  <main class="main">
    <form action class="form admin__form" @submit.prevent="newCenterSubmit">
      <div class="data-row space-between">
        <h2 class="subtitle">Nuevo centro:</h2>
        <button type="submit" class="btn btn-save">Guardar</button>
      </div>
      <div class="form__field-row half">
        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.name.$invalid && v$.form.name.$dirty">
              Por favor, introduce un nombre de centro
            </p>
            <label class="form__field-label" for="#name">Nombre *</label>
            <input
              v-model="v$.form.name.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.name.$invalid && v$.form.name.$dirty}"
              type="text"
              id="name"
              required
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('name')">
            <p v-for="(error, key) in errors.name" :key="key">{{ $t(error) }}</p>
          </div>
        </div>

        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.address.$invalid && v$.form.address.$dirty">
              Por favor, introduce una dirección
            </p>
            <label class="form__field-label" for="#address">Dirección *</label>
            <input
              v-model="v$.form.address.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.address.$invalid && v$.form.address.$dirty}"
              type="text"
              id="address"
              required
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('address')">
            <p v-for="(error, key) in errors.address" :key="key">{{ $t(error) }}</p>
          </div>
        </div>
      </div>

      <div class="form__field-row half">

        <div class="form__field-wrapper">
          <div class="form__field">
            <label class="form__field-label" for="#email">Email</label>
            <input
              v-model="v$.form.email.$model"
              class="form__field-input"
              type="email"
              id="email"
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('email')">
            <p v-for="(error, key) in errors.email" :key="key">{{ $t(error) }}</p>
          </div>
        </div>
        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.phone.$invalid && v$.form.phone.$dirty">
              Por favor, introduce un teléfono válido
            </p>
            <label class="form__field-label" for="#phone">Móvil</label>
            <input
              v-model="v$.form.phone.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.phone.$invalid && v$.form.phone.$dirty}"
              type="number"
              id="phone"
              required
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('phone')">
            <p v-for="(error, key) in errors.phone" :key="key">{{ $t(error) }}</p>
          </div>
        </div>
        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.redsysMerchantCode.$invalid && v$.form.redsysMerchantCode.$dirty">
              Por favor, introduce código
            </p>
            <label class="form__field-label" for="#redsysMerchantCode">Código mercantil *</label>
            <input
                v-model="v$.form.redsysMerchantCode.$model"
                class="form__field-input"
                :class="{'form__input--invalid': v$.form.redsysMerchantCode.$invalid && v$.form.redsysMerchantCode.$dirty}"
                type="text"
                id="redsysMerchantCode"
                required
                @click="removeErrors"
            >
            <div class="errors errors-input" v-show="errors.hasOwnProperty('redsysMerchantCode')">
              <p v-for="(error, key) in errors.redsysMerchantCode" :key="key">{{ $t(error) }}</p>
            </div>
          </div>

        </div>
        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.redsysKeySecret.$invalid && v$.form.redsysKeySecret.$dirty">
              Por favor, introduce una clave
            </p>
            <label class="form__field-label" for="#redsysKeySecret">Clave secreta *</label>
            <input
                v-model="v$.form.redsysKeySecret.$model"
                class="form__field-input"
                :class="{'form__input--invalid': v$.form.redsysKeySecret.$invalid && v$.form.redsysKeySecret.$dirty}"
                type="text"
                id="redsysKeySecret"
                required
                @click="removeErrors"
            >
            <div class="errors errors-input" v-show="errors.hasOwnProperty('redsysKeySecret')">
              <p v-for="(error, key) in errors.redsysKeySecret" :key="key">{{ $t(error) }}</p>
            </div>
          </div>
        </div>
        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.redsysTerminal.$invalid && v$.form.redsysTerminal.$dirty">
              Por favor, introduce un terminal
            </p>
            <label class="form__field-label" for="#redsysTerminal">Terminal *</label>
            <input
                v-model="v$.form.redsysTerminal.$model"
                class="form__field-input"
                :class="{'form__input--invalid': v$.form.redsysTerminal.$invalid && v$.form.redsysTerminal.$dirty}"
                type="number"
                id="redsysTerminal"
                required
                @click="removeErrors"
            >
            <div class="errors errors-input" v-show="errors.hasOwnProperty('redsysTerminal')">
              <p v-for="(error, key) in errors.redsysTerminal" :key="key">{{ $t(error) }}</p>
            </div>
          </div>
        </div>
      </div>
    </form>
  </main>

</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, numeric, email } from '@vuelidate/validators'
import { mapActions } from 'vuex'

export default {
  name: 'FormLeads',
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      form: {
        name: '',
        address: '',
        phone: '',
        email: '',
        redsysMerchantCode: '',
        redsysKeySecret: '',
        redsysTerminal: 1
      },
      errors: {},
    }
  },
  validations() {
    return {
      form: {
        name: {
          required
        },
        address: {
          required
        },
        phone: {
          required
        },
        email: {
          email
        },
        redsysMerchantCode: {
          required
        },
        redsysKeySecret: {
          required
        },
        redsysTerminal: {
          required,
          numeric
        }
      }
    }
  },
  methods: {
    ...mapActions('Admin', ['sendNewCenter']),
    newCenterSubmit() {
      if(!this.v$.$invalid) {
        this.sendNewCenter(this.form)
        .then(() => {
          this.$toast.success('Centro añadido correctamente');
          setTimeout(() => {
            this.$router.push({ name: 'centers' })
          }, 3000)
        })
        .catch (errors => {
          this.errors = errors
        })
      }
    },
    removeErrors() {
      this.errors = {}
    },
  }
}
</script>

<style scoped lang="scss">
.form {
  @include desktop {
    margin-top: 50px;
    .form__field-row {
      width: 100%;
      &.half {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .form__field-wrapper {
          display: inline-block;
          vertical-align: top;
          width: calc(50% - 20px);
          &:nth-child(2n-1) {
            margin-right: 10px;
          }
          &:nth-child(2n) {
            margin-left: 10px;
          }
        }
      }
    }
  }
}

</style>
