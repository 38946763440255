<template>
  <div class="main">
    <Loading
      v-if="isLoading" />
    <div v-else>
      <div>
        <h2 class="subtitle">Alumn@s sin centro:</h2>
        <input
          type="text"
          placeholder="Buscar alumn@"
          v-model="student"
          class="students__filters__text">
        <div class="group-list">
          <table v-if="studentSearch.length" class="admin__table">
            <thead class="admin__table__head">
              <tr>
                <th class="admin__table__head__cell">Nombre alumn@</th>
                <th class="admin__table__head__cell">Año nacimiento</th>
                <th class="admin__table__head__cell">Autorización imagen</th>
                <th class="admin__table__head__cell">Alergias</th>
                <th class="admin__table__head__cell"></th>
              </tr>
            </thead>
            <tbody class="admin__table__body">
            <tr
              v-for="(student, idx) in studentSearch"
              :key="idx"
              @click="showStudentDetail(student.id)">
              <td class="admin__table__body__cell"><span class="admin__table__body__cell__header">Nombre alumn@</span>{{ student.name }} {{ student.surnames }}</td>
              <td class="admin__table__body__cell">
                <span class="admin__table__body__cell__header">Año nacimiento</span>
                {{ student.year }}
              </td>
              <td class="admin__table__body__cell">
                <span class="admin__table__body__cell__header">Autorización imagen</span>
                {{ student.imageAuthorized ? 'Autorizado' : 'No autorizado' }}
              </td>
              <td class="admin__table__body__cell">
                <span class="admin__table__body__cell__header">Alergias</span>
                <div>
                  <p v-for="(allergy, idx) in student.allergies" :key="idx">{{allergy.name}}</p>
                </div>
              </td>
              <td class="admin__table__body__cell">
                <span class="admin__table__body__cell__header"></span>
                <button @click="deleteStudent(student.id)" class="btn">Borrar</button>
              </td>
            </tr>
            </tbody>
          </table>
          <div v-else class="message__noData">
            <div class="message__noData-icon">
              <svg width="13" height="36" viewBox="0 0 13 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.880469 -2.38419e-06H12.1805L10.2805 22.25H2.73047L0.880469 -2.38419e-06ZM6.53047 35.45C4.8638 35.45 3.49714 34.95 2.43047 33.95C1.3638 32.9167 0.830469 31.6667 0.830469 30.2C0.830469 28.7 1.3638 27.4667 2.43047 26.5C3.49714 25.5333 4.8638 25.05 6.53047 25.05C8.19714 25.05 9.54714 25.5333 10.5805 26.5C11.6471 27.4667 12.1805 28.7 12.1805 30.2C12.1805 31.6667 11.6471 32.9167 10.5805 33.95C9.5138 34.95 8.1638 35.45 6.53047 35.45Z" fill="white"/>
              </svg>
            </div>
            <div class="message__noData-text">
              <p>No hay alumn@s para mostrar</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { normalizeText } from '@/commonMethods/methods'
import Loading from '../Loading.vue'
import { apiClient } from '../../services/ApiClient'

export default {
  name: "StudentsWithoutCenter.vue",
  components: {
    Loading
  },
  data() {
    return {
      isLoading: false,
      student: '',
      isDeleating: false,
    }
  },
  computed: {
    ...mapGetters('Admin', {
      students: 'getStudentsWithoutCenter',
    }),
    studentsList() {
      return this.students.map(student => ({
        ...student,
        fullName: `${student.name} ${student.surnames}`,
        image: student.imageAuthorized ? 'Autorizado' : 'No autorizado'
      }))
    },
    studentSearch() {
      return this.studentsList.filter(_student =>
          normalizeText(_student.fullName).includes(normalizeText(this.student)) ||
          (_student.image.toUpperCase().includes(this.student.toUpperCase())) ||
          (_student.year.toString().includes(this.student)) ||
          (_student.allergies.find(allergy => normalizeText(allergy.name).includes(normalizeText(this.student))))
      )
    },
  },
  methods: {
    ...mapActions('Admin', ['fetchStudentsWithoutCenter']),
    showStudentDetail(studentId) {
      if (!this.isDeleating) {
        this.$router.push({ name: 'student-without-center-detail', params: { studentId }})
      }
      this.isDeleating = false
    },
    async deleteStudent(id) {
      this.isDeleating = true
      if (confirm('¿Estás seguro qué deseas eliminar este alum@?')) {
        try {
          await apiClient.delete(`/admin/students/${id}/without-center`)
          await this.fetchStudentsWithoutCenter()
          this.$toast.success('El alumn@ se ha eliminado correctamente.')
        } catch (error) {
          this.$toast.error('No se ha podido eliminar el alumn@.')
        }
      }
    }
  },
  async mounted() {
    try {
      this.isLoading = true
      await this.fetchStudentsWithoutCenter()
    } finally {
      this.isLoading = false
    }
  }
}
</script>

<style scoped lang="scss">

.students__filters__text {
  border: 2px solid $newPink;
  border-radius: 16px;
  box-sizing: border-box;
  font-size: 12px;
  margin-bottom: 16px;
  padding: 8px;
  width: 100%;

  @include desktop {
    width: 40%;
  }
}

</style>
