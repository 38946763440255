<template>
  <div class="recover-wrapper box">

      <h2 class="subtitle">Nueva contraseña</h2>
      <p class="text">Introduce tu email y recibirás un código para verificar tu identidad y generar una nueva contraseña:</p>
      <form action class="form" @submit.prevent="recoverPassword">
        <div class="form__field-wrapper">
          <p class="input-errors" v-if="v$.form.email.$invalid && v$.form.email.$dirty">
            Por favor, introduce un correo válido
          </p>
          <div class="form__field">
            <label class="form__field-label" for="#email">Email</label>
            <input
              v-model="v$.form.email.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.email.$invalid && v$.form.email.$dirty}"
              type="email"
              id="email"
              required
              @click="removeErrors"
            >
          </div>
          <div class="errors" v-for="(error, key) in errors" :key="key">
            <p>{{ $t(error) }}</p>
          </div>
        </div>
        
        <button :class="['btn', {'disabled': isFormSending}]">Enviar</button>
      </form>
    
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { mapActions } from 'vuex'

export default {
  name: 'RecoverPassword',
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      form: {
        email: ''
      },
      errors: {
      },
      isFormSending: false
    }
  },
  validations: {
    form: {
      email: {
        required,
        email
      }
    }
  },
  methods: {
    removeErrors() {
      this.errors = ''
    },
    ...mapActions('Auth', {
      recover: 'recover'
    }),
    async recoverPassword() {
      if(!this.v$.$invalid && !this.isFormSending) {
        this.isFormSending = true
        this.recover(this.form)
        .then(() => {
          this.$router.push({ name: 'recover/confirmation' })
          this.isFormSending = false
        })
        .catch(errors => {
          this.errors = errors
          this.isFormSending = false
        })
      }
      
    }
  } 
}
</script>

<style scoped lang="scss">
.recover-wrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  .text {
    max-width: 100%;
    margin: 0 auto 3em;
  }
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0 20px;
    .form__field-wrapper {
      .form__field {
        min-width: 300px;      
      }
    }
  }
  .btn {
    margin-bottom: 3em;
    min-width: 220px;
  }
  @include tablet {
    .text {
      max-width: 60%;
    }
    .form {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
  @include desktop {
    .text {
      max-width: 40%;
    }
  }
}
.errors-common {
  margin: 0 auto;
}
</style>
