<template>
  <div class="content__popup__wrapper">
    <div class="content__popup">
      <img src="../assets/missed_newBlue.svg" alt="close-popup" @click="$emit('handlerModal', false)" class="content__popup__close-image">
      <div class="change-card description">
        <label class="form__field-label title">Quieres actualizar el método de pago para todas tus matrículas?</label>
        <p>No realizaremos ningún cargo en este momento. Cuando llegue el momento de renovar tus matrículas, se cargarán directamente en tu tarjeta sin que tengas que hacer nada más.</p>
      </div>
      <button class="btn content__popup__button" @click="paymentMethodHandler(true)">Actualizar método de pago</button>
      <span class="exit">
        <a @click="paymentMethodHandler(false)">Cancelar</a>
      </span>
    </div>
  </div>
</template>

<script>
import { apiClient } from '../services/ApiClient'
export default {
  name: 'PaymentMethodModal',
  methods: {
    async paymentMethodHandler(isAccepted) {
      this.$emit('handlerModal', false)
      if (isAccepted) {
        return await apiClient.get('/tutor/enrollments/confirm-change-credit-card')
      } else {
        return await apiClient.get('/tutor/enrollments/confirm-dont-change-credit-card')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.content {
  min-height: calc(100vh - 80px);
  padding: 60px 15px 20px;
  transition: all .8s ease;
  &__popup {
    background-color: $white;
    border-radius: 10px;
    border: 1px solid $bg-green;
    color: $newDark;
    left: 50%;
    max-width: 350px;
    padding: 24px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    &__wrapper {
      background-color: rgba($bg-btn, 0.7);
      bottom: 0;
      height: 100vh;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 5;
    }
    &__close-image {
      height: 28px;
      padding: 16px;
      position: absolute;
      right: 0;
      top: 0;
      width: 20px;
    }
    &__text {
      margin-top: 0;
      &--header {
        margin-top: 24px;
      }
    }
    &__button {
      margin-top: 8px;
      width: 100%;
    }
    .description {
      padding-top: 20px;
      text-align: center;
      p {
        text-align: start;
      }
    }
    .exit {
      display: flex;
      justify-content: center;
      text-decoration: underline;
      padding-top: 20px;
      a {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  @include tablet {
    min-height: calc(100vh - 40px);
    padding: 20px;
    padding-left: 360px;
  }
}
</style>