<template>
  <Loading 
    v-if="isLoading" />
  <div v-else class="main">
    <h2 class="subtitle">Elige el campamento / taller</h2>
    <div>
      <p class="text" v-if="camps.length === 0">No hay campamentos / talleres disponibles</p>
      <table class="admin__table" v-if="camps.length >= 1">
        <thead class="admin__table__head">
          <tr>
            <th class="admin__table__head__cell">Nombre</th>
            <th class="admin__table__head__cell">Descripción</th>
            <th class="admin__table__head__cell"></th>
          </tr>
        </thead>
        <tbody class="admin__table__body">
          <tr v-for="(camp, idx) in camps" :key="idx">
            <td class="admin__table__body__cell"><span>Nombre</span><p>{{ camp.name }}</p></td>
            <td class="admin__table__body__cell"><span>Descripción</span><p>{{ camp.description }}</p></td>
            <td class="admin__table__body__cell picked"><button class="btn" @click="pickedGroup(camp.id, camp.name, camp.questionOptions)">Elegir este campamento / taller</button></td>
          </tr>
        </tbody>
      </table>
      <div class="errors" v-for="(error, key) in errors" :key="key">
        <p>{{ $t(error) }}</p>
      </div>
    </div>
  </div>

</template>

<script>
import Loading from './Loading.vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'NewCampByCenter',
  components: {
    Loading
  },  
  props: {
    idCenter: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      camps: [],
      isLoading: true,
      errors: {}
    }
  },
  computed: {
    ...mapGetters('Students', ['getGroupsByCenterAndStudent'])
  },
  methods: {
    ...mapActions('Students', ['studentSetGroup']),
    ...mapActions('TutorCamps', ['fetchCampsListByCenter']),
    ...mapMutations('TutorCamps', {
      setCampName: 'SET_CAMP_NAME'
    }),
    pickedGroup(campId, campName, questionOptions) {
      this.$router.push({ name: 'new-camp-student', params: { idCenter: this.idCenter, campId: campId, questionOptionsStringify: JSON.stringify(questionOptions) }})
      this.setCampName(campName)
    }
  },
  async mounted() {
    await this.fetchCampsListByCenter(this.idCenter)
    .then(response => {
      this.camps = response
      this.isLoading = false
    })
    .catch(errors => {
      this.errors = errors
    })
  },
}
</script>

<style scoped lang="scss">
.main {
  .text {
    margin-bottom: 3em;
  }
}
.admin__table__body__cell {
  cursor: auto;

  @include tablet {
    span {
      display: none;
    }
  }
}

</style>
