<template>
  <nav aria-label="Page navigation">
    <ul class="pagination">
      <li class="page-item limits"><a :title="startPage !== 1 ? 'Primera página' : null" class="page-link" :class="{ disabled: startPage === 1 }" @click="(event) => goPage(1, event)"> &lsaquo;&lsaquo; </a></li>
      <li class="page-item limits"><a :title="currentPage !== 1 ? 'Página anterior' : null" class="page-link" :class="{ disabled: currentPage === 1 }" @click="(event) => goPage('-1', event)"> &lsaquo; </a></li>
      <li v-for="page in displayedPages" :key="page" class="page-item">
        <a class="page-link" :class="{ active: currentPage === page }" @click="(event) => goPage(page, event)">{{ page }}</a>
      </li>
      <li class="page-item limits"><a :title="currentPage !== pages ? 'Página siguiente' : null" class="page-link" :class="{ disabled: currentPage === pages }" @click="(event) => goPage('+1', event)"> &rsaquo; </a></li>
      <li class="page-item limits"><a :title="lastPage !== pages ? 'Última página' : null" class="page-link" :class="{ disabled: lastPage === pages }" @click="(event) => goPage(pages, event)"> &rsaquo;&rsaquo; </a></li>
    </ul>
  </nav>
</template>

<script>

import router from "@/router";

export default {
  name: 'Pagination',
  props: {
    pages: {
      type: Number,
      default: 1
    },
  },
  computed: {
    currentPage() {
      return Number(this.$route.query.page) || 1;
    },
    startPage() {
      const _currentPage = (Number(this.$route.query.page) || 1);
      return Math.max(1, Math.min(_currentPage - 10, this.pages - 20));
    },
    lastPage() {
      const _currentPage = (Number(this.$route.query.page) || 1);
      const _startPage = Math.max(1, _currentPage - 10);
      return Math.min(this.pages, _startPage + 19);
    },
    displayedPages() {
      const _indexPages = [];
      const _currentPage = (Number(this.$route.query.page) || 1);
      const _startPage = Math.max(1, Math.min(_currentPage - 10, this.pages - 19));
      for (var i = _startPage; i < Math.min(this.pages + 1, _startPage + 20); i++) {
        _indexPages.push(i);
      }
      return _indexPages;
    }
  },
  methods: {
    goPage(number, event) {
      if(event){
        event.preventDefault();
      }
      const _goPage = (typeof number !== 'number') ? eval((this.$route.query.page ?? 1) + number) : number;
      if(_goPage <= this.pages && _goPage > 0) {
        router.push({
          name: this.$route.name,
          params: this.$route.params,
          query: {
            ...this.$route.query,
            page: _goPage
          }
        })
      }
    }
  }
}

</script>

<style scoped lang="scss">
.pagination{
  display: flex;
  justify-content: center;
  padding-left: 1px;
  list-style: none;
  border-radius: .25rem;
  margin-top: .5rem;
  margin-bottom: .5rem;
  overflow-x: auto;
  .page-item{
    display: list-item;
    margin: 0;
    flex-grow: 1;
    max-width: 40px;
    &.limits {
      max-width: 46px;
      font-family: "Londrina Solid", Arial, Helvetica, "Nimbus Sans L", sans-serif;
      font-weight: bold;
      font-size: 23px;
      .page-link {
        padding: 1px .5rem 3px .5rem;
        margin-left: 0;
        margin-right: 0;
      }
    }
    &:first-child{
      .page-link{
        border-top-left-radius: .25rem;
        border-bottom-left-radius: .25rem;
      }
    }
    &:last-child{
      .page-link{
        border-top-right-radius: .25rem;
        border-bottom-right-radius: .25rem;
      }
    }
    .page-link{
      position: relative;
      display: block;
      text-align: center;
      padding: .4rem .5rem;
      margin-left: 0;
      margin-right: 0;
      line-height: 1.25;
      background-color: #fff;
      border: 1px solid $bg-btn-active;
      text-decoration: none;
      cursor: pointer;
      &:not(.disabled):hover, &.active{
        text-decoration: none;
        background-color: $bg-btn-hover;
      }
      &.disabled{
        cursor: unset;
        color: $text-disabled;
        background-color: $bg-disabled;
      }
    }
  }
}
</style>
