<template>
  <main class="main">
    <form action class="form admin__form" @submit.prevent="newStudentSubmit">
      <div class="data-row space-between"><h2 class="subtitle">Nuevo alumn@</h2>
        <button type="submit" class="btn btn-save">Guardar</button>
      </div>
      <div class="form__field admin__form__element">
        <label
            for="tutors"
            class="form__field-label">Tutores *
        </label>
        <Select2
            class="select2"
            v-model="v$.form.tutor.$model"
            :options="formTutors"
            :settings="{  placeholder: 'Selecciona un tutor', width: '100%' }">
        </Select2>
      </div>

      <div class="form__field-row half">
        <div class="form__field-wrapper">
          <div class="form__field">
            <label class="form__field-label" for="#name">Nombre *</label>
            <input
              v-model="v$.form.name.$model"
              class="form__field-input"
              type="text"
              id="name"
              required
              placeholder=""
            >
          </div>
        </div>

        <div class="form__field-wrapper">
          <div class="form__field">
            <label class="form__field-label" for="#surnames">Apellidos *</label>
            <input
                v-model="v$.form.surnames.$model"
                class="form__field-input"
                type="text"
                id="name"
                required
                placeholder=""
            >
          </div>
        </div>
      </div>

      <div class="form__field-wrapper">
        <div class="form__field">
          <label class="form__field-label">Fecha de nacimiento *</label>
          <datepicker
              v-model="v$.form.birthday.$model"
              inputFormat="dd/MM/yyyy"
              :style="useInputStyles"
              :locale="dateLocale"
          />
        </div>
      </div>

      <Switch v-model:checked="v$.form.imageAuthorized.$model" label="Autorizo a que se tomen y publiquen fotos del alumn@" />

      <div class="form__field">
        <label class="form__field-label" for="#allergies">Alergias</label>
        <Multiselect
          class="multiselect"
          id="allergies"
          v-model="v$.form.allergies.$model"
          mode="tags"
          :searchable="true"
          :createTag="false"
          :options="formAllergies"
          :disabled="isLoading"
          :placeholder="isLoading ? 'Cargando elementos' : 'Seleciona alergias'"
        />
      </div>

      <h3>Personas autorizadas</h3>

      <div>
        <div class="form__field-row person-authorized" v-for="(personAuthorized, index) in personsAuthorized" :key="index">
          <div class="form__field-wrapper full-name">
            <div class="form__field">
              <label class="form__field-label" for="#name">Nombre y apellidos *</label>
              <input
                  v-model="personAuthorized.name"
                  class="form__field-input"
                  type="text"
                  id="name"
                  required
              >
            </div>
          </div>

          <div class="form__field-wrapper">
            <div class="form__field">
              <label class="form__field-label" for="#dni">DNI *</label>
              <input
                  v-model="personAuthorized.dni"
                  class="form__field-input"
                  type="text"
                  id="dni"
                  required
              >
            </div>
            <div class="errors errors-input" v-if="errors[index] && errors[index].dni">
              <p v-for="(error, key) in errors[index].dni" :key="key">{{ $t(error) }}</p>
            </div>
          </div>

          <div class="form__field-wrapper">
            <div class="form__field">
              <label class="form__field-label" for="#phone">Teléfono *</label>
              <input
                  v-model="personAuthorized.phone"
                  class="form__field-input"
                  type="text"
                  id="phone"
                  required
              >
            </div>
            <div class="errors errors-input" v-if="errors[index] && errors[index].phone">
              <p v-for="(error, key) in errors[index].phone" :key="key">{{ $t(error) }}</p>
            </div>
          </div>

          <div class="button__container">
            <button type="button" class="btn btn-add delete" @click="deletePersonAuthorized(index)">Borrar</button>
          </div>
        </div>
      </div>


      <div class="new-element" @click="addPersonAuthorized">
        <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M30.7758 19.504H19.6398V30.384H11.3838V19.504H0.31175V11.696H11.3838V0.815998H19.6398V11.696H30.7758V19.504Z" fill="#00ADCE"/>
        </svg>
        Añadir persona autorizada
      </div>
    </form>
  </main>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import Multiselect from '@vueform/multiselect'
import Switch from '../../components/Switch.vue'
import { mapActions, mapGetters } from 'vuex'
import Datepicker from 'vue3-datepicker'
import es from 'date-fns/locale/es'
import { formatnewDate } from '@/commonMethods/methods'
import Select2 from 'vue3-select2-component'

const useInputStyles = {
  padding: "12px 20px",
  border: "1px solid #00ADCE",
  borderRadius: "0",
  marginRight: "30px",
  width: "calc(100% - 30px)"
}

export default {
  name: 'StudentCreate',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {
    Multiselect,
    Datepicker,
    Switch,
    Select2
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      form: {
        name: '',
        surnames: '',
        birthday: new Date(),
        imageAuthorized: false,
        allergies: [],
        tutor: ''
      },
      personsAuthorized: [],
      isLoading: true,
      errors: {},
      useInputStyles,
      dateLocale: es
    }
  },
  validations() {
    return {
      form: {
        name: {
          required
        },
        surnames: {
          required
        },
        birthday: {
          required
        },
        imageAuthorized: {},
        allergies: {},
        tutor: {
          required
        }
      }
    }
  },
  computed: {
    ...mapGetters('AdminCenter', ['getAllergies']),
    ...mapGetters('Tutors', {
      tutors: 'getTutors'
    }),
    formAllergies() {
      return this.getAllergies.map(allergy => ({
        value: allergy.id,
        label: allergy.name
      }))
    },
    formTutors() {
      return this.tutors.map(tutor => ({
        id: tutor.id,
        text: `${tutor.name} ${tutor.surnames}`
      }))
    }
  },
  methods: {
    ...mapActions('AdminCenter', ['fetchAllergies', 'createStudent']),
    ...mapActions('Tutors', ['fetchTutors']),
    addPersonAuthorized() {
      this.personsAuthorized.push({
        name: '',
        dni: '',
        phone: null
      })
    },
    deletePersonAuthorized(idx) {
      this.personsAuthorized.splice(idx, 1)
      this.$nextTick(() => {
        this.$v.reset()
      })
    },
    async newStudentSubmit() {
      if(!this.v$.$invalid) {
        try {
          const student = {
            ...this.form,
            birthday: formatnewDate(this.form.birthday),
            personsAuthorized: this.personsAuthorized.length ? this.personsAuthorized : []
          }
          await this.createStudent({centerId: this.$route.params.id, student})
          this.$toast.success('Datos guardados correctamente')
          setTimeout(() => {
            this.$router.push({ name: 'students' })
          }, 3000)
        } catch(error) {
          if(error.personsAuthorized) {
            this.errors = error.personsAuthorized
            setTimeout(() => {
              this.errors = {}
            }, 4000)
          } else {
            this.errors = error
          }
        }
      }
    }
  },
  async mounted() {
    try {
      this.isLoading = true
      await this.fetchAllergies()
    } finally {
      this.isLoading = false
    }
    await this.fetchTutors({ centerId: this.$route.params.id })
  }
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped lang="scss">
.form {
  &__field-row {
    display: flex;
    flex-wrap: wrap;
    gap: 0 20px;
    width: 100%;
    .form__field-wrapper {
      width: 100%;
      .form__field {
        display: flex;
        flex-direction: column;
      }
      &.form__field-checkbox {
        display: inline-block;
        width: 100%;
        input {
          margin-right: 10px;
        }
        label {
          color: $bg-blue;
        }
      }
    }
  }
  @include tablet {
    &__field-wrapper {
      .input-errors {
        max-width: 300px;
      }
    }
    &__field {
      .form-input {
        min-width: 300px;
      }
    }
    &__field-row {
      justify-content: space-between;
      .form__field-wrapper {
        width: 48%;
      }
    }
  }
}

.form__field-row {
  &.person-authorized {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    .button__container {
      &:last-child {
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }
    .form__field-wrapper {
      width: calc(50% - 20px);
      @media (max-width: 910px) {
        width: 100%;
      }
    }
  }
}

.select2 {
  width: 100%;

  :deep {

    .select2-container--default {
      color: red;
      .select2-selection--single {
        border: 1px solid $newBlue;
        border-radius: 0;
        height: 41px;
        padding: 8px 12px;
        text-align: left;

        .select2-selection__arrow {
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}
</style>
