<template>
  <div class="recover-pass-email-wrapper box">

    <h2 class="subtitle">Nueva contraseña</h2>
    <p class="text">Introduce tu nueva contraseña</p>
    <form action class="form" @submit.prevent="recoverPasswordEmail">

      <div class="form__field-row">
        <div class="form__field-wrapper">
          <p class="input-errors" v-if="v$.form.password.$invalid && v$.form.password.$dirty">
            Tu contraseña debe tener al menos 8 caracteres
          </p>
          <div class="form__field">
            <label class="form__field-label" for="#password">Nueva contraseña</label>
            <input
              v-model="v$.form.password.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.password.$invalid && v$.form.password.$dirty}"
              type="password"
              id="password"
              placeholder=""
              required
            >
          </div>
        </div>

        <div class="form__field-wrapper">
          <p class="input-errors" v-if="v$.form.repeatPassword.$invalid && v$.form.repeatPassword.$dirty">
            Contraseña inválida
          </p>
          <div class="form__field">
            <label class="form__field-label" for="#repeatPassword">Repetir contraseña</label>
            <input
              v-model="v$.form.repeatPassword.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.repeatPassword.$invalid && v$.form.repeatPassword.$dirty}"
              type="password"
              id="repeatPassword"
              placeholder=""
              required
            >
          </div>
        </div>
      </div>
      
      <button class="btn">Enviar</button>
      <div class="errors errors-input" v-show="errors.hasOwnProperty('email')">
        <p v-for="(error, key) in errors.email" :key="key">{{ $t(error) }}</p>
      </div>

      <div class="errors" v-for="(error, key) in errors" :key="key">
        <p>{{ $t(error) }}</p>
      </div>
    </form>

  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, sameAs } from '@vuelidate/validators'
import { mapActions } from 'vuex'

export default {
  name: 'ActivateAccountByEmail',
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      form: {
        password: '',
        repeatPassword: '',
      },
      errors: {},
      token: ''
    }
  },
  validations() {
    return {
      form: {
        password: {
          required,
          minLength: minLength(8)
        },
        repeatPassword: {
          required,
          sameAsPassword: sameAs(this.form.password)
        }
      }
    }
  },
  methods: {
    ...mapActions('User', ['activateAccountByEmail']),
    async recoverPasswordEmail() {
      if(!this.v$.$invalid) {
        if(this.$route.query.token) {
          this.token = this.$route.query.token
        }
        const activeAccount = {
          token: this.token,
          password: {
            password: this.form.password,
            confirmPassword: this.form.repeatPassword
          }
        }
        this.activateAccountByEmail(activeAccount)
        .then(response => {
          if(response.isStaffMember) {
            this.$router.push({ name: 'admin-main-screen' })
          } else {
            this.$router.push({ name: 'parents-main-screen' })
          }
        })
        .catch(errors => {
          this.errors = errors
        })
      }
    }
  } 
}
</script>

<style scoped lang="scss">
.recover-pass-email-wrapper {
  text-align: center;
  .text {
    max-width: 100%;
    margin: 0 auto 3em;
  }
  .form {
    display: block;
    border-radius: 4px;
    max-width: 600px;
    margin: 0 auto;
    &__field-row {
      display: flex;
      flex-wrap: wrap;
      gap: 0 20px;
      width: 100%;
      .form__field-wrapper {
        width: 100%;
        &.form__field-code {
          width: 100%;
        }
        .form__field {
          display: flex;
          flex-direction: column;
          .form__field-input {
            width: unset;
          }
        }
      }
    }
    .errors {
      margin: 0 auto;
    }
  }
  .btn {
    margin-bottom: 3em;
    min-width: 220px;
  }
  @include tablet {
    .text {
      max-width: 60%;
    }
    .form {
      &__field-wrapper {
        .input-errors {
          max-width: 300px;
        }
      }
      &__field {
        .form-input {
          min-width: 300px;
        }
      }
      &__field-row {
        justify-content: space-between;
        .form__field-wrapper {
          width: 48%;
        }
      }
    }
  }
  @include desktop {
    .text {
      max-width: 40%;
    }
  }
}

</style>
