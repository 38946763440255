<template>
<div class="enrollment-confirmation-wrapper box">
  <div class="enrollment-confirmation-box">
    <h2 class="subtitle">Reintento de pago</h2>
    <Loading v-if="isLoading" />
    <div v-else class="enrollment-confirmation">
      <div class="credit-card">

        <form action class="form" @submit.prevent="enrollmentConfirmation">
          <div class="form__field-row half add-new-card">
            A continuación accederás a la pasarela de pago donde indicar los datos de la tarjeta de forma segura.
          </div>
          <Switch v-model:checked="isConditionAccepted" label="Acepto las condiciones de contratación" />
          <p v-if="!isConditionAccepted && showErrorCondition" class="enrollment-confirmation__errors">Debes aceptar las condiciones generales de contratación</p>
          <div class="legal">
            <p><strong>Condiciones de contratación</strong></p>
            <p class="text">
              <small>
                Antes de aceptar las condiciones generales de contratación, por favor leedlas detenidamente en:
              </small>
              <a class="enrollment-confirmation__link" href="https://lacasitadeingles.com/condiciones-generales-de-contratacion/">
                https://lacasitadeingles.com/condiciones-generales-de-contratacion/
              </a>
            </p>
          </div>
          <div class="message">
            <div class="check">
              <img class="check__img" alt="check" src="../assets/check.png">
            </div>
            <p class="text"><strong>Nuestro pago es 100% seguro y está protegido por Redsys.</strong> Almacenaremos tus datos de pago para automatizar el cobro de las cuotas de este curso y siempre podrás ver en tu perfil cualquier cargo que te hayamos realizado.</p>
          </div>
          <button class="btn" :class="{disabled: !isConditionAccepted}" type="submit">{{ getButtonText() }}</button>
        </form>
        <div class="errors" v-for="(error, key) in errors" :key="key">
          <p>{{ $t(error) }}</p>
        </div>
      </div>
    </div>
    <div>
      <form id="redsys-form" method="post" :action="url">
        <input type="hidden" name="Ds_MerchantParameters"/>
        <input type="hidden" name="Ds_SignatureVersion"/>
        <input type="hidden" name="Ds_Signature"/>
      </form>
    </div>
  </div>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { formatDate, formatPrice } from '../commonMethods/methods'
import Loading from './Loading.vue'
import Switch from '../components/Switch.vue'

export default {
  name: 'EnrollmentConfirmation',
  components: {
    Loading,
    Switch
  },
  props: {
    paymentId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      errors: [],
      isLoading: false,
      isConditionAccepted: false,
      showErrorCondition: false
    }
  },
  computed: {
    ...mapGetters('User', {
      paymentPrice: 'getPaymentPrice'
    }),
  },
  methods: {
    ...mapActions('Students', ['paymentCenterCharge']),
    ...mapActions('User', ['tutorPaymentRetry']),
    formatDate,
    formatPrice,
    getButtonText() {
      const price = this.formatPrice(this.paymentPrice)
      return `Guardar y pagar ${price}`
    },
    async enrollmentConfirmation() {
      if (this.isConditionAccepted) {
        this.showErrorCondition = false
        try {
          this.isLoading = true
          const response = await this.tutorPaymentRetry({ paymentId: this.paymentId })
          const redsysForm = document.getElementById('redsys-form')
          redsysForm.action = response.url
          redsysForm.elements['Ds_MerchantParameters'].value = response.Ds_MerchantParameters
          redsysForm.elements['Ds_SignatureVersion'].value = response.Ds_SignatureVersion
          redsysForm.elements['Ds_Signature'].value = response.Ds_Signature
          redsysForm.submit()
        } catch(err) {
          console.log('error', err)
          this.isLoading = false
          this.$toast.error('Lo sentimos, no hemos podido hacer el pago')
        }
      } else {
        this.showErrorCondition = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
.enrollment-confirmation-box {
  .subtitle {
    text-align: center;
  }
  .enrollment-confirmation {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px 100px;
    .credit-card {
      max-width: 400px;
      .add-new-card {
        margin-bottom: 30px;
      }
      .form {
        width: 100%;
        .form__field-row {
          &.half {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .form__field-wrapper {
              display: inline-block;
              vertical-align: top;
              width: calc(50% - 20px);
              &:nth-child(2n-1) {
                margin-right: 10px;
              }
              &:nth-child(2n) {
                margin-left: 10px;
              }
            }
          }
          position: relative;
          .credit-card-icon {
            .form__field-input {
              padding-left: 45px;
            }
            .icon {
              width: 25px;
              position : absolute;
              top: 7px;
              left: 12px;
            }
            .payment-method {
              padding: 10px;
              font-size: 12px;
              color: $blue-link;
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }
        .message {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 40px;
          .check {
            margin-right: 18px;
          }
          .text {
            margin-top: 6px;
            width: 80%;
          }
        }
        .btn {
          width: 100%;
        }
      }
    }
    .order {
      max-width: 400px;
      margin-bottom: 26px;
      .order__card {
        h3 {
          margin-top: 0;
          margin-bottom: 15px;
          color: $white;
          font-size: 20px;
        }
        ul {
          list-style: circle;
          margin-top: 0;
          li {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            margin: 0 0 15px 0;
            p {
              margin: 0;
            }
            span {
              font-weight: 700;
            }
          }
        }
        .order__summary {
          background-color: $bg-blue;
          padding: 15px 25px;
          border-radius: 16px;
          p {
            color: $white;
            margin: 5px 0;
            font-size: 14px;
          }
        }
        .order__payment {
          padding: 25px;
          ul {
            li {
              &:last-child {
                margin-bottom: 20px;
              }
            }
            &::after {
              content: '';
              width: 90%;
              height: 1px;
              background-color: $newText;
              display: block;
              margin: 0 auto;
            }
          }
          p {
            &.text {
              font-size: 12px;
            }
          }
          .total-pay {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 700;
            p {
              margin: 0;
            }
          }
        }
        .order__month {
          background-color: $newYellow;
          padding: 15px 25px;
          border-radius: 16px;
          p {
            &.text {
              font-size: 12px;
            }
          }
        }
      }
    }

    &__errors {
      font-size: 12px;
      color: $error;
      margin: 0 0 32px 62px;
    }

    &__link {
      color: $bg-btn-active;
      font-size: 13px;
      text-decoration: none;
    }
  }
}
.other-payment {
  cursor: pointer;
  color: $newBlue;
  margin-top: 0;
  font-size: 12px;
  margin-bottom: 40px;
}
.card__number {
  margin-bottom: 6px;
}
.btn {
  &.disabled {
    background-color: $grey;
    border: 1px solid $grey;
    cursor: not-allowed;
    pointer-events: none;
    &:hover {
      background-color: $grey;
      border: 1px solid $grey;
      color: $white;
    }
  }
}
</style>
