<template>
    <div class="main">
      <h2 class="subtitle">Edita tu perfil</h2>

      <p class="text">Actualiza los datos de acceso de tu cuenta</p>

      <form action class="form" @submit.prevent="sendProfile">

          <div class="form__field-row">

            <div class="form__field-wrapper">
              <div class="form__field">
                <label class="form__field-label" for="#name">Nombre *</label>
                <input
                  v-model="v$.getUser.name.$model"
                  class="form__field-input"
                  type="text"
                  id="name"
                  required
                >
              </div>
              <div class="errors errors-input" v-show="errors.hasOwnProperty('name')">
                <p v-for="(error, key) in errors.name" :key="key">{{ $t(error) }}</p>
              </div>
            </div>

            <div class="form__field-wrapper">
              <div class="form__field">
                <label class="form__field-label" for="#surnames">Apellidos *</label>
                <input
                  v-model="v$.getUser.surnames.$model"
                  class="form__field-input"
                  type="text"
                  id="surnames"
                  required
                >
              </div>
              <div class="errors errors-input" v-show="errors.hasOwnProperty('surnames')">
                <p v-for="(error, key) in errors.surnames" :key="key">{{ $t(error) }}</p>
              </div>
            </div>

          </div>

          <div class="form__field-row">

            <div class="form__field-wrapper">
              <p class="input-errors" v-if="v$.getUser.email.$invalid && v$.getUser.email.$dirty">
                Por favor, introduce un correo válido
              </p>
              <div class="form__field">
                <label class="form__field-label" for="#email">Email</label>
                <input
                  v-model="v$.getUser.email.$model"
                  class="form__field-input"
                  :class="{'form__input--invalid': v$.getUser.email.$invalid && v$.getUser.email.$dirty}"
                  id="email"
                  disabled
                >
              </div>
            </div>

            <div class="form__field-wrapper">
              <p class="input-errors" v-if="v$.getUser.phone.$invalid && v$.getUser.phone.$dirty">
                Por favor, introduce un teléfono válido
              </p>
              <div class="form__field">
                <label class="form__field-label" for="#phone">Teléfono *</label>
                <input
                  v-model="v$.getUser.phone.$model"
                  class="form__field-input"
                  :class="{'form__input--invalid': v$.getUser.phone.$invalid && v$.getUser.phone.$dirty}"
                  id="phone"
                  required
                  @click="removeErrors"
                >
              </div>
               <div class="errors errors-input" v-show="errors.hasOwnProperty('phone')">
                <p v-for="(error, key) in errors.phone" :key="key">{{ $t(error) }}</p>
              </div>
            </div>

            <div class="form__field-wrapper">
              <p class="input-errors" v-if="v$.getUser.dni.$invalid && v$.getUser.dni.$dirty">
                Por favor, introduce tu DNI
              </p>
              <div class="form__field">
                <label class="form__field-label" for="#dni">DNI</label>
                <input
                  v-model="v$.getUser.dni.$model"
                  class="form__field-input"
                  :class="{'form__input--invalid': v$.getUser.dni.$invalid && v$.getUser.dni.$dirty}"
                  id="dni"
                  @click="removeErrors"
                >
              </div>
               <div class="errors errors-input" v-show="errors.hasOwnProperty('dni')">
                <p v-for="(error, key) in errors.dni" :key="key">{{ $t(error) }}</p>
              </div>
            </div>
            <div class="form__field-wrapper">
              <div class="form__field">
                <label class="form__field-label" for="#center">Centro *</label>
                <input
                    :value="v$.getUser.center.$model ? v$.getUser.center.$model.name : ''"
                    class="form__field-input"
                    type="text"
                    id="center"
                    readonly
                    disabled
                >
              </div>
            </div>
          </div>

          <!-- <div class="form__field-row bank-account">
            <div class="form__field-wrapper">
              <p class="input-errors" v-if="v$.getUser.iban.$invalid && v$.getUser.iban.$dirty">
                Número de cuenta no válida
              </p>
              <div class="form__field">
                <label class="form__field-label" for="#iban">Número de cuenta</label>
                <input
                  v-model="v$.getUser.iban.$model"
                  class="form__field-input"
                  :class="{'form__input--invalid': v$.getUser.iban.$invalid && v$.getUser.iban.$dirty}"
                  id="iban"
                  type="iban"
                >
              </div>
            </div>
          </div> -->

          <div class="field-passwords">
            <p class="text">Rellena este campo solo si quieres cambiar tu contraseña de acceso</p>

            <div class="form__field-row">

              <div class="form__field-wrapper">
                <p class="input-errors" v-if="v$.password.$invalid && v$.password.$dirty">
                  Tu contraseña debe tener al menos 8 caracteres
                </p>
                <div class="form__field">
                  <label class="form__field-label" for="#password">Nueva contraseña</label>
                  <input
                    v-model="v$.password.$model"
                    class="form__field-input"
                    :class="{'form__input--invalid': v$.password.$invalid && v$.password.$dirty}"
                    id="password"
                    type="password"
                    placeholder=""
                    @click="removeErrors"
                  >
                </div>
                <div class="errors errors-input" v-show="errors.hasOwnProperty('newPassword')">
                  <p v-for="(error, key) in errors.newPassword" :key="key">{{ $t(error) }}</p>
                </div>
              </div>

              <div class="form__field-wrapper">
                <p class="input-errors" v-if="v$.repeatPassword.$invalid && v$.repeatPassword.$dirty">
                  La contraseña debe coincidir
                </p>
                <div class="form__field">
                  <label class="form__field-label" for="#repeatPassword">Repetir contraseña</label>
                  <input
                    v-model="v$.repeatPassword.$model"
                    class="form__field-input"
                    :class="{'form__input--invalid': v$.repeatPassword.$invalid && v$.repeatPassword.$dirty}"
                    id="repeatPassword"
                    type="password"
                    placeholder=""
                    @click="removeErrors"
                  >
                </div>
                <div class="errors errors-input" v-show="errors.hasOwnProperty('newPassword')">
                  <p v-for="(error, key) in errors.newPassword" :key="key">{{ $t(error) }}</p>
                </div>
              </div>

            </div>
          </div>

          <button class="btn">Actualizar datos</button>
      </form>

      <!-- <div class="errors" v-for="(error, key) in errors" :key="key">
        <p>{{ $t(error) }}</p>
      </div> -->

    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email, numeric, minLength, sameAs } from '@vuelidate/validators'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'EditProfile',
  data() {
    return {
      password: '',
      repeatPassword: '',
      errors: {
      }
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      getUser: {
        name: {
        },
        surnames: {
        },
        center: {
        },
        email: {
          email
        },
        phone: {
          required,
          numeric
        },
        dni: {
          minLength: minLength(9)
        },
        // iban: {
        //   minLength: minLength(16),
        //   maxLength: maxLength(16)
        // },
      },
      password: {
        minLength: minLength(8)
      },
      repeatPassword: {
        sameAsPassword: sameAs(this.password)
      }
    }
  },
  computed: {
    ...mapGetters('User', ['getUser']),
  },
  mounted() {
    this.removePasswrod()
  },
  methods: {
    ...mapActions('User', {
      update: 'updateUserInfo'
    }),
    removePasswrod() {
      this.password = ''
      this.repeatPassword = ''
    },
    removeErrors() {
      this.errors = ''
    },
    async sendProfile() {
      if(!this.v$.$invalid) {
        const info = {
          name: this.getUser.name,
          surnames: this.getUser.surnames,
          phone: this.getUser.phone,
          iban: this.getUser.iban,
          dni: this.getUser.dni,
          newPassword: {
            newPassword: this.password,
            confirmPassword: this.repeatPassword
          }
        }
        this.update(info)
        .then(() => {
          this.$toast.success('Datos guardados correctamente');
          this.$router.push({ name: 'parents-main-screen' })
        })
        .catch(errors => {
          this.errors = errors
        })
      }
    }

  }
}
</script>

<style scoped lang="scss">
.form {
  display: block;
  border-radius: 4px;
  max-width: 1200px;
  .field-passwords {
    background-color: $lightBgBlue;
    border-radius: 22px;
    padding: 1em 2em;
    margin-bottom: 3em;
  }
  &__field-row {
    display: flex;
    flex-wrap: wrap;
    gap: 0 20px;
    width: 100%;
    &.bank-account {
      .form__field-wrapper {
        width: 100%;
      }
    }
    .form__field-wrapper {
      width: 100%;
      .form__field {
        display: flex;
        flex-direction: column;
      }
    }
  }
  @include tablet {
    &__field-wrapper {
      .input-errors {
        max-width: 300px;
      }
    }
    &__field {
      .form-input {
        min-width: 300px;
      }
    }
    &__field-row {
      justify-content: space-between;
      .form__field-wrapper {
        width: 48%;
      }
    }
  }
}
</style>
