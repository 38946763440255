<template>
  <div class="main">
    <h2 class="subtitle">¡Hola {{ user.name }}!</h2>
    <p class="text">Estos son los alumn@s vinculados a tu cuenta</p>
    <div v-if="recoverClass.length" class="message__noData">
      <div class="message__noData-icon">
        <svg width="13" height="36" viewBox="0 0 13 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.880469 -2.38419e-06H12.1805L10.2805 22.25H2.73047L0.880469 -2.38419e-06ZM6.53047 35.45C4.8638 35.45 3.49714 34.95 2.43047 33.95C1.3638 32.9167 0.830469 31.6667 0.830469 30.2C0.830469 28.7 1.3638 27.4667 2.43047 26.5C3.49714 25.5333 4.8638 25.05 6.53047 25.05C8.19714 25.05 9.54714 25.5333 10.5805 26.5C11.6471 27.4667 12.1805 28.7 12.1805 30.2C12.1805 31.6667 11.6471 32.9167 10.5805 33.95C9.5138 34.95 8.1638 35.45 6.53047 35.45Z" fill="white"/>
        </svg>
      </div>
      <div class="message__noData-text">
        <p>{{recoverClass}}</p>
      </div>
    </div>
    <div v-if="sessionsToRecover.length" class="message__noData">
      <div class="message__noData-icon">
        <svg width="13" height="36" viewBox="0 0 13 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.880469 -2.38419e-06H12.1805L10.2805 22.25H2.73047L0.880469 -2.38419e-06ZM6.53047 35.45C4.8638 35.45 3.49714 34.95 2.43047 33.95C1.3638 32.9167 0.830469 31.6667 0.830469 30.2C0.830469 28.7 1.3638 27.4667 2.43047 26.5C3.49714 25.5333 4.8638 25.05 6.53047 25.05C8.19714 25.05 9.54714 25.5333 10.5805 26.5C11.6471 27.4667 12.1805 28.7 12.1805 30.2C12.1805 31.6667 11.6471 32.9167 10.5805 33.95C9.5138 34.95 8.1638 35.45 6.53047 35.45Z" fill="white"/>
        </svg>
      </div>
      <div class="message__noData-text">
        <p>No olvides tus próximas clases de recuperación</p>
        <p v-for="(session, idx) in sessionsToRecover" :key="idx">
          <strong>{{ session[0].student.name }}</strong> tiene recuperación el <strong>{{ reverseDate(session[0].recoveredAt) }}</strong> en <strong>{{ session[0].recoveredGroup.name }}</strong> a las <strong>{{ session[0].recoveredGroup.time }}</strong>
        </p>
      </div>
    </div>
    <div class="student__card-wrapper">
      <StudentList />
    </div>

    <div class="student__new">
      <h2 class="subtitle">¿Quieres añadir un nuevo alumn@?</h2>
      <p class="text">Añade un nuevo perfil y activa una matrícula en tan sólo 3 pasos</p>
      <router-link :to="{ name: 'new-student' }">
        <button class="btn">Añadir alumn@</button>
      </router-link>
    </div>
  </div> 
</template>

<script>
import StudentList from '../components/StudentList.vue'
import { mapGetters } from 'vuex'
import { reverseDate } from '../commonMethods/methods.js'


export default {
  name: 'ParentMainScreen',
  components: {
    StudentList
  },
  data() {
    return {
      reverseDate
    }
  },
  computed: {
    ...mapGetters('User', {
      user: 'getUser'
    }),
    recoverClass() {
      const missedsStudents = this.user.students.reduce((acc, itr) => {
        if(itr.hasMissedSessions) {
          acc.push(itr.name)
        }
        return acc
      }, [])
      if(missedsStudents.length === 0) {
        return ''
      } else if(missedsStudents.length === 1) {
        return `${missedsStudents} tiene clase de recuperación pendiente`
      } else {
        const ultimo = missedsStudents.pop()
        return `${missedsStudents.join(', ')} y ${ultimo} tienen clases de recuperación pendientes`
      }
    },
    sessionsToRecover() {
      const sessions = this.user.students.reduce((acc, itr) => {
        if(itr.upcomingMissedSessionsToRecover.length) {
          acc.push(itr.upcomingMissedSessionsToRecover)
        }
        return acc
      }, [])
      if(sessions.length === 0) {
        return ''
      } else {
        return sessions
      }
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  .main {
    .student__card-wrapper {
      margin-bottom: 5em;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      gap: 10px;
    }
    .student__new {
      text-align: center;
      margin-bottom: 3em;
    }
  }
}
.message__noData {
  margin-bottom: 40px;
  .message__noData-text {
    p {
      &:first-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
