<template>
  <div class="main">
    <h2 class="subtitle">Editar alergia:</h2>
    <AllergyForm
      :allergy="activeAllergy"
      :errors="formErrors"
      @submit="editAllergy"
    ></AllergyForm>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AllergyForm from '@/components/Partials/AllergyForm'

export default {
  name: 'AllergyDetail',
  components: {
    AllergyForm
  },
  data() {
    return {
      formErrors: {}
    }
  },
  computed: {
    ...mapGetters('Admin', {
      getActiveAllergy: 'getActiveAllergy'
    }),
    activeAllergy () {
      return this.getActiveAllergy(this.$route.params.allergyId)
    },
  },
  methods: {
    ...mapActions('Admin', ['fetchActiveAllergy', 'updateAllergy']),
    async editAllergy(newAllergy) {
      try {
        await this.updateAllergy({ allergyId: this.$route.params.allergyId, newAllergy })
        this.$toast.success('Datos enviados correctamente')
        setTimeout(() => {
          this.$router.push({ name: 'allergies' })
        }, 3000)
      } catch (errors) {
        this.formErrors = errors
      }
    }
  },
  async mounted() {
    try {
      await this.fetchActiveAllergy(this.$route.params.allergyId)
    } catch (error) {
      await this.$router.push({ name: 'allergies' })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
