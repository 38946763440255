<template>
  <div class="menu" :class="[visibleMenu ? 'visible_menu' : '']">
    <a href="#" class="menu__on" id="menu__on" @click="toggleClass()">
      <span></span>
      <span></span>
      <span></span>
    </a>
    <div class="menu__logo-wrapper">
      <router-link :to="{ name: 'parents-main-screen' }" @click="closeOnClick">
        <img class="menu__logo" alt="Vue logo" src="../assets/logo.png">
      </router-link>
    </div>

    <strong class="menu_title">Tus alumn@s</strong>
    <div class="menu_box">
      <div class="menu__student-list" v-for="(item, idx) in getStudents" :key="item.id">
        <div class="menu__student-list-item" @click="toggleStudentList(idx)"  :class="{'children-visible': dropdownActiveIndex === idx}">
          {{ item.name }}
          <img class="arrow" src="../assets/arrow.png" alt="Arrow">
        </div>
        <ul class="student__area" :class="{'show': dropdownActiveIndex === idx}">
          <li class="student__area-item">
            <router-link :to="{ name: 'enroll', params: { id: item.id } }" @click="closeOnClick">Matrículas</router-link>
          </li>
          <li class="student__area-item">
            <router-link :to="{ name: 'recover-class', params: { id: item.id } }" @click="closeOnClick">Recuperación de clases</router-link>
          </li>
          <li class="student__area-item">
            <router-link :to="{ name: 'reserved-classes', params: { id: item.id } }" @click="closeOnClick">Clases de recuperación reservadas</router-link>
          </li>
          <li class="student__area-item">
            <router-link :to="{ name: 'student-data', params: { id: item.id } }" @click="closeOnClick">Alergias y datos personales</router-link>
          </li>
          <li class="student__area-item">
            <router-link :to="{ name: 'student-report', params: { id: item.id } }" @click="closeOnClick">Informes</router-link>
          </li>
        </ul>
      </div>
      <router-link class="new-student" :to="{ name: 'new-student'}" @click="closeOnClick">
        <svg width="24" height="24" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.1094 6.26562H15.75V10.3438H10.1094V16.7188H5.8125V10.3438H0.15625V6.26562H5.8125V0.15625H10.1094V6.26562Z" fill="white"/>
        </svg>
        Nuevo alumn@
      </router-link>
      <router-link class="new-student new-tutor" :to="{ name: 'new-tutor'}" @click="closeOnClick">
        <svg width="24" height="24" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.1094 6.26562H15.75V10.3438H10.1094V16.7188H5.8125V10.3438H0.15625V6.26562H5.8125V0.15625H10.1094V6.26562Z" fill="white"/>
        </svg>
        Nuevo tutor legal
      </router-link>

    </div>

    <strong class="menu_title">Tu cuenta</strong>
    <div class="menu_box">
      <router-link class="menu_link" :to="{ name: 'tutor-camps' }" @click="closeOnClick">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path d="M4.069 13h-4.069v-2h4.069c-.041.328-.069.661-.069 1s.028.672.069 1zm3.034-7.312l-2.881-2.881-1.414 1.414 2.881 2.881c.411-.529.885-1.003 1.414-1.414zm11.209 1.414l2.881-2.881-1.414-1.414-2.881 2.881c.528.411 1.002.886 1.414 1.414zm-6.312-3.102c.339 0 .672.028 1 .069v-4.069h-2v4.069c.328-.041.661-.069 1-.069zm0 16c-.339 0-.672-.028-1-.069v4.069h2v-4.069c-.328.041-.661.069-1 .069zm7.931-9c.041.328.069.661.069 1s-.028.672-.069 1h4.069v-2h-4.069zm-3.033 7.312l2.88 2.88 1.415-1.414-2.88-2.88c-.412.528-.886 1.002-1.415 1.414zm-11.21-1.415l-2.88 2.88 1.414 1.414 2.88-2.88c-.528-.411-1.003-.885-1.414-1.414zm2.312-4.897c0 2.206 1.794 4 4 4s4-1.794 4-4-1.794-4-4-4-4 1.794-4 4zm10 0c0 3.314-2.686 6-6 6s-6-2.686-6-6 2.686-6 6-6 6 2.686 6 6z"/>
        </svg>
        Campamentos / Talleres
      </router-link>
      <router-link class="menu_link" :to="{name: 'payment-history'}" @click="closeOnClick">
        <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.164 7.165C11.014 7.356 10.462 8.398 10.933 9.493C11.431 10.648 12.854 11.388 14.027 11.096C15.066 10.839 15.546 9.844 15.096 8.801C14.625 7.706 13.312 6.974 12.164 7.165ZM13.648 10.163L13.752 10.392L13.533 10.437L13.436 10.218C13.21 10.259 12.954 10.253 12.717 10.191L12.652 9.804C12.847 9.834 13.09 9.862 13.275 9.824L13.4 9.783C13.621 9.674 13.552 9.396 13.224 9.33C12.979 9.276 12.331 9.316 12.089 8.778C11.953 8.474 12.054 8.157 12.445 8.012L12.337 7.773L12.554 7.728L12.658 7.957C12.817 7.931 13.003 7.921 13.221 7.94L13.308 8.323C13.138 8.302 12.955 8.282 12.796 8.315L12.736 8.331C12.427 8.413 12.526 8.706 12.8 8.777C13.253 8.882 13.794 8.916 14.008 9.389C14.181 9.774 13.98 10.037 13.648 10.163ZM23.96 11.22L20.194 3C14.016 7.004 7.187 2.682 2.243 7.454L6.008 15.674C11.306 11.182 18.527 15.436 23.96 11.22ZM21.157 9.368C20.782 9.889 20.504 10.485 20.338 11.109C16.745 12.203 12.447 10.908 8.32 12.35C7.653 11.996 6.817 11.774 6.131 11.794L4.996 9.307C5.428 8.782 5.768 7.982 5.914 7.213C9.313 5.987 13.566 7.368 18.112 5.812C18.633 6.158 19.242 6.409 19.842 6.533L21.157 9.368ZM24 15.01C17.143 18.951 11.601 13.586 4.5 21L0 11.03L1.402 9.567L5.209 17.973L5.207 17.98C12.652 12.385 16.402 16.804 23.316 13.417C23.61 14.065 23.881 14.749 24 15.01Z" fill="black"/>
        </svg>
        Histórico de pagos
      </router-link>
      <router-link class="menu_link" :to="{name: 'yearly'}" @click="closeOnClick">
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-file-earmark-pdf" viewBox="0 0 16 16">
          <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/>
          <path d="M4.603 14.087a.8.8 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.7 7.7 0 0 1 1.482-.645 20 20 0 0 0 1.062-2.227 7.3 7.3 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a11 11 0 0 0 .98 1.686 5.8 5.8 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.86.86 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.7 5.7 0 0 1-.911-.95 11.7 11.7 0 0 0-1.997.406 11.3 11.3 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.8.8 0 0 1-.58.029m1.379-1.901q-.25.115-.459.238c-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361q.016.032.026.044l.035-.012c.137-.056.355-.235.635-.572a8 8 0 0 0 .45-.606m1.64-1.33a13 13 0 0 1 1.01-.193 12 12 0 0 1-.51-.858 21 21 0 0 1-.5 1.05zm2.446.45q.226.245.435.41c.24.19.407.253.498.256a.1.1 0 0 0 .07-.015.3.3 0 0 0 .094-.125.44.44 0 0 0 .059-.2.1.1 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a4 4 0 0 0-.612-.053zM8.078 7.8a7 7 0 0 0 .2-.828q.046-.282.038-.465a.6.6 0 0 0-.032-.198.5.5 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822q.036.167.09.346z"/>
        </svg>
        Certificados anuales
      </router-link>
      <router-link class="menu_link" :to="{name: 'edit-profile'}" @click="closeOnClick">
        <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M24 13.616V10.384C22.349 9.797 21.306 9.632 20.781 8.365V8.364C20.254 7.093 20.881 6.23 21.628 4.657L19.343 2.372C17.782 3.114 16.91 3.747 15.636 3.219H15.635C14.366 2.693 14.2 1.643 13.616 0H10.384C9.802 1.635 9.635 2.692 8.365 3.219H8.364C7.093 3.747 6.232 3.121 4.657 2.372L2.372 4.657C3.117 6.225 3.747 7.091 3.219 8.364C2.692 9.635 1.635 9.802 0 10.384V13.616C1.632 14.196 2.692 14.365 3.219 15.635C3.749 16.917 3.105 17.801 2.372 19.342L4.657 21.628C6.219 20.885 7.091 20.253 8.364 20.781H8.365C9.635 21.307 9.801 22.36 10.384 24H13.616C14.198 22.364 14.366 21.31 15.643 20.778H15.644C16.906 20.254 17.764 20.879 19.342 21.629L21.627 19.343C20.883 17.78 20.252 16.91 20.779 15.637C21.306 14.366 22.367 14.197 24 13.616ZM12 16C9.791 16 8 14.209 8 12C8 9.791 9.791 8 12 8C14.209 8 16 9.791 16 12C16 14.209 14.209 16 12 16Z" fill="black"/>
        </svg>
        Edita tu perfil
      </router-link>
      <router-link class="menu_link" :to="{name: 'legal-tutors'}" @click="closeOnClick">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 480 448">
          <path fill="#000" d="M148.25 224c-26 0.75-49.5 12-66.25 32h-33.5c-25 0-48.5-12-48.5-39.75 0-20.25-0.75-88.25 31-88.25 5.25 0 31.25 21.25 65 21.25 11.5 0 22.5-2 33.25-5.75-0.75 5.5-1.25 11-1.25 16.5 0 22.75 7.25 45.25 20.25 64zM416 383.25c0 40.5-26.75 64.75-66.75 64.75h-218.5c-40 0-66.75-24.25-66.75-64.75 0-56.5 13.25-143.25 86.5-143.25 8.5 0 39.5 34.75 89.5 34.75s81-34.75 89.5-34.75c73.25 0 86.5 86.75 86.5 143.25zM160 64c0 35.25-28.75 64-64 64s-64-28.75-64-64 28.75-64 64-64 64 28.75 64 64zM336 160c0 53-43 96-96 96s-96-43-96-96 43-96 96-96 96 43 96 96zM480 216.25c0 27.75-23.5 39.75-48.5 39.75h-33.5c-16.75-20-40.25-31.25-66.25-32 13-18.75 20.25-41.25 20.25-64 0-5.5-0.5-11-1.25-16.5 10.75 3.75 21.75 5.75 33.25 5.75 33.75 0 59.75-21.25 65-21.25 31.75 0 31 68 31 88.25zM448 64c0 35.25-28.75 64-64 64s-64-28.75-64-64 28.75-64 64-64 64 28.75 64 64z"></path>
        </svg>
        Tutores legales
      </router-link>
      <router-link class="menu_link" :to="{name: 'contact'}" @click="closeOnClick">
        <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 2C17.514 2 22 6.486 22 12C22 17.514 17.514 22 12 22C6.486 22 2 17.514 2 12C2 6.486 6.486 2 12 2ZM12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 5.373 18.627 0 12 0ZM16.5 17.311L14.74 13.914L13.708 14.419C12.588 14.962 10.308 10.509 11.403 9.922L12.445 9.409L10.698 6L9.645 6.52C6.044 8.397 11.762 19.511 15.445 17.828L16.5 17.311Z" fill="black"/>
        </svg>
        Contacta con nosotros
      </router-link>
      <router-link v-if="getUser.hasPaymentMethod" :to="{ name: 'paymentMethodsByUser' }" class="menu_link menu_link--change-card">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 512 512">
          <path d="M464 64h-416c-26.4 0-48 21.6-48 48v288c0 26.4 21.6 48 48 48h416c26.4 0 48-21.6 48-48v-288c0-26.4-21.6-48-48-48zM48 96h416c8.673 0 16 7.327 16 16v48h-448v-48c0-8.673 7.327-16 16-16zM464 416h-416c-8.673 0-16-7.327-16-16v-144h448v144c0 8.673-7.327 16-16 16zM64 320h32v64h-32zM128 320h32v64h-32zM192 320h32v64h-32z"></path>
        </svg>
        Métodos de págo
      </router-link>
      <div class="menu_logout button" @click="logout">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16 10V5L24 12L16 19V14H8V10H16ZM0 2V22H14V20H2V4H14V2H0Z" fill="white"/>
        </svg>
        Cerrar sesión
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Sidebar',
  props: {
    isModalOpen: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      dropdownActiveIndex: -1,
      visibleMenu: false,
      visibleIcon: false,
    }
  },
  mounted() {
    this.sidebar()
  },
  computed: {
    ...mapGetters('Students', ['getStudents']),
    ...mapGetters('User', ['getUser'])
  },
  methods: {
    ...mapActions('Auth', {
      logout: 'logout'
    }),
    toggleClass(){
      this.visibleMenu = !this.visibleMenu;
      this.visibleIcon = !this.visibleIcon
      this.$emit('onVisibleMenu');
    },
    toggleStudentList(idx) {
      if(idx === this.dropdownActiveIndex) {
        this.dropdownActiveIndex = -1;
      } else {
        this.dropdownActiveIndex = idx;
      }
    },
    closeOnClick() {
      if(window.innerWidth < 768) {
        this.visibleMenu = false;
      }
    },
    sidebar() {
      if(window.innerWidth >= 768) {
        this.$emit('onVisibleMenu');
        this.visibleMenu = true;
      } else {
        this.visibleMenu = false;
      }
    },
  },
}
</script>

<style scoped lang="scss">
.menu {
  width: 100%;
  position: absolute;
  z-index: 5;
  top: 0;
  bottom: 0;
  left: -100%;
  display: flex;
  flex-direction: column;
  transition: all .8s ease;
  background-color: white;
  &.visible_menu {
    overflow-y: auto;
    left: 0;
    .menu__on {
      transform: translate(0);
      span {
        &:nth-child(1) {
          transform: rotate(45deg) translate(-2px, 1px); 
        }
        &:nth-child(2) {
          transform: translate(-50px);
          opacity: 0;
        }
        &:nth-child(3) {
          transform: rotate(-45deg) translate(-3px, 3px); 
        }
      }
    }
  }
  .menu_box {
    background-color: $white; 
    margin: 20px;
    padding: 20px;
    border-radius: 22px;

  }
  .menu__on {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(60px);
    transition: all .8s ease;
    display: inline-block;
    margin: 20px;
    width: 30px;
    height: 20px;
    z-index: 2;
    span {
      width: 100%;
      height: 4px;
      border-radius: 4px;
      display: block;
      position: absolute;
      background-color: $bg-green;
      transition: all .8s ease;
      transform-origin : 0 100%;
      &:nth-child(2) {
        top: calc(50% - 2px);
        background-color: $bg-red;
      }
      &:nth-child(3) {
        bottom: 0;
        background-color: $bg-blue;
      }
    }
    
    @include small-desktop {
      display: none !important;
    }
  }
  .menu__logo-wrapper {
    display: flex;
    position: relative;
    margin-top: 30px;
    a {
      margin: 0 auto;
      .menu__logo {
        margin: 20px auto;
        width: 180px;
        height: auto;
      }
    }
  }
  .menu_title {
    text-transform: uppercase;
    font-weight: 300;
    font-size: 18px;
    text-align: center;
    color: $newDark;
    font-family: $font__headers;
    position: relative;
    top: 12px;
  }
  &__student-list {
    margin: 0;
    margin-bottom: 20px;
    div {
      display: flex;
    }
    &-item {
      margin: 0;
      padding: 3px 30px;
      background-color: $newBlue;
      border-radius: 16px;
      position: relative;
      z-index: 1;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-decoration: none;
      color: $white;
      font-weight: 700;
      height: 46px;
      @include disable-selection;
      .arrow {
        width: 12px;
        height: 6px;
        align-self: center;
        transition: all .3s ease;
      }
      &.children-visible {
        .arrow {
          transform: rotate(180deg);
        }
      }
    }
    .student__area {
      padding: 30px 30px 20px 30px;
      margin-top: -22px;
      list-style: disc;
      background-color: $lightBgBlue;
      display: block;
      position: relative;
      z-index: 0;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      display: none;
      &.show {
        display: block;
      }
      &-item {
        margin: 0;
        padding: 15px 0 5px;
        position: relative;
        list-style: none;
        a {
          text-decoration: none;
          color: $newBlue;
          font-weight: 600;
          &.router-link-active {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .new-student {
    background-color: $newPink;
    padding: 3px 30px;
    border-radius: 16px;
    margin: 0;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $white;
    font-weight: 600;
    height: 46px;
    svg {
      margin-right: 10px;
    }
  }
  .new-tutor {
    background-color: darken($blue-link, 10);
    margin-top: 20px;
  }
  .menu_link {
    padding: 12px 5px 12px 12px;
    display: flex;
    color: $newDark;
    text-decoration: none;
    svg {
      margin-right: 10px;
      margin-top: -2px;
    }
    &.router-link-active {
      font-weight: 700;
    }

    &--change-card {
      cursor: pointer;
    }
  }
  .menu_logout {
    display: flex;
    font-weight: 700;
    border-radius: 16px;
    background-color: $color__background-logout;
    padding: 12px 30px;
    margin-top: 15px;
    align-items: center;
    text-decoration: none;
    color: $white;
    position: relative;
    cursor: pointer;
    svg {
      margin-right: 10px;
    }
  }
  @include tablet {
    width: 340px;
    left: 0 !important;
    background-color: transparent;
    &.open_default {
      overflow-y: auto;
      left: 0;
    }
  }
}
</style>
