<template>
  <div class="main">
    <h2 class="subtitle">Nueva alergia:</h2>
    <AllergyForm
      :errors="formErrors"
      @submit="createNewAllergy"
    ></AllergyForm>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import AllergyForm from '@/components/Partials/AllergyForm'

export default {
  name: 'AllergyCreate.vue',
  components: {
    AllergyForm
  },
  data() {
    return {
      formErrors: {}
    }
  },
  methods: {
    ...mapActions('Admin', ['createAllergy']),
    async createNewAllergy(newAllergy) {
      try {
        await this.createAllergy(newAllergy)
        this.$toast.success('Datos enviados correctamente')
        setTimeout(() => {
          this.$router.push({ name: 'allergies' })
        }, 3000)
      } catch (errors) {
        this.formErrors = errors
      }
    }
  }
}
</script>

<style scoped>

</style>
