import { apiClient } from '@/services/ApiClient'

const state = {
  leadsList: [],
  lead: {},
  status: {}
}

const actions = {
  async fetchLeads({ commit }, centerId) {
    await apiClient.get(`/admin/centers/${centerId}/leads`, centerId)
    .then(response => {
      commit('SET_LEADS_LIST', response.data)
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },
  async fetchLead({ commit }, ids) {
    const info = {
      centerId: ids.id,
      leadId: ids.leadId
    }
    await apiClient.get(`/admin/centers/${info.centerId}/leads/${info.leadId}`)
    .then(response => {
      commit('SET_LEAD', response.data)
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },
  async delLead({ commit } , infoDelLead) {
    const centerId = infoDelLead.centerId
    const leadId = infoDelLead.leadId
    await apiClient.delete(`/admin/centers/${centerId}/leads/${leadId}`)
    .then(() => {
      commit('DELETE_LEAD', leadId)
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },
  async updateLead( commit  , {newForm, leadId, centerId}) {
    await apiClient.patch(`/admin/centers/${centerId}/leads/${leadId}`, newForm)
    .then(() => {
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },
  async leadStatus( {commit} ) {
    await apiClient.get('/admin/leads/status')
    .then(response => {
      commit('SET_STATUS', response.data)
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },
}

const mutations = {
  SET_LEADS_LIST(state, leadsList) {
    state.leadsList = leadsList
  },
  DELETE_LEAD(state, leadId) {
    const indexLead = state.leadsList.findIndex(lead => lead.id == leadId)
    state.leadsList.splice(indexLead, 1)
  },
  SET_LEAD(state, lead) {
    state.lead = lead
  },
  SET_STATUS(state, status) {
    state.status = status
  },
}

const getters = {
  getLeadsList: (state) => state.leadsList.map(lead => ({
    ...lead,
    service: lead.service.name
  })),
  getLead: (state) => state.lead,
  getStatus: (state) => state.status
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
