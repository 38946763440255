<template>
  <main class="main new-tutor">
    <h2 class="subtitle">Nuevo tutor/a</h2>

    <form action class="form" @submit.prevent="newTutorSubmit">
      <div class="form__field-row half" >
        <div class="form__field-wrapper">
          <div class="form__field">
            <label class="form__field-label" for="#name">Nombre *</label>
            <input
                v-model="v$.form.name.$model"
                class="form__field-input"
                type="text"
                id="name"
                required
                @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('name')">
            <p v-for="(error, key) in errors.name" :key="key">{{ $t(error) }}</p>
          </div>
        </div>
        <div class="form__field-wrapper">
          <div class="form__field">
            <label class="form__field-label" for="#surnames">Apellidos *</label>
            <input
                v-model="v$.form.surnames.$model"
                class="form__field-input"
                type="text"
                id="surnames"
                required
                @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('surnames')">
            <p v-for="(error, key) in errors.surnames" :key="key">{{ $t(error) }}</p>
          </div>
        </div>
      </div>
      <div class="form__field-row half">
        <div class="form__field-wrapper">
          <div class="form__field">
            <label class="form__field-label" for="#email">Email *</label>
            <input
                v-model="v$.form.email.$model"
                class="form__field-input"
                :class="{'form__input--invalid': v$.form.email.$invalid && v$.form.email.$dirty}"
                id="email"
                required
                @click="removeErrors"
            >
          </div>
          <p class="input-errors" v-if="v$.form.email.$invalid && v$.form.email.$dirty">
            Por favor, introduce un correo válido
          </p>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('email')">
            <p v-for="(error, key) in errors.email" :key="key">{{ $t(error) }}</p>
          </div>
        </div>
        <div class="form__field-wrapper">
          <div class="form__field">
            <label class="form__field-label" for="#phone">Teléfono *</label>
            <input
                v-model="v$.form.phone.$model"
                class="form__field-input new-tutor__input--phone"
                :class="{'form__input--invalid': v$.form.phone.$invalid && v$.form.phone.$dirty}"
                id="phone"
                type="number"
                required
                @click="removeErrors"
            >
          </div>
          <p class="input-errors" v-if="v$.form.phone.$invalid && v$.form.phone.$dirty">
            Por favor, introduce un teléfono válido
          </p>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('phone')">
            <p v-for="(error, key) in errors.phone" :key="key">{{ $t(error) }}</p>
          </div>
        </div>
      </div>
      <div class="form__field-row">
        <div class="form__field-wrapper">
          <div class="form__field">
            <label class="form__field-label" for="#email">Relación familiar *</label>
            <input
                v-model="v$.form.familyRelationship.$model"
                class="form__field-input"
                :class="{'form__input--invalid': v$.form.familyRelationship.$invalid && v$.form.familyRelationship.$dirty}"
                id="familyRelationship"
                required
                @click="removeErrors"
            >
          </div>
          <p class="input-errors" v-if="v$.form.familyRelationship.$invalid && v$.form.familyRelationship.$dirty">
            Por favor, introduce la relación familiar
          </p>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('familyRelationship')">
            <p v-for="(error, key) in errors.familyRelationship" :key="key">{{ $t(error) }}</p>
          </div>
        </div>
      </div>
      <button :disabled="isLoading" class="btn">Enviar</button>
    </form>
  </main>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email, numeric } from '@vuelidate/validators'
import { mapActions } from 'vuex'

export default {
  name: 'NewTutor.vue',
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      isLoading: false,
      form: {
        name: '',
        surnames: '',
        email: '',
        phone: '',
        familyRelationship: ''
      },
      errors: {}
    }
  },
  validations() {
    return {
      form: {
        name: {
          required
        },
        surnames: {
          required
        },
        email: {
          required,
          email
        },
        phone: {
          required,
          numeric
        },
        familyRelationship: {
          required
        },
      }
    }
  },
  methods: {
    ...mapActions('Students', ['addNewTutor']),
    async newTutorSubmit() {
      if (!this.v$.$invalid) {
        try {
          await this.addNewTutor(this.form)
          this.$toast.success('Tutor guardado correctamente')
          setTimeout(() => {
            this.resetForm()
            this.$router.push({ name: 'legal-tutors' })
          }, 3000)
        } catch(errors) {
          if (errors && errors.response) {
            this.errors = errors.response.data.errors
          } else {
            this.$toast.error('Lo sentimos, no hemos podido guardar el nuevo tutor correctamente, inténtelo de nuevo')
          }
        }
      }
    },
    resetForm() {
      this.form = {
        name: '',
        surnames: '',
        email: '',
        phone: '',
        familyRelationship: ''
      }
      this.v$.$reset()
    },
    removeErrors() {
      this.errors = ''
    },
  }
}
</script>

<style scoped lang="scss">
.new-tutor {
  &__input {
    &--phone {
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
      }
    }
  }
}

</style>