<template>
  <div class="main">
    <h2 class="subtitle">Nuevo grupo:</h2>
    <GroupForm
      :services="services"
      :staffMembers="staffMembers"
      :isCreate="true"
      :errors="formErrors"
      @submit="createNewGroup"
      @on-errors-form="cleanErrors"
    ></GroupForm>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import GroupForm  from '@/components/Partials/GroupForm'

export default {
  name: 'GroupCreate',
  components: {
    GroupForm
  },
  props: {
    id: {
      type: String,
      dafault: '',
      required: true
    },
  },
  data() {
    return {
      formErrors: []
    }
  },
  computed: {
    ...mapGetters('Admin', {
      services: 'getServicesList',
      staffMembers: 'getStaffMembers'
    }),
  },
  methods: {
    ...mapActions('Admin', ['fetchServices', 'fetchStaffMembers', 'createGroup']),
    async createNewGroup(newGroup) {
      try {
        await this.createGroup({centerId: this.id, newGroup})
        this.$toast.success('Datos enviados correctamente')
        setTimeout(() => {
          this.$router.push({ name: 'groups' })
        }, 3000)
      } catch (errors) {
        this.formErrors = errors
      }
    },
    cleanErrors() {
      this.formErrors = []
    }
  },
  async mounted() {
    await this.fetchServices()
    await this.fetchStaffMembers(this.id)
  }
}
</script>

<style scoped>

</style>
