import { apiClient } from '@/services/ApiClient'
import router from '@/router'

const state = {
  temporalToken: null,
}

const initialState = () => ({
  temporalToken: null,
  centers: []
})

const actions = {
  logout({ commit }) {
    commit('DO_LOGOUT')
    commit('Students/RESET_STUDENTS', state, {root: true})
    router.push({ name: 'login' })
  },
  async login({ commit }, userLogin) {
    commit('DO_LOGOUT')
    return new Promise((resolve, reject) => {
      apiClient.post('/login', userLogin)
      .then(response => {
        commit('DO_LOGIN', response.data)
        resolve(response.data)
      })
      .catch(err => {
        reject(err.response.data.errors)
      })
    })
  },
  async refresh({ commit }) {
    return new Promise((resolve, reject) => {
      apiClient.refreshToken()
        .then(response => {
          commit('DO_LOGIN', response.data)
          resolve(response.data)
        })
        .catch(err => {
          commit('DO_LOGOUT')
          reject(err.response.data.errors)
        })
    })
  },
  async signup({ commit }, userRegister) {
    commit('DO_LOGOUT')
    await apiClient.post('/signup', userRegister)
    .then(response => {
      commit('DO_REGISTER', response.data.temporalToken)
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },
  async registerCode({ commit, state }, confirmationCode) {
    const infoCode = {
      temporalToken: state.temporalToken,
      code: confirmationCode
    }
    await apiClient.post('/signup/confirmation', infoCode)
    .then(response => {
      commit('DO_LOGIN', response.data)
    })
    .catch(error => {
      throw error
    })
  },
  async recover({ commit }, userEmail) {
    await apiClient.post('/recover-password', userEmail)
    .then(response => {
      commit('DO_REGISTER', response.data.temporalToken)
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },
  async recoverConfirmation({ commit, state }, recoverInfo) {
    const info = {
      temporalToken: state.temporalToken,
      code: recoverInfo.code,
      password: recoverInfo.password
    }
    await apiClient.post('/recover-password/confirmation', info)
    .then(response => {
      commit('DO_LOGIN', response.data)
    })
    .catch(error => {
      throw error
    })
  },
  async fetchCenters({commit}) {
    try {
      const { data } = await apiClient.get('/centers')
      commit('SET_CENTERS_LIST', data)
    } catch (error) {
      throw error.response.data.errors
    }
  },
}

const getters = {
  async getCenters() {
    const {data} = await apiClient.get('/centers');
    return data;
  },
}

const mutations = {
  SET_CENTERS_LIST(state, centers) {
    initialState.centers = centers
  },
  DO_LOGIN(state, response) {
    localStorage.setItem('accessToken', response.accessToken)
    localStorage.setItem('refreshToken', response.refreshToken)
  },
  DO_REGISTER(state, temporalToken) {
    state.temporalToken = temporalToken
  },
  DO_LOGOUT(state) {
    const newState = initialState()
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
