<template>
  <Loading
    v-if="isLoading" />
  <div v-else class="main">
    <h2 class="subtitle">Recuperación de clases {{ activeStudent.name }}</h2>
    <p class="text" v-if="recoverClass.length === 0">
      {{ activeStudent.name }} no tiene clases pendientes de recuperación.
    </p>
    <p class="text" v-if="recoverClass.length === 1">
      {{ activeStudent.name }} tiene 1 clase pendiente de recuperar, busca la fecha que más te convenga y reserva ahora tu sesión de recuperación:
    </p>
    <p class="text" v-if="recoverClass.length > 1">
      {{ activeStudent.name }} tiene {{ recoverClass.length }} clases pendientes de recuperar, busca la fecha que más te convenga y reserva ahora tu sesión de recuperación:
    </p>
    <table class="admin__table" v-if="recoverClass.length >= 1">
      <thead class="admin__table__head">
        <tr>
          <th class="admin__table__head__cell">Centro</th>
          <th class="admin__table__head__cell">Curso y nivel</th>
          <th class="admin__table__head__cell">Falta de asistencia</th>
          <th class="admin__table__head__cell">Recuperación hasta</th>
          <th class="admin__table__head__cell"></th>
        </tr>
      </thead>
      <tbody class="admin__table__body">
        <tr v-for="(recover, idx) in recoverClass" :key="idx">
          <td class="admin__table__body__cell"><span>Centro</span><p>{{ recover.missedGroup.center.name }}</p></td>  
          <td class="admin__table__body__cell"><span>Curso y nivel</span><p>{{ recover.missedGroup.service }}</p></td> 
          <td class="admin__table__body__cell"><span>Falta de asistencia</span><p>{{ reverseDate(recover.missedAt) }}</p></td>
          <td class="admin__table__body__cell"><span>Recuperación hasta</span><p>{{ formatDateShort(recover.recoverableUpTo, false) }}</p></td>
          <td class="admin__table__body__cell"><button class="btn" @click="booking(idx)">Reservar sesión</button></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Loading from '@/components/Loading'
import { mapGetters } from 'vuex'
import { formatDateShort, reverseDate } from '../commonMethods/methods'

export default {
  name: 'RecoverClass',
  components: {
    Loading
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      recoverClass: [],
      isLoading: true,
      formatDateShort,
      reverseDate
    }
  },
  mounted() {
    this.returnMissedSessions(this.id)
    .then(response => {
      this.recoverClass = response.data
      this.isLoading = false
    })
    .catch(errors => {
      console.log('errors', errors)
    })
  },
  watch: {
    id: function() {
      this.recoverClass = []
      this.returnMissedSessions(this.id)
      .then(response => {
        this.recoverClass = response.data
        this.isLoading = false
      })
      .catch(errors => {
        console.log('errors', errors)
      })
    }
  },
  computed: {
    ...mapGetters('Students', {
      returnActiveStudent: 'getActiveStudent',
      returnMissedSessions: 'getMissedSessions'
    }),
    activeStudent() {
      return this.returnActiveStudent(this.$route.params.id)
    }
  },
  methods: {
    async booking(idx) {
      const objSession = this.recoverClass[idx]
      this.$router.push({ name: 'recover-class-session', params: { id: this.id, idMissedSession: objSession.id, isEditing: false } })
    }
  }
}
</script>

<style scoped lang="scss">
.main {
  .text {
    margin-bottom: 3em;
  }
}
.admin__table__body__cell {
  cursor: auto;
  @include tablet {
    span {
      display: none;
    }
  }
}

</style>
