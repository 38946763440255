<template>
  <div class="main payments">
    <Loading v-if="isLoading" />
    <div v-else class="payments__container">
      <div class="csv__header">
        <h2 class="subtitle">Historial de pagos:</h2>
        <button
          class="btn csv__button"
          type="button"
          @click="downloadCSV">
          <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
            <path d="M22 24h-20v-24h14l6 6v18zm-7-23h-12v22h18v-16h-6v-6zm3 15v1h-12v-1h12zm0-3v1h-12v-1h12zm0-3v1h-12v-1h12zm-2-4h4.586l-4.586-4.586v4.586z"/>
          </svg>
          <span class="csv__button__text">Descargar CSV</span>
        </button>
      </div>
      <div class="payments__filters">
        <div class="payments__filters__buttons">
          <input
            type="text"
            placeholder="Buscar alumno por nombre o año"
            v-model="student"
            class="payments__filters__text">
          <button
            class="btn payments__filters__button payments__filters__button__payment"
            type="button"
            @click="makeExtraPayment">Emitir pago
          </button>
        </div>
        <button
          class="payments__filters__button btn"
          :class="{
            'payments__filters__button--open': showFilters
          }"
          type="button"
          @click="toogleFilters">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" >
            <path class="payments__filters__button__icon" d="M22.906 2.841c1.104-2.412-7.833-2.841-10.907-2.841-2.934 0-12.01.429-10.906 2.841.508 1.11 8.907 12.916 8.907 12.916v5.246l4 2.997v-8.243s8.398-11.806 8.906-12.916zm-10.901-.902c4.243 0 8.144.575 8.144 1.226s-3.9 1.18-8.144 1.18-8.042-.528-8.042-1.18 3.799-1.226 8.042-1.226z"/>
          </svg>
        </button>
        <transition name="showFilter">
          <div v-if="showFilters" class="payments__filters__container">
            <div class="payments__filters__status">
              <span class="payments__filters__title">Estado</span>
              <ul>
                <li
                  v-for="(status, idx) in paymentStatus"
                  :key="`filter--${idx}`"
                  class="payments__filters__item">
                  <input
                    type="checkbox"
                    :id="status.name"
                    :value="status.name"
                    v-model="typeAndStatusfilters[status.name]">
                  <label :for="status.name">{{status.label}}</label>
                </li>
              </ul>
            </div>
            <div class="payments__filters__methods">
              <span class="payments__filters__title">Métodos</span>
              <ul>
                <li
                  v-for="(payment, idx) in typeOfPayments"
                  :key="`filter--${idx}`"
                  class="payments__filters__item">
                  <input
                    type="checkbox"
                    :id="payment.name"
                    :value="payment.name"
                    v-model="typeAndStatusfilters[payment.name]">
                  <label :for="payment.name">{{payment.label}}</label>
                </li>
              </ul>
            </div>
          </div>
        </transition>
      </div>
      <div
        class="form__field-row half payments__filters__dates">
        <div class="form__field-wrapper">
          <div class="form__field form__field--datepicker">
            <label class="form__field-label" for="#price">Fecha inicio</label>
            <datepicker
              v-model="dateFilters.startDate"
              ref="startDatepicker"
              inputFormat="dd/MM/yyyy"
              :style="useInputStyles"
              :locale="dateLocale"
              :clearable="true"
              :upper-limit="dateFilters.endDate"
            />
          </div>
        </div>
        <div class="form__field-wrapper">
          <div class="form__field form__field--datepicker">
            <label class="form__field-label" for="#price">Fecha fin</label>
            <datepicker
              v-model="dateFilters.endDate"
              ref="endDatepicker"
              inputFormat="dd/MM/yyyy"
              :style="useInputStyles"
              :locale="dateLocale"
              :clearable="true"
              :lower-limit="dateFilters.startDate"
            />
          </div>
        </div>
      </div>
      <Pagination v-if="totalPages > 1" :pages="totalPages"></Pagination>
      <table v-if="paymentSearch.length || isTableLoading" class="admin__table">
        <thead class="admin__table__head">
        <tr>
          <th v-for="(column, idx) in tableConfig"
            :key="`columnHeader--${idx}`"
            :class="[
              'admin__table__head__cell',
              {
                'admin__table__head__cell--align-right': column.label === 'Precio'
              }
            ]"
          >{{column.label}}</th>
        </tr>
        </thead>
        <tbody class="admin__table__body" v-if="!isTableLoading">
        <tr
          v-for="(payment, idx) in paymentSearch"
          :key="`payment--${idx}`">
          <td v-for="(column, idx2) in tableConfig" :key="`columnHeader--${idx2}`"
            class="admin__table__body__cell cell-payments"
            :class="{
              'payments__error': payment.error
            }"
            @click="column.key === 'price' ? () => {} : showPayDetail(payment.id, column.key)">
            <ul
              v-if="column.isMultiple"
              class="payments__table__sublist">
              <li
                v-for="(item, idx3) in payment[column.key]"
                :key="`multipleCell-${idx}-${idx3}`"
                class="payments__table__subelement"
                ><span
                  class="admin__table__body__cell__header"
                  :class="{'payments__table--disabled': idx3 > 0 }">
                  {{ column.label }}
                </span>
                <span v-if="item.length !== 0" class="payments__table__info">{{item}}</span>
              </li>
            </ul>
            <template v-else>
              <span class="admin__table__body__cell__header">{{ column.label }}</span>
              <div v-if="column.key === 'price'"
                  :class="['payments__table__info__price', {'payments__table__info__price--update': !payment.paidAt && !payment.error}]">
                <input
                  type="number"
                  class="admin__table__body__cell__input"
                  v-model="payment.price"
                  step="0.01"
                  pattern="([0-9]{1,3}).([0-9]{1,2})"
                  @blur="onPriceChange($event.target.value, idx)"
                  @input="showConfirmBanner"
                  :disabled="!!payment.paidAt || payment.error"
                />
                <span class="admin__table__body__cell__suffix cell-content">€</span>
              </div>
              <div v-else-if="column.key === 'paymentMethod'">
                <span class="cell-content">{{ getTypeOfPayment(payment[column.key]) }}</span>
              </div>
              <button
                v-else-if="column.key === 'retry' && ((payment.isEnrollmentPayment && payment.hasError) && payment.tutor.hasPaymentMethod)"
                class="btn"
                @click="retryPay(payment.id)"
              >
                Repetir pago
              </button>
              <span v-else class="cell-content">{{payment[column.key]}}
              </span>
            </template>
            <template v-if="!payment.paidAt && column.isNotPaid">
              <svg @click.stop="acceptPaid(payment.id)" xmlns="http://www.w3.org/2000/svg"
                width="24" height="24" viewBox="0 0 512.000000 512.000000"
                preserveAspectRatio="xMidYMid meet">
                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#000000" stroke="none">
                <path d="M2315 5109 c-800 -83 -1501 -518 -1927 -1196 -604 -961 -490 -2237
                274 -3068 425 -462 951 -737 1583 -827 119 -17 512 -16 635 1 622 86 1148 360
                1572 820 349 378 572 862 650 1406 17 118 17 512 0 630 -59 416 -191 769 -410
                1099 -92 140 -185 254 -315 385 -399 404 -893 653 -1462 737 -123 18 -478 26
                -600 13z m705 -1123 c173 -29 379 -93 451 -140 49 -33 66 -76 51 -130 -20 -76
                -121 -290 -147 -313 -36 -31 -75 -29 -235 12 -193 49 -297 65 -424 65 -317 0
                -529 -109 -659 -341 l-47 -84 319 -5 319 -5 26 -24 c25 -24 26 -27 26 -157 0
                -126 -1 -134 -24 -161 l-24 -28 -361 -3 -361 -3 0 -79 0 -80 360 0 361 0 24
                -25 c24 -23 25 -29 25 -160 0 -131 -1 -137 -25 -160 l-24 -25 -331 0 -331 0 6
                -22 c16 -53 77 -167 120 -225 47 -62 136 -138 207 -174 89 -46 208 -71 353
                -76 163 -6 229 2 447 57 174 44 212 48 247 23 24 -16 84 -136 136 -270 48
                -126 27 -161 -140 -225 -548 -212 -1160 -123 -1540 223 -156 143 -294 377
                -365 617 l-22 72 -164 0 c-162 0 -165 0 -189 25 -24 23 -25 29 -25 158 0 186
                -6 180 182 187 l143 5 3 77 3 76 -140 4 c-131 3 -141 4 -165 27 -25 24 -26 28
                -26 157 0 73 3 140 6 149 14 36 57 45 226 45 l163 0 28 82 c195 566 690 893
                1322 873 72 -3 168 -11 215 -19z"/>
                </g>
                </svg>
            </template>
          </td>
        </tr>
        </tbody>
        <tbody class="admin__table__body" v-else>
        <tr>
          <td class="admin__table__body__cell enrollments__table__cell" colspan="8">
            <div class="message__loading">
              <h2>Results are loading...</h2>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <div v-else class="message__noData">
        <div class="message__noData-icon">
          <svg width="13" height="36" viewBox="0 0 13 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.880469 -2.38419e-06H12.1805L10.2805 22.25H2.73047L0.880469 -2.38419e-06ZM6.53047 35.45C4.8638 35.45 3.49714 34.95 2.43047 33.95C1.3638 32.9167 0.830469 31.6667 0.830469 30.2C0.830469 28.7 1.3638 27.4667 2.43047 26.5C3.49714 25.5333 4.8638 25.05 6.53047 25.05C8.19714 25.05 9.54714 25.5333 10.5805 26.5C11.6471 27.4667 12.1805 28.7 12.1805 30.2C12.1805 31.6667 11.6471 32.9167 10.5805 33.95C9.5138 34.95 8.1638 35.45 6.53047 35.45Z" fill="white"/>
          </svg>
        </div>
        <div class="message__noData-text">
          <p>No hay pagos para mostrar</p>
          <p>Prueba seleccionando otro centro</p>
        </div>
      </div>
    </div>
    <div v-if="showUpdatePrice" class="payments__banner">
      <p>Acabas de realizar cambios en el precio de algunos pagos ¿seguro que quieres actualizar? </p>
      <div class="payments__banner__buttons">
        <button class="btn btn-save payments__banner__button" type="reset" @click="updatePrices">Guardar</button>
        <button class="btn btn-delete payments__banner__button" type="reset" @click="dismissPrices">Cancelar</button></div>
      </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Loading from '../Loading.vue'
import { formatPrice } from '@/commonMethods/methods'
import { typeOfPayments, paymentStatus } from '@/commonMethods/constants'
import Datepicker from 'vue3-datepicker'
import es from 'date-fns/locale/es'
import Pagination from "@/components/Helpers/Pagination.vue";
import debounce from "lodash.debounce";
import router from "@/router";


const useInputStyles = {
  padding: "12px 20px",
  border: "1px solid #00ADCE",
  borderRadius: "0",
  marginRight: "30px",
  width: "calc(100% - 30px)"
}

export default {
  name: 'Payments',
  props: {
    id: {
      type: String,
      dafault: '',
      required: true
    }
  },
  components: {
    Pagination,
    Loading,
    Datepicker
  },
  data() {
    const filtersConfig = paymentStatus.concat(typeOfPayments);
    const typeAndStatusfilters = filtersConfig.reduce((acc, iter) => {
      const nameParsed = iter.name === 'paidError' ? 'error' : iter.name.toLowerCase();
      const isStatus = !!paymentStatus.find(s => s.name === iter.name);
      const isMethod = !!typeOfPayments.find(s => s.name === iter.name);
      let isChecked = true;
      if(isStatus && this.$route.query['status']){
        isChecked = this.$route.query['status']?.split(',').includes(nameParsed);
      }
      if(isMethod && this.$route.query['method']){
        isChecked = this.$route.query['method']?.split(',').includes(nameParsed);
      }
      acc[iter.name] = isChecked;
      return acc
    }, {})
    return {
      tableConfig: [
        {
          label: 'Alum@s/Tutor',
          key: 'students',
          isMultiple: true
        },
        {
          label: 'Precio',
          key: 'price'
        },
        {
          label: 'Info',
          key: 'subject'
        },
        {
          label: 'Método',
          key: 'paymentMethod'
        },
        {
          label: 'Solicitado',
          key: 'orderAt'
        },
        {
          label: 'Pagado',
          key: 'paidAt'
        },
        {
          label: 'Aceptar pago',
          key: '',
          isNotPaid: true
        },
        {
          label: '',
          key: 'retry',
        }
      ],
      student: this.$route.query['search'] || '',
      isPaid: true,
      isPaidError: true,
      isUnPaid: true,
      typeAndStatusfilters,
      showFilters: false,
      isLoading: false,
      formatPrice,
      paymentSearch: [],
      paymentsIdxnewPrice: [],
      showUpdatePrice: false,
      typeOfPayments,
      paymentStatus,
      dateFilters: {
        // startDate: new Date().getTime() - (24*60*60*1000 * 180),
        startDate: this.$route.query['dateStart'] ? Date.parse(this.$route.query['dateStart'].toString()) : null,
        endDate: this.$route.query['dateEnd'] ? Date.parse(this.$route.query['dateEnd'].toString()) : null,
      },
      useInputStyles,
      dateLocale: es,
      isTableLoading: false,
    }
  },
  computed: {
    ...mapGetters('Admin', {
      payments: 'getPayments'
    }),
    originalPaymentSearch() {
      return (this.payments.content ?? this.payments);
    },
    totalPages() {
      return this.payments.totalPages ?? 0;
    }
  },
  watch: {
    async '$route' (){
      this.isTableLoading = true;
      const _params = { limit: 25, page: 1 };

      for(const param in this.$route.query){
        _params[param] = this.$route.query[param];
      }
      await this.fetchPaymentsList({ centerId: this.id, params: _params })
      this.isTableLoading = false;
    },
    student: debounce(function(searchString){
      if(searchString.length > 0 && searchString.length < 3){
        return;
      }
      const _querySanitizer = {...this.$route.query}
      if(searchString){
        _querySanitizer['search'] = searchString;
      } else {
        delete _querySanitizer['search'];
      }
      router.push({
        name: this.$route.name,
        params: this.$route.params,
        query: {
          ..._querySanitizer,
          page: 1
        }
      })
    }, 500),
    dateFilters: {
      handler(dateFilters) {
        const queryParamSanitizer = {...this.$route.query};
        const dateParams = {};
        if(dateFilters['startDate']) {
          const dateStart = new Date(dateFilters['startDate']);
          dateParams.dateStart = `${dateStart.getFullYear()}-${(dateStart.getMonth() + 1).toString().padStart(2, '0')}-${dateStart.getDate().toString().padStart(2, '0')}`
        } else {
          delete queryParamSanitizer['dateStart'];
        }
        if(dateFilters['endDate']) {
          const dateEnd = new Date(dateFilters['endDate']);
          dateParams.dateEnd = `${dateEnd.getFullYear()}-${(dateEnd.getMonth() + 1).toString().padStart(2, '0')}-${dateEnd.getDate().toString().padStart(2, '0')}`
        } else {
          delete queryParamSanitizer['dateEnd'];
        }

        router.push({
          name: this.$route.name,
          params: this.$route.params,
          query: {
            ...queryParamSanitizer,
            ...dateParams,
            page: 1
          }
        })
      },
      deep: true
    },
    typeAndStatusfilters: {
      handler(statusFilters) {
        const queryParamSanitizer = {...this.$route.query};
        const typeStatusParams = {};
        const statuses = [
            statusFilters['paid'] ? 'paid' : false,
            statusFilters['unPaid'] ? 'unpaid' : false,
            statusFilters['paidError'] ? 'error' : false
        ].filter(e => e);
        if(!!statuses.length && statuses.length !== 3){
          typeStatusParams.status = statuses.join(',');
        } else {
          delete queryParamSanitizer.status;
        }
        const methods = [
            statusFilters['sepa'] ? 'sepa' : false,
            statusFilters['card'] ? 'card' : false,
            statusFilters['cash'] ? 'cash' : false
        ].filter(e => e);

        if(methods.length && methods.length !==3 ){
          typeStatusParams.method = methods.join(',');
        } else {
          delete queryParamSanitizer.method;
        }

        router.push({
          name: this.$route.name,
          params: this.$route.params,
          query: {
            ...queryParamSanitizer,
            ...typeStatusParams,
            page: 1
          }
        })
      },
      deep: true
    },
    originalPaymentSearch: {
      immediate: true,
      handler (updatedOriginalPaymentSearch) {
        this.paymentSearch = updatedOriginalPaymentSearch.map(payment => {
          return {
            ...payment,
            price: payment.price.toFixed(2),
            students: payment.students.length
              ? payment.students
              : payment.tutor
                ? [`${payment.tutor.name} ${payment.tutor.surnames} (Tutor)`]
                : ['']
          }
        })
      }
    },
  },
  methods: {
    ...mapActions('Admin', ['fetchPaymentsList', 'fetchSelectedPayment', 'acceptPayment', 'retryPayment', 'updatePayment', 'fetchCSVToken']),
    toogleFilters () {
      this.showFilters = !this.showFilters
    },
    showPayDetail(paymentId, key) {
      if (key !== 'retry') {
        this.$router.push({ name: 'payment-detail', params: { id: this.$route.params.id, paymentId } })
      }
    },
    async acceptPaid(paymentId) {
      if (confirm("¿Estás seguro que quieres aceptar este pago?")) {
        try {
          await this.acceptPayment({ centerId: this.$route.params.id, paymentId })
          this.$toast.success('Pago aceptado correctamente')
          setTimeout(() => {
            this.$router.push({ name: 'payments' })
          }, 3000)
        } catch (error) {
          this.errors = error
          this.$toast.error('Este pago no se ha podido aceptar')
        }
      }
    },
    async retryPay(paymentId) {
      if (confirm('¿Estás seguro de que quieres reintentar el pago?')) {
        this.isLoading = true;
        const _params = { limit: 25, page: 1 };

        for(const param in this.$route.query){
          _params[param] = this.$route.query[param];
        }
        try {
          await this.retryPayment({ centerId: this.$route.params.id, paymentId });
          this.isTableLoading = true;
          await this.fetchPaymentsList({ centerId: this.id, params: _params });
          this.$toast.success('Pago realizado correctamente');
          this.isTableLoading = false;
        } catch (error) {
          console.log('error', error)
          this.$toast.error('No se ha podido realizar el pago')
        } finally {
          this.isLoading = false
        }
      }
    },
    onPriceChange (value, paymentIndex) {
      this.paymentSearch[paymentIndex].price = parseFloat(value).toFixed(2)
      const findPriceIndex = this.paymentsIdxnewPrice.findIndex(_idx => _idx === paymentIndex)
      if (findPriceIndex < 0) {
        this.paymentsIdxnewPrice.push(paymentIndex)
      }
    },
    async updatePrices() {
      const newPaymentsPrices = this.paymentsIdxnewPrice.map(idx => ({
        id: this.paymentSearch[idx].id,
        price: parseFloat(this.paymentSearch[idx].price)
      }))
      try {
        await Promise.all(newPaymentsPrices.map( async (_price) =>
          await this.updatePayment({
            payment: { price: _price.price },
            centerId: this.$route.params.id,
            paymentId: _price.id
          })
        ))
        this.$toast.success('Precios actualizados correctamente')
      } catch (error) {
        this.$toast.error('No hemos podido actualizar los precios, inténtelo de nuevo o introduce el nuevo importe en la vista de detalle del pago')
      } finally {
        this.showUpdatePrice = false
        this.paymentsIdxnewPrice = []
      }
    },
    dismissPrices() {
      this.paymentSearch = this.originalPaymentSearch.map(payment => ({
        ...payment,
        price: payment.price.toFixed(2)
      }))
      this.showUpdatePrice = false
    },
    showConfirmBanner() {
      this.showUpdatePrice = true
    },
    makeExtraPayment() {
      this.$router.push({ name: 'payment-extra', params: { id: this.$route.params.id } })
    },
    async downloadCSV() {
      try {
        const response = await this.fetchCSVToken()
        const csv = `${process.env.VUE_APP_API_BASE_URI}/admin/centers/${this.$route.params.id}/payments.csv?token=${response.data.token}`
        window.open(csv)
      } catch (error) {
        this.$toast.error('Lo sentimos, no hemos podido descargar el CSV, inténtelo de nuevo');
      }
    },
    getTypeOfPayment(type) {
      return this.typeOfPayments.find(_type => _type.id === type).label
    }
  },
  async mounted() {
    this.isLoading = true;
    const _params = { limit: 25, page: 1 };

    for(const param in this.$route.query){
      _params[param] = this.$route.query[param];
    }
    await this.fetchPaymentsList({ centerId: this.id, params: _params })
    .then(() => {
      this.isLoading = false
    })
    .catch(() => {
      this.isLoading = true
    })
  }
}
</script>
<style lang="scss" scoped>
  .admin {
    &__table {
      &__head {
        &__cell {
          padding: 5px;
        }
      }
      &__body {
        &__cell {
          line-height: 40px;
          @include desktop {
            line-height: 26px;
          }
          &__input {
            width: 60px;
          }
        }
      }
    }
  }
  .payments {
    position: relative;

    &__container {
      min-height: calc(100vh - 215px);;
    }

    &__filters {
      align-items: flex-end;
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      position: relative;

      @include desktop {
        flex-direction: row;
      }

      &__title {
        border-bottom: 1px solid $newBlue;
        display: block;
        padding: 12px 0;
        text-align: center;
      }

      &__text {
        border: 2px solid $newPink;
        border-radius: 16px;
        flex-grow: 1;
        font-size: 12px;
        margin-right: 16px;
        max-width: 180px;
        overflow: hidden;
        padding: 8px;
        text-overflow: ellipsis;
        white-space: nowrap;

        @include desktop {
          margin-bottom: 0;
          max-width: 400px;
          width: 40%;
        }
      }

      &__buttons {
        display: flex;
        flex-direction: column;
        width: 100%;

        @include desktop {
          flex-direction: row;
          justify-content: flex-start;
        }
      }

      &__button {
        height: 34px;
        padding: 8px 16px;
        transition: border-bottom-left-radius .4s, border-bottom-right-radius .4s;

        &--open {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }

        &__icon {
          fill: $white;
        }

        &:hover {
          svg {
            path {
              fill: $newBlue;
            }
          }
        }

        &__payment {
          margin-top: 8px;
          width: 200px;

          @include desktop {
            margin-top: 0;
          }
        }
      }

      &__container {
        background-color: white;
        border: 1px solid $newBlue;
        border-radius: 16px;
        border-top-right-radius: 0;
        display: flex;
        font-size: 14px;
        margin: 0;
        position: absolute;
        right: 0;
        top: 100%;
        z-index: 1;
      }

      @include desktop {
        flex-direction: row;
      }

      &__status {
        border-right: 1px solid $newBlue;
      }

    }

    &__error {
      background-color:  lighten(desaturate($newSalmon, 10), 15);
    }

    &__message {
      background-color: $newLightBlue;
      border: 1px solid $newBlue;
      border-radius: 16px;
      font-size: 14px;
      margin-top: 32px;
      padding: 12px;
      text-align: center;
    }

    &__table {
      &__sublist {
        align-items: initial;
        display: flex;
        flex-direction: column;
        width: 100%;

        @include desktop {
          align-items: center;
        }
      }

      &__subelement {
        display: flex;
        justify-content: space-between;
        margin: 0;
      }

      &--disabled {
        display: none;
      }

      &__info {
        font-weight: 400;
        text-align: end;
        // width: 100%;

        &__price {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 4px;

          &--update {
            border: 1px solid $newBlue;
            border-radius: 16px;
            padding: 0 8px;
            margin: 10px 6px;
            height: 30px;
            line-height: 0;
          }
        }
      }
    }

    &__banner {
      align-items: center;
      background-color: rgba($bg-btn-active, 2);
      bottom: 0;
      color: $white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 12px 0 16px;
      position: sticky;
      text-align: center;

      &__buttons {
        display: flex;
      }

      &__button {
        font-size: 12px;
        min-width: 0 !important;
        padding: 8px 10px;
        width: 120px;
        &:first-child {
          margin-right: 8px;
        }
      }
    }
  }

  .showFilter {
    &-enter-active,
    &-leave-active {
      transition: opacity .4s, border-top-right-radius .4s;
    }
    &-enter-from,
    &-leave-to {
      opacity: 0;
      border-top-right-radius: 16px;
    }
    &-enter-to,
    &-leave-from {
      opacity: 1;
      border-top-right-radius: 0;
    }
  }

  .payments__table__info {
    text-align: center;
  }
    @include desktop {
  .cell-payments {
      padding: 8px;
    }
  }
</style>
