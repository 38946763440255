<template>
  <div class="student__card" v-for="(student, idx) in getStudents" :key="idx">
    <div class="student__card-data">
      <!-- <img :src="student.avatar" alt=""> -->
      <div class="card__data-text">
        <router-link :to="{ name:'enroll', params: { id: student.id } }">
          <h3>{{ student.name }}</h3>
        </router-link>
        <p>Nacid@ en {{ student.year }}</p>
      </div>
    </div>
    <div class="student__card-course" v-for="(enrollment, idx) in student.enrollments" :key="idx">
      <div class="center">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13 23H2.99996L2.99796 12.984L11.972 4.995L20.983 12.984L21 23H18V16H13V23ZM6.99996 16H4.99996V19H6.99996V16ZM11 16H8.99996V19H11V16ZM12 1L23.981 11.632L22.653 13.125L11.981 3.644L1.30896 13.125L-0.019043 11.632L12 1Z" fill="#858585"/>
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="24" height="24" fill="white"/>
            </clipPath>
          </defs>
        </svg>

        <span>{{ enrollment.center }}</span>
      </div>
      <div class="course">
        <h3>{{ enrollment.service }}</h3>
        <p v-if="enrollment.dateEnd == null && enrollment.priceToBeCharged != null" class="payments" :class="enrollment.paymentError ? 'attention':'active'">
            {{ enrollment.paymentError ? 'Ha ocurrido un error en el pago' : 'Próximo pago: ' + enrollment.priceToBeCharged + '€ el ' + formatDate(enrollment.nextPayment) }}
        </p>
        <p v-if="enrollment.dateEnd != null" class="payments finish">
            {{ 'Activa hasta: ' + formatDate(enrollment.dateEnd) }}
        </p>
      </div>

      <div v-if="enrollment.dataEnd == null && enrollment.hasOwnProperty('paymentError')" class="progress" :class="enrollment.paymentError ? 'attention':'active'">
        <p>{{ enrollment.paymentError ? 'REQUIERE ATENCIÓN':'ACTIVA' }}</p>
      </div>
      <div v-if="!enrollment.hasOwnProperty('paymentError')" class="progress finish">
        <p>TERMINADA</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatDate } from '../commonMethods/methods'

export default {
  name: 'StudentList',
  data() {
    return {
      formatDate,
    }
  },
  computed: {
    ...mapGetters('Students', ['getStudents']),
  },
}
</script>

<style scoped lang="scss">
.student__card {
  width: 100%;
  margin-bottom: 20px;
  border: 2px solid $newBlue;
  border-radius: 22px;
  h2 {
    padding: 0 30px;
  } 
  &-data {
    padding: 16px 20px;
    background-color: $newBlue;
    border-radius: 16px;
    display: flex;
    align-items: center;
    img {
      margin-right: 18px;
      width: 45px;
      height: 45px;
    }
    .card__data-text {
      h3, p {
        font-family: $font__main;
        color: $white;
        margin: 0;
      }
      a {
        text-decoration: none;
      }
    }
  }
  &-course {
    padding: 16px 20px;
    background-color: $white;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 22px;
    &:not(:last-child) {
      border-bottom: 2px solid $color__background-table-header;
    }
    p {
      margin: 0;
    }
    .center {
      padding: 0 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        margin-bottom: 6px;
        width: 24px;
        height: 24px;
        align-self: center;
      }
      span {
        color: $bg-btn;
        font-size: 12px;
        font-weight: 700;
      }
    }
    .course {
      text-align: center;
      h3 {
        margin: 0 0 .6em 0;
        color: $newDark;
      }
    }
    .progress {
      padding: 0 10px;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      &::before {
        content: '';
        margin: 0 auto 6px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: flex;
      }
      &.active {
        color: $active;
        &::before {
          background-color: $active;
        }
      }
      &.attention {
        color: $attention;
        &::before {
          background-color: $attention;
        }
      }
      &.finish {
        color: $finish;
        &::before {
          background-color: $finish;
        }
      }
    }
    &:last-child {
      border-radius: 0 0 20px 20px;
    }
  }
  @include tablet {
    &-course {
      display: grid;
      grid-template-columns: 100px 1fr 106px;
      align-items: center;
      .course {
        text-align: unset;
      }
    }
  }
}
</style>
