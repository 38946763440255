<template>
  <div class="main">
    <Loading v-if="isLoading" />
    <div v-else>
      <div class="csv__header">
        <h2 class="subtitle flex-grow">Campamentos / Talleres:</h2>
        <button class="tutor new-element compact" style="max-width: unset">
          <svg class="plus-icon" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.7758 19.504H19.6398V30.384H11.3838V19.504H0.31175V11.696H11.3838V0.815998H19.6398V11.696H30.7758V19.504Z" fill="#00ADCE"/>
          </svg>
          <router-link :to="{ name: 'camp-create' }">
          </router-link>
          Añadir nuevo campamento / taller
        </button>
        <button
          class="btn csv__button"
          type="button"
          @click="downloadCSV">
          <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
            <path d="M22 24h-20v-24h14l6 6v18zm-7-23h-12v22h18v-16h-6v-6zm3 15v1h-12v-1h12zm0-3v1h-12v-1h12zm0-3v1h-12v-1h12zm-2-4h4.586l-4.586-4.586v4.586z"/>
          </svg>
          <span class="csv__button__text">Descargar CSV</span>
        </button>
      </div>
      <div class="camps-list">
        <table class="admin__table" v-if="getCampsList.length">
          <thead class="admin__table__head">
            <tr>
              <th class="admin__table__head__cell">Nombre</th>
              <th class="admin__table__head__cell">Profesor</th>
              <th class="admin__table__head__cell">Año mínimo</th>
              <th class="admin__table__head__cell">Año máximo</th>
              <th class="admin__table__head__cell">Primer Pago</th>
              <th class="admin__table__head__cell"></th>
              <th class="admin__table__head__cell"></th>
            </tr>
          </thead>
          <tbody class="admin__table__body">
            <tr v-for="(camp, idx) in getCampsList" :key="idx">
              <td @click="showCampsDetail(camp.id)" class="admin__table__body__cell camps__table__cell cell-name"><span class="admin__table__body__cell__header">Nombre</span><span class="cell-content">{{camp.name}}</span></td>
              <td @click="showCampsDetail(camp.id)" class="admin__table__body__cell camps__table__cell"><span class="admin__table__body__cell__header">Profesor</span>{{camp.staffMember ? camp.staffMember.name : ''}}</td>
              <td @click="showCampsDetail(camp.id)" class="admin__table__body__cell camps__table__cell"><span class="admin__table__body__cell__header">Año mínimo</span>{{camp.yearStart}}</td>
              <td @click="showCampsDetail(camp.id)" class="admin__table__body__cell camps__table__cell"><span class="admin__table__body__cell__header">Año máximo</span>{{camp.yearEnd}}</td>
              <td @click="showCampsDetail(camp.id)" class="admin__table__body__cell camps__table__cell"><span class="admin__table__body__cell__header">Primer Pago</span>{{camp.firstPaymentPercentage}} %</td>
              <td @click="showCampsStudentsList(camp.id)" class="admin__table__body__cell camps__table__cell last-btn"><button class="btn students-btn">Ver alumnos</button></td>
              <td class="admin__table__body__cell camps__table__cell">
                <span class="admin__table__body__cell__header"></span>
                <button @click="deleteSelectedCamp(camp.id)" class="btn">Borrar</button>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else class="message__noData">
          <div class="message__noData-icon">
            <svg width="13" height="36" viewBox="0 0 13 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.880469 -2.38419e-06H12.1805L10.2805 22.25H2.73047L0.880469 -2.38419e-06ZM6.53047 35.45C4.8638 35.45 3.49714 34.95 2.43047 33.95C1.3638 32.9167 0.830469 31.6667 0.830469 30.2C0.830469 28.7 1.3638 27.4667 2.43047 26.5C3.49714 25.5333 4.8638 25.05 6.53047 25.05C8.19714 25.05 9.54714 25.5333 10.5805 26.5C11.6471 27.4667 12.1805 28.7 12.1805 30.2C12.1805 31.6667 11.6471 32.9167 10.5805 33.95C9.5138 34.95 8.1638 35.45 6.53047 35.45Z" fill="white"/>
            </svg>
          </div>
          <div class="message__noData-text">
            <p>No hay campamentos / talleres para mostrar</p>
            <p>Prueba seleccionando otro centro</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
// import { apiClient } from '../../services/ApiClient'
import Loading from '../../Loading.vue'

export default {
  name: 'Camps',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {
    Loading
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters('AdminCamps', ['getCampsList']),
    ...mapGetters('Admin', {
      userInfo: 'getUserInfo'
    }),
  },
  methods: {
    ...mapActions('AdminCamps', ['fetchCamps', 'deleteCamp']),
    ...mapActions('Admin',['fetchCSVToken']),
    showCampsDetail(campId) {
      this.$router.push({ name: 'camp-detail', params: { id: this.id, campId } })
    },
    showCampsStudentsList(campId) {
      this.$router.push({ name: 'camps-students', params: { id: this.id, campId } })
    },
    async deleteSelectedCamp(campId) {
      if (confirm('¿Estás seguro que quieres borrar este campamento/taller?')) {
        try {
          await this.deleteCamp({centerId: this.$route.params.id, campId })
          this.$toast.success('Campamento/taller eliminado correctamente.');
        } catch (error) {
          this.$toast.error('Este campamento/taller no se puede borrar.');
        }
      }
    },
    async downloadCSV() {
      try {
        const response = await this.fetchCSVToken()
        const csv = `${process.env.VUE_APP_API_BASE_URI}/admin/centers/${this.$route.params.id}/camps.csv?token=${response.data.token}`
        window.open(csv)
      } catch (error) {
        this.$toast.error('Lo sentimos, no hemos podido descargar el CSV, inténtelo de nuevo');
      }
    }
  },
  mounted() {
    this.isLoading = true
    this.fetchCamps(this.id)
    .then(() => {
      this.isLoading = false
    })
    .catch(error => {
      console.log('error', error);
      this.isLoading = true
    })
  }
}
</script>


<style scoped lang="scss">
  .camps {
    &__filters {
    align-items: center;
    display: flex;
    margin-bottom: 16px;
    position: relative;

    &__text {
      border: 2px solid $newPink;
      border-radius: 16px;
      flex-grow: 1;
      font-size: 12px;
      margin-right: 16px;
      max-width: 400px;
      padding: 8px;

      @include desktop {
        margin-bottom: 0;
        width: 40%;
      }
    }

    &__button {
      height: 34px;
      margin-left: auto;
      padding: 8px 16px;
      transition: border-bottom-left-radius .4s, border-bottom-right-radius .4s;

      &--open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      &__icon {
        fill: $white;
      }

      &:hover {
        svg {
          path {
            fill: $newBlue;
          }
        }
      }
    }

    &__container {
      background-color: white;
      border: 1px solid $newBlue;
      border-radius: 16px;
      border-top-right-radius: 0;
      font-size: 14px;
      margin: 0;
      position: absolute;
      right: 0;
      top: 34px;
    }

    @include desktop {
      flex-direction: row;
    }
  }

    &__error {
      background-color:  lighten(desaturate($newSalmon, 10), 15);
    }

    &__message {
      background-color: $newLightBlue;
      border: 1px solid $newBlue;
      border-radius: 16px;
      font-size: 14px;
      margin-top: 32px;
      padding: 12px;
      text-align: center;
    }
    &__table {
      &__cell {
        cursor: pointer;
      }

      &__icon {
        cursor: pointer;
      }
    }
  }
  .last-btn {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
  }
  .students-btn {
    padding: 10px;
  }
  @include desktop {
    .last-btn {
      display: revert;
    }
    .cell-name {
      width: 26%;
    }
  }
</style>
