<template>
  <main class="main">
    <Loading
      v-if="isLoading" />
    <div v-else>
      <div class="container-header">
        <h2 class="subtitle">{{labelFunc('header')}}</h2>
        <div class="explanation">
          <div class="emojis-text">
            <span>Working towards</span>
            <span>Achieved</span>
            <span>Exceeds expectations</span>
          </div>
          <div class="emojis">
            <span>😊</span>
            <span>😊😊</span>
            <span>😊😊😊</span>
          </div>
        </div>
      </div>
      <form action class="form admin__form reports" @submit.prevent="newReport">
        <div class="inputs-form">
          <div 
            class="class-wrapper" 
            :class="{ 'column-plus': userInfo.isSuperAdmin || userInfo.isSupervisor}"
          >
            <span>Elige un estudiante</span>
              <div class="class">
                <select class="form__field-input" @change="updateCheckedClass($event, 'student')">
                  <option selected value="">{{labelFunc('student')}}</option>
                  <option v-for="student in students" :key="student.id" :value="student.id">{{ student.name }} {{ student.surnames }}</option>
                </select>
              </div>
          </div>
          <div 
            v-if="userInfo.isSuperAdmin || userInfo.isSupervisor" class="class-wrapper" 
            :class="{ 'column-plus': userInfo.isSuperAdmin || userInfo.isSupervisor}"
          >
            <span>Elige un profesor</span>
            <div class="class">
              <select class="form__field-input" @change="updateCheckedClass($event, 'staffMember')">
                <option selected value="">{{labelFunc('staffMember')}}</option>
                <option v-for="member in staffMembers" :key="member.id" :value="member.id">{{ member.name }} {{ member.surnames }}</option>
              </select>
            </div>
          </div>
          <div class="class-wrapper" 
            :class="{ 'column-plus': userInfo.isSuperAdmin || userInfo.isSupervisor}"
          >
            <span>Elige un nivel</span>
            <div class="class">
              <select class="form__field-input" @change="updateCheckedClass($event, 'class')">
                <option selected value="">{{labelFunc('class')}}</option>
                <option v-for="category in classReports" :key="category.id" :value="category.id">{{ category.label }}</option>
              </select>
            </div>
          </div>
        </div>
        <table v-if="form.class && form.student" class="admin__table reports__table">
            <thead class="admin__table__head">
              <tr>
                <th></th>
                <th
                  v-for="(level, idx) in evaluationLevels"
                  :key="`level--${idx}`">
                  {{emojiLabel(level.value)}}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="reportLvl in reportLevels" :key="reportLvl.id">
                <tr
                  v-for="(legend, i) in levelSelected.legends"
                  :key="i">
                  <template v-if="classReports[i].id === reportLvl.id">
                    <td class="reports__table__aspect">
                      <span @click="evaluationHandler(i)">
                        <svg :class="{ 'collapsed': !isDescriptionHidden && !arrayOfIndex.includes(i)}" class="hidden" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" data-v-4ccc38fa="">
                          <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" data-v-4ccc38fa=""></path>
                        </svg>
                        <span>{{legendLabel(legend.score_title)}}</span>
                      </span>
                      <span v-if="!isDescriptionHidden && !arrayOfIndex.includes(i)" class="legend-description">
                        <p>{{legend.score_content_en}}</p>
                      </span>
                    </td>
                    <td
                      v-for="level in evaluationLevels"
                      :key="level.id"
                      class="emojis">
                      <input
                        type="radio" 
                        :name="legend.score_title" 
                        v-model="form.evaluation[i].value"
                        :checked="form.evaluation[i].value === level.value"
                        @change="handlerCheck(level.value, i)"
                      >
                    </td>
                  </template>
                </tr>
              </template>
            </tbody>
          </table>
        <div class="form__field-row">
          <div class="form__field-wrapper">
            <div class="form__field">
              <label class="form__field-label" for="#comment">Observaciones</label>
              <textarea
                v-model="v$.form.comment.$model"
                id="comment"
                class="form__field-input">
              </textarea>
            </div>
          </div>
        </div>
        <div class="buttons-container">
          <div class="saved-buttons">
            <button type="submit" class="btn btn-save" :class="{ 'bg-btn': studentReportToUpdate && !studentReportToUpdate.validatedAt}">Guardar</button>
            <button v-if="
              (userInfo.isSuperAdmin || userInfo.isSupervisor ) &&
              studentReportToUpdate && 
              !studentReportToUpdate.validatedAt" 
              @click="validateReport" class="btn btn-save">
              {{labelFunc('submit')}}
            </button>
          </div>
          <span type="submit" class="back" @click="$router.push({ name: 'reports' })">Volver</span>
        </div>
      </form>
    </div>

  </main>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import Loading from '@/components/Loading'
import { mapActions, mapGetters, mapState } from 'vuex'
import { aspectsToEvaluate, evaluationLevels, classReports } from '@/commonMethods/constants'
import { formatDateShort, legendLabel } from '@/commonMethods/methods'
import { emojiLabel } from '@/commonMethods/methods'

export default {
  name: "ReportCreate.vue",
  components: {
    Loading,
  },
  props: {
    studentId: {
      type: Number,
      default: null
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      asd: true,
      isLoading: false,
      isDescriptionHidden: false,
      isCreatingReport: false,
      studentReportToUpdate: null,
      form: {
        student: '',
        class: null,
        comment: '',
        staffMember: 0,
        evaluation: [
          {
            id: 1,
            value: ''
          },
          {
            id: 2,
            value: '',
          },
          {
            id: 3,
            value: '',
          },
          {
            id: 4,
            value: '',
          },
          {
            id: 5,
            value: '',
          }
        ]
      },
      levelSelected: [],
      arrayOfIndex: [],
      aspectsToEvaluate,
      evaluationLevels,
      classReports,
      emojiLabel,
      formatDateShort,
      legendLabel
    }
  },
  computed: {
    ...mapState('AdminCenter', ['reportLevels']),
    ...mapState('Admin', ['userInfo', 'staffMembers']),
    ...mapGetters('AdminCenter', {
      students: 'getStudentsByCenter',
      reports: 'getReports'
    }),
    formStudents() {
      return this.students.map(student => ({
        id: student.id,
        text: `${student.name} ${student.surnames}`
      }))
    },
  },
  validations() {
    return {
      form: {
        student: {
          required
        },
        class: {
          required
        },
        comment: {}
      }
    }
  },
  methods: {
    ...mapActions('AdminCenter', ['fetchStudents', 'getReportLeves', 'createReport', 'fetchReports', 'editReport', 'validateReportById']),
    ...mapActions('Admin', ['fetchStaffMembers']),
    updateCheckedClass(event, key) {
      if (key === 'class') {
        this.levelSelected = this.reportLevels.find(report => report.id == event.target.value)
      }
        this.form[key] = event.target.value
      if (this.userInfo.isSuperAdmin || this.userInfo.isSupervisor) this.form[key] = event.target.value
    },
    handlerCheck(level, i) {
      this.form.evaluation[i].value = level
    },
    labelFunc(type) {
      if (this.studentReportToUpdate) {
        const { label } = classReports.find(classroom => classroom.id === this.studentReportToUpdate.reportLevel.id)
        const teacherName = `${this.studentReportToUpdate.staffMember.name} ${this.studentReportToUpdate.staffMember.surnames}`
        const studentName = `${this.studentReportToUpdate.student.name} ${this.studentReportToUpdate.student.surnames}`
        if (type === 'student') return studentName
        if (type === 'class') return label
        if (type === 'staffMember') return teacherName
        if (type === 'header') return `Informe del ${formatDateShort(this.studentReportToUpdate.createdAt)}`
        if (type === 'submit') {
          if (this.userInfo.isSuperAdmin || this.userInfo.isSupervisor) {
            return 'Validar y enviar'
          } else {
            return 'Guardar'
          }
        }
      } else {
        if (type === 'class') return ''
        if (type === 'staffMember') return ''
        if (type === 'header') return 'Nuevo informe'
        if (type === 'submit') return 'Guardar'
      }
    },
    evaluationHandler(i) {
      if (this.arrayOfIndex.includes(i)) {
        let index = this.arrayOfIndex.indexOf(i)
        if (index !== -1) {
          this.arrayOfIndex.splice(index, 1)
        }
        this.isDescriptionHidden = false
      } else {
        this.arrayOfIndex.push(i)
      }
    },
    async newReport() {
      let form = null
      let isMonitor = true
      if (!this.v$.$invalid && !this.isCreatingReport) {
        try {
          this.isCreatingReport = true
          const selectedClass = this.classReports.find(_class => _class.id === parseInt(this.form.class))
          if (this.userInfo.isSuperAdmin || this.userInfo.isSupervisor) isMonitor = false
          if (!this.studentReportToUpdate) {
            let selectedStaffMember = null
            const selectedStudent = this.students.find(student => student.id === parseInt(this.form.student))
            if (this.userInfo.isSuperAdmin || this.userInfo.isSupervisor) {
              selectedStaffMember = this.staffMembers.find(member => member.id === parseInt(this.form.staffMember))
            }
            form = {
              ...this.form,
              student: selectedStudent.id,
              class: selectedClass.id,
              staffMember: selectedStaffMember ? selectedStaffMember.id : null
            }
            await this.createReport({ centerId: this.$route.params.id, report: form, isMonitor })
          } else {
            form = {
              ...this.form,
              student: parseInt(this.form.student),
              class: selectedClass.id
            }
            await this.editReport({ centerId: this.$route.params.id, reportId: this.studentReportToUpdate.id, report: form, isMonitor})
          }
          this.$toast.success('Informe guardado correctamente')
          await this.fetchReports(this.$route.params.id)
          setTimeout(() => {
            this.$router.push({ name: 'reports' })
          }, 3500)
        } catch(error) {
          console.error('error', error)
          this.$toast.error('Lo sentimos, no hemos podido generar el informe, inténtelo de nuevo.')
        } finally {
          this.isCreatingReport = false
        }
      }
    },
    async validateReport() {
      await this.validateReportById({ centerId: this.$route.params.id, reportId: this.studentReportToUpdate.id })
      await this.fetchReports(this.$route.params.id)
      this.$toast.success('Informe validado correctamente')
    }
  },
  async mounted() {
    try {
      this.isLoading = true
      await this.getReportLeves()
      await this.fetchStudents(this.$route.params.id)
      this.arrayOfIndex = Array.from(Array(this.reportLevels.length).keys())
      if (this.userInfo.isSuperAdmin || this.userInfo.isSupervisor) {
        this.fetchStaffMembers(this.$route.params.id)
      }
      if (parseInt(this.studentId)) {
        this.studentReportToUpdate = this.reports.find(student => student.id === parseInt(this.studentId))
        const level = classReports.find(classroom => classroom.id === this.studentReportToUpdate.reportLevel.id)
        this.levelSelected = this.reportLevels.find(report => report.id === level.id)
        this.form.comment = this.studentReportToUpdate.comment
        this.form.class = level.id
        this.form.staffMember = this.studentReportToUpdate.staffMember.id
        this.form.student = this.studentReportToUpdate.student.id
        this.form.evaluation[0].value = this.studentReportToUpdate.scorePronunciation
        this.form.evaluation[1].value = this.studentReportToUpdate.scoreListeningComprehension
        this.form.evaluation[2].value = this.studentReportToUpdate.scoreVocabulary
        this.form.evaluation[3].value = this.studentReportToUpdate.scoreFluency
        this.form.evaluation[4].value = this.studentReportToUpdate.scoreParticipation
      }
      } finally {
      this.isLoading = false
    }
  }
}
</script>

<style scoped lang="scss">
.header {
  padding: 0;
}
.reports {
  table {
    thead {
      display: revert;
    }
    tbody {
      td {
        display: revert;
      }
      tr {
        .emojis {
          width: 40px;
        }
       @include desktop {
        .emojis{
          width: 100px;
        }
       }
      }
    }
  }
  &__table {
    border: 1px solid $newBlue;
    margin: 50px 0 32px 0;
    &__aspect {
      padding: 8px;
      text-align: start;
      .legend-description {
        display: block;
        line-height: 20px;
        margin-top: -14px;
        margin-left: 20px;
        max-width: 400px;
        font-size: 15px;
      }
      svg {
        padding: 0 4px;
        fill: $newBlue;
        transform: rotate(-90deg);
        transition: .15s ease-in-out transform;
        &.collapsed {
            transform: rotate(0);
          }
      }
      @include desktop {
        .legend-description {
          max-width: 500px;
          margin-top: 10px;
          line-height: 22px;
        }
      }
    }
  }
  .buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .saved-buttons {
      display: flex;
      gap: 10px;
      .bg-btn {
        background-color: $white;
        color: $newGreen;
      }
      .bg-btn:hover {
        background-color: $newGreen;
        color: $white;
      }
    }
    .back {
      margin: 0;
      margin-bottom: 20px;
      color: #db4a78;
      cursor: pointer;
      font-family: "Londrina Solid", Arial, Helvetica, "Nimbus Sans L", sans-serif;
      font-size: 28px;
      text-decoration: underline;
    }
  }
}

.class-wrapper {
  color: $newBlue;
  text-align: start;

  span {
    font-weight: 700;
  }
  .class {
    display: flex;
    gap: 24px;
    select {
      width: 100%;
      @include desktop {
        width: 80%;
      }
    }
  }
  .form__field-input {
    font-size: 15px;
    margin-top: 10px;
  }
  @include tablet {
    .column-plus {
      min-width: 36% !important;
    }
    min-width: 50%;
  }
}
@include tablet {
  .column-plus {
    min-width: 36% !important;
  }
}
.class-wrapper:nth-child(3), .class-wrapper:nth-child(2) {
  margin-top: 30px;
  @include desktop {
    margin-top: 0;
  }
}
@include desktop {
  .admin {
    &__form {
      &__element {
        margin: 0;
        width: 100%;
        @include tablet {
          width: 70%;
        }
        @include desktop {
          width: 100%;
        }
      }
    }
  }
  .inputs-form {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
textarea {
  resize: none;
  padding: 6px 10px 10px 4px;
}

</style>