<template>
  <div class="main">
    <Loading v-if="isLoading" />
    <div v-else>
      <div class="csv__header">
        <h2 class="subtitle flex-grow">Servicios:</h2>
        <button class="new-element compact">
          <svg viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.7758 19.504H19.6398V30.384H11.3838V19.504H0.31175V11.696H11.3838V0.815998H19.6398V11.696H30.7758V19.504Z" fill="#00ADCE"/>
          </svg>
          <router-link :to="{ name: 'new-service' }">
          </router-link>
          Añadir nuevo servicio
        </button>
      </div>
      <table v-if="getServicesList.length" class="admin__table">
        <thead class="admin__table__head">
          <tr>
            <th v-for="(column, idx) in tableConfig"
              :key="`columnHeader--${idx}`"
              class="admin__table__head__cell"
            >{{column.label}}</th>
          </tr>
        </thead>
        <tbody class="admin__table__body">
        <tr
          v-for="(service, idx) in getServicesList"
          :key="`payment--${idx}`">
          <td v-for="(column, idx) in tableConfig" :key="`columnHeader--${idx}`"
            class="admin__table__body__cell"
            @click="showServiceDetail(service.id)">
            <span class="admin__table__body__cell__header">{{ column.label }}</span>{{ service[column.key] }}
            <template v-if="column.delete">
              <div @click.stop="deleteService(service.id)" class="btn">Borrar servicio</div>
            </template>
          </td>
        </tr>
        </tbody>
      </table>
      <div v-else class="message__noData">
        <div class="message__noData-icon">
          <svg width="13" height="36" viewBox="0 0 13 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.880469 -2.38419e-06H12.1805L10.2805 22.25H2.73047L0.880469 -2.38419e-06ZM6.53047 35.45C4.8638 35.45 3.49714 34.95 2.43047 33.95C1.3638 32.9167 0.830469 31.6667 0.830469 30.2C0.830469 28.7 1.3638 27.4667 2.43047 26.5C3.49714 25.5333 4.8638 25.05 6.53047 25.05C8.19714 25.05 9.54714 25.5333 10.5805 26.5C11.6471 27.4667 12.1805 28.7 12.1805 30.2C12.1805 31.6667 11.6471 32.9167 10.5805 33.95C9.5138 34.95 8.1638 35.45 6.53047 35.45Z" fill="white"/>
          </svg>
        </div>
        <div class="message__noData-text">
          <p>No hay servicios para mostrar</p>
          <p>Añade los servicios necesarios</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Loading from '../Loading.vue'

export default {
  name: 'ServiceEdit',
  components: {
    Loading
  },
  data() {
    return {
      tableConfig: [
        {
          label: 'Servicio',
          key: 'name'
        },
        {
          label: '',
          key: '',
          delete: true
        },
      ],
      isLoading: false
    }
  },
  computed: {
    ...mapGetters('Admin', ['getServicesList'])
  },
  methods: {
    ...mapActions('Admin', ['fetchServices', 'newService', 'deleteServiceByID']),
    showServiceDetail(serviceId) {
      this.$router.push({ name: 'service-detail', params: { serviceId } })
    },
    deleteService(serviceId) {
      if (confirm('¿Estás seguro que quieres borrar este servicio?')) {
        this.deleteServiceByID(serviceId)
        .then(() => this.$toast.success('Servicio eliminado correctamente.'))
        .catch(errors => {
          this.errors = errors
          this.$toast.error('Este servicio no se puede borrar.')
        })
      }
    }
  },
  mounted() {
    this.isLoading = true
    this.fetchServices(this.id)
    .then(() => {
      this.isLoading = false
    })
    .catch(() => {
      this.isLoading = true
    })
  }
}
</script>
<style lang="scss" scoped>
  .admin {
    &__table {
      &__body {
        &__cell {
          .btn {
            padding: 0 10px;
          }
          @include desktop {
            width: 70%;
            .btn {
              padding: 13px 10px;
            }
          }
        }
      }
    }
  }
</style>
