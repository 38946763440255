<template>
<div class="enrollment-confirmation-error-wrapper box">
  <div class="enrollment-confirmation-error">
    <h2 class="subtitle">¡Error en el pago!</h2>
    <router-link :to="{ name: 'parents-main-screen' }">
      <button class="btn">Volver al inicio</button> 
    </router-link>
  </div>
</div>
</template>

<script>
export default {
  name: 'PaymentError',
  props: {
    id: {
      type: String,
      required: true
    }
  },
}
</script>

<style scoped lang="scss">
.enrollment-confirmation-error-wrapper {
  padding: 40px 20px;
  .enrollment-confirmation-error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .btn {
      margin-bottom: 70px;
    }
    .text {
      margin-bottom: 50px;
      font-size: 20px;
    }
    .info-wrapper {
      background-color: $newYellow;
      display: flex;
      padding: 20px 25px;
      border-radius: 10px;
      max-width: 530px;
      img {
        margin-right: 20px;
        margin-top: 8px;
        width: 30px;
        height: 30px;
      }
      .info {
        p {
          margin: 0;
          &:first-child {
            font-weight: 700;
            margin-bottom: 1em;
          }
        }
      }
    }
  }
}
</style>
