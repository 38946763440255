<template>
  <main class="main">
    <Loading v-if="isLoading" />
    <div v-else>
      <h2 class="subtitle">Recuperación clases:</h2>
      <table class="admin__table" v-if="recoverClassSession.length !== 0">
        <thead class="admin__table__head">
          <tr>
            <th class="admin__table__head__cell">Centro</th>
            <th class="admin__table__head__cell">Curso y nivel</th>
            <th class="admin__table__head__cell">Día y hora</th>
            <th class="admin__table__head__cell"></th>
          </tr>
        </thead>
        <tbody class="admin__table__body">
          <tr v-for="(recoverSession, idx) in recoverClassSession" :key="idx">
            <td class="admin__table__body__cell"><span class="admin__table__body__cell__header">Centro</span><p>{{ recoverSession.center }}</p></td>  
            <td class="admin__table__body__cell"><span class="admin__table__body__cell__header">Curso y nivel</span><p>{{ recoverSession.service }}</p></td> 
            <td class="admin__table__body__cell"><span class="admin__table__body__cell__header">Día y hora</span><p>{{ reverseDate(recoverSession.date) }} a las {{ recoverSession.time }}</p></td>
            <td class="admin__table__body__cell"><button class="btn" @click="book(idx)">Elegir esta sesión</button></td>
          </tr>
        </tbody>
      </table>
      <div v-else class="message__noData">
        <div class="message__noData-icon">
          <svg width="13" height="36" viewBox="0 0 13 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.880469 -2.38419e-06H12.1805L10.2805 22.25H2.73047L0.880469 -2.38419e-06ZM6.53047 35.45C4.8638 35.45 3.49714 34.95 2.43047 33.95C1.3638 32.9167 0.830469 31.6667 0.830469 30.2C0.830469 28.7 1.3638 27.4667 2.43047 26.5C3.49714 25.5333 4.8638 25.05 6.53047 25.05C8.19714 25.05 9.54714 25.5333 10.5805 26.5C11.6471 27.4667 12.1805 28.7 12.1805 30.2C12.1805 31.6667 11.6471 32.9167 10.5805 33.95C9.5138 34.95 8.1638 35.45 6.53047 35.45Z" fill="white"/>
          </svg>
        </div>
        <div class="message__noData-text">
          <p>No tiene grupos donde recuperar la clase</p>
        </div>
      </div>

    </div>

  </main>
</template>

<script>
import { mapActions } from 'vuex'
import { reverseDate } from '../../commonMethods/methods'
import Loading from '../Loading.vue'

export default {
  name: 'StudentDetail',
  props: {
    id: {
      type: String,
      required: true
    },
    studentId: {
      type: String,
      required: true
    },
    missedSessionId: {
      type: String,
      required: true
    }
  },
  components: {
    Loading
  },
  data() {
    return {
      recoverClassSession: [],
      isLoading: false,
      reverseDate,
      errors: {}
    }
  },
  computed: {

  },
  methods: {
    ...mapActions('AdminCenter', ['studentListRecoverSessions', 'studentBookMissedSession']),
    book(idx) {
      let objBookSession = {
        recoveredGroup: this.recoverClassSession[idx].groupId,
        recoveredAt: this.recoverClassSession[idx].dateTimestamp
      }
      this.studentBookMissedSession({
        centerId: this.id,
        studentId: this.studentId,
        missedSessionId: this.missedSessionId,
        infoBookSession: objBookSession
      })
      .then(() => {
        this.$toast.success('Datos guardados correctamente');
        this.$router.push({ name: 'students' })
      })
      .catch(errors => {
        this.errors = errors
      })
    },
  },
  async mounted() {
    this.isLoading = true
    await this.studentListRecoverSessions({centerId: this.id, studentId: this.studentId, missedSessionId: this.missedSessionId})
    .then(response => {
      this.recoverClassSession = response ? response : []
      this.isLoading = false
    })
    .catch(error =>{
      console.log('error', error)
      this.isLoading = true
    })

  }
}
</script>
<style scoped lang="scss">

</style>
