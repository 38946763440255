import { apiClient } from '@/services/ApiClient'

const state = {
  students: [],
  studentReport: [],
  allergies: [],
  tutors: [],
  centers: [],
  detailedReport: null,
}

const actions = {
  addNewStudent({ commit }, student) {
    return new Promise((resolve, reject) => {
      apiClient.post('/tutor/students', student)
      .then(response => {
        commit('ADD_STUDENT', response.data)
        resolve(response.data.id)
      })
      .catch(err => {
        reject(err.response.data.errors)
      })
    })
  },
  async bookMissedSession(commit, {objBook, missedSession}) {
    await apiClient.post(`/tutor/missed-sessions/${missedSession}/recovered-group`, objBook)
    .then(() => {
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },

  async studentSetGroup({ commit }, { id, group }) {
    await apiClient.post(`/tutor/students/${id}/group`, { group })
    .then(response => {
      commit('User/SET_ACTIVE_PAYMENT', response.data, { root: true })
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },
  async setStudentsInfo({ commit, getters } , {id, profile}) {
    await apiClient.post(`/tutor/students/${id}/info`, profile)
    .then(() => {
      profile.allergies = profile.allergies.map(allergyId =>
        getters.getAllergies.find(allergy => allergy.id === allergyId)
      )
      commit('UPDATE_EDIT_STUDENT', {id, profile})
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },
  async fetchStudentInfo({ commit } , id) {
    await apiClient.get(`/tutor/students/${id}/info`)
    .then((response) => {
      const profile = response.data
      commit('UPDATE_EDIT_STUDENT', {id, profile})
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },
  async fetchAllergies({commit}) {
    try {
      const { data } = await apiClient.get('/tutor/allergies')
      commit('SET_ALLERGIES', data)
    } catch (error) {
      console.log('error', error)
    }
  },
  async paymentCenterCharge({ commit }, {centerId, groupId, studentId, typePaymentMethod, hasPaymentMethod, enrollmentForNow }) {
    try {
      const response = await apiClient.post(`/tutor/centers/${centerId}/groups/${groupId}/students/${studentId}/payment-methods/${typePaymentMethod}/charge?forNow=${enrollmentForNow}`, {hasPaymentMethod})
      if (response.status === 202) {
        commit('User/SET_PAYMENT_INFO', response.data, {root: true})
      }
      return response
    } catch (err) {
      throw err.response.data.errors
    }
  },
  async redsysPaymentMethods() {
    return await apiClient.get('/tutor/payment-method')
  },
  async savePaymentMethod({commit}, payload) {
    console.log(commit)
    let queryParams = Object.keys(payload).length
      ? Object.keys(payload).reduce((acc, iter) => {
          if (payload[iter]) {
            acc += `${acc ? '&' : ''}${iter}=${payload[iter]}`
          }
          return acc
        }, '')
      : ''

    if (queryParams) {
      queryParams = '?' + queryParams
    }
   try {
      const response = await apiClient.get(`/tutor/payment-method/save${queryParams}`)
      if (response.status === 202) {
        commit('User/SET_PAYMENT_INFO', response.data, {root: true})
      }
      return response
    } catch (err) {
      throw err.response.data.errors
    }
  },
  async fetchLegalTutors({ commit }) {
    const response = await apiClient.get('/tutor/alias')
    commit('SET_LEGAL_TUTORS', response.data)
  },
  async addNewTutor({commit}, tutor) {
    const response = await apiClient.post('/tutor/alias', tutor)
    commit('ADD_TUTOR', response.data)
  },
  async deleteTutor({commit}, tutorId) {
    await apiClient.delete(`/tutor/alias/${tutorId}`)
    commit('DELETE_LEGAL_TUTOR', tutorId)
  },
  async fetchCenters({commit}) {
    const centers = await apiClient.get('/tutor/centers')
    commit('SET_CENTERS', centers.data)
  },
  async fetchStudentReport({ commit }, studentId) {
    const { data } = await apiClient.get(`/tutor/students/${studentId}/reports`) 
    commit('SET_STUDENT_REPORT', data)
  },
  async fetchReportById({ commit }, {studentId, reportId }) {
    const { data } = await apiClient.get(`/tutor/students/${reportId}/reports/${studentId}`) 
    commit('SET_DETAILED_REPORT', data)
  }
}

const mutations = {
  ADD_STUDENT(state, student) {
    state.students.push(student)
  },
  INITIAL_STUDENT_INFO(state, students) {
    state.students = students
  },
  UPDATE_EDIT_STUDENT(state, {id, profile}) {
    const index = state.students.findIndex(student => student.id == id)
    state.students[index] = {
      ...state.students[index],
      name: profile.name,
      surnames: profile.surnames,
      birthday: profile.birthday,
      imageAuthorized: profile.imageAuthorized,
      observations: profile.observations,
      allergies: profile.allergies ? profile.allergies : [],
      personsAuthorized: profile.personsAuthorized
    }
  },
  RESET_STUDENTS(state) {
    state.students = []
  },
  SET_ALLERGIES(state, allergies) {
    state.allergies = allergies
  },
  SET_LEGAL_TUTORS(state, tutors) {
    state.tutors = tutors
  },
  ADD_TUTOR(state, newTutor) {
    state.tutors.push(newTutor)
  },
  DELETE_LEGAL_TUTOR(state, tutorId) {
    const tutorIndex = state.tutors.findIndex(tutor => tutor.id == tutorId)
    state.tutors.splice(tutorIndex, 1)
  },
  SET_CENTERS(state, centers) {
    state.centers = centers
  },
  SET_STUDENT_REPORT(state, report) {
    state.studentReport = report
  },
  SET_DETAILED_REPORT(state, report) {
    state.detailedReport = report
  }
}

const getters = {
  getStudents(state) {
    return state.students
  },
  getActiveStudent: (state) => (id) => {
    // const activeStudent = state.students.find(student => student.id == id)
    return state.students.find(student => student.id == id)
  },
  getAllergies: (state) => state.allergies,
  getEnrollmentsInfo: () => (id) => {
    return apiClient.get(`/tutor/students/${id}/enrollments`)
  },
  getMissedSessions: () => (id) => {
    return apiClient.get(`/tutor/students/${id}/missed-sessions`)
  },
  getSessions: () => (missedSessionId) => {
    return apiClient.get(`/tutor/group/missed-sessions/${missedSessionId}/recover-sessions`)
  },
  getCenters() {
    return apiClient.get('/tutor/centers')
  },
  getGroupsByCenterAndStudent: () => (centerId, studentId) => {
    return apiClient.get(`/tutor/centers/${centerId}/students/${studentId}/groups`)
  },
  getStudentInfoByID: () => (id) => {
    return apiClient.get(`/tutor/students/${id}/info`)
  },
  getLegalTutors: (state) => state.tutors,
  getTutorCenters: (state) => state.centers
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
