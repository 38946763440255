<template>
  <div class="main">
    <form class="form admin__form" action @submit.prevent="updateLeadForm">
      <div class="data-row space-between">
        <h2 class="subtitle">Editar Leads:</h2>
        <button type="submit" class="btn btn-save">Guardar</button>
      </div>
      <div class="form__field-row half">
        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.nameTutor.$invalid && v$.form.nameTutor.$dirty && !errors.nameTutor">
              Por favor, introduce un nombre
            </p>
            <label class="form__field-label" for="#nameTutor">Nombre y apellido tutor legal *</label>
            <input
              v-model="v$.form.nameTutor.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.nameTutor.$invalid && v$.form.nameTutor.$dirty}"
              type="text"
              id="nameTutor"
              required
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('nameTutor')">
            <p v-for="(error, key) in errors.nameTutor" :key="key">{{ $t(error) }}</p>
          </div>
        </div>

        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.nameStudent.$invalid && v$.form.nameStudent.$dirty && !errors.nameTutor">
              Por favor, introduce un nombre de alumn@
            </p>
            <label class="form__field-label" for="#nameStudent">Nombre alumn@ *</label>
            <input
              v-model="v$.form.nameStudent.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.nameStudent.$invalid && v$.form.nameStudent.$dirty}"
              type="text"
              id="nameStudent"
              required
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('nameStudent')">
            <p v-for="(error, key) in errors.nameStudent" :key="key">{{ $t(error) }}</p>
          </div>
        </div>
      </div>

      <div class="form__field-row half">
        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.phone.$invalid && v$.form.phone.$dirty && !errors.nameTutor">
              Por favor, introduce un teléfono válido
            </p>
            <label class="form__field-label" for="#phone">Móvil *</label>
            <input
              v-model="v$.form.phone.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.phone.$invalid && v$.form.phone.$dirty}"
              type="number"
              id="phone"
              required
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('phone')">
            <p v-for="(error, key) in errors.phone" :key="key">{{ $t(error) }}</p>
          </div>
        </div>

        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.phoneSecondary.$invalid && v$.form.phoneSecondary.$dirty">
              Por favor, introduce un teléfono válido
            </p>
            <label class="form__field-label" for="#phoneSecondary">Segundo teléfono</label>
            <input
              v-model="v$.form.phoneSecondary.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.phoneSecondary.$invalid && v$.form.phoneSecondary.$dirty}"
              type="number"
              id="phoneSecondary"
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('phoneSecondary')">
            <p v-for="(error, key) in errors.phoneSecondary" :key="key">{{ $t(error) }}</p>
          </div>
        </div>

      </div>

      <div class="form__field-row half">
        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.email.$invalid && v$.form.email.$dirty && !errors.nameTutor">
              Por favor, introduce un email válido
            </p>
            <label class="form__field-label" for="#email">Email *</label>
            <input
              v-model="v$.form.email.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.email.$invalid && v$.form.email.$dirty}"
              type="email"
              id="email"
              required
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('email')">
            <p v-for="(error, key) in errors.email" :key="key">{{ $t(error) }}</p>
          </div>
        </div>

        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.emailSecondary.$invalid && v$.form.emailSecondary.$dirty">
              Por favor, introduzca un email válido
            </p>
            <label class="form__field-label" for="#emailSecondary">Segundo email</label>
            <input
              v-model="v$.form.emailSecondary.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.emailSecondary.$invalid && v$.form.emailSecondary.$dirty}"
              type="email"
              id="emailSecondary"
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('emailSecondary')">
            <p v-for="(error, key) in errors.emailSecondary" :key="key">{{ $t(error) }}</p>
          </div>
        </div>
      </div>

      <div class="form__field-row half">
        <div class="form__field-wrapper">
          <div class="form__field">
            <label class="form__field-label" for="#phone">Interesado en: *</label>
            <select
              v-model="v$.form.service.$model"
              class="form__field-input"
              name="service"
              id="service"
              @click="removeErrors"
            >
              <option v-for="(service,idx) in formServices" :key="idx" :value="service.value">{{service.label}}</option>
            </select>
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('service')">
            <p v-for="(error, key) in errors.service" :key="key">{{ $t(error) }}</p>
          </div>
        </div>

        <div class="form__field-wrapper">
          <div class="form__field">
            <label class="form__field-label" for="#year">Año de nacimiento alumn@: *</label>
            <input
              v-model="v$.form.year.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.year.$invalid && v$.form.year.$dirty}"
              type="number"
              id="year"
              required
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('year')">
            <p v-for="(error, key) in errors.year" :key="key">{{ $t(error) }}</p>
          </div>
          <p class="input-errors" v-if="v$.form.year.$invalid && v$.form.year.$dirty">
            La longitud de este campo es de 4 dígitos
          </p>
        </div>
      </div>

      <div class="radios-wrapper">
        <label class="radios" :for="status"  v-for="(status, idx) in formStatus" :key="idx">
          <input type="radio" :id="status" :value="getStatus[status]" v-model="form.status">
          {{status}}
        </label>
      </div>

      <div class="form__field-row">
        <div class="form__field-wrapper">
          <div class="form__field">
            <label class="form__field-label" for="#observations">Observaciones del cliente *</label>
            <textarea
                v-model="v$.form.text.$model"
                id="observations"
                class="form__field-input">
            </textarea>
          </div>
          <p class="input-errors" v-if="v$.form.text.$invalid && v$.form.text.$dirty">
            El campo observaciones del cliente necesita contener información
          </p>
        </div>
      </div>

      <div class="form__field-row">
        <div class="form__field-wrapper">
          <div class="form__field">
            <label class="form__field-label" for="#comments">Comentarios internos *</label>
            <textarea
                v-model="v$.form.message.$model"
                id="comments"
                class="form__field-input">
            </textarea>
          </div>
        </div>
      </div>
    </form>

    <h3>Asigna una sesión de prueba</h3>
    <Loading v-if="isLoading" />
    <table v-else-if="recoverClassSession.length > 0" class="admin__table">
      <thead class="admin__table__head">
        <tr>
          <th class="admin__table__head__cell">Servicio</th>
          <th class="admin__table__head__cell">Fecha</th>
          <th class="admin__table__head__cell">Hora</th>
          <th class="admin__table__head__cell"></th>
        </tr>
      </thead>
      <tbody class="admin__table__body">
        <tr v-for="(session, idx) in recoverClassSession" :key="idx">
          <td class="admin__table__body__cell">
            <span class="admin__table__body__cell__header">Grupo</span>
            {{ session.groupDescription }}
          </td>
          <td class="admin__table__body__cell">
            <span class="admin__table__body__cell__header">Fecha</span>
            {{ reverseDate(session.date) }}
          </td>
          <td class="admin__table__body__cell">
            <span class="admin__table__body__cell__header">Hora</span>
            {{ session.time }}
          </td>
          <td class="admin__table__body__cell">
            <button class="btn" @click="book(idx)">Reservar esa sesión</button>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else class="message__noData">
      <div class="message__noData-icon">
        <svg width="13" height="36" viewBox="0 0 13 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.880469 -2.38419e-06H12.1805L10.2805 22.25H2.73047L0.880469 -2.38419e-06ZM6.53047 35.45C4.8638 35.45 3.49714 34.95 2.43047 33.95C1.3638 32.9167 0.830469 31.6667 0.830469 30.2C0.830469 28.7 1.3638 27.4667 2.43047 26.5C3.49714 25.5333 4.8638 25.05 6.53047 25.05C8.19714 25.05 9.54714 25.5333 10.5805 26.5C11.6471 27.4667 12.1805 28.7 12.1805 30.2C12.1805 31.6667 11.6471 32.9167 10.5805 33.95C9.5138 34.95 8.1638 35.45 6.53047 35.45Z" fill="white"/>
        </svg>
      </div>
      <div class="message__noData-text">
        <p>No hay grupos para asignar sesión de prueba</p>
      </div>
    </div>
  </div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email, maxLength, minLength, numeric } from '@vuelidate/validators'
import { mapActions, mapGetters } from 'vuex'
import { reverseDate } from '@/commonMethods/methods'
import Loading from '../Loading.vue'

export default {
  name: 'LeadForm',
  props: {
    lead: {
      type: Object,
      default: () => ({})
    },
    id: {
      type: String,
      required: true
    },
    leadId: {
      type: String,
      required: true
    }
  },
  components: {
    Loading
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      form: {
        nameTutor: '',
        nameStudent: '',
        phone: '',
        phoneSecondary: '',
        email: '',
        emailSecondary: '',
        service: '',
        year: '',
        text: '',
        message: '',
        status: null,
      },
      errors: {},
      reverseDate,
      isLoading: false,
      recoverClassSession: []
    }
  },
  validations() {
    return {
      form: {
        nameTutor: {
          required
        },
        nameStudent: {
          required
        },
        phone: {
          numeric,
          required
        },
        phoneSecondary: {
          numeric
        },
        email: {
          required,
          email
        },
        emailSecondary: {
          email
        },
        service: {
          required
        },
        year: {
          required,
          maxLength: maxLength(4),
          minLength: minLength(4)
        },
        text: {
          required,
        },
        message: {}
      }
    }
  },
  watch: {
    getLead: {
      immediate: true,
      handler(newLead) {
        this.form = newLead
        const service = {...newLead.service}
        this.form.service = service.id
      }
    }
  },
  methods: {
    ...mapActions('Admin', ['fetchServices', 'fetchGroupsSessions', 'leadSetTrialSession']),
    ...mapActions('Leads', ['fetchLead', 'delLead', 'updateLead', 'leadStatus']),
    updateLeadForm() {
      if(!this.v$.$invalid) {
        if(this.form.status === "null") {
          this.form.status = null
        }
        const newForm = {
          'nameTutor': this.form.nameTutor,
          'nameStudent': this.form.nameStudent,
          'service': this.form.service,
          'text': this.form.text,
          'year': this.form.year,
          'email': this.form.email,
          'emailSecondary': this.form.emailSecondary,
          'phone': this.form.phone,
          'phoneSecondary': this.form.phoneSecondary,
          'status': this.form.status,
          'message': this.form.message
        }
        const leadId = this.leadId
        const centerId = this.id
        this.updateLead({newForm, leadId, centerId})
        .then(() => {
          this.$toast.success('Datos guardados correctamente');
          setTimeout(() => {
            this.$router.push({ name: 'leads' })
          }, 3000)
        })
        .catch(error => {
          this.errors = error
        })
      }
    },
    removeErrors() {
      this.errors = ''
    },
    async book(idx) {
      let infoSession = {
        group: this.recoverClassSession[idx].groupId,
        date: this.recoverClassSession[idx].dateTimestamp
      }
      this.leadSetTrialSession({ centerId: this.id, leadId: this.leadId, infoSession })
      .then(() => {
        this.$toast.success('Datos guardados correctamente');
        this.$router.push({ name: 'leads', params: { id: this.id } })
      })
      .catch(errors => {
        this.errors = errors
      })
    }
  },
  computed: {
    ...mapGetters('Admin', ['getServicesList', 'getGroupsSessions']),
    ...mapGetters('Leads', ['getLead', 'getStatus']),
    formServices() {
      return this.getServicesList.map(service => ({
        value: service.id,
        label: service.name
      }))
    },
    formStatus() {
      const keys = Object.keys(this.getStatus)
      return keys
    }
  },
  mounted() {
    this.isLoading = true
    this.fetchServices()
    const info = {
      id: this.id,
      leadId: this.leadId
    }
    this.fetchLead(info)
    this.leadStatus()
    this.fetchGroupsSessions({centerId: this.id})
    .then(() => {
      this.recoverClassSession = this.getGroupsSessions
    })
    .catch(error => {
      console.log('error', error)
    })
    .finally(() => {
      this.isLoading = false
    })
  }
}
</script>

<style scoped lang="scss">
.radios-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 40px;
  gap: 20px;
  .radios {
    background-color: $newBlue;
    padding: 15px 20px;
    border-radius: 16px;
    color: $white;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    input {
      margin: 0 10px 0 0;
    }
  }
  @include tablet {
    flex-direction: row;
    justify-content: space-between;
  }
}
</style>
