<template>
  <div class="main teacher">
    <h2 class="subtitle">Registrar profesor</h2>
    <TeacherForm
      :formCenters="formCenters"
      :hasRegister="true"
      :errors="formErrors"
      @submit="signUpTeacher"
    ></TeacherForm>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import TeacherForm from '@/components/Partials/TeacherForm'

export default {
    name: 'TeachersSignUp',
    setup () {
      return { v$: useVuelidate() }
    },
    components: {
      TeacherForm
    },
    data() {
      return {
        formErrors: {}
      }
    },
    computed: {
      ...mapGetters('Admin', {
        centers: 'getCentersList'
      }),
      formCenters() {
        return this.centers.map(center => ({
          value: center.id,
          label: center.name
        }))
      }
    },
    methods: {
      ...mapActions('Admin', ['fetchCenters', 'signUpStaffMember']),
      async signUpTeacher(newTeacher) {
        try {
          await this.signUpStaffMember({ centerId: this.$route.params.id, newTeacher })
          this.$toast.success('Datos enviados correctamente')
          setTimeout(() => {
            this.$router.push({ name: 'teachers', params: { id: this.$route.params.id } })
          }, 3000)
        } catch (errors) {
          this.formErrors = errors
        }
      }
    },
    async mounted() {
      try {
        await this.fetchCenters()
      } catch (error) {
        console.log('error', error)
      }
    }
}
</script>

<style lang="scss" scoped>
  .teacher {
    &__switch {
      margin-bottom: 0;
      font-weight: 900;
    }

    &__multiselect {
      margin: 0;
    }
  }
</style>
