import { apiClient } from '@/services/ApiClient'

const state = {
  studentsByCenterId: [],
  allergies: [],
  student: {},
  missedSessions: [],
  listMissedSessions:[],
  reportLevels: [],
  reports: []
}

const actions = {
  async fetchStudents( {commit} , centerId) {
    await apiClient.get(`/admin/centers/${centerId}/students`)
    .then(response => {
      commit('SET_STUDENTS_BY_CENTER', response.data)
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },
  async fetchAllergies({ commit }) {
    await apiClient.get('/admin/allergies')
    .then(response => {
      commit('SET_ALLERGIES', response.data)
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },
  async addNewStudent(commit, infoNewStudent) {
    await apiClient.post(`/admin/centers/1/groups/1/students`, infoNewStudent.form)
    .then(() => {
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },
  async updateStudent(commit, { centerId, studentId, infoUpdate}) {
    await apiClient.patch(`/admin/centers/${centerId}/students/${studentId}`, infoUpdate)
    .then(() => {
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },
  async createStudent(commit, { centerId, student }) {
    try {
      await apiClient.post(`/admin/centers/${centerId}/students`, student)
    } catch(err) {
      throw err.response.data.errors
    }
  },
  async fetchStudent({ commit }, infoGetStudent) {
    return new Promise((resolve, reject) => {
      apiClient.get(`/admin/centers/${infoGetStudent.id}/students/${infoGetStudent.studentId}`)
      .then(response => {
        commit('SET_STUDENT', response.data)
        resolve(response.data)
      })
      .catch(err => {
        reject(err.response.data.errors)
      })
    })
  },
  async studentMissedSessions({ commit }, infoStudent) {
    await apiClient.get(`/admin/centers/${infoStudent.id}/students/${infoStudent.studentId}/missed-sessions`)
    .then(response => {
      commit('SET_MISSED_SESSIONS_BY_STUDENT', response.data)
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },
  async studentListRecoverSessions({ commit }, { centerId, studentId, missedSessionId } ) {
    return new Promise((resolve, reject) => {
      apiClient.get(`/admin/centers/${centerId}/students/${studentId}/missed-sessions/${missedSessionId}`)
      .then(response => {
        commit('SET_LIST_RECOVER_SESSIONS', response.data)
        resolve(response.data)
      })
      .catch(err => {
        reject(err.response.data.errors)
      })
    })
  },
  async studentBookMissedSession(commit, { centerId, studentId, missedSessionId, infoBookSession}) {
    await apiClient.post(`/admin/centers/${centerId}/students/${studentId}/missed-sessions/${missedSessionId}`, infoBookSession)
    .then(() => {
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },
  async getReportLeves({ commit }) {
    const { data } = await apiClient.get('/admin/report-levels')
    commit('GET_REPORT_LEVELS', data)
  },
  async createReport({ commit }, {centerId, report, isMonitor}) {
    const apiForm = {
      student: report.student,
      reportLevel: report.class,
      comment: report.comment,
      staffMember: report.staffMember,
      scorePronunciation: report.evaluation[0].value,
      scoreListeningComprehension: report.evaluation[1].value,
      scoreVocabulary: report.evaluation[2].value,
      scoreFluency: report.evaluation[3].value,
      scoreParticipation: report.evaluation[4].value
    }
    if (isMonitor) {
      delete apiForm.staffMember
    }
    await apiClient.post(`/admin/centers/${centerId}/reports`, apiForm)
    commit('SET_REPORT', report)
  },
  async editReport({ commit }, {centerId, reportId, report, isMonitor}) {
    const apiForm = {
      student: report.student,
      reportLevel: report.class,
      comment: report.comment,
      staffMember: report.staffMember,
      scorePronunciation: report.evaluation[0].value,
      scoreListeningComprehension: report.evaluation[1].value,
      scoreVocabulary: report.evaluation[2].value,
      scoreFluency: report.evaluation[3].value,
      scoreParticipation: report.evaluation[4].value
    }
    if (isMonitor) {
      delete apiForm.staffMember
    }
    await apiClient.patch(`/admin/centers/${centerId}/reports/${reportId}`, apiForm)
    commit('SET_REPORT', report)
  },
  async fetchReports({ commit }, centerId) {
    const { data } = await apiClient.get(`/admin/centers/${centerId}/reports`)
    commit('GET_REPORTS', data)
  },
  async validateReportById({ commit }, { centerId, reportId }) {
    await apiClient.patch(`/admin/centers/${centerId}/reports/${reportId}/validate`)
    commit('')
  }
}

const mutations = {
  SET_STUDENTS_BY_CENTER(state, studentsByCenterId) {
    state.studentsByCenterId = studentsByCenterId
  },
  SET_ALLERGIES(state, allergies) {
    state.allergies = allergies
  },
  SET_STUDENT(state, student) {
    state.student = student
  },
  SET_MISSED_SESSIONS_BY_STUDENT(state, missedSessions) {
    state.missedSessions = missedSessions
  },
  SET_LIST_RECOVER_SESSIONS(state, listMissedSessions) {
    state.listMissedSessions = listMissedSessions
  },
  GET_REPORT_LEVELS(state, levels) {
    state.reportLevels = levels
  },
  SET_REPORT(state, report) {
    state.reports.push(report)
  },
  GET_REPORTS(state, reports) {
    state.reports = reports
  }
}

const getters = {
  getStudentsByCenter: (state) => state.studentsByCenterId,
  getAllergies: (state) => state.allergies,
  getStudent: (state) => state.student,
  getMissedSessions: (state) => state.missedSessions,
  getListMissedSessions: (state) => state.listMissedSessions,
  getReports: (state) => state.reports
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
