<template>
  <div class="recover-code-wrapper box">

    <h2 class="subtitle">Nueva contraseña</h2>
    <p class="text">Para reestablecer la contraseña, introduce el código de validación y la nueva contraseña</p>
    <form action class="form" @submit.prevent="recoverPasswordCode">
      <div class="form__field-wrapper">
        <div class="form__field">
          <label class="form__field-label form__code__label" for="code">Código de verificación *</label>
          <input
              class="form__field-input form__code__input"
              type="number"
              id="code"
              v-model="v$.form.code.$model"
              minlength="6"
              maxlength="6"
              required>
          <p class="input-errors" v-if="v$.form.code.$invalid && v$.form.code.$dirty">
            El código es incorrecto, debe tener 6 caracteres
          </p>
        </div>
      </div>

      <div class="form__field-row">
        <div class="form__field-wrapper">
          <p class="input-errors" v-if="v$.form.password.$invalid && v$.form.password.$dirty">
            Tu contraseña debe tener al menos 8 caracteres
          </p>
          <div class="form__field">
            <label class="form__field-label" for="#password">Nueva contraseña</label>
            <input
              v-model="v$.form.password.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.password.$invalid && v$.form.password.$dirty}"
              type="password"
              id="password"
              placeholder=""
              required
            >
          </div>
        </div>

        <div class="form__field-wrapper">
          <p class="input-errors" v-if="v$.form.repeatPassword.$invalid && v$.form.repeatPassword.$dirty">
            La contraseña debe coincidir
          </p>
          <div class="form__field">
            <label class="form__field-label" for="#repeatPassword">Repetir contraseña</label>
            <input
              v-model="v$.form.repeatPassword.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.repeatPassword.$invalid && v$.form.repeatPassword.$dirty}"
              type="password"
              id="repeatPassword"
              placeholder=""
              required
            >
          </div>
        </div>
      </div>
      
      <button :class="['btn', {'disabled': isFormSending}]">Enviar</button>

      <div class="errors" v-for="(error, key) in errors" :key="key">
        <p>{{ $t(error) }}</p>
      </div>
    </form>

  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, sameAs, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'

export default {
  name: 'RecoverPasswordCode',
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      form: {
        password: '',
        repeatPassword: '',
        code: '',
      },
      errors: [],
      isFormSending: false
    }
  },
  validations() {
    return {
      form: {
        password: {
          required,
          minLength: minLength(8)
        },
        repeatPassword: {
          required,
          sameAsPassword: sameAs(this.form.password)
        },
        code: {
          required,
          minLength: minLength(6),
          maxLength: maxLength(6)
        }
      }
    }
  },
  methods: {
    ...mapActions ('Auth', {
      recoverPass: 'recoverConfirmation'
    }),
    async recoverPasswordCode() {
      if(!this.v$.$invalid && !this.isFormSending) {
        try {
          this.isFormSending = true
          await this.recoverPass({
            code: parseInt(this.form.code),
            password: this.form.password
          })
          await this.$router.push({name: 'parents-main-screen'})
        } catch (errors) {
          this.errors = ['Lo sentimos, no hemos podidos actualizar la contraseña, inténtelo de nuevo']
        } finally {
          this.isFormSending = false
        }
      }
    },
  } 
}
</script>

<style scoped lang="scss">
.recover-code-wrapper {
  text-align: center;
  .text {
    max-width: 100%;
    margin: 0 auto 3em;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  .form {
    align-items: center;
    display: flex;
    border-radius: 4px;
    flex-direction: column;
    max-width: 600px;
    margin: 0 auto;
    &__field-row {
      display: flex;
      flex-wrap: wrap;
      gap: 0 20px;
      width: 100%;
      .form__field-wrapper {
        width: 100%;
        &.form__field-code {
          width: 100%;
        }
        .form__field {
          display: flex;
          flex-direction: column;
          .form__field-input {
            width: unset;
          }
        }
      }
    }
    .form__code {

      &__input {
        color: $bg-blue;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 3px;
        min-width: 200px;
        text-align: center;
      }
    }
  }
  .btn {
    margin-bottom: 3em;
    min-width: 220px;
  }
  @include tablet {
    .text {
      max-width: 60%;
    }
    .form {
      &__field-wrapper {
        .input-errors {
          max-width: 300px;
        }
      }
      &__field {
        .form-input {
          min-width: 300px;
        }
      }
      &__field-row {
        justify-content: space-between;
        .form__field-wrapper {
          width: 48%;
        }
      }
    }
  }
  @include desktop {
    .text {
      max-width: 40%;
    }
  }
}
.errors {
  text-align: center;
}

</style>
