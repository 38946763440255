<template>
  <main class="main">
    <Loading v-if="isLoading" />
    <div v-else>
      <form action class="form admin__form" @submit.prevent="updateStudentSubmit">
        <div class="data-row space-between">
          <h2 class="subtitle">Detalle del alumn@:</h2>
          <button type="submit" :disabled="isLoading" class="btn btn-save">Guardar</button>
        </div>
        <div class="form__field-row half">
          <div class="form__field-wrapper">
            <div class="form__field">
              <p class="input-errors" v-if="v$.form.name.$invalid && v$.form.name.$dirty">
                Por favor, introduce un nombre
              </p>
              <label class="form__field-label" for="#name">Nombre *</label>
              <input
                v-model="v$.form.name.$model"
                class="form__field-input"
                :class="{'form__input--invalid': v$.form.name.$invalid && v$.form.name.$dirty}"
                type="text"
                id="name"
                required
                placeholder=""
              >
            </div>
            <div class="errors" v-if="errors.hasOwnProperty('name')">
              <p v-for="(error, key) in errors.name" :key="key">{{ $t(error) }}</p>
            </div>
          </div>
          <div class="form__field-wrapper">
            <div class="form__field">
              <p class="input-errors" v-if="v$.form.surnames.$invalid && v$.form.surnames.$dirty">
                Por favor, introduce los apellidos
              </p>
              <label class="form__field-label" for="#surnames">Apellidos *</label>
              <input
                v-model="v$.form.surnames.$model"
                class="form__field-input"
                :class="{'form__input--invalid': v$.form.surnames.$invalid && v$.form.surnames.$dirty}"
                type="text"
                id="surnames"
                required
                placeholder=""
              >
            </div>
            <div class="errors" v-if="errors.hasOwnProperty('surnames')">
              <p v-for="(error, key) in errors.surnames" :key="key">{{ $t(error) }}</p>
            </div>
          </div>
        </div>
        <div class="form__field-wrapper">
          <div class="form__field">
            <label class="form__field-label">Fecha de nacimiento *</label>
            <datepicker
              v-model="form.birthday"
              inputFormat="dd/MM/yyyy"
              :style="useInputStyles"
              :locale="dateLocale"
            />
          </div>
        </div>
        <Switch
          v-model:checked="form.imageAuthorized"
          @onChangeSwitch="confirmationMessage"
          label="Autorizo a que se tomen y publiquen fotos del alumn@" />
        <div class="form__field">
          <label class="form__field-label" for="#allergies">Alergias</label>
          <Multiselect
            class="multiselect"
            v-model="v$.form.allergies.$model"
            mode="tags"
            id="allergies"
            :searchable="true"
            :createTag="false"
            :options="formAllergies"
            :disabled="isLoadingAllergies"
            :placeholder="isLoadingAllergies ? 'Cargando elementos' : 'Seleciona alergias'"
          />
        </div>
        <h3>Personas autorizadas</h3>
        <div class="form__field-row person-authorized" v-for="(personAuthorized, index) in personsAuthorized" :key="index">
          <div class="form__field-wrapper full-name">
            <div class="form__field">
              <label class="form__field-label" for="#name">Nombre y apellidos *</label>
              <input
                v-model="personAuthorized.name"
                class="form__field-input"
                type="text"
                id="name"
                required
                @click="removeErrors"
              >
            </div>
          </div>
          <div class="form__field-wrapper">
            <div class="form__field">
              <label class="form__field-label" for="#dni">DNI *</label>
              <input
                v-model="personAuthorized.dni"
                class="form__field-input"
                type="text"
                id="dni"
                required
                @click="removeErrors"
              >
            </div>
            <div class="errors errors-input" v-if="errors[index] && errors[index].dni">
              <p v-for="(error, key) in errors[index].dni" :key="key">{{ $t(error) }}</p>
            </div>
          </div>
          <div class="form__field-wrapper">
            <div class="form__field">
              <label class="form__field-label" for="#phone">Teléfono *</label>
              <input
                v-model="personAuthorized.phone"
                class="form__field-input"
                type="text"
                id="phone"
                required
                @click="removeErrors"
              >
            </div>
            <div class="errors errors-input" v-if="errors[index] && errors[index].phone">
              <p v-for="(error, key) in errors[index].phone" :key="key">{{ $t(error) }}</p>
            </div>
          </div>
          <div class="button__container">
            <button type="submit" class="btn btn-add delete" @click="deletePersonAuthorized(index)">Borrar</button>
          </div>
        </div>
        <div class="new-element" @click="addPersonAuthorized">
          <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.7758 19.504H19.6398V30.384H11.3838V19.504H0.31175V11.696H11.3838V0.815998H19.6398V11.696H30.7758V19.504Z" fill="#00ADCE"/>
          </svg>
            Añadir persona autorizada
        </div>
        <h3>Observaciones del alumn@</h3>
        <div class="form__field-wrapper">
          <div class="form__field">
            <textarea
              v-model="form.observations"
              placeholder=""
              rows="8"
              no-resize
              class="form__field-input text-area"
            >
            </textarea>
          </div>
        </div>
      </form>
      <h3>Tutores legales:</h3>
      <form action :class="[
          'form',
          'admin__form',
          {'tutor__form': tutorAlias.length}
        ]">
        <div class="form__field-row half">
          <div class="form__field-wrapper">
            <div class="form__field">
              <label class="form__field-label" for="#name">Nombre</label>
              <input
                v-model="tutorForm.name"
                class="form__field-input"
                type="text"
                id="name"
                disabled
              >
            </div>
          </div>
          <div class="form__field-wrapper">
            <div class="form__field">
              <label class="form__field-label" for="#surnames">Apellidos</label>
              <input
                v-model="tutorForm.surnames"
                class="form__field-input"
                type="text"
                id="surnames"
                disabled
              >
            </div>
          </div>
        </div>
        <div class="form__field-row half">
          <div class="form__field-wrapper">
            <div class="form__field">
              <label class="form__field-label" for="#email">Email</label>
              <input
                v-model="tutorForm.email"
                class="form__field-input"
                type="text"
                id="email"
                disabled
              >
            </div>
          </div>
          <div class="form__field-wrapper">
            <div class="form__field">
              <label class="form__field-label" for="#emailSecondary">Segundo email</label>
              <input
                v-model="tutorForm.emailSecondary"
                class="form__field-input"
                type="email"
                id="emailSecondary"
                disabled
              >
            </div>
          </div>
        </div>
        <div class="form__field-row half">
          <div class="form__field-wrapper">
            <div class="form__field">
              <label class="form__field-label" for="#phone">Teléfono</label>
              <input
                v-model="tutorForm.phone"
                class="form__field-input"
                type="text"
                id="phone"
                disabled
              >
            </div>
          </div>
          <div class="form__field-wrapper">
            <div class="form__field">
              <label class="form__field-label" for="#phoneSecondary">Segundo teléfono</label>
              <input
                v-model="tutorForm.phoneSecondary"
                class="form__field-input"
                type="number"
                id="phoneSecondary"
                disabled
              >
            </div>
          </div>
        </div>
      </form>
      <div v-if="tutorAlias.length">
        <TutorAliasInfo :tutorAlias="tutorAlias"/>
      </div>
      <h3>Sessiones perdidas:</h3>
      <table v-if="getMissedSessions.length" class="admin__table">
        <thead class="admin__table__head">
          <tr>
            <th class="admin__table__head__cell">Curso y nivel</th>
            <th class="admin__table__head__cell">Falta de asistencia</th>
            <th class="admin__table__head__cell">Recuperación hasta</th>
            <th class="admin__table__head__cell"></th>
          </tr>
        </thead>
        <tbody class="admin__table__body">
          <tr v-for="(missedSession, idx) in getMissedSessions" :key="idx">
            <td class="admin__table__body__cell"><span class="admin__table__body__cell__header">Curso y nivel</span>{{ missedSession.missedGroup.service }}</td>
            <td class="admin__table__body__cell"><span class="admin__table__body__cell__header">Falta de asistencia</span>{{ reverseDate(missedSession.missedAt) }}</td>
            <td class="admin__table__body__cell"><span class="admin__table__body__cell__header">Recuperación hasta</span>{{ formatDateShort(missedSession.recoverableUpTo) }}</td>
            <td class="admin__table__body__cell"><button class="btn" @click="booking(idx)">Reservar sesión</button></td>
          </tr>
        </tbody>
      </table>
      <div v-else class="message__noData">
        <div class="message__noData-icon">
          <svg width="13" height="36" viewBox="0 0 13 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.880469 -2.38419e-06H12.1805L10.2805 22.25H2.73047L0.880469 -2.38419e-06ZM6.53047 35.45C4.8638 35.45 3.49714 34.95 2.43047 33.95C1.3638 32.9167 0.830469 31.6667 0.830469 30.2C0.830469 28.7 1.3638 27.4667 2.43047 26.5C3.49714 25.5333 4.8638 25.05 6.53047 25.05C8.19714 25.05 9.54714 25.5333 10.5805 26.5C11.6471 27.4667 12.1805 28.7 12.1805 30.2C12.1805 31.6667 11.6471 32.9167 10.5805 33.95C9.5138 34.95 8.1638 35.45 6.53047 35.45Z" fill="white"/>
          </svg>
        </div>
        <div class="message__noData-text">
          <p>Este alumn@ no tiene sesiones perdidas</p>
          <p>Prueba seleccionando otro alumno</p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import Multiselect from '@vueform/multiselect'
import Switch from '../../components/Switch.vue'
import { mapActions, mapGetters } from 'vuex'
import { formatDateShort, reverseDate, formatnewDate } from '@/commonMethods/methods'
import Loading from '../Loading.vue'
import TutorAliasInfo from "@/components/Partials/TutorAliasInfo";
import { ref } from 'vue'
import Datepicker from 'vue3-datepicker'
import es from 'date-fns/locale/es'

const useInputStyles = {
  padding: "12px 20px",
  border: "1px solid #00ADCE",
  borderRadius: "0",
  marginRight: "30px",
  width: "calc(100% - 30px)"
}

export default {
  name: 'StudentDetail',
  props: {
    id: {
      type: String,
      required: true
    },
    studentId: {
      type: String,
      required: true
    }
  },
  components: {
    Multiselect,
    Switch,
    Loading,
    Datepicker,
    TutorAliasInfo
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      form: {
        name: '',
        surnames: '',
        birthday: ref(new Date()),
        imageAuthorized: false,
        allergies: [],
        observations: ''
      },
      isLoading: false,
      errors: {},
      formatDateShort,
      recoverClass: [],
      isLoadingAllergies: false,
      reverseDate,
      tutorForm: {
        name: '',
        surnames: '',
        email: '',
        emailSecondary: '',
        phone: '',
        phoneSecondary: ''
      },
      tutorAlias: [],
      personsAuthorized: [],
      useInputStyles,
      dateLocale: es,
      isModalOpen: true,
    }
  },
  validations() {
    return {
      form: {
        name: {
          required
        },
        surnames: {
          required
        },
        imageAuthorized: false,
        allergies: false,
      }
    }
  },
  computed: {
    ...mapGetters('AdminCenter', ['getAllergies', 'getStudent', 'getMissedSessions']),
    formAllergies() {
      return this.getAllergies.map(allergy => ({
        value: allergy.id,
        label: allergy.name
      }))
    }
  },
  methods: {
    ...mapActions('AdminCenter', ['fetchAllergies', 'fetchStudent', 'studentMissedSessions', 'updateStudent']),
    addPersonAuthorized() {
      this.personsAuthorized.push({
        name: '',
        dni: '',
        phone: null
      })
    },
    deletePersonAuthorized(idx) {
      this.personsAuthorized.splice(idx, 1)
    },
    async updateStudentSubmit() {
      if(!this.v$.$invalid) {
        const infoUpdate = {
          name: this.form.name,
          surnames: this.form.surnames,
          imageAuthorized: this.form.imageAuthorized,
          birthday: formatnewDate(this.form.birthday),
          allergies: [...this.form.allergies],
          observations: this.form.observations,
          personsAuthorized: this.personsAuthorized
        }
        try {
          await this.updateStudent({ centerId: this.id, studentId: this.studentId,  infoUpdate: infoUpdate })
          this.$toast.success('Datos guardados correctamente');
          setTimeout(() => {
            this.$router.push({ name: 'students' })
          }, 3000)
        } catch (error) {
          if(error.personsAuthorized) {
            this.errors = error.personsAuthorized
          } else {
            this.errors = error
          }
        }
      }
    },
    booking(idx) {
      const objSession = this.getMissedSessions[idx]
      this.$router.push({ name: 'student-detail-recover-class', params: { id: this.id, studentId: this.studentId, missedSessionId: objSession.id } })
    },
    confirmationMessage() {
      if (this.form.imageAuthorized) {
        setTimeout(() => confirm('¿Estás seguro del cambio que quieres realizar?') ? this.form.imageAuthorized = false : this.form.imageAuthorized = true, 200)
      } else {
        setTimeout(() => confirm('¿Estás seguro del cambio que quieres realizar?') ? this.form.imageAuthorized = true : this.form.imageAuthorized = false, 200)
      }
    }
  },
  async mounted() {
    const info = {
      id: this.id,
      studentId: this.$route.params.studentId
    }
    this.isLoading = true
    this.isLoadingAllergies = true
    try {
      await this.fetchAllergies()
      this.fetchStudent(info)
      .then(response => {
        this.form = {
          name: response.name,
          surnames: response.surnames,
          birthday: new Date(`${response.monthOfBirth}/${response.dayOfBirth}/${response.year}`),
          imageAuthorized: response.imageAuthorized,
          allergies: response.allergies.map(allergy => allergy.id),
          observations: response.observations
        }
        this.tutorForm = {
          name: response.tutor.name,
          surnames: response.tutor.surnames,
          email: response.tutor.email,
          emailSecondary: response.tutor.emailSecondary,
          phone: response.tutor.phone,
          phoneSecondary: response.tutor.phoneSecondary
        }
        this.tutorAlias = response.tutorAlias
        response.personsAuthorized.forEach(personAuthorized => {
          this.personsAuthorized.push({
            name: personAuthorized.name,
            dni: personAuthorized.dni,
            phone: personAuthorized.phone
          })
        })
      })
      .catch(errors => {
        console.log('errors en la vista ', errors);
      })
      await this.studentMissedSessions(info)
    } catch (error) {
      console.log('error', error)
    } finally {
      this.isLoading = false
      this.isLoadingAllergies = false
    }
  }
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped lang="scss">
.multiselect {
  margin-top: 0;
}
.admin__table__body__cell {
  cursor: auto;
}
.new-element {
  max-width: 280px;
}
.form__field-wrapper {
  &.full-name {
    width: 100% !important;
    margin-right: 0 !important;
  }
}
.form__field-row {
  &.person-authorized {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 20px;
    .button__container {
      &:last-child {
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }
    .form__field-wrapper {
      width: calc(50% - 20px);
      @media (max-width: 910px) {
        width: 100%;
      }
    }
  }
}

.tutor__form {
  border-bottom: 1px solid $newBlue;
}
</style>
