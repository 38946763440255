<template>
  <main class="main">
    <form action class="form admin__form" @submit.prevent="newServiceSubmit">
      <div class="data-row space-between"><h2 class="subtitle">Nuevo servicio:</h2>
        <button type="submit" class="btn btn-save">Guardar</button>
      </div>
      <div class="form__field-row">
        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.name.$invalid && v$.form.name.$dirty">
              Por favor, introduce un nombre de servicio
            </p>
            <label class="form__field-label" for="#name">Nombre *</label>
            <input
              v-model="v$.form.name.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.name.$invalid && v$.form.name.$dirty}"
              type="text"
              id="name"
              required
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('name')">
            <p v-for="(error, key) in errors.name" :key="key">{{ $t(error) }}</p>
          </div>
        </div>
      </div>
    </form>
  </main>

</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { mapActions } from 'vuex'

export default {
  name: 'FormLeads',
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      form: {
        name: '',
      },
      errors: {},
    }
  },
  validations() {
    return {
      form: {
        name: {
          required
        }
      }
    }
  },
  methods: {
    ...mapActions('Admin', ['newService']),
    newServiceSubmit() {
      if(!this.v$.$invalid) {
        this.newService(this.form)
        .then(() => {
          this.$toast.success('Servicio añadido correctamente');
          setTimeout(() => {
            this.$router.push({ name: 'services-edit' })
          }, 3000)
        })
        .catch (errors => {
          this.errors = errors
        })
      }
    },
    removeErrors() {
      this.errors = {}
    },
  }
}
</script>
<style lang="scss" scoped>
</style>
