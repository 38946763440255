<template>
  <div>
    <form
      class="form admin__form alias__form"
      v-for="(alias, idx) in tutorAlias"
      :key="`alias--${idx}`">
    <div class="form__field-row half">
      <div class="form__field-wrapper">
        <div class="form__field">
          <label class="form__field-label" for="#name">Nombre</label>
          <input
              v-model="alias.name"
              class="form__field-input"
              type="text"
              id="name"
              disabled
          >
        </div>
      </div>

      <div class="form__field-wrapper">
        <div class="form__field">
          <label class="form__field-label" for="#surnames">Apellidos</label>
          <input
              v-model="alias.surnames"
              class="form__field-input"
              type="text"
              id="surnames"
              disabled
          >
        </div>
      </div>
    </div>

    <div class="form__field-row half">
      <div class="form__field-wrapper">
        <div class="form__field">
          <label class="form__field-label" for="#email">Email</label>
          <input
              v-model="alias.email"
              class="form__field-input"
              type="text"
              id="email"
              disabled
          >
        </div>
      </div>

      <div class="form__field-wrapper">
        <div class="form__field">
          <label class="form__field-label" for="#emailSecondary">Teléfono</label>
          <input
              v-model="alias.phone"
              class="form__field-input"
              type="text"
              id="phone"
              disabled
          >
        </div>
      </div>
    </div>

    <div class="form__field-row half">
      <div class="form__field-wrapper">
        <div class="form__field">
          <label class="form__field-label" for="#phone">Relación familiar</label>
          <input
              v-model="alias.familyRelationship"
              class="form__field-input"
              type="text"
              id="phone"
              disabled
          >
        </div>
      </div>
    </div>
  </form>
  </div>
</template>

<script>
export default {
  name: 'TutorAliasInfo',
  props: {
    tutorAlias: {
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.alias__form {
  border-bottom: 1px solid $newBlue;
  margin: 32px 0;

  &:last-child {
    border: none;
  }
}
</style>
