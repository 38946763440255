<template>
  <div class="main">
    <h2 class="subtitle">Editar grupo:</h2>
    <Loading v-if="isLoading" />
    <GroupForm
      v-else
      :groupForm="form"
      @submit="saveNewGroup"
      :errors="formErrors"
      :staffMembers="staffMembers"
      :services="services"
    ></GroupForm>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import GroupForm  from '@/components/Partials/GroupForm'
import { timesTampToDay, utcToTimestamp } from '@/commonMethods/methods'
import Loading from '../Loading.vue'

export default {
  name: 'GroupDetail',
  components: {
    GroupForm,
    Loading
  },
  data() {
    return {
      form: {},
      timesTampToDay,
      utcToTimestamp,
      formErrors: [],
      isLoading: true
    }
  },
  computed: {
    ...mapGetters('Admin', {
      activeGroup: 'getActiveGroup',
      staffMembers: 'getStaffMembers',
      services: 'getServicesList'
    })
  },
  watch: {
    activeGroup: {
      immediate: true,
      handler(newActiveGroup) {
        if (newActiveGroup) {
          this.form = {
            ...newActiveGroup,
            dateStart: newActiveGroup.dateStart ? new Date(newActiveGroup.dateStart * 1000) : '',
            dateEnd: newActiveGroup.dateEnd ? new Date(newActiveGroup.dateEnd * 1000) : '',
            hour: newActiveGroup.time,
            service: newActiveGroup.service ? newActiveGroup.service.id : '',
            priceEnrollmentDefault: newActiveGroup.price,
            description: newActiveGroup.description,
            name: newActiveGroup.name,
            staffMember: newActiveGroup.staffMember ? newActiveGroup.staffMember.id : ''
          }
        }
      }
    }
  },
  methods: {
    ...mapActions('Admin', ['fetchGroupById', 'updateGroup', 'fetchStaffMembers', 'fetchServices']),
    async saveNewGroup(newGroupInfo) {
      const groupInfo = {
        yearStart: newGroupInfo.yearStart,
        yearEnd: newGroupInfo.yearEnd,
        weekDay: newGroupInfo.weekDay,
        dateStart: newGroupInfo.dateStart,
        dateEnd: newGroupInfo.dateEnd,
        priceEnrollmentDefault: newGroupInfo.priceEnrollmentDefault,
        priceFeeDefault: newGroupInfo.priceFeeDefault,
        hour: newGroupInfo.hour,
        description: newGroupInfo.description,
        name: newGroupInfo.name,
        service: newGroupInfo.service,
        staffMember: newGroupInfo.staffMember,
        fixedPlaces: newGroupInfo.fixedPlaces,
        extraPlaces: newGroupInfo.extraPlaces
      }
      try {
        await this.updateGroup({ centerId: this.$route.params.id, groupId: this.$route.params.groupId, groupInfo})
        this.$toast.success('Datos enviados correctamente')
        setTimeout(() => {
          this.$router.push({ name: 'groups' })
        }, 3000)
      } catch (errors) {
        this.formErrors = errors
      }
    }
  },
  async mounted() {
    this.isLoading = true
    try {
      await this.fetchGroupById({ centerId: this.$route.params.id, groupId: this.$route.params.groupId })
      await this.fetchStaffMembers(this.$route.params.id)
      await this.fetchServices()
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
