<template>
  <Loading v-if="isLoading" />
  <main v-else class="main reports">
    <h2 v-if="detailedReport" class="subtitle">Informe del día {{formatDateShort(detailedReport.validatedAt)}}</h2>
    <template v-if="studentReport.length && isList">
      <table class="admin__table">
        <thead class="admin__table__head">
        <tr>
          <th
            v-for="(column, idx) in tableConfig"
            :key="`columnHeader--${idx}`"
            class="admin__table__head__cell">
            <span>{{column.label}}</span>
          </th>
        </tr>
        </thead>
        <tbody class="admin__table__body">
        <tr
          v-for="(report, idx) in studentReport"
          :key="`report--${idx}`">
          <td
            v-for="(column, idx2) in tableConfig"
            :key="`columnHeader--${idx2}`"
            class="admin__table__body__cell"
          >
            <span class="admin__table__body__cell__header">{{ column.label }}</span>
            <img v-if="column.key === 'level'" :src="report.reportLevel.title" alt="level" class="class-image" />
            <div v-if="column.key === 'monitor'">{{report.staffMember.name}} {{report.staffMember.surnames}}</div>
            <div v-if="column.key === 'student'">{{report.student.name}} {{report.student.surnames}}</div>
            <div v-if="column.key === 'date'"><span class="date">{{formatDateShort(report.validatedAt)}}</span></div>
            <span v-if="column.key === 'detail'" @click="goToReportDetail(report.id, report.student.id)" class="btn btn-detail">Ver más</span>
          </td>
        </tr>
        </tbody>
      </table>
    </template>
    <DetailedReportByStudent v-else-if="studentReport.length && !isList" @back-to-list="handlerList"  />
    <div v-else class="message__noData">
      <div class="message__noData-icon">
        <svg width="13" height="36" viewBox="0 0 13 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.880469 -2.38419e-06H12.1805L10.2805 22.25H2.73047L0.880469 -2.38419e-06ZM6.53047 35.45C4.8638 35.45 3.49714 34.95 2.43047 33.95C1.3638 32.9167 0.830469 31.6667 0.830469 30.2C0.830469 28.7 1.3638 27.4667 2.43047 26.5C3.49714 25.5333 4.8638 25.05 6.53047 25.05C8.19714 25.05 9.54714 25.5333 10.5805 26.5C11.6471 27.4667 12.1805 28.7 12.1805 30.2C12.1805 31.6667 11.6471 32.9167 10.5805 33.95C9.5138 34.95 8.1638 35.45 6.53047 35.45Z" fill="white"/>
        </svg>
      </div>
      <div class="message__noData-text">
        <p>No hay informes para mostrar</p>
      </div>
    </div>
    <div class="description">
      <p>
        Estos informes no son de "notas" con aprobados y suspensos. Os ofrecemos, en ambio, un documento con información específica para mostrar el progreso
        de vuestros hijos, sus fortalezas y las tareas en las que seguiremos trabajando parra completar su aprendizaje.
      </p>
      <p>
        Puesto que son los profesores de vuestros hijos los que redactan los comentarios personalizados sobre cada alumno/a, esta sección de los Progress Reports
        está escrita en inglés. No obstante, incluimos una rúbrica muy visual con los criterios y competencias detallados en ambos idiomas para que se entiendan fácilmente.
      </p>
      <p>
        El número de "caritas sonrientes" asignadas a cada competencia dependerá del criterio del profesor y confiamos en ese número irá aumentando a medida que avance el curso.
        No le damos mayor importancia al hecho de tener 1, 2 o 3 caritas sonrientes, ya que nuestro objetivo es valorar su esfuerzo y moivar sus ganas de aprender para el próximo trimestre.
      </p>
    </div>
    <h3>RÚBRICAS DE CRITERIOS Y COMPETENCIAS</h3>
    <div class="container-level">
      <a href="/documents/Bumblebees.pdf" download="bumblebees.pdf" class="level">
        <img class="logo" src="../assets/levels/bumblebees-pet.png" alt="bumblebees-pet">
        <img class="title" src="../assets/levels/bumblebees-logo.png" alt="bumblebees-logo">
      </a>
      <a href="/documents/HunnyBunnies.pdf" target="_blank" download="hunnybunnies.pdf" class="level">
        <img class="logo" src="../assets/levels/hunnybunnies-pet.png" alt="hunnybunnies-pet">
        <img class="title" src="../assets/levels/hunnybunnies-logo.png" alt="hunnybunnies-logo">
      </a>
      <a href="/documents/coolKoalas.pdf" target="_blank" download="coolkoalas.pdf" class="level">
        <img class="logo" src="../assets/levels/coolkoalas-pet.png" alt="coolkoalas-pet">
        <img class="title" src="../assets/levels/coolkoalas-logo.png" alt="coolkoalas-logo">
      </a>
      <a href="/documents/WildCats.pdf" target="_blank" download="wildcats.pdf" class="level">
        <img class="logo" src="../assets/levels/wildcats-pet.png" alt="wildcats-pet">
        <img class="title" src="../assets/levels/wildcats-logo.png" alt="wildcats-logo.png">
      </a>
      <a href="/documents/BlueJays.pdf" target="_blank" download="bluejays.pdf" class="level">
        <img class="logo" src="../assets/levels/bluejays-pet.png" alt="bluejays-pet">
        <img class="title" src="../assets/levels/bluejays-logo.png" alt="bluejays-logo">
      </a>
    </div>
  </main>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { formatDateShort } from '@/commonMethods/methods'
import Loading from '@/components/Loading'
import DetailedReportByStudent from './DetailedReportByStudent.vue'

export default {
  name: 'Reports',
  components: {
    Loading,
    DetailedReportByStudent
  },
  props: {
    id: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      tableConfig: [
        {
          label: 'Clase',
          key: 'level'
        },
        {
          label: 'Profesor',
          key: 'monitor'
        },
        {
          label: 'Fecha',
          key: 'date',
        },
        {
          label: 'Detalle',
          key: 'detail'
        }
      ],
      isLoading: false,
      student: null,
      isList: true,
      formatDateShort
    }
  },
  watch: {
    id: {
      async handler(currentId) {
        if (currentId) {
          this.isLoading = true
          try {
            await this.fetchStudentReport(Number(currentId))
          } finally {
            this.isLoading = false
            this.student = this.students.find(student => student.id == this.id)
          }
        }
      }
    }
  },
  computed: {
    ...mapState('Students', ['studentReport', 'students', 'detailedReport']),
  },
  methods: {
    ...mapActions('Students', ['fetchStudentReport', 'fetchReportById']),
    async goToReportDetail(studentId, reportId) {
      this.isLoading = true
      try {
        await this.fetchReportById({ studentId, reportId })
      } finally {
        this.isList = false
        this.isLoading = false
      }
    },
    handlerList(value) {
      this.isList = value
    }
  },
  async mounted() {
    this.isLoading = true
    try {
      await this.fetchStudentReport(Number(this.id))
    } finally {
      this.isLoading = false
    }
    this.student = this.students.find(student => student.id === Number(this.id))
  }
}
</script>

<style scoped lang="scss">
  .reports {
    .subtitle {
      margin: 0;
    }
    h2 {
      padding: 0 15px;
      @include tablet {
        padding: 0 30px;
      }
    }
    .admin {
      &__table {
        margin-top: 30px;
      }
    }
    &__search {
      border: 2px solid $newPink;
      border-radius: 16px;
      flex-grow: 1;
      font-size: 12px;
      margin-right: 16px;
      max-width: 500px;
      padding: 8px;

      @include desktop {
        margin-bottom: 32px;
        width: 45%;
      }
    }
    .aspect {
      line-height: 40px;
      p {
        margin: 4px 0;
        font-size: 14px;
        @include tablet {
          font-size: 18px;
        }
      }
      .border-line {
        border-bottom: .5px solid $newBlue;
      }
    }
    .listening {
      line-height: 20px;
    }
    .emoji {
      line-height: 54px;
      @include desktop {
        line-height: 28px;
      }
    }
    .class-image {
      width: 160px;
      vertical-align: middle;
    }
    .monitor {
      line-height: 16px;
    }
    .detail {
      cursor: pointer;
      line-height: 16px;
      text-decoration: underline;
    }
    .btn-detail {
      padding: 20px;
      margin: 10px 0;
      line-height: 0;
      @include desktop {
        padding: 12px 20px;
      }
    }
  }
  .description {
    font-size: 14px;
    line-height: 22px;
    margin-top: 30px;
  }
  h3 {
    margin-top: 30px;
  }
  .container-level {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    .level {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
      .logo {
        width: 80px;
      }
      .title {
        width: 120px;
      }
    }
    .level:nth-child(3) {
      .logo {
        width: 90px;
      }
      .title {
        width: 120px;
      }
    }
  }
  @include desktop {
    .description, h3 {
      padding-left: 6px;
    }
    .container-level {
      flex-direction: row;
      justify-content: space-between;
    }
  }
</style>
