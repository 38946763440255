<template>
  <form class="admin__form">
    <ul class="form__field-wrapper">
      <li class="form__field admin__form__element">
        <p class="input-errors" v-if="v$.form.name.$invalid && v$.form.name.$dirty">
          Por favor, introduce un nombre
        </p>
        <label for="#name" class="form__field-label">Nombre *</label>
        <input
          v-model="v$.form.name.$model"
          class="form__field-input"
          :class="{'form__input--invalid': v$.form.name.$invalid && v$.form.name.$dirty}"
          type="text"
          id="name"
          required
        >
          <div class="errors errors-input" v-show="errors.hasOwnProperty('name')">
            <p v-for="(error, key) in errors.name" :key="key">{{ $t(error) }}</p>
          </div>
      </li>
      <li class="form__field admin__form__element">
        <p class="input-errors" v-if="v$.form.surnames.$invalid && v$.form.surnames.$dirty">
          Por favor, introduce los apellidos
        </p>
        <label
          for="surnames"
          class="form__field-label">Apellidos *
        </label>
        <input
          class="form__field-input"
          :class="{'form__input--invalid': v$.form.surnames.$invalid && v$.form.surnames.$dirty}"
          type="text"
          id="surnames"
          v-model="v$.form.surnames.$model"
          required
        >
          <div class="errors errors-input" v-show="errors.hasOwnProperty('surnames')">
            <p v-for="(error, key) in errors.surnames" :key="key">{{ $t(error) }}</p>
          </div>
      </li>
      <li class="form__field admin__form__element">
        <label
          for="email"
          class="form__field-label">Email *
        </label>
        <input
          class="form__field-input"
          :class="{'form__input--invalid': v$.form.email.$invalid && v$.form.email.$dirty}"
          type="text"
          id="email"
          v-model="v$.form.email.$model"
          required
          :disabled="!hasRegister"
        >
        <p class="input-errors" v-if="v$.form.email.$invalid && v$.form.email.$dirty">
          Por favor, introduce un email válido
        </p>
        <div class="errors errors-input" v-show="errors.hasOwnProperty('email')">
          <p v-for="(error, key) in errors.email" :key="key">{{ $t(error) }}</p>
        </div>
      </li>
      <li class="form__field admin__form__element">
        <label
          for="centers"
          class="form__field-label">Centros *
        </label>
        <Multiselect
          class="multiselect teacher__multiselect"
          v-model="v$.form.centers.$model"
          mode="tags"
          :searchable="true"
          :createTag="false"
          :options="formCenters"
          placeholder="Seleciona centros"
          required
        
        />
        <p class="input-errors" v-if="v$.form.centers.$invalid && v$.form.centers.$dirty">
          Por favor, seleccione algún centro
        </p>
      </li>
      <li class="admin__form__element">
        <Switch
          class="teacher__switch"
          v-model:checked="v$.form.isSupervisor.$model"
          label="Es supervisor"
        />
      </li>
    </ul>
    <button
      type="button"
      class="btn btn-save"
      @click="submitForm">Guardar
    </button>
  </form>
</template>

<script>
import Switch from '../../components/Switch'
import Multiselect from '@vueform/multiselect'
import { useVuelidate } from '@vuelidate/core'
import { email, required } from '@vuelidate/validators'

export default {
  name: 'teacherForm',
  components: {
    Switch,
    Multiselect
  },
  setup () {
    return { v$: useVuelidate() }
  },
  props: {
    teacherForm: {
      type: Object,
      default: () => ({})
    },
    formCenters: {
      type: Array,
      default: () => ([])
    },
    hasRegister: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    teacherForm: {
      immediate: true,
      handler(newTeacher) {
        if (newTeacher) {
          this.form = {
            ...newTeacher,
            isSupervisor: !!newTeacher.isSupervisor
          }
        }
      }
    },
    hasRegister: {
      immediate: true,
      handler(hasRegister) {
        if (hasRegister) {
          this.isEditing = true
        }
      }
    }
  },
  data() {
    return {
      form: {
        name: '',
        surnames: '',
        email: '',
        centers: [],
        isSupervisor: false
      }
    }
  },
  validations() {
    return {
      form: {
        name: {
          required
        },
        surnames: {
          required
        },
        email: {
          required,
          email
        },
        centers: {
          required
        },
        isSupervisor: {}
      }
    }
  },
  methods: {
    submitForm(ev) {
      ev.stopPropagation()
      if (!this.v$.$invalid) {
        this.$emit('submit', this.form)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .teacher {
    &__switch {
      margin-bottom: 0;
      font-weight: 900;
    }

    &__multiselect {
      margin: 0;
    }
  }
  .form__field-wrapper {
    @include tablet {
      flex-direction: row;
      .admin__form__element {
        width: 50%;
        &:nth-child(odd) {
          .form__field-input, .form__field-label {
            margin-right: 30px;
          }
        }
      }
    }
  }
</style>
