<template>
  <div class="main">
    <h2 class="subtitle">Histórico de pagos</h2>
    <p class="text" v-if="isLoading">
      ¡Cargando datos!
    </p>
    <p class="text" v-if="returnPaymentList != null && !isLoading">Consulta el histórico de todos los pagos asociados a tu cuenta</p>
    <p class="text" v-if="returnPaymentList == null">No tienes histórico de pagos asociados a la cuenta</p>
    <table class="admin__table" v-if="returnPaymentList != null && returnPaymentList.length">
      <thead class="admin__table__head">
        <tr>
          <th class="admin__table__head__cell">Fecha</th>
          <th class="admin__table__head__cell">Importe</th>
          <!-- <th class="admin__table__head__cell">Método de pago</th> -->
          <th class="admin__table__head__cell">Concepto</th>
          <th class="admin__table__head__cell">Estado</th>
          <th class="admin__table__head__cell"></th>
        </tr>
      </thead>
      <tbody class="admin__table__body">
        <tr v-for="(payment, idx) in returnPaymentList" :key="idx">
          <td class="admin__table__body__cell"><span>Fecha</span><p>{{ formatDateShort(payment.orderAt) }}</p></td>  
          <td class="admin__table__body__cell price"><span>Importe</span><p>{{ formatPrice(payment.price) }}</p></td> 
          <!-- <td class="admin__table__body__cell"><span>Método de pago</span><p>{{ payment.paymentMethod }}</p></td> -->
          <td class="admin__table__body__cell"><span>Concepto</span><p>{{ payment.subject }}</p></td>
          <td class="admin__table__body__cell"><span>Estado</span><p>{{ getPaymentState(payment) }}</p></td>
          <td class="admin__table__body__cell"><span></span><button class="btn" v-if="payment.hasError" @click="pay(payment)">Reintentar pago</button></td>
        </tr>
      </tbody>
    </table>
    <div class="errors" v-for="(error, key) in errors" :key="key">
      <p>{{ $t(error) }}</p>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { formatPrice, formatDateShort } from '@/commonMethods/methods'

export default {
  name: 'PaymentHistory',
  data() {
    return {
      paymentList: [],
      isLoading: true,
      errors: {},
      formatPrice,
      formatDateShort
    }
  },
  async mounted() {
    this.isLoading = true
    try {
      await this.tutorPaymentList();
    } catch(error) {
      this.errors = error
    } finally {
      this.isLoading = false
    }
  },
  computed: {
    ...mapGetters('User', {
      returnPaymentList: 'getPaymentList'
    }),
  },
  methods: {
    ...mapActions('User', ['tutorPaymentList']),
    ...mapActions('Students', ['paymentCenterCharge']),
    ...mapMutations('User', {
      setUserInfo: 'SET_PAYMENT_PRICE_RETRY'
    }),
    pay(payment) {
      this.setUserInfo(payment.price)
      this.$router.push({ name: 'enrollment-confirmation-retry', params: { paymentId: payment.id } })
    },
    getPaymentState(payment) {
      return !payment.hasError && !payment.paidAt 
      ? 'Proximo pago' 
      : payment.hasError
        ? payment.error
        : 'Pago correcto'
    }
  }
}
</script>

<style scoped lang="scss">
.main {
  .text {
    margin-bottom: 3em;
  }
  .table {
    thead {
      tr {
        th {
          text-align: left;
          padding: 0 20px;
        }
      }
    }
    tbody {
      tr {
        td {
          &:first-child {
            text-align: left;
          }
        }
      }
    }
  }
}
.admin__table__body__cell {
  cursor: auto;
  @include tablet {
    span {
      display: none;
    }
  }
}
</style>
