import { apiClient } from '@/services/ApiClient'
import { formatDateShort } from '@/commonMethods/methods'
import router from '@/router'

const state = {
  centers: [],
  center: {},
  user: {},
  services: [],
  groupsSessions: [],
  activeGroup: {},
  missingStudentsIds: [],
  payments: [],
  staffMembers: [],
  allergies: [],
  userInfo: {},
  enrollments: [],
  enrollment: {},
  groupLists: [],
  tutorRedsysList: [],
  activeStudentName: '',
  studentsWithoutCenter: [],
  studentWithoutCenter: null,
  students: [],
}

const actions = {
  async fetchAdmin( {commit} ) {
    return new Promise((resolve, reject) => {
      apiClient.get('/admin/centers')
      .then(response => {
        commit('SET_CENTERS_LIST', response.data)
        resolve(response.data)
      })
      .catch(err => {
        apiClient.refreshToken()
        reject(err.response.data.errors)
      })
    })
  },
  async fetchCenters({commit}) {
    try {
      const { data } = await apiClient.get('/admin/centers')
      commit('SET_CENTERS_LIST', data)
    } catch (error) {
      throw error.response.data.errors
    }
  },
  async fetchCenterById( { commit }, centerId) {
    await apiClient.get(`/admin/centers/${centerId}`)
    .then(response => {
      commit('SET_CENTER', response.data)
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },
  async fetchServices({commit}) {
    try {
      const { data } = await apiClient.get('/admin/services')
      commit('SET_SERVICES_LIST', data)
    } catch (error) {
      throw error.response.data.errors
    }
  },
  async sendForm(commit , formInfo) {
    await apiClient.post('/lead/create', formInfo)
    .then(() => {
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },
  async sendNewCenter({ commit } , newCenterInfo) {
    await apiClient.post('/admin/centers', newCenterInfo)
    .then(response => {
      commit('ADD_NEW_CENTER', response.data)
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },
  async fetchGroupsSessions({ commit }, { centerId, date }) {
    let queryParams = ''
    if (date) {
      queryParams = `?date=${date}`
    }
    try {
      const response = await apiClient.get(`/admin/centers/${centerId}/groups/sessions${queryParams}`)
      commit('SET_GROUPS_SESSIONS', response.data)
    } catch (err) {
      throw err.response.data.errors
    }
  },
  async fetchGroupById({ commit }, { centerId, groupId }) {
    const { data } = await  apiClient.get(`/admin/centers/${centerId}/groups/${groupId}`)
    commit('SET_ACTIVE_GROUP', data)
  },
  async groupMissedSessionsCreate(commit, info) {
    const centerId = info.missingSession.centerId
    const groupId = info.missingSession.groupId
    const studentsId = {
      students: info.studentMissingSession,
      studentsForPhoto: info.studentsForPhoto,
      studentsForVideo: info.studentsForVideo
    }
    await apiClient.post(`/admin/centers/${centerId}/groups/${groupId}/sessions/${info.missingSession.date}`, studentsId)
    .then(() => {
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },
  async studentChangeGroup(commit , { centerId, studentId, newGroup }) {
    await apiClient.post(`/admin/centers/${centerId}/students/${studentId}/group/change`, newGroup)
  },
  async studentsIds({ commit }, idx) {
    commit('UPDATE_STUDENTS_IDS_MISSING_SESSION', idx)
  },
  async fetchPaymentsList( { commit } , obj ) {
    const {centerId, params} = obj;
    let queryParams = '';
    if(params){
      queryParams = '?' + Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
    }
    const { data } = await apiClient.get(`/admin/centers/${centerId}/payments${queryParams}`)
    commit('SET_PAYMENTS', data)
  },
  async fetchSelectedPayment( { state, commit }, { centerId, paymentId }) {
    const findSelectedPayment = state.payments.find(payment => payment.id === paymentId)
    if (!findSelectedPayment) {
      const { data } = await apiClient.get(`/admin/centers/${centerId}/payments/${paymentId}`)
      commit('ADD_PAYMENT', data)
    }
  },
  async updatePayment( { commit }, { payment, centerId, paymentId } ) {
    try {
      await apiClient.patch(`/admin/centers/${centerId}/payments/${paymentId}`, payment)
      commit('UPDATE_PAYMENT', { payment, paymentId })
    } catch (err) {
      throw err.response.data.errors
    }
  },
  async acceptPayment( { commit } , { centerId, paymentId }) {
    await apiClient.patch(`/admin/centers/${centerId}/payments/${paymentId}/accept`)
      .then(response => {
        const payment = response.data
        commit('UPDATE_PAYMENT', {payment, paymentId})
      })
      .catch(err => {
        throw err.response.data.errors
      })
  },
  async retryPayment({ commit }, {centerId, paymentId} ) {
    await apiClient.patch(`/admin/centers/${centerId}/payments/${paymentId}/retry`)
    commit('')
  },
  async savePaymentMethods( { commit }, { centerId, paymentMethods } ) {
    await apiClient.post(`/admin/centers/${centerId}/save-payment-methods`, paymentMethods)
    commit('UPDATE_CENTER', { id: centerId, payload: paymentMethods  })
  },
  async fetchStaffMembers( {commit}, centerId ) {
    const { data } = await apiClient.get(`/admin/centers/${centerId}/staff-members`)
    commit('SET_STAFF_MEMBERS', data)
  },
  async deleteStaffMember( { commit }, { centerId, teacherId } ) {
    await apiClient.delete(`/admin/centers/${centerId}/staff-members/${teacherId}`)
    commit('DELETE_STAFF_MEMBER', teacherId)
  },
  // eslint-disable-next-line no-empty-pattern
  async updateStaffMember( {}, { centerId, teacherId, newTeacherInfo } ) {
    try {
      const teacher = {
        name: newTeacherInfo.name,
        surnames: newTeacherInfo.surnames,
        centers: newTeacherInfo.centers,
        isSupervisor: newTeacherInfo.isSupervisor ? 1 : 0
      }
      await apiClient.patch(`/admin/centers/${centerId}/staff-members/${teacherId}`, teacher)
    } catch(e) {
      throw e.response.data.errors
    }
  },
  async fetchSelectedTeacher( { state, commit }, { centerId, teacherId }) {
    const findSelectedTeacher = state.staffMembers.find(teacher => teacher.id.toString() === teacherId)
    if (!findSelectedTeacher) {
      const { data } = await apiClient.get(`/admin/centers/${centerId}/staff-members/${teacherId}`)
      commit('SET_NEW_TEACHER', data)
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async signUpStaffMember( { }, { centerId, newTeacher } ) {
    try {
      const teacher = {
        ...newTeacher,
        isSupervisor: newTeacher.isSupervisor ? 1 : 0
      }
      await apiClient.post(`/admin/centers/${centerId}/staff-members`, teacher)
    } catch(e) {
      throw e.response.data.errors
    }
  },
  async deleteCenterByID({ commit } , centerId) {
    await apiClient.delete(`/admin/centers/${centerId}`)
    .then(() => {
      commit('DELETE_CENTER', centerId)
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },
  async updateCenterByID( commit, { centerId, centerInfo }) {
    try {
      await apiClient.patch(`/admin/centers/${centerId}`, centerInfo)
    } catch (err) {
      throw err.response.data.errors
    }
  },
  async fetchSelectedService( { state, commit }, serviceId) {
    const findSelectedService = state.services.find(service => service.id.toString() === serviceId)
    if (!findSelectedService) {
      const { data } = await apiClient.get(`/admin/services/${serviceId}`)
      commit('SET_NEW_SERVICE', data)
    }
  },
  async newService( { commit }, newService ) {
    await apiClient.post(`/admin/services`, newService)
    .then(response => {
      commit('SET_NEW_SERVICE', response.data)
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },
  async deleteServiceByID( { commit }  , serviceId) {
    await apiClient.delete(`/admin/services/${serviceId}`)
    .then(() => {
      commit('DELETE_SERVICE', serviceId)
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },
  async updateService( commit , {serviceId, serviceInfo}) {
    await apiClient.patch(`/admin/services/${serviceId}`, serviceInfo)
    .catch(err => {
      throw err.response.data.errors
    })
  },
  async fetchAllergies( { commit } ) {
    const { data } = await apiClient.get('/admin/allergies')
    commit('SET_ALLERGIES_LIST', data)
  },
  async fetchActiveAllergy( { state, commit }, allergyId ) {
    const findActiveAllergy = state.allergies.find(allergy => allergy.id.toString() === allergyId)
    if (!findActiveAllergy) {
      const { data } = await apiClient.get(`/admin/allergies/${allergyId}`)
      commit('ADD_NEW_ALLERGY', data)
    }
  },
  async createAllergy( {  commit }, newAllergy ) {
    try {
      const { data } = await apiClient.post('/admin/allergies', newAllergy)
      commit('ADD_NEW_ALLERGY', { data })
    } catch (e) {
      throw e.response.data.errors
    }
  },
  async updateAllergy( commit, { allergyId, newAllergy } ) {
    const allergy = {
      name: newAllergy.name
    }
    try {
      await apiClient.patch(`/admin/allergies/${allergyId}`, allergy)
    } catch (e) {
      throw e.response.data.errors
    }
  },
  async deleteAllergy( { commit }, allergyId ) {
    await apiClient.delete(`/admin/allergies/${allergyId}`)
    commit('DELETE_ALLERGY', allergyId)
  },
  async userInfo({ commit }) {
    await apiClient.get('/user/info')
    .then(response => {
      commit('SET_USER_INFO', response.data)
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },
  async fetchEnrollments( { commit }, obj) {
    const { centerId, isDateEnd, params } = obj
    const queryParams = {...params};
    if (isDateEnd) {
        queryParams.finalized = true;
    }
    let queryString = '';
    for(const param of Object.keys(queryParams)){
        queryString+= (queryString === '' ? '?' : '&');
        queryString+=`${param}=${queryParams[param]}`;
    }

    await apiClient.get(`/admin/centers/${centerId}/enrollments${queryString}`)
    .then(response => {
      const enrollments = response.data;
          enrollments['content'] = enrollments['content'].map(enrollment => ({
        ...enrollment,
        studentName: `${enrollment.student.name} ${enrollment.student.surnames}`
      }))
      commit('SET_ENROLLMENTS', enrollments);
      // console.log(queryParams);
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },
  async fetchEnrollment( { commit }, {centerId, enrollmentId}) {
    await apiClient.get(`/admin/centers/${centerId}/enrollments/${enrollmentId}`)
    .then(response => {
      commit('SET_ENROLLMENT', response.data)
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },
  async updateEnrollmentsFinish( commit , {centerId, enrollmentId, dateEnd}) {
    await apiClient.patch(`/admin/centers/${centerId}/enrollments/${enrollmentId}/finish`, {dateEnd})
    .then(() => {
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },
  async fetchGroupList( { commit }, {centerId, filters}) {
    let queryParams = filters
      ? Object.keys(filters).reduce((acc, filter) => {
          if (filters[filter]) {
            acc += `${acc ? '&' : ''}${filter}=${filters[filter]}`
          }
          return acc
        }, '')
      : ''

    if (queryParams) {
      queryParams = '?' + queryParams
    }
    await apiClient.get(`/admin/centers/${centerId}/groups${queryParams}`)
    .then(response => {
      commit('SET_GROUP_LIST', response.data)
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },
  async createGroup( { commit }, {centerId, newGroup} ) {
    try {
      const { data } = await apiClient.post(`/admin/centers/${centerId}/groups`, newGroup)
      commit('ADD_NEW_GROUP', { data })
    } catch (e) {
      throw e.response.data.errors
    }
  },
  async updateGroup( commit , {centerId, groupId, groupInfo} ) {
    try {
      await apiClient.patch(`/admin/centers/${centerId}/groups/${groupId}`, groupInfo)
    } catch (e) {
      throw e.response.data.errors
    }
  },
  async deleteGroupById( { commit }, { centerId, groupId } ) {
    await apiClient.delete(`/admin/centers/${centerId}/groups/${groupId}`)
    commit('DELETE_GROUP', groupId)
  },
  async createEnrollment( { commit }, {centerId, newEnrollment}) {

    try {
      const { data } = await apiClient.post(`/admin/centers/${centerId}/enrollments`, newEnrollment)
      const enrollment = {
        ...data,
        studentName: `${data.student.name} ${data.student.surnames}`
      }
      commit('ADD_NEW_ENROLLMENT', enrollment)
    } catch (err) {
      throw err.response.data.errors
    }
  },
  async updateEnrollment(commit, {centerId, enrollmentId, updateEnrollmentInfo}) {
    await apiClient.patch(`/admin/centers/${centerId}/enrollments/${enrollmentId}`, updateEnrollmentInfo)
    .then(() => {
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },
  async leadSetTrialSession(commit , {centerId, leadId, infoSession}) {
    await apiClient.post(`/admin/centers/${centerId}/leads/${leadId}/trial-session`, infoSession)
    .then(() => {
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },
  async fetchTutorRedsysList({ commit }, centerId) {
    const response = await apiClient.get(`/admin/centers/${centerId}/tutor-redsys`)
    commit('SET_TUTOR_REDSYS_LIST', response.data)
  },
  async chargeNewPayment({ commit }, {centerId, tutorId, payload}) {
    const response = await apiClient.post(`/admin/centers/${centerId}/payments/charge?tutor=${tutorId}`, payload)
    if (response.status === 202) {
      commit('ADD_PAYMENT', response.data)
    }
  },
  async fetchCSVToken() {
    return await apiClient.get('/admin/csv-token')
  },
  async fetchStudentsWithoutCenter({commit}) {
    const { data } = await apiClient.get('/admin/students/without-center')
    commit('SET_STUDENTS_WITHOUT_CENTER', data)
  },
  async fetchStudentWithoutCenter({commit}, studentId) {
    const { data } = await apiClient.get(`/admin/students/${studentId}/without-center`)
    commit('SET_STUDENT_WITHOUT_CENTER', data)
  },
  async updateStudentWithoutCenter(commit, { studentId, newStudent }) {
    try {
      await apiClient.patch(`/admin/students/${studentId}/without-center`, newStudent)
    } catch (err) {
      throw err.response.data.errors
    }
  },
  async fetchStudentsList({commit}) {
    const { data } = await apiClient.get('/admin/students')
    commit('SET_STUDENTS_LIST', data)
  },
  async handleSwitchUser(commit, {route, endpoint}) {
    try {
      const { data } = await apiClient.post(endpoint, {})
      localStorage.setItem('accessToken', data.accessToken)
      localStorage.setItem('refreshToken', data.refreshToken)

      router.push({ name: route })
    } catch(error) {
      return error
    }
  }
}

const mutations = {
  SET_CENTERS_LIST(state, centers) {
    state.centers = centers
  },
  SET_SERVICES_LIST(state, services) {
    state.services = services
  },
  ADD_NEW_CENTER(state, newCenter) {
    state.centers.push(newCenter)
  },
  SET_GROUPS_SESSIONS(state, groupsSessions) {
    state.groupsSessions = groupsSessions
  },
  DELETE_GROUP(state, groupId) {
    const groupIndex = state.groupLists.findIndex(group => group.id == groupId)
    state.groupLists.splice(groupIndex, 1)
  },
  UPDATE_STUDENTS_ID(state, missingStudentsIds) {
    state.missingStudentsIds = missingStudentsIds
  },
  UPDATE_STUDENTS_IDS_MISSING_SESSION(state, idx) {
    const reduce = state.groupsSessions[idx].students.reduce(
      function (valorAnterior, valorActual) {
        if(valorActual.isMissing) {
          valorAnterior.push(valorActual.id)
        }
        return valorAnterior;
      },
      []
    )
    state.missingStudentsIds = reduce

    return reduce
  },
  SET_PAYMENTS(state, payments) {
    state.payments = payments
  },
  ADD_PAYMENT(state, mewPayment) {
    state.payments.push(mewPayment)
  },
  UPDATE_PAYMENT(state, { payment, paymentId }) {
    const acceptedPaymentIndex = state.payments.content.findIndex(_payment => _payment.id === parseInt(paymentId))
    state.payments.content[acceptedPaymentIndex] = {
      ...state.payments.content[acceptedPaymentIndex],
      ...payment
    }
  },
  UPDATE_CENTER(state, { id, payload }) {
    const centerIdx = state.centers.findIndex(center => center.id === parseInt(id))
    state.centers[centerIdx].paymentMethods = payload.paymentMethods
  },
  SET_STAFF_MEMBERS(state, staffMembers) {
    state.staffMembers = staffMembers
  },
  DELETE_STAFF_MEMBER(state, staffId) {
    const staffIndex = state.staffMembers.findIndex(member => member.id === staffId)
    state.staffMembers.splice(staffIndex, 1)
  },
  SET_NEW_TEACHER(state, newTeacher) {
    state.staffMembers.push(newTeacher)
  },
  DELETE_CENTER(state, centerId) {
    const indexCenter = state.centers.findIndex(center => center.id == centerId)
    state.centers.splice(indexCenter, 1)
  },
  SET_CENTER(state, center) {
    state.center = center
  },
  SET_NEW_SERVICE(state, newService) {
    state.services.push(newService)
  },
  DELETE_SERVICE(state, serviceId) {
    const indexService = state.services.findIndex(service => service.id == serviceId)
    state.services.splice(indexService, 1)
  },
  SET_ALLERGIES_LIST(state, allergies) {
    state.allergies = allergies
  },
  ADD_NEW_ALLERGY(state, newAllergy) {
    state.allergies.push(newAllergy)
  },
  DELETE_ALLERGY(state, allergyId) {
    const indexAllergy = state.allergies.findIndex(center => center.id == allergyId)
    state.allergies.splice(indexAllergy, 1)
  },
  SET_USER_INFO(state, userInfo) {
    state.userInfo = userInfo
  },
  SET_ENROLLMENTS(state, enrollmentsInfo) {
    state.enrollments = enrollmentsInfo
  },
  SET_ENROLLMENT(state, enrollmentInfo) {
    state.enrollment = enrollmentInfo
  },
  SET_GROUP_LIST(state, groupLists) {
    state.groupLists = groupLists
  },
  ADD_NEW_GROUP(state, newGroup) {
    state.groupLists.push(newGroup)
  },
  SET_ACTIVE_GROUP(state, activeGroup) {
    state.activeGroup = activeGroup
  },
  ADD_NEW_ENROLLMENT(state, mewEnrollment) {
    state.enrollments.push(mewEnrollment)
  },
  SET_TUTOR_REDSYS_LIST(state, redsysList) {
    state.tutorRedsysList = redsysList
  },
  SET_STUDENTS_WITHOUT_CENTER(state, students) {
    state.studentsWithoutCenter = students
  },
  SET_STUDENT_WITHOUT_CENTER(state, student) {
    state.studentWithoutCenter = student
  },
  SET_STUDENTS_LIST(state, students) {
    state.students = students
  }
}

const getters = {
  getCentersList:(state) => state.centers,
  getServicesList:(state) => state.services,
  getActiveCenter: (state) => (id) => {
    return state.centers.find(center => center.id == id)
  },
  getUserInfo: (state) => state.userInfo,
  getGroupsSessions:(state) => state.groupsSessions,
  getActiveGroup: (state) => state.activeGroup,
  getMissingStudentsId:(state) => state.missingStudentsIds,
  // getPayments: (state) => state.payments.map(formatPayment),
  getPayments(state) {
    const paymentsContent = (state.payments.content ?? state.payments).map(payment => ({
      ...payment,
      students: payment.student.map(item =>
          (item.name + ' ' + item.surnames +' ('+ item.year +')')
      ),
      names: payment.student.map(item =>
          item.name
      ),
      years: payment.student.map(item =>
          item.year
      ),
      price: payment.price,
      orderAt: payment.orderAt ? formatDateShort(payment.orderAt) : '',
      paidAt: payment.paidAt ? formatDateShort(payment.paidAt) : ''
    }));

    return state.payments.content ? {
        ...state.payments,
        content: paymentsContent
      } : paymentsContent;
  },
  // getActivePayment: (state) => (paymentId) => formatPayment(state.payments.find(payment => payment.id.toString() === paymentId)),
  getActivePayment: (state) => (paymentId) => {
    let activePayment = state.payments.find(payment => payment.id.toString() === paymentId)
    if (activePayment !== undefined) {
      return {
        ...activePayment,
        students: activePayment.student ? activePayment.student.map(item =>
            (item.name + ' ' + item.surnames + ' ('+ item.year +')')
        ) : '',
        price: activePayment.price ? activePayment.price : '',
        orderAt: activePayment.orderAt ? formatDateShort(activePayment.orderAt) : '',
        paidAt: activePayment.paidAt ? formatDateShort(activePayment.paidAt) : ''
      }
    } else {
      return ''
    }
  },
  getStaffMembers: (state) => state.staffMembers.map(staffMember => ({
    ...staffMember,
    centers: staffMember.centers.map(center => `${center.name} (${center.address})`)
  })),
  getActiveTeacher: (state) => (teacherId) => state.staffMembers.find(teacher => teacher.id.toString() === teacherId),
  getCenter: (state) => state.center,
  getActiveService: (state) => (id) => {
    return state.services.find(service => service.id == id)
  },
  getAllergies: (state) => state.allergies,
  getActiveAllergy: (state) => (allergyId) => state.allergies.find(allergy => allergy.id.toString() === allergyId),
  getEnrollments: (state) => state.enrollments,
  getEnrollment: (state) => state.enrollment,
  getGroupLists: (state) => state.groupLists,
  getTutorRedsysList: (state) => state.tutorRedsysList,
  getStudentsWithoutCenter: (state) => state.studentsWithoutCenter,
  getStudentWithoutCenter: (state) => state.studentWithoutCenter,
  getStudentsList: (state) => state.students
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
