<template>
<div class="enrollment-confirmation-wrapper box">
  <div class="enrollment-confirmation-box">
    <h2 class="subtitle">Formaliza la matrícula del campamento / taller</h2>
    <p class="text loading" v-if="isLoading">
      ¡Cargando datos!
    </p>
    <div v-else class="enrollment-confirmation">
      <div class="credit-card">
        <h3 v-if="summary.firstPaymentPercentage !== 100">Elige tu forma de pago</h3>

        <div v-if="summary.firstPaymentPercentage !== 100" class="radios-wrapper">
          <label for="noFullPayment">
            <input type="radio" id="noFullPayment" :value="false" v-model="isFullPayment" checked>
            <div>
              <span>Dos pagos:</span>
              <ol>
                <li>Para campamentos urbanos o talleres, el segundo pago se cobrará automáticamente una semana antes del
                  comienzo de los mismos.</li>
                <li>Para Camp Treetop (campamento residencial) el segundo cobro se realizará automáticamente el día 1 de junio.</li>
              </ol>
            </div>
          </label>
          <label for="fullPayment">
            <input type="radio" id="fullPayment" :value="true" v-model="isFullPayment">
            <div>
              <span>Un pago único ahora mismo</span>
            </div>
          </label>
        </div>

        <h3>Datos de pago</h3>
        <form action class="form" @submit.prevent="paymentCharge">
          <template v-if="!getUserInfo.isSwitchTutor">
            <div class="form__field-row" v-if="hasPaymentMethod">
              <div class="form__field-wrapper card__number">
                <div class="form__field">
                  <label class="form__field-label" for="#credit-card">Número de tarjeta: *</label>
                  <input
                    class="form__field-input"
                    type="text"
                    id="cardNumber"
                    required
                    :placeholder="`Tarjeta con expiración: ${summary.redsysExpireDate}`"
                    :disabled="true"
                  >
                </div>
              </div>
            </div>
            <p class="other-payment" v-if="hasPaymentMethod" @click="updateCardNumber">¿Quieres utilizar un método de pago diferente?</p>

            <div class="form__field-row half add-new-card" v-if="!hasPaymentMethod">
              A continuación accederás a la pasarela de pago donde indicar los datos de la tarjeta de forma segura.
            </div>
            <Switch v-model:checked="isConditionAccepted" label="Acepto las condiciones de contratación" />
            <p v-if="!isConditionAccepted && showErrorCondition" class="enrollment-confirmation__errors">Debes aceptar las condiciones generales de contratación</p>
            <div class="legal">
              <p><strong>Condiciones de contratación</strong></p>
              <p class="text">
                <small>
                  Antes de aceptar las condiciones generales de contratación, por favor leedlas detenidamente en:
                </small>
                <a class="enrollment-confirmation__link" href="https://lacasitadeingles.com/condiciones-generales-de-contratacion/">
                  https://lacasitadeingles.com/condiciones-generales-de-contratacion/
                </a>
              </p>
            </div>
            <div class="message">
              <div class="check">
                <img class="check__img" alt="check" src="../assets/check.png">
              </div>
              <p class="text"><strong>Nuestro pago es 100% seguro y está protegido por Redsys.</strong> Almacenaremos tus datos de pago para automatizar el cobro de las cuotas de este curso y siempre podrás ver en tu perfil cualquier cargo que te hayamos realizado.</p>
            </div>
          </template>
          <p v-else>Al estar contratando un campamento en nombre de este tutor, los pagos se marcarán SEPA y autopagados.</p>
          <button class="btn" :class="{disabled: !isConditionAccepted || isLoading}" type="submit">{{ getButtonText() }}</button>

        </form>
        <div class="errors" v-for="(error, key) in errors" :key="key">
          <p>{{ $t(error) }}</p>
        </div>
      </div>
      <div class="order">
        <h3>Resumen de tu pedido:</h3>
        <h4>{{ campName }}</h4>
        <div class="order__card">
          <div class="order__summary">
            <div v-for="(student, idx) in studentsInfo.studentsInfo" :key="idx">
                <p><strong>{{student.studentName}}</strong></p>
              <div class="services" v-for="(services, idx) in student.studentInfo" :key="idx">
                <p>{{services.serviceName}} <span>{{formatPrice(services.servicePrice)}}</span></p>
              </div>
            </div>
          </div>
          <div class="order__discount" :class="{fullpayment: isFullPayment}">
            <p>Subtotal: <span>{{ formatPrice(summary.totalWithoutDiscounts) }}</span></p>
            <p v-if="summary.discountPerStudents">Descuento por hermanos: <span>-{{ formatPrice(summary.discountPerStudents) }}</span></p>
            <p v-if="summary.discountPerPeriods">Descuento por duración: <span>-{{ formatPrice(summary.discountPerPeriods) }}</span></p>
            <p v-if="summary.discountPerGroups">Descuento Casita Family: <span>-{{ formatPrice(summary.discountPerGroups) }}</span></p>
            <ul></ul>
            <p v-if="!isFullPayment">Total: <span>{{ formatPrice(summary.totalWithDiscounts) }}</span></p>
          </div>
          <div class="order__payment">
            <ul></ul>
            <div class="total-pay">
              <p v-if="!isFullPayment">Total a pagar ahora: ({{ summary.firstPaymentPercentage }}%)</p>
              <p v-else>Total:</p>
              <span v-if="!isFullPayment">{{ formatPrice((summary.totalWithDiscounts * summary.firstPaymentPercentage)/100) }}</span>
              <span v-else>{{ formatPrice(summary.totalWithDiscounts) }}</span>
            </div>
          </div>
          <div class="order__month" v-if="!isFullPayment">
            <ul>
              <li><p>Pago {{ 100 - summary.firstPaymentPercentage }}% restante</p><span>{{ formatPrice(summary.totalWithDiscounts -  (summary.totalWithDiscounts * summary.firstPaymentPercentage)/100  ) }}</span></li>
            </ul>
            <ol>
              <li class="text">Para campamentos urbanos o talleres, el segundo pago se cobrará automáticamente una semana antes del
                comienzo de los mismos.</li>
              <li class="text">Para Camp Treetop (campamento residencial) el segundo cobro se realizará automáticamente el día 1 de
                junio.</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div>
      <form id="redsys-form" method="post" :action="url">
        <input type="hidden" name="Ds_MerchantParameters"/>
        <input type="hidden" name="Ds_SignatureVersion"/>
        <input type="hidden" name="Ds_Signature"/>
      </form>
    </div>
  </div>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { formatDate, formatPrice } from '@/commonMethods/methods'
import Switch from '../components/Switch.vue'

export default {
  name: 'EnrollmentConfirmation',
  components: {
    Switch
  },
  props: {
    campId: {
      type: String,
      required: true
    },
    idCenter: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      errors: [],
      isLoading: false,
      summary: {},
      hasPaymentMethod: false,
      isFullPayment: false,
      isConditionAccepted: false,
      showErrorCondition: false
    }
  },
  computed: {
     ...mapGetters('TutorCamps', {
      infoOrderSummary: 'getInfoOrderSummary',
      campName: 'getCampName',
      studentsInfo: 'getStudentsInfo'
    }),
    ...mapGetters('Admin', ['getUserInfo'])
  },
  methods: {
    ...mapActions('User', ['fetchProfile']),
    ...mapActions('TutorCamps', ['orderSummary', 'paymentCampCharge']),
    formatDate,
    formatPrice,
    updateCardNumber() {
      this.hasPaymentMethod = false
    },
    getButtonText() {
      let price = ''
      if(this.isFullPayment) {
        price = this.formatPrice(this.summary.totalWithDiscounts)
      } else {
        price = this.formatPrice((this.summary.totalWithDiscounts * this.summary.firstPaymentPercentage)/100)
      }
      if (this.hasPaymentMethod) {
        return `Pagar ahora ${price}`
      } else {
        return `Guardar y pagar ${price}`
      }
    },
    async paymentCharge() {
      if (this.isConditionAccepted && !this.isLoading) {
        this.showErrorCondition = false
        try {
          this.isLoading = true
          let campInfoPayment = {
            hasPaymentMethod: this.hasPaymentMethod,
            orderSummary: [
              ...this.infoOrderSummary
            ],
            isFullPayment: this.isFullPayment
          }

          const response = await this.paymentCampCharge({ campId: this.campId, campInfoPayment })

          if (response.status === 200) {
            const redsysForm = document.getElementById('redsys-form')
            redsysForm.action = response.data.url
            redsysForm.elements['Ds_MerchantParameters'].value = response.data.Ds_MerchantParameters
            redsysForm.elements['Ds_SignatureVersion'].value = response.data.Ds_SignatureVersion
            redsysForm.elements['Ds_Signature'].value = response.data.Ds_Signature
            redsysForm.submit()
          } else {
            await this.$router.push({ name: 'new-camp-confirmation-ok', params: { id: response.data.paymentId } })
          }
        } catch(errors) {
          if (errors && errors.cardNumber) {
            this.errors = errors.cardNumber
          } else {
            this.errors = errors
          }
        } finally {
          this.isLoading = false
        }
      } else {
        this.showErrorCondition = true
      }
    },
  },
  async mounted() {
    if (this.campName.length === 0) {
      this.$router.push({ name: 'new-camp-center', params: { idCenter: this.idCenter } })
    } else {
      this.isLoading = true
      this.orderSummary({campId: this.campId, infoOrderSummary: this.infoOrderSummary})
      .then(response => {
        this.summary = response
        this.hasPaymentMethod = response.hasPaymentMethod
        this.isFullPayment = this.summary.firstPaymentPercentage === 100;
        this.isLoading = false
      })
      .catch(err => {
        console.log('error', err);
      })
    }

    if (this.getUserInfo.isSwitchTutor) {
      this.isConditionAccepted = true
    }
  }
}
</script>

<style scoped lang="scss">
.enrollment-confirmation-box {
  .subtitle {
    text-align: center;
  }
  .enrollment-confirmation {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
    gap: 10px 60px;
    .credit-card {
      min-width: 70vw;
      .add-new-card {
        margin-bottom: 30px;
      }
      .form {
        width: 100%;
        .form__field-row {
          &.half {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .form__field-wrapper {
              display: inline-block;
              vertical-align: top;
              width: calc(50% - 20px);
              &:nth-child(2n-1) {
                margin-right: 10px;
              }
              &:nth-child(2n) {
                margin-left: 10px;
              }
            }
          }
          position: relative;
          .credit-card-icon {
            .form__field-input {
              padding-left: 45px;
            }
            .icon {
              width: 25px;
              position : absolute;
              top: 7px;
              left: 12px;
            }
            .payment-method {
              padding: 10px;
              font-size: 12px;
              color: $blue-link;
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }
        .message {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 40px;
          .check {
            margin-right: 18px;
          }
          .text {
            margin-top: 6px;
            width: 80%;
          }
        }
        .btn {
          width: 100%;
        }
      }
      @media (min-width: 995px) {
        max-width: 400px;
        min-width: 400px;
      }
    }
    .order {
      min-width: 70vw;
      margin-bottom: 26px;
      h4 {
        color: $newBlue;
        text-align: center;
        font-weight: 600;
      }
      .order__card {
        ul {
          list-style: circle;
          margin-top: 0;
          li {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            margin: 0 0 15px 0;
            p {
              margin: 0;
            }
            span {
              font-weight: 700;
            }
          }
        }
        .order__summary {
          background-color: $bg-blue;
          padding: 15px 25px;
          border-radius: 16px;
          margin-bottom: 20px;
          p {
            color: $white;
            margin: 5px 0;
            font-size: 14px;
            display: flex;
            justify-content: space-between;
          }
          .services {
            margin-left: 20px;
          }
        }
        .order__discount {
          margin: 0 25px;
          p {
            display: flex;
            justify-content: space-between;
            span {
              margin-left: 20px;
            }
          }
          &.fullpayment {
            ul {
              display: none;
            }
          }
          ul {
            li {
              &:last-child {
                margin-bottom: 20px;
              }
            }
            &::after {
              content: '';
              width: 90%;
              height: 1px;
              background-color: $newText;
              display: block;
              margin: 0 auto;
            }
          }
        }
        .order__payment {
          margin: 0 25px 25px;
          ul {
            li {
              &:last-child {
                margin-bottom: 20px;
              }
            }
            &::after {
              content: '';
              width: 90%;
              height: 1px;
              background-color: $newText;
              display: block;
              margin: 0 auto;
            }
          }
          p {
            &.text {
              font-size: 12px;
            }
          }
          .total-pay {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 700;
            p {
              margin: 0;
              margin-right: 20px;
            }
          }
        }
        .order__month {
          background-color: $newYellow;
          padding: 15px 25px;
          border-radius: 16px;
          p, li {
            font-weight: 700;
            &.text {
              font-size: 12px;
              font-weight: 400;
            }
          }
        }
      }
      @media (min-width: 995px) {
        max-width: 400px;
        min-width: 400px;
        position: sticky;
        top: 0;
        align-self: flex-end;
      }
    }

    &__errors {
      font-size: 12px;
      color: $error;
      margin: 0 0 32px 62px;
    }

    &__link {
      color: $bg-btn-active;
      font-size: 13px;
      text-decoration: none;
    }
  }
}
.other-payment {
  cursor: pointer;
  color: $newBlue;
  margin-top: 0;
  font-size: 12px;
  margin-bottom: 40px;
}
.card__number {
  margin-bottom: 6px;
}
.radios-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-bottom: 30px;
  gap: 20px;
  label {
    background-color: $newLightBlue;
    border-radius: 18px;
    padding: 20px 20px 20px 32px;
    display: inline-block;
    position: relative;
    div {
      margin-left: 6px;
      span {
        font-weight: 700;
      }
      p {
        margin: 8px 0;
      }
    }
    input {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
input[type=radio] {
  margin: 0;
  width: auto;
  display: inline-block;
  opacity: 0;
  & + span, & + div {
    position: relative;
    cursor: pointer;
    padding-left: 5px;

    &::before {
      content: " ";
      position: absolute;
      right: 100%;
      top: 6px;
      margin-top: -8px;
      height: 18px;
      width: 18px;
      border-radius: 50%;
      background-color: transparent;
      border: 2px solid $black !important;
    }
    &::after {
      content: " ";
      width: 25px;
      height: 17px;
      position: absolute;
      left: -16px;
      top: 3px;
      margin-top: -7px;
    }
  }
  &:checked + span::after, &:checked + div::after{
    background: url('../assets/check-radio.svg');
    background-repeat: no-repeat;
  }
  &:checked + span::before, &:checked + div::before {
    background-color: $black;
  }
}
.loading {
  text-align: center;
}
.btn {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  &.disabled {
    background-color: $grey;
    border: 1px solid $grey;
    cursor: not-allowed;
    pointer-events: none;
    &:hover {
      background-color: $grey;
      border: 1px solid $grey;
      color: $white;
    }
  }
}
</style>
