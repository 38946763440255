<template>
  <header class="header">
    <router-link :to="{ name: 'login' }">
      <img class="header__logo" alt="Vue logo" src="../assets/logo.png">
    </router-link>
  </header>
</template>

<script>
export default {
  name: 'Header',
}
</script>

<style scoped lang="scss">

</style>
