<template>
  <footer class="footer-wrapper">
    <div class="footer">
      <div class="copy-wrapper">
        <router-link :to="{ name: 'parents-main-screen' }" class="logo-wrapper">
          <img class="menu__logo" alt="Vue logo" src="../assets/logo.png">
        </router-link>
        <p class="copy">La Casita de Inglés © 2021</p>
      </div>
      <div class="legal-wrapper">
        <ul class="legal">
          <li><a href="https://lacasitadeingles.com/politica-de-privacidad/" target="_blank">Política de privacidad</a></li>
          <li><a href="https://lacasitadeingles.com/politica-de-cookies/" target="_blank">Cookies</a></li>
          <li><a href="https://lacasitadeingles.com/aviso-legal/" target="_blank">Aviso legal</a></li>
          <li><a href="https://lacasitadeingles.com/condiciones-generales-de-contratacion/" target="_blank">Condiciones generales de contratacion</a></li>
        </ul>
      </div>
      <div class="social-wrapper">
        <ul>
          <li><a href="https://www.instagram.com/casitadeingles/" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M14.829 6.302c-.738-.034-.96-.04-2.829-.04s-2.09.007-2.828.04c-1.899.087-2.783.986-2.87 2.87-.033.738-.041.959-.041 2.828s.008 2.09.041 2.829c.087 1.879.967 2.783 2.87 2.87.737.033.959.041 2.828.041 1.87 0 2.091-.007 2.829-.041 1.899-.086 2.782-.988 2.87-2.87.033-.738.04-.96.04-2.829s-.007-2.09-.04-2.828c-.088-1.883-.973-2.783-2.87-2.87zm-2.829 9.293c-1.985 0-3.595-1.609-3.595-3.595 0-1.985 1.61-3.594 3.595-3.594s3.595 1.609 3.595 3.594c0 1.985-1.61 3.595-3.595 3.595zm3.737-6.491c-.464 0-.84-.376-.84-.84 0-.464.376-.84.84-.84.464 0 .84.376.84.84 0 .463-.376.84-.84.84zm-1.404 2.896c0 1.289-1.045 2.333-2.333 2.333s-2.333-1.044-2.333-2.333c0-1.289 1.045-2.333 2.333-2.333s2.333 1.044 2.333 2.333zm-2.333-12c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.958 14.886c-.115 2.545-1.532 3.955-4.071 4.072-.747.034-.986.042-2.887.042s-2.139-.008-2.886-.042c-2.544-.117-3.955-1.529-4.072-4.072-.034-.746-.042-.985-.042-2.886 0-1.901.008-2.139.042-2.886.117-2.544 1.529-3.955 4.072-4.071.747-.035.985-.043 2.886-.043s2.14.008 2.887.043c2.545.117 3.957 1.532 4.071 4.071.034.747.042.985.042 2.886 0 1.901-.008 2.14-.042 2.886z"/></svg></a></li>
          <li><a href="https://www.facebook.com/casitadeingles" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3 8h-1.35c-.538 0-.65.221-.65.778v1.222h2l-.209 2h-1.791v7h-3v-7h-2v-2h2v-2.308c0-1.769.931-2.692 3.029-2.692h1.971v3z"/></svg></a></li>
          <li><a href="https://www.youtube.com/user/casitadeingles" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.441 16.892c-2.102.144-6.784.144-8.883 0-2.276-.156-2.541-1.27-2.558-4.892.017-3.629.285-4.736 2.558-4.892 2.099-.144 6.782-.144 8.883 0 2.277.156 2.541 1.27 2.559 4.892-.018 3.629-.285 4.736-2.559 4.892zm-6.441-7.234l4.917 2.338-4.917 2.346v-4.684z"/></svg></a></li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
.footer-wrapper {
  background-color: $newBlue;
  .footer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 30px;
    max-width: 1260px;
    .copy-wrapper {
      .logo-wrapper {
        width: 200px;
        margin: 50px 0 25px;
        display: block;
        img {
          width: 100%;
        }
      }
      .copy {
        font-size: 12px;
        text-align: center;
        color: $white;
      }
    }
    .legal-wrapper {
      ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        li {
          a {
            text-decoration: none;
            color: $white;
            font-size: 14px;
            font-weight: 700;
          }
          &:hover {
            a {
              color: $newYellow;
            }
          }
        }
      }
    }
    .social-wrapper {
      ul {
        display: flex;
        li {
          font-weight: 700;
          color: $white;
          svg {
            fill: $white;
            width: 30px;
            height: 30px;
          }
          &:hover {
            svg {
              fill: $newYellow;
            }
          }
        }
      }
    }
  }
  @include tablet {
    .footer {
      flex-direction: row;
      .legal-wrapper {
        ul {
          flex-direction: row;
          flex-wrap: wrap;
          li {
            margin: 0;
            &::after {
              content: '';
              width: 1px;
              height: 10px;
              display: inline-block;
              background-color: $newGrey;
              margin: 0 16px;
            }
            &:last-child {
              &::after {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
</style>