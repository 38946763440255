<template>
  <div class="main">
    <Loading v-if="isLoading" />
    <div v-else>
      <div class="csv__header">
        <h2 class="subtitle flex-grow">Alumn@s:</h2>
        <button class="new-element compact">
          <svg viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.7758 19.504H19.6398V30.384H11.3838V19.504H0.31175V11.696H11.3838V0.815998H19.6398V11.696H30.7758V19.504Z" fill="#00ADCE"/>
          </svg>
          <router-link :to="{ name: 'student-create' }"></router-link>
          Añadir nuevo alumn@
        </button>
        <button
          class="btn csv__button"
          type="button"
          @click="downloadCSV">
          <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
            <path d="M22 24h-20v-24h14l6 6v18zm-7-23h-12v22h18v-16h-6v-6zm3 15v1h-12v-1h12zm0-3v1h-12v-1h12zm0-3v1h-12v-1h12zm-2-4h4.586l-4.586-4.586v4.586z"/>
          </svg>
          <span class="csv__button__text">Descargar CSV</span>
        </button>
      </div>
      <input
        type="text"
        placeholder="Buscar alumn@"
        v-model="input"
        class="students__filters__text">
      <div class="group-list">
        <table class="admin__table" v-if="studentSearch.length">
          <thead class="admin__table__head">
            <tr>
              <th class="admin__table__head__cell">Nombre alumn@</th>
              <th class="admin__table__head__cell">Nombre grupo</th>
              <th class="admin__table__head__cell">Año nacimiento</th>
              <th class="admin__table__head__cell">Autorización imagen</th>
              <th class="admin__table__head__cell">Alergias</th>
              <th v-if="userInfo.isSupervisor || userInfo.isSuperAdmin" class="admin__table__head__cell"></th>
            </tr>
          </thead>
          <tbody class="admin__table__body">
            <tr v-for="(student, idx) in studentSearch" :key="idx">
              <td @click="showStudentDetail(student.id)" class="admin__table__body__cell cell-students" :class="{'noSupervisor': !userInfo.isSupervisor}"><span class="admin__table__body__cell__header">Nombre alumn@</span>{{ student.name }} {{ student.surnames }}</td>
              <td @click="showStudentDetail(student.id)" class="admin__table__body__cell cell-students" :class="{'noSupervisor': !userInfo.isSupervisor}">
                <span class="admin__table__body__cell__header">Nombre grupo</span>
                <div class="group-name">
                  <p v-for="(group, idx) in student.groups" :key="idx" class="groups-name">{{ group.name }}</p>
                </div>
              </td>
              <td @click="showStudentDetail(student.id)" class="admin__table__body__cell cell-students" :class="{'noSupervisor': !userInfo.isSupervisor}">
                <span class="admin__table__body__cell__header">Año nacimiento</span>
                {{ student.year }}
              </td>
              <td @click="showStudentDetail(student.id)" class="admin__table__body__cell cell-students" :class="{'noSupervisor': !userInfo.isSupervisor}">
                <span class="admin__table__body__cell__header">Autorización imagen</span>
                {{ student.imageAuthorized ? 'Autorizado' : 'No autorizado' }}
              </td>
              <td @click="showStudentDetail(student.id)" class="admin__table__body__cell cell-students" :class="{'noSupervisor': !userInfo.isSupervisor}">
                <span class="admin__table__body__cell__header">Alergias</span>
                <div>
                  <div v-for="(allergy, idx) in student.allergies" :key="idx">{{allergy.name}}</div>
                </div>
              </td>
              <td v-if="userInfo.isSupervisor || userInfo.isSuperAdmin" class="admin__table__body__cell cell-students" :class="{'noSupervisor': !userInfo.isSupervisor}">
                <span class="admin__table__body__cell__header"></span>
                <button @click="deleteStudent(student.id)" class="btn">Borrar</button>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else class="message__noData">
          <div class="message__noData-icon">
            <svg width="13" height="36" viewBox="0 0 13 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.880469 -2.38419e-06H12.1805L10.2805 22.25H2.73047L0.880469 -2.38419e-06ZM6.53047 35.45C4.8638 35.45 3.49714 34.95 2.43047 33.95C1.3638 32.9167 0.830469 31.6667 0.830469 30.2C0.830469 28.7 1.3638 27.4667 2.43047 26.5C3.49714 25.5333 4.8638 25.05 6.53047 25.05C8.19714 25.05 9.54714 25.5333 10.5805 26.5C11.6471 27.4667 12.1805 28.7 12.1805 30.2C12.1805 31.6667 11.6471 32.9167 10.5805 33.95C9.5138 34.95 8.1638 35.45 6.53047 35.45Z" fill="white"/>
            </svg>
          </div>
          <div class="message__noData-text">
            <p>No hay alumn@s para mostrar</p>
            <p>Prueba seleccionando otro centro</p>
          </div>
        </div>
      </div>
    </div>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Loading from '../Loading.vue'
import { apiClient } from '@/services/ApiClient'

export default {
  name: 'Students',
  components: {
    Loading
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      input: '',
      groups: []
    }
  },
  computed: {
    ...mapGetters('AdminCenter', {
      students: 'getStudentsByCenter',
    }),
    ...mapGetters('Admin', {
      userInfo: 'getUserInfo'
    }),
    studentsList() {
      return this.students.map(student => ({
        ...student,
        fullName: `${student.name} ${student.surnames}`,
        image: student.imageAuthorized ? 'Autorizado' : 'No autorizado'
      }))
    },
    studentSearch() {
      return this.studentsList.filter(_student =>
          (_student.fullName.normalize("NFD").replace(/\p{Diacritic}/gu, "").toUpperCase().includes(this.input.normalize("NFD").replace(/\p{Diacritic}/gu, "").toUpperCase()) ||
          _student.year.toString().toUpperCase().includes(this.input.toUpperCase()) ||
          _student.groups.find(group => group.name.normalize("NFD").replace(/\p{Diacritic}/gu, "").toUpperCase().includes(this.input.normalize("NFD").replace(/\p{Diacritic}/gu, "").toUpperCase())) ||
          _student.image.toUpperCase().includes(this.input.toUpperCase()) ||
          _student.allergies.find(allergy => allergy.name.normalize("NFD").replace(/\p{Diacritic}/gu, "").toUpperCase().includes(this.input.normalize("NFD").replace(/\p{Diacritic}/gu, "").toUpperCase()))
        )
      )
    }
  },
  methods: {
    ...mapActions('AdminCenter', ['fetchStudents', 'studenDelete']),
    ...mapActions('Admin', ['fetchCSVToken']),
    showStudentDetail(studentId) {
      if(this.userInfo.isSupervisor) {
        this.$router.push({ name: 'student-detail', params: { id: this.id, studentId } })
      }
    },
    async downloadCSV() {
      try {
        const response = await this.fetchCSVToken()
        const csv = `${process.env.VUE_APP_API_BASE_URI}/admin/centers/${this.$route.params.id}/students.csv?token=${response.data.token}`
        window.open(csv)
      } catch (error) {
        this.$toast.error('Lo sentimos, no hemos podido descargar el CSV, inténtelo de nuevo');
      }
    },
    async deleteStudent(studentId) {
      if (confirm('¿Éstas seguro que quieres borrar este estudiante?')) {
        try {
          await apiClient.delete(`/admin/centers/${this.id}/students/${studentId}`)
          this.$toast.success('El alumn@ se ha borrado correctamente.')
          await this.fetchStudents(this.id)
        } catch (error) {
          const { errors } = error.response.data
          if (errors[0] === 'error.student.with-active-enrollments') {
            this.$toast.error('No ha sido posible borrar a este alumn@ debido a que cuenta con matrículas activas.')
          } else if (errors[0] === 'error.student.with-future-payments') {
            this.$toast.error('No ha sido posible borrar a este alumn@ debido a que cuenta con pagos pendientes.')
          } else if (errors[0] === 'error.student.with-active-camps') {
            this.$toast.error('No ha sido posible borrar a este alumn@ debido a cuenta con campamentos activos.')
          } else {
            this.$toast.error('No ha sido posible borrar a este alumn@.')
          }
        }
      }
    }
  },
  mounted() {
    this.isLoading = true
    this.fetchStudents(this.id)
    .then(() => {
      this.isLoading = false
    })
    .catch(() => {
      this.isLoading = true
    })
  }
}
</script>
<style lang="scss" scoped>
.group-wrapper {
  background-color: $newLightBlue;
  border-radius: 18px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  // padding: 30px;
  .group-info {
    span {
      font-weight: 700;
    }
    p {
      margin-top: 4px;
    }
  }
  .student {
    color: $newBlue;
    font-weight: 700;
    display: flex;
    align-items: center;
    align-self: flex-end;
    margin-top: 30px;
    cursor: pointer;
    svg {
      margin-left: 12px;
      transition: transform .3s ease;
    }
    &.open {
      svg {
        transform: rotate(-180deg);
      }
    }
  }
  @include tablet {
    flex-direction: row;
    justify-content: space-between;
    gap: 20px 0;
    text-align: unset;
    padding: 20px 30px;
    .group-info {
      p {
        margin-bottom: 0;
      }
    }
    .session {
      flex: 0 0 100px;
    }
    .group {
      flex: 0 0 300px;
    }
    .tutor {
      flex: 0 0 300px;
    }
    .student {
      flex: 0 0 172px;
      justify-content: flex-end;
      margin-top: 0;
    }
  }
}
.students__filters__text {
  border: 2px solid $newPink;
  border-radius: 16px;
  box-sizing: border-box;
  font-size: 12px;
  margin-bottom: 16px;
  padding: 8px;
  width: 100%;

  @include desktop {
    width: 40%;
  }
}
.noSupervisor {
  cursor: auto;
}
.groups-name {
  text-align: end;
  margin: 0;
}
@include desktop {
  .cell-students {
    line-height: 26px;
    .groups-name {
      text-align: center;
    }
  }
  // .cell-students:first-child {
  //   width: 24%;
  // }
}
</style>
