<template>
  <main class="main">
    <Loading
      v-if="isLoading" />
    <div v-else>
      <form action class="form admin__form" @submit.prevent="updateStudentSubmit">
        <div class="data-row space-between">
          <h2 class="subtitle">Detalle del alumn@:</h2>
          <button type="submit" :disabled="isLoading" class="btn btn-save">Guardar</button>
        </div>
        <div class="form__field-row half">
          <div class="form__field-wrapper">
            <div class="form__field">
              <p class="input-errors" v-if="v$.form.name.$invalid && v$.form.name.$dirty">
                Por favor, introduce un nombre
              </p>
              <label class="form__field-label" for="#name">Nombre *</label>
              <input
                v-model="v$.form.name.$model"
                class="form__field-input"
                :class="{'form__input--invalid': v$.form.name.$invalid && v$.form.name.$dirty}"
                type="text"
                id="name"
                required
                placeholder=""
              >
            </div>
            <div class="errors" v-if="errors.hasOwnProperty('name')">
              <p v-for="(error, key) in errors.name" :key="key">{{ $t(error) }}</p>
            </div>
          </div>
          <div class="form__field-wrapper">
            <div class="form__field">
              <p class="input-errors" v-if="v$.form.surnames.$invalid && v$.form.surnames.$dirty">
                Por favor, introduce los apellidos
              </p>
              <label class="form__field-label" for="#surnames">Apellidos *</label>
              <input
                v-model="v$.form.surnames.$model"
                class="form__field-input"
                :class="{'form__input--invalid': v$.form.surnames.$invalid && v$.form.surnames.$dirty}"
                type="text"
                id="surnames"
                required
                placeholder=""
              >
            </div>
            <div class="errors" v-if="errors.hasOwnProperty('surnames')">
              <p v-for="(error, key) in errors.surnames" :key="key">{{ $t(error) }}</p>
            </div>
          </div>
        </div>
        <div class="form__field-wrapper">
          <div class="form__field">
            <label class="form__field-label">Fecha de nacimiento *</label>
            <datepicker
              v-model="form.birthday"
              inputFormat="dd/MM/yyyy"
              :style="useInputStyles"
              :locale="dateLocale"
            />
          </div>
        </div>
        <Switch
          v-model:checked="form.imageAuthorized"
          @onChangeSwitch="confirmationMessage"
          label="Autorizo a que se tomen y publiquen fotos del alumn@" />
        <div class="form__field">
          <label class="form__field-label" for="#allergies">Alergias</label>
          <Multiselect
            class="multiselect"
            v-model="v$.form.allergies.$model"
            mode="tags"
            id="allergies"
            :searchable="true"
            :createTag="false"
            :options="formAllergies"
            :disabled="isLoadingAllergies"
            :placeholder="isLoadingAllergies ? 'Cargando elementos' : 'Seleciona alergias'"
          />
        </div>
        <h3>Personas autorizadas</h3>
        <div class="form__field-row person-authorized" v-for="(personAuthorized, index) in personsAuthorized" :key="index">
          <div class="form__field-wrapper full-name">
            <div class="form__field">
              <label class="form__field-label" for="#name">Nombre y apellidos *</label>
              <input
                v-model="personAuthorized.name"
                class="form__field-input"
                type="text"
                id="name"
                required
              >
            </div>
          </div>
          <div class="form__field-wrapper">
            <div class="form__field">
              <label class="form__field-label" for="#dni">DNI *</label>
              <input
                v-model="personAuthorized.dni"
                class="form__field-input"
                type="text"
                id="dni"
                required
              >
            </div>
            <div class="errors errors-input" v-if="errors[index] && errors[index].dni">
              <p v-for="(error, key) in errors[index].dni" :key="key">{{ $t(error) }}</p>
            </div>
          </div>
          <div class="form__field-wrapper">
            <div class="form__field">
              <label class="form__field-label" for="#phone">Teléfono *</label>
              <input
                v-model="personAuthorized.phone"
                class="form__field-input"
                type="text"
                id="phone"
                required
              >
            </div>
            <div class="errors errors-input" v-if="errors[index] && errors[index].phone">
              <p v-for="(error, key) in errors[index].phone" :key="key">{{ $t(error) }}</p>
            </div>
          </div>
          <div class="button__container">
            <button type="submit" class="btn btn-add delete" @click="deletePersonAuthorized(index)">Borrar</button>
          </div>
        </div>
        <div class="new-element" @click="addPersonAuthorized">
          <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.7758 19.504H19.6398V30.384H11.3838V19.504H0.31175V11.696H11.3838V0.815998H19.6398V11.696H30.7758V19.504Z" fill="#00ADCE"/>
          </svg>
          Añadir persona autorizada
        </div>
        <h3>Observaciones del alumn@</h3>
        <div class="form__field-wrapper">
          <div class="form__field">
            <textarea
              v-model="form.observations"
              placeholder=""
              rows="8"
              no-resize
              class="form__field-input text-area"
            >
            </textarea>
          </div>
        </div>
      </form>
      <h3>Tutores legales:</h3>
      <form action :class="[
          'form',
          'admin__form',
          {'tutor__form': tutorAlias.length}
        ]">
        <div class="form__field-row half">
          <div class="form__field-wrapper">
            <div class="form__field">
              <label class="form__field-label" for="#name">Nombre</label>
              <input
                v-model="tutorForm.name"
                class="form__field-input"
                type="text"
                id="name"
                disabled
              >
            </div>
          </div>
          <div class="form__field-wrapper">
            <div class="form__field">
              <label class="form__field-label" for="#surnames">Apellidos</label>
              <input
                v-model="tutorForm.surnames"
                class="form__field-input"
                type="text"
                id="surnames"
                disabled
              >
            </div>
          </div>
        </div>
        <div class="form__field-row half">
          <div class="form__field-wrapper">
            <div class="form__field">
              <label class="form__field-label" for="#email">Email</label>
              <input
                v-model="tutorForm.email"
                class="form__field-input"
                type="text"
                id="email"
                disabled
              >
            </div>
          </div>
          <div class="form__field-wrapper">
            <div class="form__field">
              <label class="form__field-label" for="#emailSecondary">Segundo email</label>
              <input
                v-model="tutorForm.emailSecondary"
                class="form__field-input"
                type="email"
                id="emailSecondary"
                disabled
              >
            </div>
          </div>
        </div>
        <div class="form__field-row half">
          <div class="form__field-wrapper">
            <div class="form__field">
              <label class="form__field-label" for="#phone">Teléfono</label>
              <input
                v-model="tutorForm.phone"
                class="form__field-input"
                type="text"
                id="phone"
                disabled
              >
            </div>
          </div>
          <div class="form__field-wrapper">
            <div class="form__field">
              <label class="form__field-label" for="#phoneSecondary">Segundo teléfono</label>
              <input
                v-model="tutorForm.phoneSecondary"
                class="form__field-input"
                type="number"
                id="phoneSecondary"
                disabled
              >
            </div>
          </div>
        </div>
      </form>
      <div v-if="tutorAlias.length">
        <TutorAliasInfo :tutorAlias="tutorAlias"/>
      </div>
    </div>
  </main>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { ref } from 'vue'
import Loading from '../Loading.vue'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import es from "date-fns/locale/es"
import Datepicker from 'vue3-datepicker'
import Switch from '../../components/Switch.vue'
import Multiselect from '@vueform/multiselect'
import TutorAliasInfo from "@/components/Partials/TutorAliasInfo";
import { formatnewDate } from '@/commonMethods/methods'

const useInputStyles = {
  padding: "12px 20px",
  border: "1px solid #00ADCE",
  borderRadius: "0",
  marginRight: "30px",
  width: "calc(100% - 30px)"
}

export default {
  name: "StudentWithoutCenterDetail.vue",
  components: {
    Loading,
    Datepicker,
    Switch,
    Multiselect,
    TutorAliasInfo
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      isLoading: false,
      form: {
        name: '',
        surnames: '',
        birthday: ref(new Date()),
        imageAuthorized: false,
        allergies: [],
        observations: ''
      },
      tutorForm: {
        name: '',
        surnames: '',
        email: '',
        emailSecondary: '',
        phone: '',
        phoneSecondary: ''
      },
      personsAuthorized: [],
      dateLocale: es,
      useInputStyles,
      isLoadingAllergies: false,
      errors: {},
      tutorAlias: [],
    }
  },
  computed: {
    ...mapGetters('AdminCenter', {
      allergies: 'getAllergies'
    }),
    ...mapGetters('Admin', {
      student: 'getStudentWithoutCenter'
    }),
    formAllergies() {
      return this.allergies.map(allergy => ({
        value: allergy.id,
        label: allergy.name
      }))
    }
  },
  watch: {
    student: {
      immediate: true,
      handler(newStudent) {
        if (newStudent) {
          this.form = {
            name: newStudent.name,
            surnames: newStudent.surnames,
            birthday: new Date(`${newStudent.monthOfBirth}/${newStudent.dayOfBirth}/${newStudent.year}`),
            imageAuthorized: newStudent.imageAuthorized,
            allergies: newStudent.allergies.map(allergy => allergy.id),
            observations: newStudent.observations
          }

          newStudent.personsAuthorized.forEach(personAuthorized => {
            this.personsAuthorized.push({
              name: personAuthorized.name,
              dni: personAuthorized.dni,
              phone: personAuthorized.phone
            })
          })

          this.tutorAlias = newStudent.tutorAlias

          this.tutorForm = {
            name: newStudent.tutor.name,
            surnames: newStudent.tutor.surnames,
            email: newStudent.tutor.email,
            emailSecondary: newStudent.tutor.emailSecondary,
            phone: newStudent.tutor.phone,
            phoneSecondary: newStudent.tutor.phoneSecondary
          }
        }
      }
    }
  },
  validations() {
    return {
      form: {
        name: {
          required
        },
        surnames: {
          required
        },
        imageAuthorized: false,
        allergies: false,
      }
    }
  },
  methods: {
    ...mapActions('AdminCenter', ['fetchAllergies']),
    ...mapActions('Admin', ['fetchStudentWithoutCenter', 'updateStudentWithoutCenter']),
    addPersonAuthorized() {
      this.personsAuthorized.push({
        name: '',
        dni: '',
        phone: null
      })
    },
    deletePersonAuthorized(idx) {
      this.personsAuthorized.splice(idx, 1)
    },
    async updateStudentSubmit() {
      if(!this.v$.$invalid) {
        const newStudent = {
          name: this.form.name,
          surnames: this.form.surnames,
          imageAuthorized: this.form.imageAuthorized,
          birthday: formatnewDate(this.form.birthday),
          allergies: [...this.form.allergies],
          observations: this.form.observations,
          personsAuthorized: this.personsAuthorized
        }
        try {
          await this.updateStudentWithoutCenter({ studentId: this.$route.params.studentId,  newStudent })
          this.$toast.success('Datos guardados correctamente');
          setTimeout(() => {
            this.$router.push({ name: 'students-without-center' })
          }, 3000)
        } catch (error) {
          if(error.personsAuthorized) {
            this.errors = error.personsAuthorized
            console.log('errors', this.errors)
          } else {
            this.errors = error
          }
        }
      }
    },
    confirmationMessage() {
      if (this.form.imageAuthorized) {
        setTimeout(() => confirm('¿Estás seguro del cambio que quieres realizar?') ? this.form.imageAuthorized = false : this.form.imageAuthorized = true, 200)
      } else {
        setTimeout(() => confirm('¿Estás seguro del cambio que quieres realizar?') ? this.form.imageAuthorized = true : this.form.imageAuthorized = false, 200)
      }
    }
  },
  async mounted() {
    try {
      this.isLoading = true
      await this.fetchAllergies()
      await this.fetchStudentWithoutCenter(this.$route.params.studentId)
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoading = false
    }
  }
}
</script>

<style scoped>

</style>
