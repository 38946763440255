<template>
  <div class="register">
    <Header></Header>

    <main class="main">
      <router-view
        @onNewUserForm="createUser"
      ></router-view>
    </main> 
  </div>
  <Footer></Footer>
</template>

<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'

export default {
  name: 'RegisterLayout',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      users: []
    }
  },
  methods: {
    createUser(user) {
      this.users.push(user);
    }
  } 
}
</script>

<style scoped lang="scss">
.register {
  background-color: $newGrey;
  max-width: 1400px;
  margin: 0 auto;
}
</style>
