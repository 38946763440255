<template>
  <div class="main groups">
    <Loading v-if="isLoading" />
    <div v-else>
      <div class="csv__header">
        <h2 class="subtitle flex-grow">Grupos:</h2>
        <button class="new-element compact" v-if="userInfo.isSupervisor">
          <svg viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.7758 19.504H19.6398V30.384H11.3838V19.504H0.31175V11.696H11.3838V0.815998H19.6398V11.696H30.7758V19.504Z" fill="#00ADCE"/>
          </svg>
          <router-link :to="{ name: 'group-create' }"></router-link>
          Añadir nuevo grupo
        </button>
        <button
          class="btn csv__button"
          type="button"
          @click="downloadCSV">
          <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
            <path d="M22 24h-20v-24h14l6 6v18zm-7-23h-12v22h18v-16h-6v-6zm3 15v1h-12v-1h12zm0-3v1h-12v-1h12zm0-3v1h-12v-1h12zm-2-4h4.586l-4.586-4.586v4.586z"/>
          </svg>
          <span class="csv__button__text">Descargar CSV</span>
        </button>
      </div>
      <div class="groups__filters">
        <div class="form__field">
          <label class="form__field-label" for="#phone">Año mínimo</label>
          <select
              v-model="form.yearStart"
              class="form__field-input"
              name="yearStart"
              id="yearStart"
              @change="selectStartYear">
            <option value=""></option>
            <option v-for="(year,idx) in yearListStart" :key="`year--${idx}`" :value="year.value">{{year.value}}</option>
          </select>
        </div>
        <div class="form__field">
          <label class="form__field-label" for="#phone">Año máximo</label>
          <select
              v-model="form.yearEnd"
              class="form__field-input"
              name="yearStart"
              id="yearStart"
              @change="selectEndYear">
            <option value=""></option>
            <option v-for="(year,idx) in yearListEnd" :key="`year--${idx}`" :value="year.value">{{year.value}}</option>
          </select>
        </div>
      </div>

      <table v-if="groups.length" class="admin__table">
        <thead class="admin__table__head">
        <tr>
          <th v-for="(column, idx) in tableConfig"
              :key="`columnHeader--${idx}`"
              class="admin__table__head__cell"
          >{{column.label}}</th>
        </tr>
        </thead>
        <tbody class="admin__table__body">
        <tr
            v-for="(element, idx) in groups"
            :key="`payment--${idx}`">
          <td v-for="(column, idx) in tableConfig" :key="`columnHeader--${idx}`"
              class="admin__table__body__cell teachers__table__cell"
              :class="[
                {'noSupervisor': !userInfo.isSupervisor},
                {'total-students': column.key === 'totalStudentsFixed'}
              ]"
              @click="showGroupDetail(element.id)">
            <ul
                v-if="column.isMultiple"
                class="teachers__table__sublist">
              <li
                  v-for="(item, idx2) in element[column.key]"
                  :key="`multipleCell-${idx}-${idx2}`"
                  class="teachers__table__subelement"
              ><span
                  class="admin__table__body__cell__header"
                  :class="{'teachers__table--disabled': idx2 > 0 }">
                  {{ column.label }}
                </span>
                <span class="teachers__table__info">{{item}}</span>
              </li>
            </ul>
            <template v-else>
              <span class="admin__table__body__cell__header">{{ column.label }}</span>
              <template
                  v-if="column.isIcon">
                <img
                    v-if="element[column.key]"
                    src="../../assets/attent.svg" alt="check"
                    class="teachers__table__icon teachers__table__icon--checked">
                <img
                    v-else
                    src="../../assets/missed.svg" alt="check"
                    class="teachers__table__icon teachers__table__icon--unchecked">
              </template>
              <template v-else-if="column.delete && userInfo.isSupervisor" @click.stop="deleteGroup(element.id)" class="btn">
                <span class="admin__table__body__cell__header"></span>
                <button @click.stop="deleteGroup(element.id)" class="btn">Borrar</button>
              </template>
              <template v-else>
                <span>{{element[column.key]}}</span>
              </template>
            </template>
          </td>
        </tr>
        </tbody>
      </table>
      <div v-else class="message__noData">
        <div class="message__noData-icon">
          <svg width="13" height="36" viewBox="0 0 13 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.880469 -2.38419e-06H12.1805L10.2805 22.25H2.73047L0.880469 -2.38419e-06ZM6.53047 35.45C4.8638 35.45 3.49714 34.95 2.43047 33.95C1.3638 32.9167 0.830469 31.6667 0.830469 30.2C0.830469 28.7 1.3638 27.4667 2.43047 26.5C3.49714 25.5333 4.8638 25.05 6.53047 25.05C8.19714 25.05 9.54714 25.5333 10.5805 26.5C11.6471 27.4667 12.1805 28.7 12.1805 30.2C12.1805 31.6667 11.6471 32.9167 10.5805 33.95C9.5138 34.95 8.1638 35.45 6.53047 35.45Z" fill="white"/>
          </svg>
        </div>
        <div class="message__noData-text">
          <p>No hay grupos para mostrar</p>
          <p>Prueba seleccionando otro centro</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Loading from '../Loading.vue'
import { dayOfWeek } from '@/commonMethods/methods'

export default {
  name: 'Groups',
  components: {
    Loading
  },
  data() {
    const actualYear = new Date().getFullYear()
    const last20years = Array(20).fill('').map((item, idx) => ({
      id: idx,
      value: actualYear - idx
    }))
    return {
      isLoading: false,
      dayOfWeek,
      tableConfig: [
        {
          label: 'Nombre',
          key: 'name',
        },
        {
          label: 'Servicio',
          key: 'service',
        },
        {
          label: 'Profesor',
          key: 'staffMember',
        },
        {
          label: 'Día',
          key: 'weekDay',
        },
        {
          label: 'Hora',
          key: 'time',
        },
        {
          label: 'Alumn@s',
          key: 'totalStudentsFixed',
        },
        {
          label: 'Plazas',
          key: 'fixedPlaces',
        },
        {
          label: 'Año mínimo',
          key: 'yearStart',
        },
        {
          label: 'Año máximo',
          key: 'yearEnd',
        },
        {
          label: '',
          key: '',
          delete: true
        },
      ],
      last20years,
      yearListStart: last20years,
      yearListEnd: last20years,
      form: {
        yearStart: '',
        yearEnd: ''
      }
    }
  },
  computed: {
    ...mapGetters('Admin', {
      getGroups: 'getGroupLists',
      userInfo: 'getUserInfo'
    }),
    groups() {
      return this.getGroups.map(group => ({
        ...group,
        service: group.service ? group.service.name : '',
        weekDay: group.weekDay ? this.dayOfWeek(group.weekDay) : '',
        staffMember: group.staffMember ? group.staffMember.name : '',
        fixedPlaces: `${group.fixedPlaces} + ${group.extraPlaces}`
      }))
    }
  },
  watch:{
    '$route' (newRoute){
      this.fetchGroupList({ centerId: newRoute.params.id })
    }
  },
  methods: {
    ...mapActions('Admin',['fetchGroupList', 'deleteGroupById', 'fetchCSVToken']),
    async _fetchGroupList () {
      const filters = {
        minYear: this.form.yearStart,
        maxYear: this.form.yearEnd
      }
      await this.fetchGroupList({centerId: this.$route.params.id, filters})
    },
    showGroupDetail(groupId) {
      if(this.userInfo.isSupervisor) {
        this.$router.push({ name: 'group-detail', params: { id: this.$route.params.id, groupId } })
      }
    },
    async deleteGroup(groupId) {
      if (confirm('¿Estás seguro que quieres borrar este grupo?')) {
        try {
          await this.deleteGroupById({ centerId: this.$route.params.id, groupId })
          this.$toast.success('Grupo borrado correctamente.')
        } catch (error) {
          this.$toast.error('Este grupo no se ha podido borrar.')
        }
      }
    },
    async selectStartYear() {
      this.yearListEnd = this.form.yearStart
          ? this.last20years.filter(year => parseInt(year.value) >= parseInt(this.form.yearStart))
          : this.last20years
      await this._fetchGroupList()
    },
    async selectEndYear() {
      this.yearListStart = this.form.yearEnd
          ? this.last20years.filter(year => parseInt(year.value) <= parseInt(this.form.yearEnd))
          : this.last20years
      await this._fetchGroupList()
    },
    async downloadCSV() {
      let queryParams = ''
      if (this.form.yearStart) {
        queryParams = `&minYear=${this.form.yearStart}`
      }
      if (this.form.yearEnd) {
        queryParams = `&maxYear=${this.form.yearEnd}`
      }
      try {
        const response = await this.fetchCSVToken()
        const csv = `${process.env.VUE_APP_API_BASE_URI}/admin/centers/${this.$route.params.id}/groups.csv?token=${response.data.token}${queryParams}`
        window.open(csv)
      } catch (error) {
        this.$toast.error('Lo sentimos, no hemos podido descargar el CSV, inténtelo de nuevo');
      }
    }
  },
  async mounted() {
    this.isLoading = true
    try {
      await this._fetchGroupList()
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoading = false
    }
  }
}
</script>
<style lang="scss" scoped>
.admin {
  &__table {
    &__head {
      &__cell {
        padding: 9px 4px;
      }
    }
    &__body {
      &__cell {
        @include desktop {
          padding: 10px 6px;
        }
        &__header {
          @include desktop {
            display: none;
          }
        }
      }
      td {
        @include desktop {
          padding: 10px 0;
        }
      }
      td:last-child {
        padding-right: 6px;
      }
    }
  }
}
.session-list {
  background-color: $newSoftBlue;
  border-radius: 18px;
  margin-bottom: 30px;
}
.session-wrapper {
  background-color: $newLightBlue;
  border-radius: 18px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 30px;
  .session-detail {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    flex-wrap: wrap;
    gap: 8px 0;
  }
  .session-info {
    span {
      font-weight: 700;
    }
    p {
      margin-top: 4px;
    }
  }
  .student {
    color: $newBlue;
    font-weight: 700;
    display: flex;
    align-items: center;
    align-self: flex-end;
    margin-top: 30px;
    cursor: pointer;
    svg {
      margin-left: 12px;
      transition: transform .3s ease;
    }
    &.open {
      svg {
        transform: rotate(-180deg);
      }
    }
  }
  @include tablet {
    flex-direction: row;
    justify-content: space-between;
    gap: 20px 0;
    text-align: unset;
    padding: 20px 30px;
    .session-detail {
      flex-direction: row;
    }
    .session-info {
      margin-right: 20px;
      p {
        margin-bottom: 0;
      }
    }
    .student {
      flex: 0 0 172px;
      justify-content: flex-end;
      margin-top: 0;
    }
  }
}
.student-list {
  padding: 30px;
  display: none;
  &.show {
    display: flex;
    flex-direction: column;
  }
  >p {
    font-weight: 700;
  }
  ul {
    width: 100%;
    margin-bottom: 30px;
    list-style: none;
    li {
      margin: 26px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:first-child {
        margin-top: 0;
      }
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 40%;
        margin: 0;
      }
      span {
        padding: 8px;
        border-radius: 6px;
        color: $white;
        font-weight: 700;
        width: 118px;
        @include disable-selection;
        &.attend {
          background-color: $bg-green;
          cursor: pointer;
          &::before {
            content: url('../../assets/attent.svg');
            width: 18px;
            height: 18px;
            display: inline-block;
            margin-right: 8px;
          }
        }
      }
      .missed {
        background-color: $newPink;
        cursor: pointer;
        &::before {
          content: url('../../assets/missed.svg');
          width: 18px;
          height: 18px;
          display: inline-block;
          margin-right: 8px;
        }
      }
      .test {
        background-color: $newSoftDark;
        text-transform: uppercase;
        text-align: center;
      }
    }
  }
  .btn {
    align-self: center;
  }
  @include desktop {
    ul {
      column-count: 2;
      column-rule: 1px solid $newLightBlue;
      column-gap: 150px;
      text-align: left;
      display: inline-block;
      li {
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;
      }
    }
    .btn {
      align-self: flex-end;
    }
  }
}
.noSupervisor {
  cursor: auto;
}

.groups {

  &__filters {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 16px;

    :first-child {
      margin-bottom: 12px;
    }

    @include desktop {
      flex-direction: row;
      justify-content: flex-end;

      :first-child {
        margin-right: 12px;
      }
    }
  }
}
</style>
