import { apiClient } from '@/services/ApiClient'

const state = {
  camps: []
}

const actions = {
  async fetchCamps({ commit } , centerId) {
    await apiClient.get(`/admin/centers/${centerId}/camps`)
    .then((response) => {
      commit('SET_CAMPS_LIST', response.data)
    })
    .catch(err => {
      throw err
    })
  },
  async campCreate(commit, [ centerId, infoCampCreate ] ) {
    await apiClient.post(`/admin/centers/${centerId}/camps`, infoCampCreate)
    .then(() => {
    })
    .catch(err => {
      throw err
    })
  },
  async campUpdate(commit, [ centerId, campId, infoCampUpdate ] ) {
    await apiClient.patch(`/admin/centers/${centerId}/camps/${campId}`, infoCampUpdate)
    .then(() => {
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },
  async fetchCampsStudents(commit, { centerId, campId }) {
    return new Promise((resolve, reject) => {
      apiClient.get(`/admin/centers/${centerId}/camps/${campId}/students`)
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        reject(err.response.data.errors)
      })
    })
  },
  async deleteCamp({commit}, { centerId, campId }) {
    await apiClient.delete(`admin/centers/${centerId}/camps/${campId}`)
    commit('DELETE_CAMP', campId)
  }
}

const mutations = {
  SET_CAMPS_LIST(state, camps) {
    state.camps = camps
  },
  DELETE_CAMP(state, campId) {
    const campIndex = state.camps.findIndex(camp => camp.id == campId)
    state.camps.splice(campIndex, 1)
  }
}

const getters = {
  getCampsList(state) {
    return state.camps
  },
  getActiveCamp: (state) => (campId) => state.camps.find(camp => camp.id.toString() === campId)
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
