<template>
<div class="enrollment-confirmation-wrapper box">
  <div class="enrollment-confirmation-box">
    <h2 class="subtitle">Formaliza la matrícula</h2>
    <Loading v-if="isLoading" />
    <div v-else class="enrollment-confirmation">
      <div class="credit-card">
        <p class="text">&nbsp;</p>
        <form action class="form" @submit.prevent="paymentCharge">
          <div class="form__field-row" v-if="hasCardNumber && isCardAvailable">
            <div class="form__field-wrapper card__number">
              <div class="form__field">
                <label class="form__field-label" for="#credit-card">Número de tarjeta: *</label>
                <input
                  class="form__field-input"
                  type="text"
                  id="cardNumber"
                  required
                  :placeholder="`Tarjeta con expiración: ${activePayment.redsysExpireDate}`"
                  :disabled="true"
                >
              </div>
            </div>
          </div>
          <div class="enrollment-confirmation__payments-methods" v-if="showPaymentMethods">
            <label class="form__field-label" for="#payment-methods">Métodos de pago disponibles: </label>
            <div class="form__field-input enrollment__payments">
              <div
                  v-for="(type, idx) in availablePayments"
                  :key="type.id">
                <label class="form__field-label" for="#payment">{{type.label}}</label>
                <input
                    type="radio"
                    id="payment"
                    :value="type.id"
                    name="typeOfPayment"
                    :checked="idx === 0"
                    @change="selectedTypeOfPayment($event)">
              </div>
            </div>
          </div>
          <div v-if="isCardAvailable">
            <p class="other-payment" v-if="hasCardNumber" @click="updateCardNumber">¿Quieres actualizar los datos de tu tarjeta?</p>
            <div class="form__field-row half add-new-card" v-else>
              A continuación accederás a la pasarela de pago donde indicar los datos de la tarjeta de forma segura.
            </div>
          </div>

          <select v-if="!activePayment?.isFutureGroup" @change="handleChange" class="form__field-input enrollment-select" name="" id="">
            <option :value="true">Iniciar matrícula inmediatamente</option>
            <option :value="false">Iniciar matrícula el día 1 del próximo mes</option>
          </select>
          <Switch class="enrollment-confirmation__terms" v-model:checked="isConditionAccepted" label="Acepto las condiciones de contratación" />
          <p v-if="!isConditionAccepted && showErrorCondition" class="enrollment-confirmation__errors">Debes aceptar las condiciones generales de contratación</p>
          <div class="legal">
            <p><strong>Condiciones de contratación</strong></p>
            <p class="text">
              <small>
                Antes de aceptar las condiciones generales de contratación, por favor leedlas detenidamente en:
              </small>
              <a class="enrollment-confirmation__link" href="https://lacasitadeingles.com/condiciones-generales-de-contratacion/">
                https://lacasitadeingles.com/condiciones-generales-de-contratacion/
              </a>
            </p>
          </div>
          <div v-if="isCardAvailable" class="message">
            <div class="check">
              <img class="check__img" alt="check" src="../assets/check.png">
            </div>
            <p class="text"><strong>Nuestro pago es 100% seguro y está protegido por Redsys.</strong> Almacenaremos tus datos de pago para automatizar el cobro de las cuotas de este curso y siempre podrás ver en tu perfil cualquier cargo que te hayamos realizado.</p>
          </div>
          <button
            class="btn"
            :class="{disabled: !isConditionAccepted}"
            type="submit">{{ getButtonText() }}</button>
        </form>
        <div class="errors" v-for="(error, key) in errors" :key="key">
          <p>{{ $t(error) }}</p>
        </div>
      </div>
      <div class="order">
        <p class="text">Resumen de tu pedido:</p>
        <div v-if="user" class="order__card">
          <div v-if="studentData" class="order__summary">
            <h3>{{ activeGroup ? activeGroup.service.name : '' }}</h3>
            <p>Alumno: {{ studentData.name }}</p>
            <p>Duración: hasta {{ activeGroup ? formatDate(activeGroup.dateEnd, false) : '' }}</p>
          </div>
          <div v-if="activePayment" class="order__payment">
            <ul>
              <li><p>Matrícula</p><span>{{ formatPrice(activePayment.enrollment) }}</span></li>
              <li><p>Cuota mes en curso</p><span>{{ formatPrice(activePayment.currentMonthFee) }}</span></li>
              <li><p class="text">Prorrateado en base a las sesiones pendientes este mes</p></li>
            </ul>
            <div class="total-pay">
              <p>Total a pagar ahora</p>
              <span>{{ formatPrice(activePayment.totalAmountDue) }}</span>
            </div>
          </div>
          <div v-if="activePayment" class="order__month">
            <h3>Pagos mes a mes</h3>
            <ul>
              <li><p>Cuota mensual</p><span>{{ formatPrice(activePayment.monthlyFee) }}</span></li>
            </ul>
            <p class="text">Cargaremos este importe automáticamente en tu tarjeta de crédito el día 1 de cada mes. Puedes cancelar la renovación de la matrícula en cualquier momento con 1 mes de antelación.</p>
          </div>
        </div>
      </div>
    </div>
    <div>
      <form id="redsys-form" method="post" :action="url">
        <input type="hidden" name="Ds_MerchantParameters"/>
        <input type="hidden" name="Ds_SignatureVersion"/>
        <input type="hidden" name="Ds_Signature"/>
      </form>
    </div>
  </div>
</div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import { formatDate, formatPrice } from '../commonMethods/methods'
import Loading from '../components/Loading.vue'
import Switch from '../components/Switch.vue'
import { typeOfPayments } from '@/commonMethods/constants'

export default {
  name: 'EnrollmentConfirmation',
  components: {
    Loading,
    Switch
  },
  props: {
    centerId: {
      type: String,
      required: true
    },
    studentId: {
      type: String,
      required: true
    },
    groupId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      errors: [],
      activeUpdateCard: false,
      isLoading: false,
      isConditionAccepted: false,
      showErrorCondition: false,
      typeOfPayments: typeOfPayments,
      selectedPaymentId: 0,
      availablePayments: [],
      activeGroup: null,
      isCardAvailable: false,
      hasCardNumber: false,
      showPaymentMethods: true,
      enrollmentForNow: true,
    }
  },
  computed: {
    ...mapGetters('User', {
      getUser: 'getUser',
      activePayment: 'getActivePayment'
    }),
    ...mapGetters('Students', {
      getStudents: 'getStudents',
      getGroupsByCenterAndStudent: 'getGroupsByCenterAndStudent'
    }),
    user() {
      return this.getUser && Object.keys(this.getUser).length
        ? this.getUser
        : null
    },
    studentData() {
      return this.user
          ? this.user.students.find(student => student.id === parseInt(this.$route.params.studentId))
          : null
    }
  },
  watch: {
    activePayment: {
      immediate: true,
      handler(newActivePayment) {
        if (newActivePayment && Object.keys(newActivePayment).length ) {
          this.availablePayments = this.typeOfPayments.filter(type =>
              newActivePayment.paymentMethodsAvailables
                ? newActivePayment.paymentMethodsAvailables.includes(type.id)
                : false
          )
          this.selectedPaymentId = this.availablePayments[0].id
          this.showPaymentMethods = newActivePayment.paymentMethodsAvailables.length < 1 || newActivePayment.paymentMethodsAvailables.includes(1) || newActivePayment.paymentMethodsAvailables.includes(2)
          this.isCardAvailable = newActivePayment.paymentMethodsAvailables.includes(0)
          this.hasCardNumber = newActivePayment.hasPaymentMethod && !this.activeUpdateCard
        }
      }
    }
  },
  methods: {
    ...mapActions('Students', ['paymentCenterCharge', 'studentSetGroup']),
    ...mapActions('User', ['fetchProfile']),
    ...mapMutations('User', {
      setActivePayment: 'SET_ACTIVE_PAYMENT'
    }),
    formatDate,
    formatPrice,
    updateCardNumber() {
      this.activeUpdateCard = true
      this.hasCardNumber = false
    },
    getButtonText() {
      const price = this.activePayment ? this.formatPrice(this.activePayment.totalAmountDue) : 0
      if (this.hasCardNumber) {
        return `Pagar ahora ${price}`
      } else {
        return `Guardar y pagar ${price}`
      }
    },
    handleChange(e) {
      this.enrollmentForNow = e.target.value === 'true'
    },
    selectedTypeOfPayment(event) {
      this.selectedPaymentId = event.target.value
    },
    async paymentCharge() {
      if (this.isConditionAccepted) {
        this.showErrorCondition = false
        try {
          this.isLoading = true
          const response = await this.paymentCenterCharge({
            centerId: this.centerId,
            groupId: this.groupId,
            studentId: this.studentId,
            typePaymentMethod: this.selectedPaymentId,
            hasPaymentMethod: !this.activeUpdateCard,
            enrollmentForNow: this.enrollmentForNow
          })
          if (response.status === 200) {
            this.isLoading = false
            const redsysForm = document.getElementById('redsys-form')
            redsysForm.action = response.data.url
            redsysForm.elements['Ds_MerchantParameters'].value = response.data.Ds_MerchantParameters
            redsysForm.elements['Ds_SignatureVersion'].value = response.data.Ds_SignatureVersion
            redsysForm.elements['Ds_Signature'].value = response.data.Ds_Signature
            redsysForm.submit()
          } else {
            await this.$router.push({ name: 'enrollment-confirmation-ok', params: { id: response.data.paymentId } })
            this.isLoading = false
          }
        } catch(errors) {
          if(errors && errors.cardNumber) {
            this.errors = errors.cardNumber
          } else {
            this.errors = errors
          }
        }
      } else {
        this.showErrorCondition = true
      }
    }
  },
  async mounted() {
    const { groupId, studentId, centerId } = this.$route.params
    this.isLoading = true
    const { data: availableGroups } = await this.getGroupsByCenterAndStudent(centerId, studentId)
    this.activeGroup =  availableGroups.find(group => group.id === parseInt(groupId)) || null

    await this.studentSetGroup({ id: studentId, group: parseInt(groupId)})

    if (!this.user || !Object.keys(this.user).length) {
      await this.fetchProfile()
    }

    this.isLoading = false
  },
  beforeUnmount() {
    this.setActivePayment({})
  }
}
</script>

<style scoped lang="scss">
.enrollment-confirmation-box {
  .subtitle {
    text-align: center;
  }
  .enrollment__payments {
    border-radius: 16px;
    min-height: 27px;
  }
  .enrollment-confirmation {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px 100px;
    .credit-card {
      max-width: 400px;
      .add-new-card {
        margin: 30px 0;
      }
      .form {
        width: 100%;
        .form__field-row {
          &.half {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .form__field-wrapper {
              display: inline-block;
              vertical-align: top;
              width: calc(50% - 20px);
              &:nth-child(2n-1) {
                margin-right: 10px;
              }
              &:nth-child(2n) {
                margin-left: 10px;
              }
            }
          }
          position: relative;
          .credit-card-icon {
            .form__field-input {
              padding-left: 45px;
            }
            .icon {
              width: 25px;
              position : absolute;
              top: 7px;
              left: 12px;
            }
            .payment-method {
              padding: 10px;
              font-size: 12px;
              color: $blue-link;
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }
        .message {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 40px;
          .check {
            margin-right: 18px;
          }
          .text {
            margin-top: 6px;
            width: 80%;
          }
        }
        .btn {
          width: 100%;
        }
      }
      .enrollment-select {
        background-color: $white;
      }
    }
    .order {
      max-width: 400px;
      margin-bottom: 26px;
      .order__card {
        h3 {
          margin-top: 0;
          margin-bottom: 15px;
          color: $white;
          font-size: 20px;
        }
        ul {
          list-style: circle;
          margin-top: 0;
          li {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            margin: 0 0 15px 0;
            p {
              margin: 0;
            }
            span {
              font-weight: 700;
            }
          }
        }
        .order__summary {
          background-color: $bg-blue;
          padding: 15px 25px;
          border-radius: 16px;
          p {
            color: $white;
            margin: 5px 0;
            font-size: 14px;
          }
        }
        .order__payment {
          padding: 25px;
          ul {
            li {
              &:last-child {
                margin-bottom: 20px;
              }
            }
            &::after {
              content: '';
              width: 90%;
              height: 1px;
              background-color: $newText;
              display: block;
              margin: 0 auto;
            }
          }
          p {
            &.text {
              font-size: 12px;
            }
          }
          .total-pay {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 700;
            p {
              margin: 0;
            }
          }
        }
        .order__month {
          background-color: $newYellow;
          padding: 15px 25px;
          border-radius: 16px;
          p {
            &.text {
              font-size: 12px;
            }
          }
        }
      }
    }

    &__payments-methods {
      margin-bottom: 8px;
    }

    &__terms {
      margin-top: 30px;
    }

    &__errors {
      font-size: 12px;
      color: $error;
      margin: 0 0 32px 62px;
    }

    &__link {
      color: $bg-btn-active;
      font-size: 13px;
      text-decoration: none;
    }
  }
}
.other-payment {
  cursor: pointer;
  color: $newBlue;
  margin-top: 0;
  font-size: 12px;
  margin-bottom: 40px;
}
.card__number {
  margin-bottom: 6px;
}

.btn {
  &.disabled {
    background-color: $grey;
    border: 1px solid $grey;
    cursor: not-allowed;
    pointer-events: none;
    &:hover {
      background-color: $grey;
      border: 1px solid $grey;
      color: $white;
    }
  }
}
select {
  width: 100%;
}

</style>
