const forceTwoDigitNumbers = (value) => {
  return (value < 10 ? '0' : '') + value
}
const formatDate = (timestamp, withHours) => {
  const date = timestamp * 1000
  const newDate = new Date(date)
  const year = newDate.getFullYear()
  var months = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'];
  const month = months[newDate.getMonth()]
  const day = newDate.getDate()
  if (withHours) {
    const hour = forceTwoDigitNumbers(newDate.getHours())
    const minutes = forceTwoDigitNumbers(newDate.getMinutes())
    return `${day} de ${month} de ${year} a las ${hour}:${minutes}`
  } else {
    return `${day} de ${month} de ${year}`
  }
}

const dayOfWeek = (weekDay) => {
  let dayOfWeek = ''
  switch(weekDay) {
    case 0:
      dayOfWeek = 'Domingo'
      break
    case 1:
      dayOfWeek = 'Lunes'
      break
    case 2:
      dayOfWeek = 'Martes'
      break
    case 3:
      dayOfWeek = 'Miércoles'
      break
    case 4:
      dayOfWeek = 'Jueves'
      break
    case 5:
      dayOfWeek = 'Viernes'
      break
    case 6:
      dayOfWeek = 'Sábado'
      break
  }
  return dayOfWeek
}

const timesTampToDay = (timestamp) => {
  const today = new Date()
  const day = new Date(timestamp*1000)
  var months = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'];

  const yearToday = today.getFullYear()
  const monthToday = months[today.getMonth()]
  const dayToday = forceTwoDigitNumbers(today.getDate())

  const yearDay = day.getFullYear()
  const monthDay = months[day.getMonth()]
  const dayDay = forceTwoDigitNumbers(day.getDate())

  if(yearToday === yearDay && monthToday === monthDay && dayToday === dayDay) {
    return 'Hoy'
  } else {
    return 'Mañana'
  }
}

const formatDateShort = (timestamp) => {
  const newDate = new Date(timestamp * 1000)
  const day = forceTwoDigitNumbers(newDate.getDate())
  const month = forceTwoDigitNumbers(newDate.getMonth() + 1)
  const year = newDate.getFullYear()
  return !!timestamp ? `${day}/${month}/${year}` : ''

}

const formatPrice = (value) => {
  return new Intl.NumberFormat("es-ES", {style: "currency", currency: "EUR"}).format(value)
}

const utcToTimestamp = (value) => {
  return value ? (value.getTime() / 1000) : ''
}

const reverseDate = (value) => {
  const response = value.split('-')
  return `${response[2]}/${response[1]}/${response[0]}`
}

const sqlToTimestamp = (value) => {
  const newDate = new Date(value);
  return newDate ? (newDate.getTime() / 1000) : ''
}

const dateToSql = (date) => {
  const year = date.getFullYear()
  const month = forceTwoDigitNumbers(date.getMonth() + 1)
  const day = forceTwoDigitNumbers(date.getDate())
  return `${year}-${month}-${day}`
}

const formatnewDate = (date) => {
  const d = new Date(date)
  const month = '' + (d.getMonth() + 1)
  const day = '' + d.getDate()
  const year = d.getFullYear()

  return [year, forceTwoDigitNumbers(month), forceTwoDigitNumbers(day)].join('-');
}

const formatDateFromApi = (dateString = '') => {
  // Date from API => dd/mm/yyyy
  const datePiece = dateString.split('/').map(piece => piece ? parseInt(piece) : 0)
  return new Date(datePiece[2], datePiece[1] - 1, datePiece[0], 12,0,0).getTime()
}

const getStartDateTimestamp = (dateOrTimestamp) => {
  const date = typeof dateOrTimestamp === 'number' ? new Date(dateOrTimestamp) : dateOrTimestamp
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0,0,0).getTime()
}

const getEndDateTimestamp = (dateOrTimestamp) => {
  const date = typeof dateOrTimestamp === 'number' ? new Date(dateOrTimestamp) : dateOrTimestamp
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23,59,59).getTime()
}

const normalizeText = (value) => {
  return value.normalize("NFD").replace(/\p{Diacritic}/gu, "").toUpperCase()
}

const emojiLabel = (value, isByTitle, isSigleReport, report, index) => {
  const oneEmoji = '😊'
  const twoEmojis = '😊😊'
  const threeEmojis = '😊😊😊'
  if (isByTitle) {
    const titleTrimmed = value.replaceAll(' ', '')
    if (isSigleReport) {
      if (report[`score${titleTrimmed}`] === 3) {
        return threeEmojis
      } else if (report[`score${titleTrimmed}`] === 2) {
        return twoEmojis
      } else {
        return oneEmoji
      }
    } else {
      if (report[index][`score${titleTrimmed}`] === 3) {
        return threeEmojis
      } else if (report[index][`score${titleTrimmed}`] === 2) {
        return twoEmojis
      } else {
        return oneEmoji
      }
    }
  } else {
    if (value === 3) {
      return threeEmojis
    } else if (value === 2) {
      return twoEmojis
    } else {
      return oneEmoji
    }
  }
}

const legendLabel = (title) => {
  const listeningTitle = 'Listening'
  if (title === 'Listening Comprehension') return listeningTitle
  return title
}

export {
  formatDate,
  forceTwoDigitNumbers,
  dayOfWeek,
  timesTampToDay,
  formatDateShort,
  formatPrice,
  utcToTimestamp,
  reverseDate,
  sqlToTimestamp,
  dateToSql,
  formatnewDate,
  formatDateFromApi,
  getStartDateTimestamp,
  getEndDateTimestamp,
  normalizeText,
  emojiLabel,
  legendLabel
}
