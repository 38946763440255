<template>
  <div class="main">
    <Loading v-if="isLoading" />
    <div v-else>
      <div class="csv__header">
        <h2 class="subtitle flex-grow">Tutores legales:</h2>
        <button class="tutor new-element compact">
          <svg viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M30.7758 19.504H19.6398V30.384H11.3838V19.504H0.31175V11.696H11.3838V0.815998H19.6398V11.696H30.7758V19.504Z"
                fill="#00ADCE"/>
          </svg>
          <router-link :to="{ name: 'tutor-create' }">
          </router-link>
          Añadir nuevo tutor
        </button>
      </div>
      <input
        type="text"
        placeholder="Buscar tutor legal"
        v-model="input"
        class="tutor__filters__text"
      >
      <Pagination v-if="totalPages > 1" :pages="totalPages"></Pagination>
      <div class="tutors-list">
        <table class="admin__table" v-if="tutorSearch.length || !isTableLoading">
          <thead class="admin__table__head">
            <tr>
              <th class="admin__table__head__cell">Nombre</th>
              <th class="admin__table__head__cell">Apellidos</th>
              <th class="admin__table__head__cell">Email</th>
              <th class="admin__table__head__cell">Teléfono</th>
              <th class="admin__table__head__cell"></th>
            </tr>
          </thead>
          <tbody class="admin__table__body" v-if="!isTableLoading">
            <tr v-for="(tutor, idx) in tutorSearch" :key="idx">
              <td @click="showTutorDetail(tutor.id)" class="admin__table__body__cell cell-tutors"><span class="admin__table__body__cell__header">Nombre alumn@</span>{{ tutor.name }}</td>
              <td @click="showTutorDetail(tutor.id)" class="admin__table__body__cell"><span class="admin__table__body__cell__header">Nombre grupo</span>{{ tutor.surnames }}</td>
              <td @click="showTutorDetail(tutor.id)" class="admin__table__body__cell"><span class="admin__table__body__cell__header">Email</span>{{ tutor.email }}</td>
              <td @click="showTutorDetail(tutor.id)" class="admin__table__body__cell"><span class="admin__table__body__cell__header">Teléfono</span>{{ tutor.phone }}</td>
              <td @click="goToTutorSide(tutor.id)" class="admin__table__body__cell"><span class="admin__table__body__cell__header">Suplantar</span><button class="btn">Suplantar</button></td>
            </tr>
          </tbody>
          <tbody class="admin__table__body" v-else>
          <tr>
            <td class="admin__table__body__cell enrollments__table__cell" colspan="8">
              <div class="message__loading">
                <h2>Results are loading...</h2>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
        <div v-else class="message__noData">
          <div class="message__noData-icon">
            <svg width="13" height="36" viewBox="0 0 13 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.880469 -2.38419e-06H12.1805L10.2805 22.25H2.73047L0.880469 -2.38419e-06ZM6.53047 35.45C4.8638 35.45 3.49714 34.95 2.43047 33.95C1.3638 32.9167 0.830469 31.6667 0.830469 30.2C0.830469 28.7 1.3638 27.4667 2.43047 26.5C3.49714 25.5333 4.8638 25.05 6.53047 25.05C8.19714 25.05 9.54714 25.5333 10.5805 26.5C11.6471 27.4667 12.1805 28.7 12.1805 30.2C12.1805 31.6667 11.6471 32.9167 10.5805 33.95C9.5138 34.95 8.1638 35.45 6.53047 35.45Z" fill="white"/>
            </svg>
          </div>
          <div class="message__noData-text">
            <p>No hay elementos para mostrar</p>
            <p>Prueba seleccionando otro centro</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Loading from '../../Loading.vue'
import Pagination from "@/components/Helpers/Pagination.vue";
import debounce from "lodash.debounce";
import router from "@/router";
// import { apiClient } from '../../../services/ApiClient'

export default {
  name: 'Tutors',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {
    Pagination,
    Loading
  },
  data() {
    return {
      isLoading: false,
      isTableLoading: false,
      input: this.$route.query['search'] || '',
      isTutorSwitch: false
    }
  },
  watch: {
    async '$route'() {
      this.isTableLoading = true;
      const _params = {limit: 25, page: 1};
      // const _params = {};

      for (const param in this.$route.query) {
        _params[param] = this.$route.query[param];
      }
      if(!this.isTutorSwitch) {
        await this.fetchTutors({centerId: this.id, params: _params})
      }
      this.isTableLoading = false;
    },
    input: debounce(function(searchString){
      if(searchString.length > 0 && searchString.length < 3){
        return;
      }
      const _querySanitizer = {...this.$route.query}
      if(searchString){
        _querySanitizer['search'] = searchString;
      } else {
        delete _querySanitizer['search'];
      }
      router.push({
        name: this.$route.name,
        params: this.$route.params,
        query: {
          ..._querySanitizer,
          page: 1
        }
      })
    }, 500),
  },
  computed: {
    ...mapGetters('Tutors', ['getTutors']),
    totalPages() {
      return this.getTutors?.totalPages;
    },
    tutorSearch() {
      return this.getTutors?.content ?? this.getTutors ?? [];
    }
  },
  methods: {
    ...mapActions('Tutors', ['fetchTutors']),
    ...mapActions('Admin', ['handleSwitchUser']),
    showTutorDetail(tutorId) {
      this.$router.push({ name: 'tutor-detail', params: { id: this.id, tutorId: tutorId } })
    },
    async goToTutorSide(tutorId) {
      this.isTutorSwitch = true;
      const response = await this.handleSwitchUser({
        route: 'parents-main-screen',
        endpoint: `/admin/centers/${this.id}/tutors/${tutorId}/switch-user`
      })
      if (response) {
        this.$toast.error('Ha ocurrido un error, inténtelo de nuevo.')
        this.isTutorSwitch = false;
      }
    }
  },
  mounted() {
    this.isLoading = true
    const _params = { limit: 25, page: 1 };
    // const _params = {};
    for(const param in this.$route.query){
      _params[param] = this.$route.query[param];
    }
    this.fetchTutors({ centerId: this.id, params: _params })
    .then(() => {
      this.isLoading = false
    })
    .catch(() => {
      this.isLoading = true
    })
  }
}
</script>
<style lang="scss" scoped>
.tutor__filters__text {
  border: 2px solid $newPink;
  border-radius: 16px;
  box-sizing: border-box;
  font-size: 12px;
  margin-bottom: 16px;
  padding: 8px;
  width: 100%;

}
@include desktop {
  .tutor__filters__text {
    width: 40%
  }
  .cell-tutors {
    width: 20%;
  }
}
</style>
