<template>
  <div class="main">
    <h2 class="subtitle">Elige la clase</h2>
    <p class="text" v-if="isLoading">
      ¡Cargando datos!
    </p>
    <div v-else>
      <p class="text" v-if="groups.length === 0">No hay clases disponibles</p>

      <table class="admin__table" v-if="groups.length >= 1">
        <thead class="admin__table__head">
          <tr>
            <th class="admin__table__head__cell">Curso y nivel</th>
            <th class="admin__table__head__cell">Día y hora</th>
            <th class="admin__table__head__cell"></th>
          </tr>
        </thead>
        <tbody class="admin__table__body">
          <tr v-for="(group, idx) in groups" :key="idx">
            <td class="admin__table__body__cell"><span>Curso y nivel</span><p>{{ group.service.name }}</p></td>
            <td class="admin__table__body__cell"><span>Día y hora</span><p>{{ dayOfWeek(group.weekDay) }} a las {{ group.time }}</p></td>
            <td class="admin__table__body__cell picked"><button class="btn" @click="pickedGroup(idx)">Elegir este grupo</button></td>
          </tr>
        </tbody>
      </table>
      <div class="errors" v-for="(error, key) in errors" :key="key">
        <p>{{ $t(error) }}</p>
      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { formatDate, dayOfWeek } from '../commonMethods/methods'

export default {
  name: 'RecoverClass',
  props: {
    id: {
      type: String,
      required: true
    },
    idCenter: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      groups: [],
      isLoading: true,
      formatDate,
      dayOfWeek,
      errors: {}
    }
  },
  mounted() {
    this.getGroupsByCenterAndStudent(this.idCenter, this.id)
    .then(response => {
      this.groups = response.data
      this.isLoading = false
    })
    .catch(errors => {
      this.errors = errors
    })
  },
  watch: {
    id: function() {
      this.groups = []
      this.isLoading = true
      this.getGroupsByCenterAndStudent(this.idCenter, this.id)
      .then(response => {
        this.groups = response.data
        this.isLoading = false
      })
      .catch(errors => {
        this.errors = errors
      })
    }
  },
  computed: {
    ...mapGetters('Students', ['getGroupsByCenterAndStudent']),
    ...mapGetters('Students', {
      returnActiveStudent: 'getActiveStudent',
    }),
    activeStudent() {
      return this.returnActiveStudent(this.$route.params.id)
    }
  },
  methods: {
    ...mapActions('Students', ['studentSetGroup']),
    pickedGroup(idx) {
      this.$router.push({ name: 'enrollment-confirmation', params: { centerId: this.idCenter, studentId: this.id, groupId: this.groups[idx].id } })
      /*this.studentSetGroup({
        id: this.id,
        group: this.groups[idx].id,
        infoStudent: {
          studentName: this.activeStudent.name,
          serviceName: this.groups[idx].service.name,
          dataEnd: this.groups[idx].dateEnd
        }
      })
      .then(() => {

      })
      .catch(errors => {
        this.errors = errors
      })*/
    }
  }
}
</script>

<style scoped lang="scss">
.main {
  .text {
    margin-bottom: 3em;
  }
}
.admin__table__body__cell {
  cursor: auto;

  @include tablet {
    span {
      display: none;
    }
  }
}

</style>
