<template>
<div class="enrollment-confirmationOK-wrapper box">
  <div class="enrollment-confirmationOK">
    <img src="../assets/checkOK.png" alt="Check">
    <h2 class="subtitle">¡Todo listo!</h2>

    <p class="text loading" v-if="isLoading">
      ¡Cargando datos!
    </p>
    <template v-else>
      <p v-if="paymentInfo.students.length > 1" class="text">{{ getStudentsNames(paymentInfo.students) }} ya están inscrit@s en el campamento / taller {{paymentInfo.camp}}</p>
      <p v-else class="text">{{ paymentInfo.students[0].name }} ya está inscrit@ en el campamento / taller {{paymentInfo.camp}}</p>
      <router-link :to="{ name: 'parents-main-screen' }">
        <button class="btn">Volver al inicio</button>
      </router-link>
      <div class="info-wrapper">
        <img src="../assets/info.png" alt="Información">
        <div class="info">
          <p>Puedes cancelar tu matrícula en cualquier momento con 30 días de antelación</p>
        </div>
      </div>
    </template>
  </div>
</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'NewCampConfirmationOK',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: true
    }
  },
  methods: {
    ...mapActions('User', ['paymentCheck']),
    getStudentsNames(students) {
      let names = []
      students.forEach(student => {
        names.push(student.name)
      })

      return `${names.join(', ')}`
    },
  },
  computed: {
    ...mapGetters('User', {
      paymentInfo: 'getPaymentCheckInfo'
    })
  },
  mounted() {
    this.paymentCheck(this.id)
      .catch(error => {
        console.log(error)
      })
      .finally(() => this.isLoading = false)
  }
}
</script>

<style scoped lang="scss">
.enrollment-confirmationOK-wrapper {
  padding: 40px 20px;
  .enrollment-confirmationOK {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .btn {
      margin-bottom: 70px;
    }
    .text {
      margin-bottom: 50px;
      font-size: 20px;
    }
    .info-wrapper {
      background-color: $newYellow;
      display: flex;
      padding: 20px 25px;
      border-radius: 10px;
      max-width: 530px;
      img {
        margin-right: 20px;
        margin-top: 8px;
        width: 30px;
        height: 30px;
      }
      .info {
        p {
          margin: 0;
          &:first-child {
            font-weight: 700;
            margin-bottom: 1em;
          }
        }
      }
    }
  }
}
</style>
