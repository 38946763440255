<template>
  <div class="main">
    <Loading v-if="isLoading" />
    <div v-else>
      <form  action class="form admin__form" @submit.prevent="updateEnrollmentSubmit">

        <div class="data-row space-between">
          <h2 class="subtitle">Editar matrícula:</h2>
          <div class="btn-wrapper" v-if="form.dateEnd === null">
            <button type="submit" class="btn btn-save">Guardar</button>
          </div>
        </div>
        <div class="form__field-row half">
          <div class="form__field-wrapper">
            <div class="form__field data-row">
              <span class="form__field-label inline">Alumn@ :</span>
              <span class="text-left inline">{{ studentName }}</span>
              <div class="errors errors-input" v-show="errors.hasOwnProperty('student')">
                <p v-for="(error, key) in errors.student" :key="key">{{ $t(error) }}</p>
              </div>
            </div>
          </div>

          <div class="form__field-wrapper">
            <div class="form__field data-row">
              <span class="form__field-label inline">Grupos :</span>
              <span class="text-left inline">{{ groupName }}</span>
            </div>
            <div class="errors errors-input" v-show="errors.hasOwnProperty('group')">
              <p v-for="(error, key) in errors.group" :key="key">{{ $t(error) }}</p>
            </div>
          </div>
        </div>

        <div class="form__field-row half">
          <div class="form__field-wrapper">
            <div class="form__field data-row">
              <span class="form__field-label">Fecha de comienzo :</span>
              <span class="text-left inline">{{ dateStartFormat }}</span>
            </div>
          </div>
          <div class="form__field-wrapper">
            <div class="form__field data-row">
              <span class="form__field-label">Coste Matrícula :</span>
              <span class="text-left">{{ form.priceEnrollment }}</span>
              <input
                  style="display: none"
                  v-model="form.priceEnrollment"
                  class="form__field-input"
                  type="number"
                  step="any"
                  min="0"
                  id="priceEnrollment"
                  @click="removeErrors"
                  disabled
              >
            </div>
          </div>
        </div>
        <!--        Keeping this just in case-->
        <datepicker
            style="display:none"
            v-model="v$.form.picked.$model"
            inputFormat="dd/MM/yyyy"
            :lower-limit="form.lowerLimit"
            :style="useInputStyles"
            disabled
            :locale="dateLocale"
        />
        <!--        Keeping this just in case-->
        <div class="form__field-row half">
          <div class="form__field-wrapper">
            <div class="form__field">
              <p class="input-errors" v-if="v$.form.price.$invalid && v$.form.price.$dirty">
                Por favor, introduce un precio
              </p>
              <label class="form__field-label" for="#price">Mensualidad *</label>
              <input
                  v-model="v$.form.price.$model"
                  class="form__field-input"
                  :class="{'form__input--invalid': v$.form.price.$invalid && v$.form.price.$dirty}"
                  type="number"
                  step="any"
                  min="0"
                  id="price"
                  required
                  @click="removeErrors"
              >
            </div>
          </div>
          <div class="form__field-wrapper">
            <div class="form__field">
              <label class="form__field-label" for="#priceEnrollment">Método de pago</label>
              <select
                class="form__field-input"
                name="paymetMethod"
                id="paymetMethod"
                @click="removeErrors"
                v-model="v$.form.paymentMethod.$model"
                @change="paymentMethodChange"
              >
              <option :value="getEnrollment.paymentMethod">{{getEnrollment.paymentMethod}}</option>
              <option
                v-for="types in typesOfPaymentsFiltered"
                :key="types.id"
                :value="types.label"
              >
              {{types.label}}
              </option>
              </select>
            </div>
          </div>
        </div>
        <div class="form__field-row half" v-if="v$.form.subjectDefault">
          <div class="form__field-wrapper">
            <div class="form__field">
              <label class="form__field-label" for="#subjectDefault">Concepto :</label>
              <input
                  style="display: none"
                  v-model="form.subjectDefault"
                  class="form__field-input"
                  type="text"
                  id="subjectDefault"
                  @click="removeErrors"
                  :disabled="form.dateEnd === null"
              >
              <span class="text-left inline">{{form.subjectDefault}}</span>
            </div>
          </div>
        </div>
        <OldEnrollment :data="getEnrollment.oldEnrollmentData" @detailOldEnrollment="detailOldEnrollment" />
      </form>
    </div>
  </div>
</template>
<script>
import Datepicker from 'vue3-datepicker'
import Loading from '@/components/Loading'
import OldEnrollment from './OldEnrollment.vue'
import es from 'date-fns/locale/es'
import { mapActions, mapGetters, mapState } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { formatDate } from '@/commonMethods/methods'
import { ref } from 'vue'
import { typeOfPayments } from '@/commonMethods/constants'

const useInputStyles = {
  padding: "12px 20px",
  border: "1px solid #00ADCE",
  borderRadius: "0",
  marginRight: "30px",
  width: "calc(100% - 30px)"
}

export default {
  name: 'EnrollmentDetail',
  components: {
    Datepicker,
    Loading,
    OldEnrollment
  },
  props: {
    id: {
      type: String,
      required: true
    },
    enrollmentId: {
      type: String,
      required: true
    }
  },
  setup () {
    return {
      v$: useVuelidate(),
    }
  },
  data() {
    return {
      form: {
        student: '',
        group: '',
        price: null,
        picked: ref(new Date()),
        lowerLimit: ref(new Date()),
        priceEnrollment: null,
        subjectDefault: '',
        dateEnd: null,
        paymentMethod: null
      },
      errors: {},
      formatDate,
      useInputStyles,
      isLoading: false,
      dateLocale: es,
      typesOfPayments: typeOfPayments,
      typesOfPaymentsFiltered: [],
      paymentsMapped: [],
      studentName: '...',
      groupName: '...',
    }
  },
  validations() {
    return {
      form: {
        student : {
          required
        },
        group: {
          required
        },
        price: {
          required
        },
        picked: {
          required
        },
        paymentMethod: {
          required
        }
      }
    }
  },
  computed: {
    ...mapGetters('Admin', ['getEnrollment']),
    ...mapState('Admin', ['centers']),
    dateStartFormat() {
      return new Date(this.form.picked).toISOString().split('T')[0];
    }
    // formStudents() {
    //   return this.getStudentsList.map(student => ({
    //     value: student.id,
    //     label: student.name
    //   }))
    // },
  },
  watch: {
    getEnrollment: {
      immediate: true,
      handler(newEnrollment) {
        if(newEnrollment) {
          this.form = newEnrollment
          const student = {...newEnrollment.student}
          this.studentName = `${student.name} ${student.surnames}`;
          this.form.student = student.id
          const group = {...newEnrollment.group}
          this.groupName = group.name;
          this.form.group = group.id
          this.form.price = newEnrollment.priceFee
          this.form.picked = new Date(newEnrollment.dateStart * 1000)
          if (newEnrollment.paymentMethod >= 0) {
            const findPaymentIndex = this.typesOfPayments.findIndex(type => type.id === newEnrollment.paymentMethod)
            this.form.paymentMethod = this.typesOfPayments[findPaymentIndex].label
            const index = this.centers.findIndex(center => center.id == this.id)
            this.paymentsMapped = this.centers[index].paymentMethods.map(payment => this.typesOfPayments[payment])
            this.typesOfPaymentsFiltered = this.paymentsMapped.filter(payment => payment.label !== newEnrollment.paymentMethod)
          }
        }
      }
    },
    enrollmentId: {
      immediate: true,
      async handler(enrollmentId) {
        await this.enrollmentDetailFetch(enrollmentId)
      }
    },
  },
  methods: {
    ...mapActions('Admin', ['fetchEnrollment', 'updateEnrollment']),
    removeErrors() {
      this.errors = {}
    },
    paymentMethodChange() {
      this.typesOfPaymentsFiltered = this.paymentsMapped.filter(payment => payment.label !== this.form.paymentMethod)
    },
    updateEnrollmentSubmit() {
      const paymentMethod = this.typesOfPayments.find(payment => payment.label === this.form.paymentMethod)
      const updateEnrollmentInfo = {
        priceFee: this.form.price,
        subjectDefault: this.form.subjectDefault,
        paymentMethod: paymentMethod.id
      }
      if(!this.v$.$invalid) {
        this.updateEnrollment({ centerId: this.id, enrollmentId: this.enrollmentId, updateEnrollmentInfo })
        .then(() => {
          this.$toast.success('Datos guardados correctamente.');
          setTimeout(() => {
            this.$router.push({ name: 'enrollments', params: { id: this.id } })
          }, 3000)
        })
        .catch(errors => {
          this.$toast.error('No es posible modificar el método de pago a "Tarjeta" ya que no existe una tarjeta asociada.');
          this.errors = errors
        })
      }
    },
    async detailOldEnrollment(enrollmentId) {
      await this.enrollmentDetailFetch(enrollmentId)
      this.$router.push({ name: 'enrollment-detail', params: { id: this.id, enrollmentId } })
    },
    async enrollmentDetailFetch(enrollmentId) {
      this.isLoading = true
      try {
        await this.fetchEnrollment({centerId: this.id, enrollmentId})
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    }
  },
  async mounted() {
    await this.enrollmentDetailFetch(this.enrollmentId)
  }
}
</script>
<style scoped lang="scss">
.form__field-wrapper {
  @include tablet {
    flex-direction: row;
    .admin__form__element {
      width: 50%;
      &:nth-child(odd) {
        .form__field-input, .form__field-label {
          margin-right: 30px;
        }
        .v3dp__datepicker {
          width: calc(100% - 41px);
          margin-right: 30px;
        }
      }
    }
  }
}
.v3dp__datepicker {
  text-align: left;
  width: calc(100% - 12px);
}
</style>
