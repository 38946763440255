<template>
  <div class="main">
    <h2 class="subtitle">Detalle profesor</h2>
    <TeacherForm
      :teacherForm="form"
      :formCenters="formCenters"
      :errors="formErrors"
      @submit="saveNewTeacher"
    ></TeacherForm>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TeacherForm from '@/components/Partials/TeacherForm'

export default {
  name: 'TeacherDetail',
  components: {
    TeacherForm
  },
  data() {
    return {
      form: {},
      formErrors: {}
    }
  },
  computed: {
    ...mapGetters('Admin', {
      getActiveTeacher: 'getActiveTeacher',
      centers: 'getCentersList'
    }),
    activeTeacher () {
      return this.getActiveTeacher(this.$route.params.teacherId)
    },
    formCenters() {
      return this.centers.map(center => ({
        value: center.id,
        label: center.name
      }))
    }
  },
  watch: {
    activeTeacher: {
      immediate: true,
      handler(newActiveTeacher) {
        if (newActiveTeacher) {
          this.form = {
            ...newActiveTeacher,
            centers: newActiveTeacher.centers.map(center => center.id)
          }
        }
      }
    }
  },
  methods: {
    ...mapActions('Admin', ['fetchSelectedTeacher', 'fetchCenters', 'updateStaffMember']),
    async saveNewTeacher(newTeacherInfo) {
      try {
        await this.updateStaffMember({ centerId: this.$route.params.id, teacherId: this.$route.params.teacherId, newTeacherInfo})
        this.$toast.success('Datos enviados correctamente')
        setTimeout(() => {
          this.$router.push({ name: 'teachers', params: { id: this.$route.params.id } })
        }, 3000)
      } catch (errors) {
        this.formErrors = errors
      }
    }
  },
  async mounted() {
    await this.fetchCenters()
    try {
      await this.fetchSelectedTeacher({ centerId: this.$route.params.id, teacherId: this.$route.params.teacherId })
    } catch (error) {
      console.log('error', error)
    }
  }
}
</script>

<style scoped>

</style>
