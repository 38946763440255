<template>
  <template v-if="data !== null">
    <h2>Histórico de matrícula:</h2>
    <table class="admin__table">
      <thead class="admin__table__head">
        <tr>
          <th class="admin__table__head__cell">Nombre de grupo</th>
          <th class="admin__table__head__cell">Curso y nivel</th>
          <th class="admin__table__head__cell">Fecha inicio</th>
          <th class="admin__table__head__cell">Fecha fin</th>
          <th class="admin__table__head__cell">Método de pago</th>
          <th class="admin__table__head__cell">Precio</th>
        </tr>
      </thead>
      <tbody @click="detail-old-enrollment" class="admin__table__body">
        <tr>
          <td class="admin__table__body__cell"><span class="admin__table__body__cell__header">Nombre de grupo </span><span class="cell-content">{{data.group.name}}</span></td>
          <td class="admin__table__body__cell"><span class="admin__table__body__cell__header">Curso y nivel </span><span class="cell-content">{{data.group.service.name}}</span></td>
          <td class="admin__table__body__cell"><span class="admin__table__body__cell__header">Fecha inicio </span><span class="cell-content">{{formatDateShort(data.dateStart)}}</span></td>
          <td class="admin__table__body__cell"><span class="admin__table__body__cell__header">Fecha fin </span><span class="cell-content">{{formatDateShort(data.dateEnd)}}</span></td>
          <td class="admin__table__body__cell"><span class="admin__table__body__cell__header">Método de pago </span><span class="cell-content">{{getPaymentMethod(data.paymentMethod)}}</span></td>
          <td class="admin__table__body__cell"><span class="admin__table__body__cell__header">Precio </span><span class="cell-content">{{formatPrice(data.priceFee)}}</span></td>
        </tr>
      </tbody>
    </table>
  </template>
</template>

<script>

import { formatDateShort, formatPrice } from '@/commonMethods/methods'
import { typeOfPayments } from '@/commonMethods/constants'

export default {
  name: 'OldEnrollment',
  emits: ['detail-old-enrollment'],
  props: {
    data: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      formatDateShort,
      formatPrice,
      typeOfPayments
    }
  },
  methods: {
    detailOldEnrollment() {
      this.$emit('detail-old-enrollment', this.data.id)
    },
    getPaymentMethod(type) {
      return this.typeOfPayments.find(_type => _type.id === type).label
    }
  }
}
</script>


<style scoped lang="scss">
  h2 {
    text-align: start;
  }
  table {
    margin-bottom: 30px;
  }
</style>
