import {apiClient} from '@/services/ApiClient'

const state = {
  camps: [],
  students: [],
  campInfo: [],
  campName: '',
  campInfoPayment: [],
  studentsInfoPayment: [],
  isFullPayment: false,
  studentsInfo: []
}

const actions = {
  async fetchTutorStudents({commit}) {
    return new Promise((resolve, reject) => {
      apiClient.get('/tutor/students')
      .then(response => {
        commit('SET_TUTOR_STUDENTS', response.data)
        resolve(response.data)
      })
      .catch(err => {
        reject(err.response.data.errors)
      })
    })
  },
  async fetchCampsListByCenter(commit, centerId) {
    return new Promise((resolve, reject) => {
      apiClient.get(`/tutor/centers/${centerId}/camps`)
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        reject(err.response.data.errors)
      })
    })
  },
  async studentListCamps(commit, campId) {
    return new Promise((resolve, reject) => {
      apiClient.get(`/tutor/camps/${campId}/compatible-students`)
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        reject(err.response.data.errors)
      })
    })
  },
  async periodsByCampAndStudent(commit, { campId, studentId }) {
    return new Promise((resolve, reject) => {
      apiClient.get(`/tutor/camps/${campId}/students/${studentId}/periods`)
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        reject(err.response.data.errors)
      })
    })
  },
  async servicesListByPeriods(commit, periodId) {
    return new Promise((resolve, reject) => {
      apiClient.get(`/tutor/camp-periods/${periodId}/services`)
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        reject(err.response.data.errors)
      })
    })
  },
  async orderSummary({ commit }, { campId, infoOrderSummary }) {
    campId = parseInt(campId)
    return new Promise((resolve, reject) => {
      apiClient.post(`/tutor/camps/${campId}/order-summary`, infoOrderSummary)
      .then(response => {
        commit('SET_CAMP_INFO_PAYMENT', response.data)
        resolve(response.data)
      })
      .catch(err => {
        reject(err.response.data.errors)
      })
    })
  },
  async paymentCampCharge(commit, {campId, campInfoPayment}) {
    try {
      return await apiClient.post(`/tutor/payments/camps/${campId}/hiring`, campInfoPayment)
    } catch (err) {
      throw err.response.data.errors
    }
  }
}

const mutations = {
  SET_TUTOR_STUDENTS(state, studentsInfo) {
    state.students = studentsInfo
  },
  SET_CAMP_INFO(state, { campInfo, isFullPayment }) {
    state.campInfo = campInfo
    state.isFullPayment = isFullPayment
  },
  SET_CAMP_NAME(state, campName) {
    state.campName = campName
  },
  SET_CAMP_INFO_PAYMENT(state, campInfoPayment) {
    state.studentsInfoPayment = []
    for(let i=0; i<campInfoPayment.students.length; i++) {
      state.studentsInfoPayment.push(campInfoPayment.students[i].name)
    }
    state.campInfoPayment = campInfoPayment
  },
  SET_STUDENTS_INFO(state, studentsInfo) {
    state.studentsInfo = studentsInfo
  }
}

const getters = {
  getInfoOrderSummary(state) {
    return state.campInfo
  },
  getCampName(state) {
    return state.campName
  },
  getCampInfoPayment(state) {
    return state.campInfoPayment
  },
  getStudents() {
    return state.studentsInfoPayment
  },
  getIsFullPayment() {
    return state.isFullPayment
  },
  getStudentsInfo(state) {
    return state.studentsInfo
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
