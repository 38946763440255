<template>
  <main class="main">
    <h2 class="subtitle">Tutores legales:</h2>
    <table v-if="tutors.length" class="admin__table">
      <thead class="admin__table__head">
        <tr>
          <th v-for="(column, idx) in tableConfig"
              :key="`columnHeader--${idx}`"
              class="admin__table__head__cell"
          >{{column.label}}</th>
        </tr>
      </thead>
      <tbody class="admin__table__body">
        <tr
            v-for="(tutor, idx) in tutors"
            :key="`tutor--${idx}`">
          <td v-for="(column, idx) in tableConfig" :key="`columnHeader--${idx}`"
              class="admin__table__body__cell">
            <span class="admin__table__body__cell__header">{{ column.label }}</span>{{ tutor[column.key] }}
            <template v-if="column.delete">
              <span class="admin__table__body__cell__header"></span>
              <button @click.stop="deleteSelectedTutor(tutor.id)" class="btn">Borrar</button>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else class="message__noData">
      <div class="message__noData-icon">
        <svg width="13" height="36" viewBox="0 0 13 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.880469 -2.38419e-06H12.1805L10.2805 22.25H2.73047L0.880469 -2.38419e-06ZM6.53047 35.45C4.8638 35.45 3.49714 34.95 2.43047 33.95C1.3638 32.9167 0.830469 31.6667 0.830469 30.2C0.830469 28.7 1.3638 27.4667 2.43047 26.5C3.49714 25.5333 4.8638 25.05 6.53047 25.05C8.19714 25.05 9.54714 25.5333 10.5805 26.5C11.6471 27.4667 12.1805 28.7 12.1805 30.2C12.1805 31.6667 11.6471 32.9167 10.5805 33.95C9.5138 34.95 8.1638 35.45 6.53047 35.45Z" fill="white"/>
        </svg>
      </div>
      <div class="message__noData-text">
        <p>No hay tutores para mostrar</p>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: 'LegalTutors.vue',
  data() {
    return {
      tableConfig: [
        {
          label: 'Nombre',
          key: 'name'
        },
        {
          label: 'Apellidos',
          key: 'surnames'
        },
        {
          label: 'Email',
          key: 'email'
        },
        {
          label: 'Teléfono',
          key: 'phone'
        },
        {
          label: 'Relación familiar',
          key: 'familyRelationship'
        },
        {
          label: '',
          key: '',
          delete: true
        }
      ],
    }
  },
  computed: {
    ...mapGetters('Students', {
      tutors: 'getLegalTutors'
    })
  },
  methods: {
    ...mapActions('Students', ['fetchLegalTutors', 'deleteTutor']),
    async deleteSelectedTutor(tutorId) {
      if (confirm("¿Estás seguro que quieres borrar este tutor/a?")) {
        try {
          await this.deleteTutor(tutorId)
          this.$toast.success('Eliminad@ correctamente')
        } catch (error) {
          this.$toast.error('Lo sentimos, este tutor/a no se ha podido eliminar correctamente');
        }
      }
    }
  },
  async mounted() {
    try {
      await this.fetchLegalTutors()
    } catch (error) {
      console.log(error)
    }
  }
}
</script>

<style scoped>

</style>