<template>
  <h3>Próximas sesiones:</h3>
  <div class="data">
    <table class="admin__table">
        <thead class="admin__table__head">
          <tr>
            <th class="admin__table__head__cell">Centro</th>
            <th class="admin__table__head__cell">Servicio</th>
            <th class="admin__table__head__cell">Fecha</th>
            <th class="admin__table__head__cell"></th>
          </tr>
        </thead>
        <tbody v-for="session in sessionsOrdered" :key="session.id" class="admin__table__body">
          <tr>
            <td class="admin__table__body__cell"><span class="admin__table__body__cell__header">Centro</span>{{ session.center }}</td>  
            <td class="admin__table__body__cell"><span class="admin__table__body__cell__header">Servicio</span>{{ session.service }}</td>  
            <td class="admin__table__body__cell"><span class="admin__table__body__cell__header">Fecha</span>{{ session.date }}</td>  
            <td class="admin__table__body__cell">
              <span class="admin__table__body__cell__header"></span>
              <button @click="markMissesSession(session.groupId, session.dateTimestamp)" class="btn">Cancelar sesión</button>
            </td> 
          </tr>
        </tbody>
      </table>
  </div>
</template>

<script>
import { apiClient } from '../services/ApiClient'

export default {
  props: {
    id: {
      type: Number,
      default: 0
    },
    sessions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      sessionsOrdered: []
    }
  },
  watch: {
    sessions: {
      immediate: true,
      handler(value) {
        this.sessionsOrdered = []
        value.forEach(data => {
          if (data.sessions.length) {
            data.sessions.forEach(session => {
              this.sessionsOrdered.push(session)
            })
          }
        })
        this.sessionsOrdered.sort((a, b) => a.dateTimestamp - b.dateTimestamp)
      }
    }
  },
  methods: {
    async markMissesSession(missedGroup, missedAt) {
      const missedSession = {
        student: this.id,
        missedGroup,
        missedAt
      }
      if (confirm('¿Estás seguro de que quieres cancelar esta sesión?')) {
        try {
          await apiClient.post('/tutor/missed-sessions/create', missedSession)
          this.$toast.success('Se ha cancelado correctamente la sesión.')
          this.$emit('updatedSessionList')
        } catch (error) {
          this.$toast.error('Lo sentimos, no ha sido posible cancelar la sesión, inténtelo de nuevo.')
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .admin {
    &__table {
     margin-bottom: 30px;
     &__body {
      // cursor: auto;
      td:last-child {
        border-bottom: 2px solid #45B8AC;
        @include desktop {
          border: 0;
        }
      }
      &__cell {
        cursor: auto;
      }
     }
    }
  } 
  h3 {
    font-size: 26px;
    text-align: start;
    margin-bottom: 20px;
    padding: 22px 20px;
    padding-left: 0px;
    margin: 0;
  }
  .btn {
    margin: 10px 0;
  }
</style>
