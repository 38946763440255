<template>
  <div class="main">

    <h2 class="subtitle">Nuevo campamento / taller</h2>
    
    <p class="text" v-if="isLoading">
      ¡Cargando datos!
    </p>
    <p class="text" v-if="!isLoading && centers.length != 0">
      Elige un centro
    </p>
    <p class="text" v-if="!isLoading && centers.length === 0">
      No hay centros disponibles
    </p>

    <table class="admin__table" v-if="centers.length >= 1">
      <thead class="admin__table__head">
        <tr>
          <th class="admin__table__head__cell">Centro</th>
          <th class="admin__table__head__cell"></th>
        </tr>
      </thead>
      <tbody class="admin__table__body">
        <tr v-for="(center, idx) in centers" :key="idx">
          <td class="admin__table__body__cell"><span>Centro</span><p>{{ center.name }}</p></td>  
          <td class="admin__table__body__cell picked"><button class="btn" @click="picked(idx)">Elegir este centro</button></td>
        </tr>
      </tbody>
    </table>

  </div>

</template>

<script>
export default {
  name: 'NewCamp',
  data() {
    return {
      isLoading: true,
      centers: []
    }
  },
  watch: {
    id: function() {
      this.centers = []
      this.isLoading = true
      this.getCenters()
      .then (response => {
        this.centers = response.data
        this.isLoading = false
      })
      .catch(errors => {
        this.isLoading = true
        this.errros = errors
      })
    }
  },
  methods: {
    getCenters() {
      return this.$store.getters['Students/getCenters']
    },
    picked(idx) {
      const center = this.centers[idx]
      this.$router.push({ name: 'new-camp-center', params: { idCenter: center.id } })
    }
  },
  mounted() {
    this.getCenters()
    .then (response => {
      this.centers = response.data
      this.isLoading = false
    })
    .catch(errors => {
      this.isLoading = true
      this.errros = errors
    })
  }
}
</script>

<style scoped lang="scss">
.main {
  .text {
    margin-bottom: 3em;
  }
}
.admin__table__body__cell {
  @include tablet {
    span {
      display: none;
    }
  }
}

</style>
