<template>
  <main class="main payment-methods">
    <h2>Métodos de pago</h2>
    <div class="payment-methods__info">
      <ul class="payment-methods__list">
        <li
            v-for="(typeOfPayment, idx) in typeOfPayments"
            :key="`type--${idx}`">
          <input
              type="checkbox"
              id="typeOfPayment.id"
              :value="typeOfPayment.id"
              :checked="activeCenter.paymentMethods.includes(typeOfPayment.id)"
              @change="changePayment">
          <label for="unDateEnd">{{typeOfPayment.label}}</label>
        </li>
      </ul>
      <p v-if="!selectedPayments.length"
          class="payment-methods__error"
        :class="{'payment-methods__error--default': selectedPayments.length}">Debes seleccionar al menos un método de pago
      </p>
      <button
          class="btn payment-methods__button"
          :class="[
            {disabled: !selectedPayments.length},
            {'payment-methods__button--error': selectedPayments.length}
          ]"
          @click="saveMethods">Guardar
      </button>
    </div>
  </main>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import { typeOfPayments } from '@/commonMethods/constants'

export default {
  name: 'PaymentMethods.vue',
  data() {
    return {
      isLoading: false,
      typeOfPayments: typeOfPayments,
      selectedPayments: []
    }
  },
  computed: {
    ...mapGetters('Admin', {
      getActiveCenter: 'getActiveCenter'
    }),
    activeCenter() {
      return this.getActiveCenter(this.$route.params.id)
    },
  },
  watch: {
    activeCenter: {
      immediate: true,
      handler (newActiveCenter) {
        if (newActiveCenter) {
          this.selectedPayments = newActiveCenter.paymentMethods.length
              ? newActiveCenter.paymentMethods
              : typeOfPayments.filter(type => type.isDefault)
        }
      }
    }
  },
  methods: {
    ...mapActions('Admin', ['savePaymentMethods']),
    changePayment(event) {
      const findIndxSelection = this.selectedPayments.findIndex(payment => payment === parseInt(event.target.value))
      if (findIndxSelection === -1) {
        this.selectedPayments.push(parseInt(event.target.value))
      } else {
        this.selectedPayments.splice(findIndxSelection, 1)
      }
    },
    async saveMethods() {

      if(this.selectedPayments.length) {
        this.isLoading = true
        try {
          const paymentMethods = {
            paymentMethods: this.selectedPayments
          }
          await this.savePaymentMethods({ centerId: this.$route.params.id, paymentMethods })
          this.$toast.success('Método de pago guardado correctamente');
        } catch(error) {
          this.$toast.error('Lo sentimos, no hemos podido guardar los métodos de pago correctamente, inténtelo de nuevo')
        }
      }

    }
  }
}
</script>

<style scoped lang="scss">

.payment-methods {

  &__info {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 30px;

  }

  &__list {
    background-color: $newSoftBlue;
    border-radius: 18px;
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    padding: 30px;
    width: 100%;

    @include desktop {
      flex-direction: row;
      justify-content: space-around;
    }
  }

  &__error {
    color: $error;
    margin: 0;
    text-align: center;
  }

  &__button {
    margin-top: 24px;

    &--error {
      margin-top: 44px;
    }
  }
}

</style>