<template>
  <Loading v-if="isLoading" />
  <div v-else class="main">
    <h2 class="subtitle">Matrículas activas de {{activeStudent.name}}</h2>
    <p class="text" v-if="enrollments.activesEnrollments.length === 0 && !isLoading">
      Actualmente este alumn@ no tiene matrículas activas:
    </p>
    <p class="text" v-if="enrollments.activesEnrollments.length === 1">
      Actualmente este alumn@ tiene la siguiente matrícula activa:
    </p>
    <p class="text" v-if="enrollments.activesEnrollments.length > 1">
      Actualmente este alumn@ tiene las siguientes matrículas activas:
    </p>
    <table class="admin__table" v-if="enrollments.activesEnrollments.length >= 1">
      <thead class="admin__table__head">
        <tr>
          <th class="admin__table__head__cell">Centro</th>
          <th class="admin__table__head__cell">Curso y nivel</th>
          <th class="admin__table__head__cell">Activa desde</th>
          <th class="admin__table__head__cell">Próximo pago</th>
        </tr>
      </thead>
      <tbody class="admin__table__body">
        <tr v-for="(course, idx) in enrollments.activesEnrollments" :key="idx">
          <td class="admin__table__body__cell"><span>Centro</span><p>{{ course.group.center.name }}</p></td>  
          <td class="admin__table__body__cell"><span>Curso y nivel</span><p class="courseGroup"><span>{{ course.group.service.name }}</span><span>{{ course.group.name }}</span></p></td> 
          <td class="admin__table__body__cell"><span>Activa desde</span><p>{{ formatDate(course.group.dateStart, false) }}</p></td>
          <td class="admin__table__body__cell">
            <span>Próximo pago</span>
            <p v-if="course.priceToBeCharged" class="payments courseGroup" :class="course.paymentError ? 'attention':'active'"><span>{{ course.paymentError ? 'Ha ocurrido un error en el pago' : course.priceToBeCharged + ' €' }}</span><span>{{ 'el ' + formatDate(course.nextPayment) }}</span></p>
            <p v-else class="payments"></p>
          </td>
        </tr>
      </tbody>
    </table>
    <StudentsSessions 
      v-if="sessions.length" 
      :sessions="sessions" 
      :id="activeStudent.id" 
      @updatedSessionList="updateSessionList"
    />
    <div class="search-group-wrapper">
      <div class="search-group" v-show="!isLoading">
        <h3>Apunta a {{activeStudent.name}} a un grupo de la extraescolar del curso actual</h3>
        <router-link :to="{ name: 'new-group' }">
          <button class="btn">Buscar grupo</button>
        </router-link>
      </div>
      <div class="search-group" v-show="!isLoading">
        <h3>Apunta a {{activeStudent.name}} a un Campamento / Taller</h3>
        <router-link :to="{ name: 'new-camp' }">
          <button class="btn">Buscar Campamento / Taller</button>
        </router-link>
      </div>
    </div>
    <h2 class="subtitle" v-if="enrollments.previousEnrollments.length != 0">
      Matrículas anteriores
    </h2>
    <table class="admin__table" v-if="enrollments.previousEnrollments.length != 0">
      <thead class="admin__table__head">
        <tr>
          <th class="admin__table__head__cell">Centro</th>
          <th class="admin__table__head__cell">Curso y nivel</th>
          <th class="admin__table__head__cell">Activa desde</th>
          <th class="admin__table__head__cell">Hasta</th>
        </tr>
      </thead>
      <tbody class="admin__table__body">
        <tr v-for="(pastCourses, idx) in enrollments.previousEnrollments" :key="idx">
          <td class="admin__table__body__cell"><span>Centro</span><p>{{ pastCourses.group.center.name }}</p></td>  
          <td class="admin__table__body__cell"><span>Curso y nivel</span><p class="courseGroup"><span>{{ pastCourses.group.service.name }}</span><span>{{ pastCourses.group.name }}</span></p></td> 
          <td class="admin__table__body__cell"><span>Activa desde</span><p>{{ formatDate(pastCourses.group.dateStart, false) }}</p></td>
          <td class="admin__table__body__cell"><span>Hasta</span><p>{{ formatDate(pastCourses.group.dateEnd, false) }}</p></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Loading from './Loading.vue'
import StudentsSessions from './StudentsSessions.vue'
import { apiClient } from '../services/ApiClient'
import { mapGetters } from 'vuex'
import { formatDate } from '../commonMethods/methods'

export default {
  name: 'Enroll',
  components: {
    StudentsSessions,
    Loading
  },
  props: {
    id: {
      type: String,
    }
  },
  data() {
    return {
      enrollments: {
        activesEnrollments: [],
        previousEnrollments: []
      },
      sessions: [],
      isLoading: false,
      formatDate
    }
  },
  mounted() {
    this.isLoading = true
    this.returnEnrollmentsInfo(this.id)
    .then(response => {
      this.enrollments.activesEnrollments = response.data.activesEnrollments
      this.enrollments.previousEnrollments = response.data.previousEnrollments
      this.isLoading = false
    })
    .catch(err => {
      console.log('error', err)
    })
    apiClient.get(`/tutor/students/${this.id}/sessions`)
      .then(response => {
        this.sessions = response.data
        this.isLoading = false
      })
      .catch(err => console.log(err))
  },
  watch: {
    id: function() {
      this.isLoading = true
      this.enrollments.activesEnrollments = []
      this.enrollments.previousEnrollments = []
      this.returnEnrollmentsInfo(this.id)
        .then(response => {
          this.enrollments.activesEnrollments = response.data.activesEnrollments
          this.enrollments.previousEnrollments = response.data.previousEnrollments
          this.isLoading = false
        })
        .catch(err => {
          console.log('error', err)
        })
      apiClient.get(`/tutor/students/${this.id}/sessions`)
        .then(response => this.sessions = response.data)
        .catch(err => console.log(err))
    }
  },
  computed: {
    ...mapGetters('Students', {
      returnActiveStudent: 'getActiveStudent',
      returnEnrollmentsInfo: 'getEnrollmentsInfo'
    }),
    activeStudent() {
      return this.returnActiveStudent(this.$route.params.id)
    },
  },
  methods: {
    updateSessionList() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      this.isLoading = true
      apiClient.get(`/tutor/students/${this.id}/sessions`)
        .then(response => {
          this.sessions = response.data
          this.isLoading = false
        })
    }
  }
  
}
</script>

<style scoped lang="scss">
.content {
  .main {
    .text {
      margin-bottom: 3em;
    }
    .search-group {
      padding: 1em 2em;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: $lightBgBlue;
      border-radius: 22px;
      padding: 1em 2em;
      flex: 1;
      h3 {
        text-align: center;
        color: $newDark;
      }
      a {
        margin: 0 auto;
      }
    }
  }
  @include tablet {
    .main {
      .search-group {
        flex-direction: column;
        justify-content: space-between;
        flex: .5;
        min-width: 250px;
        h3 {
          margin-right: 10px;
        }
        a {
          margin: 0;
          display: flex;
          align-items: center;
          text-decoration: none;
        }
      }
    }
  }
}
.admin__table__body__cell {
  cursor: auto;
  @include desktop {
    span {
      display: none;
    }
  }
}
.courseGroup {
  display: flex;
  flex-direction: column;
  span {
    display: unset;
    &:first-child {
      font-weight: 700;
    }
    &:last-child {
      font-size: 12px;
    }
  }
}
.search-group-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  margin-top: 48px;
  @include tablet {
    flex-direction: row;
  }
}
</style>
