<template>
  <div class="main">
    <form action class="form admin__form" @submit.prevent="updateCenter">
      <div class="data-row space-between"><h2 class="subtitle">Editar centro:</h2>
        <button type="submit" class="btn btn-save">Guardar</button>
      </div>
      <div class="form__field-row half">
        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.name.$invalid && v$.form.name.$dirty">
              Por favor, introduce un nombre de centro
            </p>
            <label class="form__field-label" for="#name">Nombre centro *</label>
            <input
              v-model="v$.form.name.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.name.$invalid && v$.form.name.$dirty}"
              type="text"
              id="name"
              required
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('name')">
            <p v-for="(error, key) in errors.name" :key="key">{{ $t(error) }}</p>
          </div>
        </div>
        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.address.$invalid && v$.form.address.$dirty">
              Por favor, introduce una dirección
            </p>
            <label class="form__field-label" for="#address">Dirección *</label>
            <input
              v-model="v$.form.address.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.address.$invalid && v$.form.address.$dirty}"
              type="text"
              id="address"
              required
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('address')">
            <p v-for="(error, key) in errors.address" :key="key">{{ $t(error) }}</p>
          </div>
        </div>
      </div>
      <div class="form__field-row half">
        <div class="form__field-wrapper">
          <div class="form__field">
            <label class="form__field-label" for="#email">Email</label>
            <input
              v-model="v$.form.email.$model"
              class="form__field-input"
              type="email"
              id="email"
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('email')">
            <p v-for="(error, key) in errors.email" :key="key">{{ $t(error) }}</p>
          </div>
        </div>
        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.phone.$invalid && v$.form.phone.$dirty">
              Por favor, introduce un teléfono válido
            </p>
            <label class="form__field-label" for="#phone">Teléfono *</label>
            <input
              v-model="v$.form.phone.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.phone.$invalid && v$.form.phone.$dirty}"
              type="phone"
              id="phone"
              required
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('phone')">
            <p v-for="(error, key) in errors.phone" :key="key">{{ $t(error) }}</p>
          </div>
        </div>
      </div>
      <div class="form__field-row third">
        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.redsysMerchantCode.$invalid && v$.form.redsysMerchantCode.$dirty">
              Por favor, introduce código
            </p>
            <label class="form__field-label" for="#redsysMerchantCode">Código mercantil *</label>
            <input
                v-model="v$.form.redsysMerchantCode.$model"
                class="form__field-input"
                :class="{'form__input--invalid': v$.form.redsysMerchantCode.$invalid && v$.form.redsysMerchantCode.$dirty}"
                type="text"
                id="redsysMerchantCode"
                required
                @click="removeErrors"
            >
            <div class="errors errors-input" v-show="errors.hasOwnProperty('redsysMerchantCode')">
              <p v-for="(error, key) in errors.redsysMerchantCode" :key="key">{{ $t(error) }}</p>
            </div>
          </div>

        </div>
        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.redsysKeySecret.$invalid && v$.form.redsysKeySecret.$dirty">
              Por favor, introduce una clave
            </p>
            <label class="form__field-label" for="#redsysKeySecret">Clave secreta *</label>
            <input
                v-model="v$.form.redsysKeySecret.$model"
                class="form__field-input"
                :class="{'form__input--invalid': v$.form.redsysKeySecret.$invalid && v$.form.redsysKeySecret.$dirty}"
                type="text"
                id="redsysKeySecret"
                required
                @click="removeErrors"
            >
            <div class="errors errors-input" v-show="errors.hasOwnProperty('redsysKeySecret')">
              <p v-for="(error, key) in errors.redsysKeySecret" :key="key">{{ $t(error) }}</p>
            </div>
          </div>
        </div>
        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.redsysTerminal.$invalid && v$.form.redsysTerminal.$dirty">
              Por favor, introduce un terminal
            </p>
            <label class="form__field-label" for="#redsysTerminal">Terminal *</label>
            <input
                v-model="v$.form.redsysTerminal.$model"
                class="form__field-input"
                :class="{'form__input--invalid': v$.form.redsysTerminal.$invalid && v$.form.redsysTerminal.$dirty}"
                type="number"
                id="redsysTerminal"
                required
                @click="removeErrors"
            >
            <div class="errors errors-input" v-show="errors.hasOwnProperty('redsysTerminal')">
              <p v-for="(error, key) in errors.redsysTerminal" :key="key">{{ $t(error) }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="form__field-row half">
        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.taxId.$invalid && v$.form.taxId.$dirty">
              Por favor, introduce un CIF
            </p>
            <label class="form__field-label" for="#taxId">CIF *</label>
            <input
                v-model="v$.form.taxId.$model"
                class="form__field-input"
                :class="{'form__input--invalid': v$.form.taxId.$invalid && v$.form.taxId.$dirty}"
                type="text"
                id="taxId"
                required
                @click="removeErrors"
            >
            <div class="errors errors-input" v-show="errors.hasOwnProperty('taxId')">
              <p v-for="(error, key) in errors.taxId" :key="key">{{ $t(error) }}</p>
            </div>
          </div>
        </div>
        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.taxAddress.$invalid && v$.form.taxAddress.$dirty">
              Por favor, introduce una direccion fiscal
            </p>
            <label class="form__field-label" for="#taxAddress">Direccion fiscal *</label>
            <input
                v-model="v$.form.taxAddress.$model"
                class="form__field-input"
                :class="{'form__input--invalid': v$.form.taxAddress.$invalid && v$.form.taxAddress.$dirty}"
                type="text"
                id="taxAddress"
                required
                @click="removeErrors"
            >
            <div class="errors errors-input" v-show="errors.hasOwnProperty('taxAddress')">
              <p v-for="(error, key) in errors.taxAddress" :key="key">{{ $t(error) }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="form__field-row half">
        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.companyName.$invalid && v$.form.companyName.$dirty">
              Por favor, introduce una razón social
            </p>
            <label class="form__field-label" for="#companyName">Razón Social *</label>
            <input
                v-model="v$.form.companyName.$model"
                class="form__field-input"
                :class="{'form__input--invalid': v$.form.companyName.$invalid && v$.form.companyName.$dirty}"
                type="text"
                id="companyName"
                required
                @click="removeErrors"
            >
            <div class="errors errors-input" v-show="errors.hasOwnProperty('companyName')">
              <p v-for="(error, key) in errors.companyName" :key="key">{{ $t(error) }}</p>
            </div>
          </div>
        </div>
        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.administrator.$invalid && v$.form.administrator.$dirty">
              Por favor, introduce el nombre del administrador
            </p>
            <label class="form__field-label" for="#administrator">Administrador *</label>
            <input
                v-model="v$.form.administrator.$model"
                class="form__field-input"
                :class="{'form__input--invalid': v$.form.administrator.$invalid && v$.form.administrator.$dirty}"
                type="text"
                id="administrator"
                required
                @click="removeErrors"
            >
            <div class="errors errors-input" v-show="errors.hasOwnProperty('administrator')">
              <p v-for="(error, key) in errors.administrator" :key="key">{{ $t(error) }}</p>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email, numeric } from '@vuelidate/validators'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'LeadForm',
  props: {
    id: {
      type: String,
      required: true
    },
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      form: {
        name: '',
        address: '',
        email: '',
        phone: '',
        redsysMerchantCode: '',
        redsysKeySecret: '',
        redsysTerminal: null,
        taxId: '',
        taxAddress: '',
        companyName: '',
        administrator: ''
      },
      errors: {}
    }
  },
  validations() {
    return {
      form: {
        name: {
          required
        },
        address: {
          required
        },
        email: {
          email
        },
        phone: {
          required,
          numeric
        },
        redsysMerchantCode: {
          required
        },
        redsysKeySecret: {
          required
        },
        redsysTerminal: {
          required,
          numeric
        },
        taxId: {
          required
        },
        taxAddress: {
          required
        },
        companyName: {
          required
        },
        administrator: {
          required
        },
      }
    }
  },
  watch: {
    center: {
      immediate: true,
      handler(newCenter) {
        if(newCenter) {
          this.form = newCenter
        }
      }
    }
  },
  computed: {
    ...mapGetters('Admin', {
      center: 'getCenter'
    }),
  },
  methods: {
    ...mapActions('Admin', ['updateCenterByID', 'fetchCenterById', 'deleteCenterByID']),
    async updateCenter() {
      if (!this.v$.$invalid) {
        const centerInfo = {
          name: this.form.name,
          address: this.form.address,
          email: this.form.email,
          phone: this.form.phone,
          redsysMerchantCode: this.form.redsysMerchantCode,
          redsysKeySecret: this.form.redsysKeySecret,
          redsysTerminal: this.form.redsysTerminal,
          taxId: this.form.taxId,
          taxAddress: this.form.taxAddress,
          companyName: this.form.companyName,
          administrator: this.form.administrator,
        }
        try {
          await this.updateCenterByID({centerId: this.id, centerInfo })
          this.$toast.success('Datos guardados correctamente');
          setTimeout(() => {
            this.$router.push({ name: 'centers' })
          }, 3000)
        } catch (error) {
          this.errors = error
        }
      }
    },
    removeErrors() {
      this.errors = ''
    }
  },
  mounted() {
    this.fetchCenterById(this.id)
  }
}
</script>

<style scoped lang="scss">

</style>
