<template>
  <Loading
    v-if="isLoading" />
  <div v-else class="main">
    <h2 class="subtitle">Elige tu sesión de recuperación</h2>
    <table class="admin__table" v-if="recoverClassSession.length >= 1">
      <thead class="admin__table__head">
        <tr>
          <th class="admin__table__head__cell">Centro</th>
          <th class="admin__table__head__cell">Grupo</th>
          <th class="admin__table__head__cell">Curso y nivel</th>
          <th class="admin__table__head__cell">Día y hora</th>
          <th class="admin__table__head__cell"></th>
        </tr>
      </thead>
      <tbody class="admin__table__body">
        <tr v-for="(recoverSession, idx) in recoverClassSession" :key="idx">
          <td class="admin__table__body__cell"><span class="admin__table__body__cell__header">Centro</span><p>{{ recoverSession.center }}</p></td>  
          <td class="admin__table__body__cell"><span class="admin__table__body__cell__header">Grupo</span><p>{{ recoverSession.groupName }}</p></td>  
          <td class="admin__table__body__cell"><span class="admin__table__body__cell__header">Curso y nivel</span><p>{{ recoverSession.service }}</p></td> 
          <td class="admin__table__body__cell"><span class="admin__table__body__cell__header">Día y hora</span><p>{{ reverseDate(recoverSession.date) }} a las {{ recoverSession.time }}</p></td>
          <td class="admin__table__body__cell">
            <span class="admin__table__body__cell__header"></span>
            <button 
              class="btn"
              :class="{'disabled': isBooking && indexSelected === idx}" 
              @click="book(idx)">{{indexSelected === idx ? 'Reservando sesión' : 'Elegir esta sesión'}}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else>
      <p class="text">No tiene grupos donde recuperar la clase</p>
    </div>
    <div class="errors" v-show="errors.hasOwnProperty('session')">
      <p v-for="(error, key) in errors.session" :key="key">{{ $t(error) }}</p>
    </div>
    <div class="errors" v-show="errors.hasOwnProperty('missed_session')">
      <p v-for="(error, key) in errors.missed_session" :key="key">{{ $t(error) }}</p>
    </div>
    <div class="errors" v-for="(error, key) in errors" :key="key">
      <p>{{ $t(error) }}</p>
    </div>
  </div>

</template>

<script>
import Loading from '@/components/Loading'
import { mapActions, mapGetters } from 'vuex'
import { reverseDate, sqlToTimestamp } from '../commonMethods/methods'

export default {
  name: 'RecoverClass',
  components: {
    Loading
  },
  props: {
    id: {
      type: String,
      required: true
    },
    idMissedSession: {
      type: String,
      required: true
    },
    isEditing: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      recoverClassSession: [],
      isLoading: true,
      isBooking: false,
      errors: {},
      indexSelected: null,
      reverseDate,
      sqlToTimestamp
    }
  },
  mounted() {
    this.returnSessions(this.idMissedSession)
    .then(response => {
      this.recoverClassSession = response.data
      this.isLoading = false
    })
    .catch(errors => {
      this.errors = errors
    })
  },
  watch: {
    id: function() {
      this.recoverClassSession = []
      this.returnSessions(this.idMissedSession)
      .then(response => {
        this.recoverClassSession = response.data
        this.isLoading = false
      })
      .catch(errors => {
        this.errors = errors
      })
    }
  },
  computed: {
    ...mapGetters('Students', {
      returnSessions: 'getSessions'
    }),
    activeStudent() {
      return this.returnActiveStudent(this.$route.params.id)
    }
  },
  methods: {
    ...mapActions('Students', ['bookMissedSession']),
    async book(idx) {
      const setEditingBoolean = (this.isEditing === 'true')
      let checkingBooking = confirm('¿Estás seguro que quieres reservar esta sesión?')
      if (checkingBooking) {
        this.indexSelected = idx
        this.isBooking = true
        let objBookSession = {
          recoveredGroup: this.recoverClassSession[idx].groupId,
          recoveredAt: this.recoverClassSession[idx].dateTimestamp
        }
        this.bookMissedSession({
          objBook: objBookSession,
          missedSession: this.idMissedSession
        })
        .then(() => {
          if (setEditingBoolean) {
            this.$router.push({ name: 'reserved-classes' })
            this.$toast.success('Datos guardados correctamente');
          } else {
            this.$router.push({ name: 'recover-class' })
          }
        })
        .catch(errors => {
          this.errors = errors
          this.$toast.error('Sesión ya recuperada');
        })
        .finally(() => this.isBooking = false)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.main {
  .text {
    margin-bottom: 3em;
  }
  .admin {
    &__table {
      &__body {
        &__cell {
          cursor: auto;
        }
      }
    }
  }
}

.disabled {
  pointer-events: none;
  background-color: #60d4c8;
  opacity: .9;
}

</style>
