<template>
  <div class="register-wrapper box">

    <div class="block-title-text">
      <h2 class="subtitle">Crea tu cuenta</h2>
      <p class="text">Introduce los datos del primer tutor legal para que podamos crear tu cuenta.</p>
    </div>
    <div class="form-wrapper">

      <form action class="form" @submit.prevent="newUserSubmit">
        <div class="form__field-row">
          <div class="form__field-wrapper wide">
            <div class="form__field center">
              <label class="form__field-label" for="#center">Selecciona centro:</label>
              <select
                  v-model="userForm.center"
                  class="form__field-input"
                  name="center"
                  id="center"
                  @click="removeErrors"
                  style="width: 300px"
              >
                <option v-for="(center,idx) in centers" :key="idx" :value="center.value">{{ center.label }}</option>
              </select>
            </div>
            <p class="input-errors" v-if="v$.userForm.center.$invalid && v$.userForm.center.$dirty">
              Debes elegir uno de nuestros centros
            </p>
            <div class="errors errors-input" v-show="errors.hasOwnProperty('center')">
              <p v-for="(error, key) in errors.center" :key="key">{{ $t(error) }}</p>
            </div>
          </div>
        </div>
        <div v-if="!v$.userForm.center.$invalid">
          <div class="form__field-row">
            <div class="form__field-wrapper">
              <div class="form__field">
                <label class="form__field-label" for="#name">Nombre *</label>
                <input
                    v-model="v$.userForm.name.$model"
                    class="form__field-input"
                    type="text"
                    id="name"
                    required
                    @click="removeErrors"
                >
              </div>
              <div class="errors errors-input" v-show="errors.hasOwnProperty('name')">
                <p v-for="(error, key) in errors.name" :key="key">{{ $t(error) }}</p>
              </div>
            </div>

            <div class="form__field-wrapper">
              <div class="form__field">
                <label class="form__field-label" for="#surnames">Apellidos *</label>
                <input
                    v-model="v$.userForm.surnames.$model"
                    class="form__field-input"
                    type="text"
                    id="surnames"
                    required
                    @click="removeErrors"
                >
              </div>
              <div class="errors errors-input" v-show="errors.hasOwnProperty('surnames')">
                <p v-for="(error, key) in errors.surnames" :key="key">{{ $t(error) }}</p>
              </div>
            </div>
          </div>

          <div class="form__field-row">
            <div class="form__field-wrapper">
              <div class="form__field">
                <label class="form__field-label" for="#email">Email *</label>
                <input
                    v-model="v$.userForm.email.$model"
                    class="form__field-input"
                    :class="{'form__input--invalid': v$.userForm.email.$invalid && v$.userForm.email.$dirty}"
                    id="email"
                    required
                    @click="removeErrors"
                >
              </div>
              <p class="input-errors" v-if="v$.userForm.email.$invalid && v$.userForm.email.$dirty">
                Por favor, introduce un correo válido
              </p>
              <div class="errors errors-input" v-show="errors.hasOwnProperty('email')">
                <p v-for="(error, key) in errors.email" :key="key">{{ $t(error) }}</p>
              </div>
            </div>

            <div class="form__field-wrapper">
              <div class="form__field">
                <p class="input-errors" v-if="v$.userForm.emailSecondary.$invalid && v$.userForm.emailSecondary.$dirty">
                  Por favor, introduzca un email válido
                </p>
                <label class="form__field-label" for="#emailSecondary">Segundo email</label>
                <input
                    v-model="v$.userForm.emailSecondary.$model"
                    class="form__field-input"
                    :class="{'form__input--invalid': v$.userForm.emailSecondary.$invalid && v$.userForm.emailSecondary.$dirty}"
                    type="email"
                    id="emailSecondary"
                    @click="removeErrors"
                >
              </div>
              <div class="errors errors-input" v-show="errors.hasOwnProperty('emailSecondary')">
                <p v-for="(error, key) in errors.emailSecondary" :key="key">{{ $t(error) }}</p>
              </div>
            </div>

          </div>

          <div class="form__field-row">
            <div class="form__field-wrapper">
              <div class="form__field">
                <label class="form__field-label" for="#phone">Teléfono *</label>
                <input
                    v-model="v$.userForm.phone.$model"
                    class="form__field-input"
                    :class="{'form__input--invalid': v$.userForm.phone.$invalid && v$.userForm.phone.$dirty}"
                    id="phone"
                    type="number"
                    required
                    @click="removeErrors"
                >
              </div>
              <p class="input-errors" v-if="v$.userForm.phone.$invalid && v$.userForm.phone.$dirty">
                Por favor, introduce un teléfono válido
              </p>
              <div class="errors errors-input" v-show="errors.hasOwnProperty('phone')">
                <p v-for="(error, key) in errors.phone" :key="key">{{ $t(error) }}</p>
              </div>
            </div>

            <div class="form__field-wrapper">
              <div class="form__field">
                <p class="input-errors" v-if="v$.userForm.phoneSecondary.$invalid && v$.userForm.phoneSecondary.$dirty">
                  Por favor, introduce un teléfono válido
                </p>
                <label class="form__field-label" for="#phoneSecondary">Segundo teléfono</label>
                <input
                    v-model="v$.userForm.phoneSecondary.$model"
                    class="form__field-input"
                    :class="{'form__input--invalid': v$.userForm.phoneSecondary.$invalid && v$.userForm.phoneSecondary.$dirty}"
                    type="number"
                    id="phoneSecondary"
                    @click="removeErrors"
                >
              </div>
              <div class="errors errors-input" v-show="errors.hasOwnProperty('phoneSecondary')">
                <p v-for="(error, key) in errors.phoneSecondary" :key="key">{{ $t(error) }}</p>
              </div>
            </div>
          </div>

          <div class="form__field-row">
            <div class="form__field-wrapper">
              <div class="form__field">
                <label class="form__field-label" for="#password">Contraseña *</label>
                <input
                    v-model="v$.userForm.password.$model"
                    class="form__field-input"
                    :class="{'form__input--invalid': v$.userForm.password.$invalid && v$.userForm.password.$dirty}"
                    id="password"
                    type="password"
                    required
                    @click="removeErrors"
                >
              </div>
              <p class="input-errors" v-if="v$.userForm.password.$invalid && v$.userForm.password.$dirty">
                Tu contraseña debe tener al menos 8 caracteres
              </p>
              <div class="errors errors-input" v-show="errors.hasOwnProperty('password')">
                <p v-for="(error, key) in errors.password" :key="key">{{ $t(error) }}</p>
              </div>
            </div>

            <div class="form__field-wrapper">
              <div class="form__field">
                <label class="form__field-label" for="#repeatPassword">Repetir contraseña *</label>
                <input
                    v-model="v$.repeatPassword.$model"
                    class="form__field-input"
                    :class="{'form__input--invalid': v$.repeatPassword.$invalid && v$.repeatPassword.$dirty}"
                    id="repeatPassword"
                    type="password"
                    @click="removeErrors"
                >
              </div>
              <p class="input-errors" v-if="v$.repeatPassword.$invalid && v$.repeatPassword.$dirty">
                La contraseña debe coincidir
              </p>
            </div>
          </div>
          <Switch
              v-model:checked="userForm.agree"
              link="https://lacasitadeingles.com/politica-de-privacidad/"
              label="He leido y acepto la %politica de privacidad%"
          />
          <div class="form__field-row">
            <div class="form__field-wrapper wide">
              <div class="form__field">
                <p class="input-errors" v-if="v$.userForm.agree.$invalid && v$.userForm.agree.$dirty">
                  Debes aceptar la politica de privacidad para registrarte
                </p>
                <div class="errors errors-input" v-show="errors.hasOwnProperty('agree')">
                  <p v-for="(error, key) in errors.agree" :key="key">{{ $t(error) }}</p>
                </div>
              </div>
            </div>
          </div>
          <button class="btn">Completar registro</button>
        </div>
      </form>
    </div>

  </div>
</template>

<script>
import {useVuelidate} from '@vuelidate/core'
import {required, email, minLength, numeric, sameAs} from '@vuelidate/validators'
import {mapActions, mapGetters} from 'vuex'
import Switch from "@/components/Switch.vue";

export default {
  name: 'Register',
  components: {Switch},
  setup() {
    return {v$: useVuelidate()}
  },
  data() {
    return {
      userForm: {
        name: '',
        surnames: '',
        email: '',
        emailSecondary: '',
        phone: '',
        phoneSecondary: '',
        password: '',
        center: null,
        agree: false
      },
      repeatPassword: '',
      centers: [],
      errors: {}
    }
  },
  validations() {
    return {
      userForm: {
        name: {
          required
        },
        surnames: {
          required
        },
        center: {
          required
        },
        agree: {
          required,
          requiredTrue: value => value
        },
        email: {
          required,
          email
        },
        emailSecondary: {
          email
        },
        phone: {
          required,
          numeric
        },
        phoneSecondary: {
          numeric
        },
        password: {
          required,
          minLength: minLength(8)
        }
      },
      repeatPassword: {
        required,
        sameAsPassword: sameAs(this.userForm.password)
      }
    }
  },
  methods: {
    ...mapActions('Auth', {
      actionRegister: 'signup'
    }),
    ...mapActions('Auth', ['fetchCenters']),
    removeErrors() {
      this.errors = ''
    },
    async newUserSubmit() {
      if (!this.v$.$invalid) {
        const _submitted = {...this.userForm}
        delete _submitted.agree;
        this.actionRegister(_submitted)
            .then(() => {
              this.$router.push({name: 'register-confirmation'})
            })
            .catch(errors => {
              this.errors = errors
            })
      } else {
        this.v$.userForm['center'].$touch();
        this.v$.userForm['agree'].$touch();
      }
    }
  },
  computed: {
    ...mapGetters('Auth', ['getCenters']),
  },
  async mounted() {
    this.getCenters.then((centers) => {
      this.centers = centers.map(center => ({
        value: center.id,
        label: center.name
      }))
    })
  }
}
</script>

<style scoped lang="scss">
.register-wrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  .block-title-text {
    margin-bottom: 1em;
  }
  .form-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    .block-title-text {
      margin-top: 80px;
    }
  }
  .form {
    display: block;
    padding: 20px 0;
    border-radius: 4px;
    width: 100%;
    &__field-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 0 20px;
      .form__field-wrapper {
        width: 80%;
        &.wide{
          width: 100%;
        }
        .form__field {
          display: flex;
          flex-direction: column;
          .form__field-input {
            width: unset;
          }
        }
      }
    }
    .btn {
      margin-top: 20px;
    }
  }
  @include tablet {
    .form-wrapper {
      .block-title-text {
        margin-top: 60px;
      }
    }
    .form {
      width: 80%;
      &__field-wrapper:not(.wide) {
        .input-errors {
          max-width: 300px;
        }
      }
      &__field {
        .form-input {
          min-width: 300px;
        }
      }
      &__field-row {
        justify-content: space-between;
        .form__field-wrapper:not(.wide) {
          width: 48%;
        }
      }
    }
  }
  @include desktop {
    .form {
      width: 60%;
    }
  }
}
</style>
