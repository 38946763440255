import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createI18n } from 'vue-i18n'
import es from './i18n/es.json'
import store from './store'
import Toaster from "@meforma/vue-toaster";
require('./scss/styles.scss');


const i18n = createI18n({
  locale: 'es',
  messages: {
    es
  }
})
const app = createApp(App)

app.use(router)
app.use(store)
app.use(i18n)
app.use(Toaster, {
  position: 'top-right'
})

// Vue.use(Toasted, {
//   duration: 9000,
//   position: 'top-right',
//   action : {
//     text : 'Okay',
//     onClick : (e, toastObject) => {
//         toastObject.goAway(0);
//     }
//   }
// })
app.mount('#app')

export default app;