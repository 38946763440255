<template>
  <form class="form admin__form">
    <ul class="form__field-wrapper">
      <li class="form__field admin__form__element">
        <label
          for="service"
          class="form__field-label">Servicios *
        </label>
        <select
          v-model="v$.form.service.$model"
          class="form__field-input"
          :class="{'form__input--invalid': v$.form.service.$invalid && v$.form.service.$dirty}"
          name="service"
          id="service"
          required
          @click="removeErrors"
        >
          <option v-for="(service,idx) in formServices" :key="idx" :value="service.value">{{service.label}}</option>
        </select>
        <p class="input-errors" v-if="v$.form.service.$invalid && v$.form.service.$dirty">
          Por favor, introduce un nombre
        </p>
        <div class="errors errors-input" v-show="errors.hasOwnProperty('service')">
          <p v-for="(error, key) in errors.service" :key="key">{{ $t(error) }}</p>
        </div>
      </li>
      <li class="form__field admin__form__element">
        <label
          for="staffMember"
          class="form__field-label">Profesor
        </label>
        <select
          v-model="form.staffMember"
          class="form__field-input"
          name="staffMember"
          id="staffMember"
          @click="removeErrors"
        >
          <option v-for="(staffMember,idx) in formStaffMembers" :key="idx" :value="staffMember.value">{{staffMember.label}}</option>
        </select>
      </li>
      <li class="form__field admin__form__element">
        <label
          for="name"
          class="form__field-label">Nombre de grupo *
        </label>
        <input
          class="form__field-input"
          :class="{'form__input--invalid': v$.form.name.$invalid && v$.form.name.$dirty}"
          type="text"
          id="name"
          required
          v-model="v$.form.name.$model"
          @click="removeErrors">
          <p class="input-errors" v-if="v$.form.name.$invalid && v$.form.name.$dirty">
            Por favor, introduce un nombre de grupo
          </p>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('name')">
            <p v-for="(error, key) in errors.name" :key="key">{{ $t(error) }}</p>
          </div>
      </li>
      <li class="form__field admin__form__element">
        <label
          for="fixedPlaces"
          class="form__field-label">Número de plazas fijas *
        </label>
        <input
          class="form__field-input"
          :class="{'form__input--invalid': v$.form.fixedPlaces.$invalid && v$.form.fixedPlaces.$dirty}"
          type="number"
          id="fixedPlaces"
          required
          v-model="v$.form.fixedPlaces.$model"
          @click="removeErrors">
          <p class="input-errors" v-if="v$.form.fixedPlaces.$invalid && v$.form.fixedPlaces.$dirty">
            Por favor, introduce un número de plazas fijas
          </p>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('fixedPlaces')">
            <p v-for="(error, key) in errors.fixedPlaces" :key="key">{{ $t(error) }}</p>
          </div>
      </li>
      <li class="form__field admin__form__element">
        <label
          for="extraPlaces"
          class="form__field-label">Número de plazas extra *
        </label>
        <input
          class="form__field-input"
          :class="{'form__input--invalid': v$.form.extraPlaces.$invalid && v$.form.extraPlaces.$dirty}"
          type="number"
          id="extraPlaces"
          required
          v-model="v$.form.extraPlaces.$model"
          @click="removeErrors">
          <p class="input-errors" v-if="v$.form.extraPlaces.$invalid && v$.form.extraPlaces.$dirty">
            Por favor, introduce un número de plazas extra
          </p>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('extraPlaces')">
            <p v-for="(error, key) in errors.extraPlaces" :key="key">{{ $t(error) }}</p>
          </div>
      </li>
      <li class="form__field admin__form__element">
        <label
          for="weekDay"
          class="form__field-label">Día de la semana *
        </label>
        <select
          v-model="v$.form.weekDay.$model"
          name="select"
          class="form__field-input"
          :class="{'form__input--invalid': v$.form.weekDay.$invalid && v$.form.weekDay.$dirty}"
          id="service"
          required
          @click="removeErrors"
        >
          <option
            v-for="(weekDay, idx) in formWeekDay"
            :key="`weekDay--${idx}`" :value="weekDay.value">{{weekDay.label}}
          </option>
        </select>
        <p class="input-errors" v-if="v$.form.weekDay.$invalid && v$.form.weekDay.$dirty">
          Por favor, introduce un día de la semana
        </p>
        <div class="errors errors-input" v-show="errors.hasOwnProperty('weekDay')">
          <p v-for="(error, key) in errors.weekDay" :key="key">{{ $t(error) }}</p>
        </div>
      </li>
      <li class="form__field admin__form__element">
        <label
          for="yearStart"
          class="form__field-label">Año mínimo de nacimiento *
        </label>
        <input
          class="form__field-input"
          :class="{'form__input--invalid': v$.form.yearStart.$invalid && v$.form.yearStart.$dirty}"
          type="number"
          id="yearStart"
          required
          v-model="v$.form.yearStart.$model"
          @click="removeErrors"/>
          <p class="input-errors" v-if="v$.form.yearStart.$invalid && v$.form.yearStart.$dirty">
            Por favor, introduce un año válido
          </p>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('yearStart')">
            <p v-for="(error, key) in errors.yearStart" :key="key">{{ $t(error) }}</p>
          </div>
      </li>
      <li class="form__field admin__form__element">
        <label
          for="yearEnd"
          class="form__field-label">Año máximo de nacimiento *
        </label>
        <input
          class="form__field-input"
          :class="{'form__input--invalid': v$.form.yearEnd.$invalid && v$.form.yearEnd.$dirty}"
          type="number"
          id="yearEnd"
          required
          v-model="v$.form.yearEnd.$model"
          @click="removeErrors"/>
          <p class="input-errors" v-if="v$.form.yearEnd.$invalid && v$.form.yearEnd.$dirty">
            Por favor, introduce un año válido
          </p>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('yearEnd')">
            <p v-for="(error, key) in errors.yearEnd" :key="key">{{ $t(error) }}</p>
          </div>
      </li>
      <li class="form__field admin__form__element">
        <label class="form__field-label" for="#dateStart">Fecha comienzo *</label>
        <datepicker
          v-model="form.dateStart"
          inputFormat="dd/MM/yyyy"
          :lower-limit="lowerLimit"
          class="datepicker-wrapper"
          :style="useInputStyles"
          :locale="dateLocale"
        />
      </li>
      <li class="form__field admin__form__element">
        <label class="form__field-label" for="#dateEnd">Fecha finalización *</label>
        <datepicker
          v-model="form.dateEnd"
          inputFormat="dd/MM/yyyy"
          :lower-limit="lowerLimit"
          :style="useInputStyles"
          :locale="dateLocale"
        />
      </li>
      <li class="form__field admin__form__element">
        <label
          for="price"
          class="form__field-label">Importe matrícula *
        </label>
        <input
          class="form__field-input"
          :class="{'form__input--invalid': v$.form.priceEnrollmentDefault.$invalid && v$.form.priceEnrollmentDefault.$dirty}"
          type="number"
          id="priceEnrollmentDefault"
          required
          v-model="v$.form.priceEnrollmentDefault.$model"
          @click="removeErrors"/>
          <p class="input-errors" v-if="v$.form.priceEnrollmentDefault.$invalid && v$.form.priceEnrollmentDefault.$dirty">
            Por favor, introduce el coste de la matrícula
          </p>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('priceEnrollmentDefault')">
            <p v-for="(error, key) in errors.priceEnrollmentDefault" :key="key">{{ $t(error) }}</p>
          </div>
      </li>
      <li class="form__field admin__form__element">
        <label
          for="priceFeeDefault"
          class="form__field-label">Importe mensual *
        </label>
        <input
          class="form__field-input"
          type="number"
          id="priceFeeDefault"
          v-model="v$.form.priceFeeDefault.$model"
          required
          @click="removeErrors"/>
          <p class="input-errors" v-if="v$.form.priceFeeDefault.$invalid && v$.form.priceFeeDefault.$dirty">
            Por favor, introduce el coste de la mensualidad
          </p>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('priceFeeDefault')">
            <p v-for="(error, key) in errors.priceFeeDefault" :key="key">{{ $t(error) }}</p>
          </div>
      </li>
      <li class="form__field admin__form__element">
        <label
          for="hour"
          class="form__field-label">Hora *
        </label>
        <input
          class="form__field-input"
          type="text"
          id="hour"
          required
          v-model="v$.form.hour.$model"
          @click="removeErrors"/>
          <p class="input-errors" v-if="v$.form.hour.$invalid && v$.form.hour.$dirty">
            Por favor, introduce una hora
          </p>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('hour')">
            <p v-for="(error, key) in errors.hour" :key="key">{{ $t(error) }}</p>
          </div>
      </li>
      <li class="form__field admin__form__element">
        <label
          for="description"
          class="form__field-label">Descripción *
        </label>
        <textarea
          class="form__field-input"
          type="text"
          id="description"
          required
          v-model="v$.form.description.$model"
          @click="removeErrors">
        </textarea>
        <p class="input-errors" v-if="v$.form.description.$invalid && v$.form.description.$dirty">
          Por favor, introduce una descripción
        </p>
        <div class="errors errors-input" v-show="errors.hasOwnProperty('description')">
          <p v-for="(error, key) in errors.description" :key="key">{{ $t(error) }}</p>
        </div>
      </li>
    </ul>
    <button
      type="button"
      class="btn btn-save"
      @click="submitForm">Guardar
    </button>
  </form>
</template>

<script>
  import { required, numeric, maxLength, minLength } from '@vuelidate/validators'
  import { useVuelidate } from '@vuelidate/core'
  import { ref } from 'vue'
  import Datepicker from 'vue3-datepicker'
  import { utcToTimestamp } from '@/commonMethods/methods'
  import es from 'date-fns/locale/es'

  const useInputStyles = {
    padding: "12px 20px",
    border: "1px solid #00ADCE",
    borderRadius: "0",
    marginRight: "30px",
    width: "calc(100% - 30px)"
  }

  export default {
    name: 'GroupForm',
    components: {
      Datepicker
    },
    setup () {
      return { v$: useVuelidate() }
    },
    props: {
      groupForm: {
        type: Object,
        default: () => ({})
      },
      isCreate: {
        type: Boolean,
        default: false
      },
      services: {
        type: Array,
        default: () => ([])
      },
      staffMembers: {
        type: Array,
        default: () => ([])
      },
      errors: {
        type: Array,
        default: () => ([])
      }
    },
    watch: {
      groupForm: {
        immediate: true,
        handler(newGroup) {
          if (newGroup) {
            this.form = {
              ...newGroup,
              staffMember: newGroup.staffMember ? newGroup.staffMember : '',
              service: newGroup.service ? newGroup.service : ''
            }
          }
        }
      }
    },
    data() {
      return {
        lowerLimit: ref(new Date()),
        weekDictionary: [
          {
            id: 1,
            label: 'Lunes',
          },
          {
            id: 2,
            label: 'Martes',
          },
          {
            id: 3,
            label: 'Miércoles',
          },
          {
            id: 4,
            label: 'Jueves',
          },
          {
            id: 5,
            label: 'Viernes',
          },
          {
            id: 6,
            label: 'Sábado',
          },
          {
            id: 0,
            label: 'Domingo',
          }
        ],
        form: {
          service: '',
          staffMember: '',
          name: '',
          description: '',
          yearStart: '',
          yearEnd: '',
          weekDay: '',
          dateStart: ref(new Date()),
          dateEnd: ref(new Date()),
          priceEnrollmentDefault: null,
          priceFeeDefault: '',
          hour: '',
          fixedPlaces: 0,
          extraPlaces: 0,
        },
        utcToTimestamp,
        useInputStyles,
        dateLocale: es
      }
    },
    validations() {
      return {
        form: {
          service: {
            required
          },
          name: {
            required
          },
          description: {
            required
          },
          yearStart: {
            required,
            numeric,
            maxLength: maxLength(4),
            minLength: minLength(4)
          },
          yearEnd: {
            required,
            numeric,
            maxLength: maxLength(4),
            minLength: minLength(4)
          },
          weekDay: {
            required
          },
          priceEnrollmentDefault: {
            required,
            numeric
          },
          priceFeeDefault: {
            numeric
          },
          hour: {
            required
          },
          fixedPlaces: {
            required
          },
          extraPlaces: {
            required
          }
        }
      }
    },
    methods: {
      submitForm() {
        const objSubmitForm = {
          ...this.form,
          dateStart: utcToTimestamp(this.form.dateStart),
          dateEnd: utcToTimestamp(this.form.dateEnd),
          weekDay: this.form.weekDay
        }
        if (!this.v$.$invalid) {
          this.$emit('submit', objSubmitForm)
        }
      },
      removeErrors() {
        this.$emit('on-errors-form')
      }
    },
    computed: {
      formServices() {
        return this.services.map(service => ({
          value: service.id,
          label: service.name
        }))
      },
      formStaffMembers() {
        return this.staffMembers.map(staffMember => ({
          value: staffMember.id,
          label: staffMember.name
        }))
      },
      formWeekDay() {
        return this.weekDictionary.map(weekDay => ({
          value: weekDay.id,
          label: weekDay.label
        }))
      }
    }
  }


</script>
<style scoped lang="scss">
.form__field-wrapper {
  @include tablet {
    flex-direction: row;
    .admin__form__element {
      width: 50%;
      &:nth-child(odd) {
        .form__field-input, .form__field-label {
          margin-right: 30px;
        }
        .v3dp__datepicker {
          width: calc(100% - 41px);
          margin-right: 30px;
        }
      }
    }
  }
}
.v3dp__datepicker {
  text-align: left;
  width: calc(100% - 12px);
}
</style>
