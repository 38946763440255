<template>
  <main class="main">
    <form action class="form admin__form" @submit.prevent="newTutorSubmit">
      <div class="data-row space-between">
        <h2 class="subtitle">Nuevo tutor:</h2>
        <button type="submit" class="btn btn-save">Guardar</button>
      </div>
      <h3>Tutor</h3>
      <div class="form__field-row half">
        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.name.$invalid && v$.form.name.$dirty">
              Por favor, introduce un nombre
            </p>
            <label class="form__field-label" for="#name">Nombre *</label>
            <input
              v-model="v$.form.name.$model"
              class="form__field-input"
            :class="{'form__input--invalid': v$.form.name.$invalid && v$.form.name.$dirty}"
              type="text"
              id="name"
              required
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('name')">
            <p v-for="(error, key) in errors.name" :key="key">{{ $t(error) }}</p>
          </div>
        </div>

        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.surnames.$invalid && v$.form.surnames.$dirty">
              Por favor, introduce los apellidos
            </p>
            <label class="form__field-label" for="#surnames">Apellidos *</label>
            <input
              v-model="v$.form.surnames.$model"
              class="form__field-input"
            :class="{'form__input--invalid': v$.form.surnames.$invalid && v$.form.surnames.$dirty}"
              type="text"
              id="surnames"
              required
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('surnames')">
            <p v-for="(error, key) in errors.surnames" :key="key">{{ $t(error) }}</p>
          </div>
        </div>
      </div>
      <div class="form__field-row half">
        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.email.$invalid && v$.form.email.$dirty">
              Por favor, introduce un email válido
            </p>
            <label class="form__field-label" for="#email">Email *</label>
            <input
              v-model="v$.form.email.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.email.$invalid && v$.form.email.$dirty}"
              type="email"
              id="email"
              required
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('email')">
            <p v-for="(error, key) in errors.email" :key="key">{{ $t(error) }}</p>
          </div>
        </div>
        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.emailSecondary.$invalid && v$.form.emailSecondary.$dirty">
              Por favor, introduzca un email válido
            </p>
            <label class="form__field-label" for="#emailSecondary">Segundo email</label>
            <input
              v-model="v$.form.emailSecondary.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.emailSecondary.$invalid && v$.form.emailSecondary.$dirty}"
              type="email"
              id="emailSecondary"
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('emailSecondary')">
            <p v-for="(error, key) in errors.emailSecondary" :key="key">{{ $t(error) }}</p>
          </div>
        </div>
      </div>
      <div class="form__field-row half">

        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.phone.$invalid && v$.form.phone.$dirty">
              Por favor, introduce un teléfono válido
            </p>
            <label class="form__field-label" for="#phone">Móvil *</label>
            <input
              v-model="v$.form.phone.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.phone.$invalid && v$.form.phone.$dirty}"
              type="number"
              id="phone"
              required
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('phone')">
            <p v-for="(error, key) in errors.phone" :key="key">{{ $t(error) }}</p>
          </div>
        </div>
        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.phoneSecondary.$invalid && v$.form.phoneSecondary.$dirty">
              Por favor, introduce un teléfono válido
            </p>
            <label class="form__field-label" for="#phoneSecondary">Segundo teléfono</label>
            <input
              v-model="v$.form.phoneSecondary.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.phoneSecondary.$invalid && v$.form.phoneSecondary.$dirty}"
              type="number"
              id="phoneSecondary"
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('phoneSecondary')">
            <p v-for="(error, key) in errors.phoneSecondary" :key="key">{{ $t(error) }}</p>
          </div>
        </div>
      </div>
      <h3>Alumno</h3>
      <div class="form__field-row half">
        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.studentName.$invalid && v$.form.studentName.$dirty">
              Por favor, introduce un nombre
            </p>
            <label class="form__field-label" for="#name">Nombre *</label>
            <input
                v-model="v$.form.studentName.$model"
                class="form__field-input"
                :class="{'form__input--invalid': v$.form.studentName.$invalid && v$.form.studentName.$dirty}"
                type="text"
                id="studentName"
                required
                placeholder=""
            >
          </div>
          <div class="errors" v-show="errors.hasOwnProperty('name')">
            <p v-for="(error, key) in errors.name" :key="key">{{ $t(error) }}</p>
          </div>
        </div>
        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.studentSurnames.$invalid && v$.form.studentSurnames.$dirty">
              Por favor, introduce los apellidos
            </p>
            <label class="form__field-label" for="#studentSurnames">Apellidos *</label>
            <input
                v-model="v$.form.studentSurnames.$model"
                class="form__field-input"
                :class="{'form__input--invalid': v$.form.studentSurnames.$invalid && v$.form.studentSurnames.$dirty}"
                type="text"
                id="studentSurnames"
                required
                placeholder=""
            >
          </div>
          <div class="errors" v-show="errors.hasOwnProperty('surnames')">
            <p v-for="(error, key) in errors.surnames" :key="key">{{ $t(error) }}</p>
          </div>
        </div>
      </div>
      <div class="form__field-wrapper">
        <div class="form__field">
          <label class="form__field-label">Fecha de nacimiento *</label>
          <datepicker
              v-model="v$.form.birthday.$model"
              inputFormat="dd/MM/yyyy"
              :style="useInputStyles"
              :locale="dateLocale"
          />
        </div>
      </div>
      <Switch v-model:checked="form.studentImageAuthorized" label="Autorizo a que se tomen y publiquen fotos del alumn@" />
      <div class="form__field">
        <label class="form__field-label" for="#studentAllergies">Alergias</label>
        <Multiselect
            class="multiselect"
            id="studentAllergies"
            v-model="v$.form.studentAllergies.$model"
            mode="tags"
            :searchable="true"
            :createTag="false"
            :options="formAllergies"
            :disabled="isLoading"
            :placeholder="isLoading ? 'Cargando elementos' : 'Seleciona alergias'"
        />
      </div>

      <h3>Personas autorizadas</h3>

      <div class="form__field-row person-authorized" v-for="(personAuthorized, index) in personsAuthorized" :key="index">
        <div class="form__field-wrapper full-name">
          <div class="form__field">
            <label class="form__field-label" for="#name">Nombre y apellidos *</label>
            <input
              v-model="personAuthorized.name"
              class="form__field-input"
              type="text"
              id="name"
              required
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('name')">
            <p v-for="(error, key) in errors.name" :key="key">{{ $t(error) }}</p>
          </div>
        </div>

        <div class="form__field-wrapper">
          <div class="form__field">
            <label class="form__field-label" for="#dni">DNI *</label>
            <input
              v-model="personAuthorized.dni"
              class="form__field-input"
              type="text"
              id="dni"
              required
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('dni')">
            <p v-for="(error, key) in errors.dni" :key="key">{{ $t(error) }}</p>
          </div>
        </div>

        <div class="form__field-wrapper">
          <div class="form__field">
            <label class="form__field-label" for="#phone">Teléfono *</label>
            <input
              v-model="personAuthorized.phone"
              class="form__field-input"
              type="text"
              id="phone"
              required
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('phone')">
            <p v-for="(error, key) in errors.phone" :key="key">{{ $t(error) }}</p>
          </div>
        </div>

        <div>
          <button type="submit" class="btn btn-add delete" @click="deletePersonAuthorized(index)">Borrar</button>
        </div>
      </div>


      <div class="new-element" @click="addPersonAuthorized">
        <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M30.7758 19.504H19.6398V30.384H11.3838V19.504H0.31175V11.696H11.3838V0.815998H19.6398V11.696H30.7758V19.504Z" fill="#00ADCE"/>
        </svg>
          Añadir persona autorizada
      </div>

      <h3>Observaciones del alumn@</h3>

      <div class="form__field-wrapper">
        <div class="form__field">
          <textarea
            v-model="form.observations"
            placeholder=""
            rows="8"
            no-resize
            class="form__field-input text-area"
          >
          </textarea>
        </div>
      </div>
    </form>
  </main>

</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, numeric, email } from '@vuelidate/validators'
import { mapActions, mapGetters } from 'vuex'
import Switch from '../../../components/Switch.vue'
import Multiselect from '@vueform/multiselect'
import { formatnewDate } from '@/commonMethods/methods'
import { ref } from 'vue'
import Datepicker from 'vue3-datepicker'
import es from 'date-fns/locale/es'

const useInputStyles = {
  padding: "12px 20px",
  border: "1px solid #00ADCE",
  borderRadius: "0",
  marginRight: "30px",
  width: "calc(100% - 30px)"
}

export default {
  name: 'TutorCreate',
  components: {
    Switch,
    Multiselect,
    Datepicker
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      form: {
        name: '',
        surnames: '',
        email: '',
        emailSecondary: '',
        phone: '',
        phoneSecondary: '',
        studentName: '',
        studentSurnames: '',
        birthday: ref(new Date()),
        studentImageAuthorized: false,
        studentAllergies: [],
        observations: ''
      },
      errors: {},
      isLoading: true,
      useInputStyles,
      personsAuthorized: [],
      dateLocale: es
    }
  },
  validations() {
    return {
      form: {
        name: {
          required
        },
        surnames: {
          required
        },
        email: {
          required,
          email
        },
        emailSecondary: {
          email
        },
        phone: {
          required,
          numeric
        },
        phoneSecondary: {
          numeric
        },
        studentName: {
          required
        },
        studentSurnames: {
          required
        },
        birthday: {
          required
        },
        studentImageAuthorized: false,
        studentAllergies: false
      }
    }
  },
  computed: {
    ...mapGetters('AdminCenter', ['getAllergies']),
    formAllergies() {
      return this.getAllergies.map(allergy => ({
        value: allergy.id,
        label: allergy.name
      }))
    }
  },
  methods: {
    ...mapActions('Tutors', ['registerTutor']),
    ...mapActions('AdminCenter', ['fetchAllergies']),
    addPersonAuthorized() {
      this.personsAuthorized.push({
        name: '',
        dni: '',
        phone: null
      })
    },
    deletePersonAuthorized(idx) {
      this.personsAuthorized.splice(idx, 1)
    },
    newTutorSubmit() {
      if(!this.v$.$invalid) {
        const infoCreateTutor = {
          name: this.form.name,
          surnames: this.form.surnames,
          email: this.form.email,
          emailSecondary: this.form.emailSecondary,
          phone:this.form.phone,
          phoneSecondary: this.form.phoneSecondary,
          students: [
            {
              name: this.form.studentName,
              surnames: this.form.studentSurnames,
              birthday: formatnewDate(this.form.birthday),
              imageAuthorized: this.form.studentImageAuthorized,
              allergies: this.form.studentAllergies,
              observations: this.form.observations,
              personsAuthorized: this.personsAuthorized
            }
          ]
        }
        this.registerTutor({centerId: this.id, infoCreateTutor})
        .then(() => {
          this.$toast.success('Datos guardados correctamente');
          setTimeout(() => {
            this.$router.push({ name: 'tutors' })
          }, 3000)
        })
        .catch(errors => {
          this.errors = errors
        })
      }
    },
    removeErrors() {
      this.errors = {}
    }
  },
  async mounted() {
    this.isLoading = true
    await this.fetchAllergies()
    this.isLoading = false
  }
}
</script>

<style scoped lang="scss">
.new-element {
  max-width: 280px;
}
.form__field-wrapper {
  &.full-name {
    width: 100% !important;
    margin-right: 0 !important;
  }
}
.form__field-row {
  &.person-authorized {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 20px;
    div {
      &:last-child {
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }
    .form__field-wrapper {
      width: calc(50% - 20px);
      @media (max-width: 910px) {
        width: 100%;
      }
    }
  }

}
</style>
