<template>
  <div class="main sessions">
    <Loading v-if="isLoading" />
    <div v-else>
      <h2 class="subtitle">Próximas sesiones en {{ activeCenter.name }}:</h2>
      <div class="form__field-wrapper">
        <div class="form__field form__field--datepicker sessions__datepicker">
          <label class="form__field-label">Fecha</label>
          <datepicker
            v-model="date"
            ref="date"
            inputFormat="dd/MM/yyyy"
            :style="useInputStyles"
            :locale="dateLocale"
            :clearable="true"
          />
        </div>
      </div>
      <div class="session-list" v-for="(session, groupIdx) in sessions" :key="groupIdx">
        <div class="session-wrapper">
          <div class="session-detail">
            <div class="session session-info">
              <span>{{dateLabel(session.dateTimestamp)}}</span>
              <p>{{session.time}}h</p>
            </div>
            <div class="group session-info">
              <span>Grupo</span>
              <p>{{session.groupName}} / {{ session.service }}</p>
            </div>
            <div class="tutor session-info">
              <span>Profesor</span>
              <p>{{getName(session)}}</p>
            </div>
          </div>

          <div class="student student-empty " v-if="session.students.length === 0">
            <p v-if="session.students.length === 0">No hay alumnos</p>
          </div>
          <div v-else class="student" :class="{'open': dropdownActiveIndex === groupIdx}" @click="viewListStudent(groupIdx)" >
            <p>{{dropdownActiveIndex === groupIdx ? 'Ocultar alumn@s' : 'Ver alumn@s' }}</p>
            <svg width="15" height="7" viewBox="0 0 15 7" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.5 7L0.138785 0.249999L14.8612 0.25L7.5 7Z" fill="#00ADCE"/>
            </svg>
          </div>
        </div>
        <div class="student-list" :class="{'show': dropdownActiveIndex === groupIdx}">
          <p>Alumn@s inscritos a este grupo:</p>
          <ul>
            <li
                v-for="(student, idx) in session.students"
                :key="idx"
                class="student__wrapper"
            >
              <div class="student__wrapper__container">
                <a @click="openModal(student)" href="#modal" class="openModal">{{ student.name }} {{ student.surnames }}</a>
                <p class="student__wrapper__info allergies">
                  <svg class="student__wrapper__info__image" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 24 24">
                    <path d="M11.415 12.393l1.868-2.289c.425-.544.224-.988-.055-2.165-.205-.871-.044-1.854.572-2.5 1.761-1.844 5.343-5.439 5.343-5.439l.472.37-3.693 4.728.79.617 3.87-4.59.479.373-3.558 4.835.79.618 3.885-4.58.443.347-3.538 4.85.791.617 3.693-4.728.433.338s-2.55 4.36-3.898 6.535c-.479.771-1.425 1.161-2.334 1.167-1.211.007-1.685-.089-2.117.464l-2.281 2.795c2.445 2.962 4.559 5.531 5.573 6.829.771.987.065 2.421-1.198 2.421-.42 0-.853-.171-1.167-.573l-8.36-10.072s-.926.719-1.944 1.518c-3.172-5.184-6.267-11.661-6.267-13.955 0-.128-.034-.924.732-.924.245 0 .493.116.674.344.509.642 5.415 6.513 10.002 12.049m-2.952 3.617l1.953 2.365-4.115 5.055c-.295.378-.736.576-1.182.576-1.198 0-1.991-1.402-1.189-2.428l4.533-5.568z" fill="#45B8AC"/></svg>
                    <strong v-if="student.allergies.length > 0">Alergias:</strong> <span :class="['student__wrapper__info__allergies', { 'student__wrapper__info__allergies--without': !student.allergies.length}]">{{ getAllergies(student.allergies) }}</span>
                </p>
                <p class="student__wrapper__info">
                  <svg class="student__wrapper__info__image" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 24 24">
                    <path d="M4.348 21.241l4.185-7.249 5.67 9.806c-.714.133-1.45.202-2.203.202-2.907 0-5.575-1.036-7.652-2.759zm18.97-5.247c-1.182 3.345-3.806 6.012-7.124 7.252l-4.187-7.252h11.311zm-14.786-6l-5.656 9.797c-1.793-2.097-2.876-4.819-2.876-7.791 0-.684.057-1.354.167-2.006h8.365zm12.583-5.795c1.798 2.098 2.885 4.824 2.885 7.801 0 .679-.057 1.345-.165 1.994h-8.373l5.653-9.795zm-11.305-3.999c.71-.131 1.442-.2 2.19-.2 2.903 0 5.566 1.033 7.642 2.751l-4.18 7.24-5.652-9.791zm2.19 7.794h-11.314c1.186-3.344 3.812-6.008 7.132-7.244l4.182 7.244z" fill="#45B8AC"/>
                  </svg>
                  <strong v-if="!student.imageAuthorized" class="student__wrapper__info__text">No</strong> <span :class="[ 'student__wrapper__info__allow-images', { 'student__wrapper__info__allow-images--authorized': student.imageAuthorized }]">permite fotografías</span>
                </p>
              </div>
              <div class="btn-options">
                <span @click="onMissing(groupIdx, idx)" class="missed student__wrapper__info__button" :class="{'attend': !student.isMissing}">{{getButtonLabel(student.isMissing, session.dateTimestamp, session.time)}}</span>
                <div class="multimedia-wrapper">
                  <span @click="handleTrackingMultimedia('sendPhoto', groupIdx, idx)" class="missed student__wrapper__info__button" :class="{'attend': student.sendPhoto}">Foto</span>
                  <span @click="handleTrackingMultimedia('sendVideo', groupIdx, idx)" class="missed student__wrapper__info__button" :class="{'attend': student.sendVideo}">Vídeo</span>
                </div>
              </div>
            </li>
            <li class="sessions__available-places">
              <p class="sessions__available-places__label">Plazas disponibles </p>
              <p class="sessions__available-places__number">{{session.availablePlaces}}</p>
            </li>
          </ul>
          <button
            :class="[
              'btn',
              'student__wrapper__button',
              {'student__wrapper__button--hidden': !areSessionDirty[groupIdx]}
            ]" @click="sendMissingSessions(groupIdx, session.date)">Guardar cambios</button>
          <div
            v-if="session.recoveryStudents.length"
            class="sessions__extra">
            <p class="sessions__extra__title">Alumn@s de recuperación</p>
            <ul>
              <li
                v-for="student in session.recoveryStudents"
                :key="`recover-student--${student.id}`"
                class="student__wrapper sessions__extra__student">
                <div class="student__wrapper__container sessions__extra__student__container">
                  <a href="#modal" class="openModal"  @click="openModal(student)">{{ student.name }} {{ student.surnames }}</a>
                  <p class="student__wrapper__info allergies">
                    <svg class="student__wrapper__info__image" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 24 24">
                      <path d="M11.415 12.393l1.868-2.289c.425-.544.224-.988-.055-2.165-.205-.871-.044-1.854.572-2.5 1.761-1.844 5.343-5.439 5.343-5.439l.472.37-3.693 4.728.79.617 3.87-4.59.479.373-3.558 4.835.79.618 3.885-4.58.443.347-3.538 4.85.791.617 3.693-4.728.433.338s-2.55 4.36-3.898 6.535c-.479.771-1.425 1.161-2.334 1.167-1.211.007-1.685-.089-2.117.464l-2.281 2.795c2.445 2.962 4.559 5.531 5.573 6.829.771.987.065 2.421-1.198 2.421-.42 0-.853-.171-1.167-.573l-8.36-10.072s-.926.719-1.944 1.518c-3.172-5.184-6.267-11.661-6.267-13.955 0-.128-.034-.924.732-.924.245 0 .493.116.674.344.509.642 5.415 6.513 10.002 12.049m-2.952 3.617l1.953 2.365-4.115 5.055c-.295.378-.736.576-1.182.576-1.198 0-1.991-1.402-1.189-2.428l4.533-5.568z" fill="#45B8AC"/></svg>
                    <strong v-if="student.allergies.length > 0">Alergias:</strong> <span :class="['student__wrapper__info__allergies', { 'student__wrapper__info__allergies--without': !student.allergies.length}]">{{ getAllergies(student.allergies) }}</span>
                  </p>
                  <p class="student__wrapper__info">
                    <svg class="student__wrapper__info__image" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 24 24">
                      <path d="M4.348 21.241l4.185-7.249 5.67 9.806c-.714.133-1.45.202-2.203.202-2.907 0-5.575-1.036-7.652-2.759zm18.97-5.247c-1.182 3.345-3.806 6.012-7.124 7.252l-4.187-7.252h11.311zm-14.786-6l-5.656 9.797c-1.793-2.097-2.876-4.819-2.876-7.791 0-.684.057-1.354.167-2.006h8.365zm12.583-5.795c1.798 2.098 2.885 4.824 2.885 7.801 0 .679-.057 1.345-.165 1.994h-8.373l5.653-9.795zm-11.305-3.999c.71-.131 1.442-.2 2.19-.2 2.903 0 5.566 1.033 7.642 2.751l-4.18 7.24-5.652-9.791zm2.19 7.794h-11.314c1.186-3.344 3.812-6.008 7.132-7.244l4.182 7.244z" fill="#45B8AC"/>
                    </svg>
                    <strong v-if="!student.imageAuthorized" class="student__wrapper__info__text">No</strong> <span :class="[ 'student__wrapper__info__allow-images', { 'student__wrapper__info__allow-images--authorized': student.imageAuthorized }]">permite fotografías</span>
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div
            v-if="session.trialStudents.length"
            class="sessions__extra">
            <p class="sessions__extra__title">Alumn@s de prueba</p>
            <ul>
              <li
                v-for="(trialStudent, idx) in session.trialStudents"
                :key="`trial-student--${idx}`"
                class="student__wrapper">
                <div class="student__wrapper__container">
                  <a href="#modal" class="openModal"  @click="openModal(trialStudent)">{{ trialStudent.name ? trialStudent.name : '' }}</a>
                  <div class="student__wrapper__info sessions__extra__info">
                    <p>
                      <strong>Estudiante:</strong>
                      {{trialStudent.student}}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

      </div>
      <div class="errors" v-for="(error, key) in errors" :key="key">
        <p>{{ $t(error) }}</p>
      </div>

      <div class="modal" id="modal">
        <a href="#" class="modal-bg"></a>
        <div class="modal-content">
          <a href="#" class="modal-exit" @click="closeModal">
            <svg viewBox="0 0 20 20">
              <path fill="#FA2A8A" d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"></path>
            </svg>
          </a>
          <h3 class="modal-title">Datos del alumn@ {{ modalStudent ? modalStudent.name : '' }}:</h3>
          <p class="modal-text" v-if="modalStudent && modalStudent.allergies && modalStudent.allergies.length === 1"><strong>Alergia:</strong> {{ groupSessionInfo.students ? getAllergiesModal(modalStudent.allergies) : '' }}</p>
          <p class="modal-text" v-if="modalStudent && modalStudent.allergies && modalStudent.allergies.length > 1"><strong>Alergias:</strong> {{ groupSessionInfo.students ? getAllergiesModal(modalStudent.allergies) : '' }}</p>
          <p class="modal-text"><strong>Teléfono de contacto:</strong> {{ modalStudent ? modalStudent.contactPhone : '' }}</p>
        </div>
      </div>

      <div v-if="sessions.length === 0" class="message__noData">
        <div class="message__noData-icon">
          <svg width="13" height="36" viewBox="0 0 13 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.880469 -2.38419e-06H12.1805L10.2805 22.25H2.73047L0.880469 -2.38419e-06ZM6.53047 35.45C4.8638 35.45 3.49714 34.95 2.43047 33.95C1.3638 32.9167 0.830469 31.6667 0.830469 30.2C0.830469 28.7 1.3638 27.4667 2.43047 26.5C3.49714 25.5333 4.8638 25.05 6.53047 25.05C8.19714 25.05 9.54714 25.5333 10.5805 26.5C11.6471 27.4667 12.1805 28.7 12.1805 30.2C12.1805 31.6667 11.6471 32.9167 10.5805 33.95C9.5138 34.95 8.1638 35.45 6.53047 35.45Z" fill="white"/>
          </svg>
        </div>
        <div class="message__noData-text">
          <p>No hay sesiones para mostrar</p>
          <p>Prueba seleccionando otro centro</p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { dateToSql, getEndDateTimestamp, getStartDateTimestamp, formatDateShort } from '../../commonMethods/methods'
import Loading from '../Loading.vue'
import Datepicker from 'vue3-datepicker'
import es from 'date-fns/locale/es'

const useInputStyles = {
  padding: "12px 20px",
  border: "1px solid #00ADCE",
  borderRadius: "0",
  marginRight: "30px",
  width: "calc(100% - 30px)"
}

export default {
  name: 'Groups',
  components: {
    Loading,
    Datepicker
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      open: true,
      isMissing: true,
      studentMissingSession: [],
      errors: null,
      dropdownActiveIndex: -1,
      isLoading: false,
      dateToSql,
      positionStudent: 0,
      modalStudent: null,
      groupSessionInfo: [],
      useInputStyles,
      dateLocale: es,
      date: null,
      sessions: [],
      mysqlDate: ''
    }
  },
  computed: {
    ...mapGetters('Admin', {
      returnActiveCenter: 'getActiveCenter',
      groupsSessions: 'getGroupsSessions',
      getMissingStudentsId: 'getMissingStudentsId'
    }),
    activeCenter() {
      return this.returnActiveCenter(this.id)
    },
    areSessionDirty() {
      return this.sessions.map((session, sessionIdx) => {
        return session.students.reduce((acc, student, studentIdx) => {
          if (acc || (student.isMissing !== this.groupsSessions[sessionIdx].students[studentIdx].isMissing) 
            || (student.sendPhoto !== this.groupsSessions[sessionIdx].students[studentIdx].sendPhoto) 
            || (student.sendVideo !== this.groupsSessions[sessionIdx].students[studentIdx].sendVideo)
          ) {
            return true
          }
          return acc
        }, false)
      })
    }
  },
  watch: {
    date(updateDate) {
      if (updateDate) {
        this.mysqlDate = this.dateToSql(updateDate)
      } else {
        this.mysqlDate = ''
      }
      this.fetchGroupsSessions({ centerId: this.id, date: this.mysqlDate })
    },
    groupsSessions: {
      immediate: true,
      handler(newSessions) {
        this.sessions = JSON.parse(JSON.stringify(newSessions))
      }
    }
  },
  methods: {
    ...mapActions('Admin', {
      fetchGroupsSessions: 'fetchGroupsSessions',
      missedSessions: 'groupMissedSessionsCreate',
      studentsIds: 'studentsIds'
    }),
    viewListStudent(idx) {
      if(idx === this.dropdownActiveIndex) {
        this.open = !this.open
        this.dropdownActiveIndex = -1;
      } else {
        this.open = !this.open
        this.dropdownActiveIndex = idx;
      }
      this.studentsIds(idx)
      this.studentMissingSession = [...this.getMissingStudentsId]
      this.groupSessionInfo = this.sessions[idx]
    },
    onMissing(groupIdx, studentIdx) {
      this.sessions[groupIdx].students[studentIdx].isMissing = !this.sessions[groupIdx].students[studentIdx].isMissing
    },
    handleTrackingMultimedia(type, groupIdx, studentIdx) {
      this.sessions[groupIdx].students[studentIdx][type] = !this.sessions[groupIdx].students[studentIdx][type]
    },
    sendMissingSessions(groupIdx, date){
      const info = {
        missingSession: {
          centerId: this.id,
          groupId: this.sessions[groupIdx].groupId,
          date
        },
        studentMissingSession: this.sessions[groupIdx].students.reduce((acc, student) => {
          if (student.isMissing) {
           acc.push(student.id)
          }
          return acc
        }, []),
        studentsForPhoto: this.sessions[groupIdx].students.reduce((acc, student) => {
          if (student.sendPhoto) {
           acc.push(student.id)
          }
          return acc
        }, []),
        studentsForVideo: this.sessions[groupIdx].students.reduce((acc, student) => {
          if (student.sendVideo) {
           acc.push(student.id)
          }
          return acc
        }, [])
      }
      this.missedSessions(info)
          .then(() => {
            this.$toast.success('Datos guardados correctamente')
          })
          .catch(errors => {
            this.errors = errors
          })
    },
    getName(session) {
      let name = ''
      if (session.tutor) {
        if (session.tutor.surnames) {
          name = `${session.tutor.name} ${session.tutor.surnames}`
        } else {
          name = session.tutor.name
        }
      } else {
        name = 'No se ha asignado profesor'
      }
      return name
    },
    openModal(student) {
      this.modalStudent = student
    },
    closeModal() {
      this.modalStudent = null
    },
    getAllergies(allergies) {
      if(allergies.length === 0) {
        return 'Sin alergias.'
      } else {
        return `${allergies.join(', ')}`
      }
    },
    getAllergiesModal(allergies) {
      if(allergies.length === 0) {
        return ''
      } else {
        return `${allergies.join(', ')}`
      }
    },
    getButtonLabel(isMissing, date, time) {
      const timestampDate = date * 1000
      const timePositions = time.split(':')
      const hours = parseInt(timePositions[0]) * 60 * 60 * 1000
      const minutes = parseInt(timePositions[1]) * 60 * 1000
      const _date = timestampDate + hours + minutes
      const now = new Date().getTime()

      return isMissing
        ? now < _date ? 'Faltará' : 'Ha faltado'
        : now < _date ? 'Asistirá' : 'Ha asistido'
    },
    dateLabel(date){
      const timestampSessionDate = date * 1000
      const today = new Date().getTime()
      const startToday = getStartDateTimestamp(today)
      const endToday = getEndDateTimestamp(today)
      const endTomorrow = endToday + (24 * 60 * 60 * 1000)
      return timestampSessionDate < startToday
      ? formatDateShort(date)
      : timestampSessionDate < endToday
        ? 'Hoy'
        : timestampSessionDate > endTomorrow
            ? formatDateShort(date)
            : 'Mañana'
    }
  },
  mounted() {
    this.isLoading = true
    this.fetchGroupsSessions({ centerId: this.id, date: this.mysqlDate })
    .then(() => {
      this.isLoading = false
    })
    .catch(() => {
      this.isLoading = true
    })
  }
}
</script>
<style lang="scss" scoped>

.session-list {
  background-color: rgba($newSoftBlue, 0.4);
  border: 1px solid $newSoftBlue;
  border-radius: 18px;
  margin-bottom: 30px;
}

.session-wrapper {
  background-color: $newSoftBlue;
  border-radius: 18px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 30px;
  .session-detail {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .session-info {
    span {
      font-weight: 700;
    }
    p {
      margin-top: 4px;
    }
  }
  .student {
    color: $newBlue;
    text-shadow:  1px 1px rgba(lighten($newSoftBlue, 50), 0.9);
    font-weight: 700;
    display: flex;
    align-items: center;
    align-self: flex-end;
    margin-top: 30px;
    cursor: pointer;
    &.student-empty {
      cursor: auto;
    }
    svg {
      margin-left: 12px;
      transition: transform .3s ease;
    }
    &.open {
      svg {
        transform: rotate(-180deg);
      }
    }
  }
  @include tablet {
    flex-direction: row;
    justify-content: space-between;
    gap: 20px 0;
    text-align: unset;
    padding: 20px 30px;
    .session-detail {
      flex-direction: row;
      gap: 20px 0;
    }
    .session-info {
      p {
        margin-bottom: 0;
      }
    }
    .session {
      min-width: 100px;
      margin-right: 20px;
    }
    .group {
      min-width: 200px;
      margin-right: 20px;
    }
    .tutor {
      min-width: 200px;
      margin-right: 20px;
    }
    .student {
      flex: 0 0 172px;
      margin-top: 0;
      @include desktop {
        justify-content: flex-end;
      }
    }
  }

  @include desktop {
    .session-detail {
      justify-content: space-between;
      width: 75%;
    }

    .session {
      margin-right: 0px;
    }
    .group {
      min-width: 300px;
      margin-right: 0px;
    }
    .tutor {
      margin-right: 0px;
    }
  }
}

.sessions {
  &__datepicker {
    @include desktop {
      max-width: 320px;
    }
  }

  &__available-places {
    align-items: center;
    flex-direction: column;
    justify-content: center !important;

    &__label {
      font-weight: 700;
      margin-bottom: 12px !important;
    }
  }

  &__extra {
    margin-top: 12px;

    &__title {
      font-weight: 700;
    }

    &__student {
      align-items: flex-start !important;
      display: flex;
      min-height: 100px;
      padding-top: 12px;


      &__container {
        width: 100% !important;
      }
    }

    &__info {
      font-size: 12px;
    }
  }
}

.student-list {
  padding: 30px;
  display: none;
  &.show {
    display: flex;
    flex-direction: column;
  }
  >p {
    font-weight: 700;
  }
  ul {
    width: 100%;
    margin-bottom: 30px;
    list-style: none;
    li {
      margin: 26px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      div {
        display: flex;
        flex-direction: column;
        width: 50%;
      }
      &:first-child {
        margin-top: 0;
      }
      p {
        margin: 0;
      }
      .allergies {
        font-size: 12px;
        font-weight: 700;
      }
      span {
        padding: 8px;
        border-radius: 6px;
        color: $white;
        font-weight: 700;
        width: 118px;
        @include disable-selection;
        &.attend {
          background-color: $bg-green;
          cursor: pointer;
          display: flex;
          @include tablet {
            max-width: 140px;
          }
          &::before {
            content: url('../../assets/attent.svg');
            width: 18px;
            height: 18px;
            display: inline-block;
            margin-right: 8px;
          }
        }
      }
      .missed {
        background-color: $newPink;
        cursor: pointer;
        &::before {
          content: url('../../assets/missed.svg');
          width: 18px;
          height: 18px;
          display: inline-block;
          margin-right: 8px;
        }
      }
      .test {
        background-color: $newSoftDark;
        text-transform: uppercase;
        text-align: center;
      }
    }
  }
  .btn {
    align-self: center;
  }
  @include desktop {
    ul {
      column-count: 2;
      column-rule: 1px solid $newLightBlue;
      column-gap: 150px;
      text-align: left;
      display: inline-block;
      li {
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;
      }
    }
    .btn {
      align-self: flex-end;
    }
  }
  .btn-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    .multimedia-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      @include tablet {
        flex-direction: row;
        gap: 10px;
        width: 100%;
      }
    }
  }
}

.student__wrapper {
  border-bottom: 1px solid rgba($newBlue, 0.4);
  flex-direction: column;
  padding-bottom: 16px;
  width: 100%;

  & &__container {
    width: 100%;
  }

  & &__info {
    display: flex;
    margin-top: 12px;
    width: 100%;

    &__allergies {
      color: $newDark;
      margin-left: 8px;
      padding: 0;
      width: 100%;

      &--without {
        margin-left: 0;
      }
    }

    &__image {
      height: 16px;
      margin-right: 8px;
      flex-shrink: 0;
      width: 16px;
    }

    &__text {
      font-size: 12px;
    }

    &__allow-images {
      color: $newDark;
      font-size: 12px;
      font-weight: 500;
      margin-left: 0.5em;
      padding: 0;
      width: 100%;

      &--authorized {
        margin-left: 0;
      }

      &--authorized:first-letter {
        text-transform: capitalize;
      }
    }

    &__button {
      margin-top: 16px;
    }
  }

  &__button {
    align-self: center !important;

    &--hidden {
      display: none;
    }
  }
}

.openModal {
  cursor: pointer;
  text-decoration: none;
  color: $black;
}

/* Modal container*/
.modal {
  background-color: transparent;
  visibility: hidden;
  opacity: 0;
  position: fixed;
  max-width: inherit;
  width: 100%;
  height: 100%;
  -webkit-transition: all .7s;
  transition: all .7s;

  /* Background modal*/
  .modal-bg:active, .modal-bg:hover, .modal-bg:visited, .modal-bg:link {
    text-decoration: none;
    visibility: hidden;
    opacity: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    -webkit-transition: all .7s;
    transition: all .7s;
    cursor: default;
  }

  /* Modal content or body*/
  .modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: white;
    height: auto;
    border-radius: 18px;
    text-align: center;
    z-index: 300;
    padding: 40px;

    p:last-child {
      margin-bottom: 0;
    }

    /* Modal is closed at lose target*/
    .modal-exit:link, .modal-exit:active, .modal-exit:visited, .modal-exit:hover {
      position: absolute;
      cursor: pointer;
      top: 15px;
      right: 15px;
      opacity: 1;
      svg {
        width: 30px;
        height: 30px;
      }
    }

    .modal-title {
      text-transform: uppercase;
      margin-bottom: 20px;
      margin-top: 0;
    }

  }

  @include tablet {
    .modal-content {
      width: 50%;
    }
  }

}

/* Modal container gets target and it is shown and background modal too*/
.modal:target, .modal:target .modal-bg {
  display: block;
  z-index: 100;
  opacity: 1;
  visibility: visible;
}

/* Background modal overlaps to container*/
.modal:target .modal-bg {
  z-index: 200;
}

@media (min-width: #{$small-desktop-width}) {
  .student__wrapper {
    flex-direction: row;

    & &__container {
      width: 50%;
    }
  }
}

</style>
