<template>
  <div class="save-payment-wrapper box">
    <div class="save-payment__ok">
      <img src="../assets/checkOK.png" alt="Check">
      <h2 class="subtitle">¡Todo listo!</h2>
      <p class="text">El método de pago ha sido añadido correctamente.</p>
      <router-link :to="{ name: 'parents-main-screen' }">
        <button class="btn">Volver al inicio</button>
      </router-link>

    </div>
  </div>
</template>

<script>
export default {
  name: 'SavePaymentOk.vue'
}
</script>

<style scoped lang="scss">
.save-payment-wrapper {
  padding: 40px 20px;

  .save-payment__ok {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .btn {
      margin-bottom: 70px;
    }
    .text {
      font-size: 20px;
      margin-bottom: 50px;
      text-align: center;
    }
  }
}
</style>