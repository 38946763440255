<template>
  <label
    class="container"
    :class="{'input--disabled': disabled}"
  >
    <input
      v-bind="$attrs"
      class="input"
      type="checkbox"
      :checked="checked"
      :disabled="disabled"
      @change="changeSwitch"
    />
    <span class="switch"></span>
    <span v-if="!link" class="label">{{ label }}</span>
    <span v-else class="label">{{ labelSegment[0] }}<a :href="link" target="_blank">{{ linkSegment }}</a>{{ labelSegment[1] }}</span>
  </label>
</template>

<script>
export default {
  name: "Switch",
  props: {
    link: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    handleChecked: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    labelSegment() {
      const _pieces = this.label.split('%');
      return [_pieces[0], _pieces[2]]
    },
    linkSegment() {
      const _pieces = this.label.split('%');
      return _pieces[1]
    }
  },
  methods: {
    changeSwitch(e) {
      this.$emit('onChangeSwitch')
      this.$emit('update:checked', e.target.checked)
    }
  }
};
</script>

<style scoped lang="scss">
.container {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.label {
  margin-left: 12px;
  color: $bg-blue;
  /* Show an ellipsis if the text takes more than one line */
  overflow: hidden;
  text-overflow: ellipsis;
  //white-space: nowrap;
  a{
    text-decoration: none;
  }
}

/* Visually hide the checkbox input */
.input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;

  &--disabled {
    cursor: auto;
  }
}

.switch {
  --switch-container-width: 50px;
  --switch-size: calc(var(--switch-container-width) / 2);

  --light-gray: #e2e8f0;
  --gray: #cbd5e0;
  --dark-gray: #a0aec0;
  --teal: #0FCBAD;
  --dark-teal: #319795;

  /* Vertically center the inner circle */
  display: flex;
  align-items: center;
  position: relative;
  height: var(--switch-size);
  flex-basis: var(--switch-container-width);
  /* Make the container element rounded */
  border-radius: var(--switch-size);
  background-color: var(--light-gray);
  /* In case the label gets really long, the toggle shouldn't shrink. */
  flex-shrink: 0;

  transition: background-color 0.25s ease-in-out;
}

.switch::before {
  content: "";
  position: absolute;
  /* Move a little bit the inner circle to the right */
  left: 1px;
  height: calc(var(--switch-size) - 4px);
  width: calc(var(--switch-size) - 4px);
  /* Make the inner circle fully rounded */
  border-radius: 9999px;
  background-color: white;
  border: 2px solid var(--light-gray);

  transition: transform 0.375s ease-in-out;
}

.input:checked + .switch {
  background-color: var(--teal);
}

.input:checked + .switch::before {
  border-color: var(--teal);
  /* Move the inner circle to the right */
  transform: translateX(
    calc(var(--switch-container-width) - var(--switch-size))
  );
}

.input:focus + .switch::before {
  border-color: var(--gray);
}

.input:focus:checked + .switch::before {
  border-color: var(--dark-teal);
}

.input:disabled + .switch {
  background-color: var(--gray);
}

.input:disabled + .switch::before {
  background-color: var(--dark-gray);
  border-color: var(--dark-gray);
}
</style>
