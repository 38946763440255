<template>
  <main class="main reports">
    <h2 class="subtitle">Métodos de pago:</h2>
    <p>Estos son los métodos de pago que tienes guardados para tus pagos en nuestras Casitas.</p>
    <p>Puedes actualizar tus métodos de pago en cualquier momento y eliminarlos siempre que no estén vinculados a ningún servicio activo.</p>
    <template v-if="paymentInfo.length && !isLoading">
      <table class="admin__table">
        <thead class="admin__table__head">
        <tr>
          <th
            v-for="(column, idx) in tableConfig"
            :key="`columnHeader--${idx}`"
            class="admin__table__head__cell">
            <span>{{column.label}}</span>
          </th>
        </tr>
        </thead>
        <tbody class="admin__table__body">
        <tr
          v-for="(payment, idx) in paymentInfo"
          :key="`report--${idx}`">
          <td
            v-for="(column, idx2) in tableConfig"
            :key="`columnHeader--${idx2}`"
            class="admin__table__body__cell"
          >
          <span class="admin__table__body__cell__header">{{ column.label }}</span>
          <span v-if="column.key === 'expiry-date'" class="text-value">{{payment.expiryDate}}</span>
          <span v-if="column.key === 'centre'" class="text-value">{{payment.center.name}}</span>
          <template v-if="column.key === 'change'">
            <span class="admin__table__body__cell__header"></span>
            <button @click="openCardModal(payment)" class="btn">Cambiar</button>
          </template>
          <template :class="{'hover-text': !payment.canDelete}" data-hover="Actualmente tienes servicios activos vinculados a esta tarjeta." v-if="column.key === 'delete'">
            <span class="admin__table__body__cell__header"></span>
            <button class="btn" @click="deletePaymentMethod(payment.center.id, payment.canDelete)" :class="{ 'disabled-btn-delete': !payment.canDelete }">Borrar</button>
          </template>
          </td>
        </tr>
        </tbody>
      </table>
    </template>
    <Loading v-else-if="isLoading" />
    <div v-else class="message__noData box">
      <div class="message__noData-icon">
        <svg width="13" height="36" viewBox="0 0 13 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.880469 -2.38419e-06H12.1805L10.2805 22.25H2.73047L0.880469 -2.38419e-06ZM6.53047 35.45C4.8638 35.45 3.49714 34.95 2.43047 33.95C1.3638 32.9167 0.830469 31.6667 0.830469 30.2C0.830469 28.7 1.3638 27.4667 2.43047 26.5C3.49714 25.5333 4.8638 25.05 6.53047 25.05C8.19714 25.05 9.54714 25.5333 10.5805 26.5C11.6471 27.4667 12.1805 28.7 12.1805 30.2C12.1805 31.6667 11.6471 32.9167 10.5805 33.95C9.5138 34.95 8.1638 35.45 6.53047 35.45Z" fill="white"/>
        </svg>
      </div>
      <div class="message__noData-text">
        <p>{{errorLabel}}</p>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions } from 'vuex'
import { apiClient } from '@/services/ApiClient'
import Loading from './Loading.vue'
export default {
  components: {
    Loading
  },
  data() {
    return {
      isModalOpen: false,
      isLoading: false,
      tableConfig: [
        {
          label: 'Caducidad',
          key: 'expiry-date'
        },
        {
          label: 'Centro',
          key: 'centre'
        },
        {
          label: '',
          key: 'change',
        },
        {
          label: '',
          key: 'delete'
        }
      ],
      paymentInfo: [],
      paymentMethods: [],
      errorLabel: ''
    }
  },
  methods: {
    ...mapActions('User', ['paymentMethodsByTutor', 'handlerPaymentMethod']),
    ...mapActions('Students', ['redsysPaymentMethods']),
    openCardModal(payment) {
      this.handlerPaymentMethod({ value: true, payment })
    },
    async deletePaymentMethod(id, canDelete) {
      if (canDelete) {
        this.isLoading = true
        try {
          await apiClient.delete(`/tutor/payment-method/${id}`)
          const { data } = await apiClient.get('/tutor/payment-method')
          this.paymentInfo = data
          setTimeout(() => {
            this.$toast.success('Tarjeta borrada correctamente')
          }, 2000);
          this.isLoading = false
        } catch (error) {
          this.isLoading = false
          this.$toast.error('Lo sentimos, no hemos podido borrar la tarjeta, inténtelo de nuevo.')
        }
      } else {
        return false
      }
    }
  },
  async mounted() {
    this.isLoading = true
    try {
      const { data } =  await apiClient.get('/tutor/payment-method')
      this.paymentInfo = data
      if (this.paymentInfo.length) {
        this.errorLabel = 'No tienes ningún método de pago'
      }
    } catch (error) {
      this.errorLabel = 'Ha ocurrido un error, inténtelo de nuevo'
    } finally {
      this.isLoading = false
    }
  }
}
</script>

<style scoped lang="scss">
p:nth-child(3) {
  margin-bottom: 30px;
}
  .modify-svg {
    width: 24px;
  }
  .text-value {
    font-weight: 100;
  }
  svg {
    cursor: pointer;
  }
  .disabled-btn-delete {
    cursor: not-allowed;
  }
  .hover-text {
    position: relative;
  }
  .hover-text:before {
    content: attr(data-hover);
    visibility: hidden;
    opacity: 0;
    width: 250px;
    line-height: 20px;
    background-color: #f2f2f2;
    color: $newBlue;
    text-align: center;
    font-size: 14px;
    font-weight: 100;
    border-radius: 5px;
    padding: 6px 10px;
    transition: opacity .5 ease-in-out;
    position: absolute;
    z-index: 1;
    right: 20px;
    top: -30px;
  }
  .hover-text:hover:before {
    opacity: 1;
    visibility: visible;
  }
</style>