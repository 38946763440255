<template>
  <main class="main">
    <div v-if="!update">
      <h2 class="subtitle">Edita los datos personales del alumn@</h2>
      <form action class="form" @submit.prevent="editStudentData">
        <div class="form__field-row">
          <div class="form__field-wrapper">
            <p class="input-errors" v-if="v$.form.name.$invalid && v$.form.name.$dirty">
              Por favor, introduce un nombre
            </p>
            <div class="form__field">
              <label class="form__field-label" for="#name">Nombre *</label>
              <input
                v-model="v$.form.name.$model"
                class="form__field-input"
                :class="{'form__input--invalid': v$.form.name.$invalid && v$.form.name.$dirty}"
                type="text"
                id="name"
                required
              >
            </div>
          </div>
          <div class="form__field-wrapper">
            <div class="form__field">
              <p class="input-errors" v-if="v$.form.surnames.$invalid && v$.form.surnames.$dirty">
                Por favor, introduce los apellidos
              </p>
              <label class="form__field-label" for="#surnames">Apellidos *</label>
              <input
                v-model="v$.form.surnames.$model"
                class="form__field-input"
                :class="{'form__input--invalid': v$.form.surnames.$invalid && v$.form.surnames.$dirty}"
                type="text"
                id="surnames"
                required
                placeholder=""
              >
            </div>
            <div class="errors" v-show="errors.hasOwnProperty('surnames')">
              <p v-for="(error, key) in errors.surnames" :key="key">{{ $t(error) }}</p>
            </div>
          </div>
        </div>
        <div class="form__field-wrapper">
          <div class="form__field">
            <label class="form__field-label">Fecha de nacimiento *</label>
            <datepicker
              v-model="v$.form.birthday.$model"
              inputFormat="dd/MM/yyyy"
              :style="useInputStyles"
              :locale="dateLocale"
            />
          </div>
        </div>
        <Switch
          v-model:checked="form.imageAuthorized"
          :disabled="activeStudent.imageAuthorized"
          label="Todos los tutores legales autorizan a que se publiquen fotos del alumn@. En caso de que haya más de un progenitor o tutor legal todos están de acuerdo."
          @onChangeSwitch="openModal"
        />
        <h3>Alergias e intolerancias</h3>
        <Multiselect
          class="multiselect"
          v-model="v$.form.allergies.$model"
          mode="tags"
          :searchable="true"
          :createTag="false"
          :options="formAllergies"
          :disabled="isLoading"
          :placeholder="isLoading ? 'Cargando elementos' : 'Seleciona alergias'"
        />
        <h3>Personas autorizadas</h3>
        <div class="form__field-row person-authorized" v-for="(personAuthorized, index) in personsAuthorized" :key="index">
          <div class="form__field-wrapper full-name">
            <div class="form__field">
              <label class="form__field-label" for="#name">Nombre y apellidos *</label>
              <input
                v-model="personAuthorized.name"
                class="form__field-input"
                type="text"
                id="name"
                required
                @click="removeErrors"
              >
            </div>
            <div class="errors errors-input" v-show="errors.hasOwnProperty('name')">
              <p v-for="(error, key) in errors.name" :key="key">{{ $t(error) }}</p>
            </div>
          </div>
          <div class="form__field-wrapper">
            <div class="form__field">
              <label class="form__field-label" for="#dni">DNI *</label>
              <input
                v-model="personAuthorized.dni"
                class="form__field-input"
                type="text"
                id="dni"
                required
                @click="removeErrors"
              >
            </div>
            <div class="errors errors-input" v-show="errors.hasOwnProperty('dni')">
              <p v-for="(error, key) in errors.dni" :key="key">{{ $t(error) }}</p>
            </div>
          </div>
          <div class="form__field-wrapper">
            <div class="form__field">
              <label class="form__field-label" for="#phone">Teléfono *</label>
              <input
                v-model="personAuthorized.phone"
                class="form__field-input"
                type="text"
                id="phone"
                required
                @click="removeErrors"
              >
            </div>
            <div class="errors errors-input" v-show="errors.hasOwnProperty('phone')">
              <p v-for="(error, key) in errors.phone" :key="key">{{ $t(error) }}</p>
            </div>
          </div>
          <div>
            <button type="submit" class="btn btn-add delete" @click="deletePersonAuthorized(index)">Borrar</button>
          </div>
        </div>
        <div class="new-element" @click="addPersonAuthorized">
          <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.7758 19.504H19.6398V30.384H11.3838V19.504H0.31175V11.696H11.3838V0.815998H19.6398V11.696H30.7758V19.504Z" fill="#00ADCE"/>
          </svg>
            Añadir persona autorizada
        </div>
        <h3>Observaciones del alumn@</h3>
        <div class="form__field-wrapper">
          <div class="form__field">
            <textarea
              v-model="form.observations"
              placeholder=""
              rows="8"
              no-resize
              class="form__field-input text-area"
            >
            </textarea>
          </div>
        </div>
        <button class="btn">Guardar datos</button>
      </form>
      <div class="errors" v-for="(error, key) in errors" :key="key">
        <p>{{ $t(error) }}</p>
      </div>
    </div>
    <div v-if="isModalOpen" class="modal__wrapper">
      <div class="legal modal">
        <img src="../assets/missed_newBlue.svg"
          alt="close-modal"
          class="modal__close-image"
          @click="closeModal">
        <p class="modal__title"><strong>AUTORIZACIÓN PARA LA PUBLICACIÓN DE IMÁGENES DE LOS ALUMNOS:</strong></p>
        <div class="text">
          <p>
            <small>Durante la realización de muchas de las actividades de La Casita de Inglés y para que
          podáis participar en ella aún sin estar presentes, os pedimos autorización para que vuestros hijos puedan
          aparecer en imágenes / vídeos durante la realización de dichas actividades. La Casita de Inglés utilizaría
          estas imágenes o vídeos para publicarlos en distintos medios de Comunicación Digital que utilizamos: vídeos de
          YouTube, página Web de La Casita de Inglés, Blog, Instagram, etc. Dado que el derecho a la propia imagen está
          reconocido en el artículo 18 de la Constitución y regulado por la Ley 1/1982, de 5 de mayo sobre el derecho al
          honor, a la intimidad personal y familiar y a la propia imagen y en la Ley 15/1999, de 13 de Diciembre, sobre
          la Protección de Datos de Carácter Personal, os solicitamos la autorización pertinente. Un ejemplo del tipo de
          imágenes que publicamos lo puedes ver en este enlace: </small>
          </p>
          <div class="banner">
            <a href="http://www.instagram.com/casitadeingles" target="__blank">
              <img alt="@casitadeingles" class="banner-icon" width="32" height="32" src="../assets/insta.png">
              <span class="banner-text">@casitadeingles</span>
            </a>
          </div>
          <p>
            <small>
              <strong>Adicionalmente, es importante informaros que, en caso de no otorgar autorización para la
            publicación de imágenes de vuestros hijos, estos no podrán participar en algunas actividades grupales, como
            por ejemplo los videoclips navideños. Asimismo, en estas circunstancias, el número de imágenes y vídeos que
            podréis recibir será significativamente menor, debido a las limitaciones de tiempo y recursos para producir
            material individualizado.</strong>
            </small>
          </p>
        </div>
        <button
          class="btn modal__button"
          type="text"
          @click="acceptConditions">Aceptar condiciones
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import Multiselect from '@vueform/multiselect'
import Switch from './Switch.vue'
import { mapActions, mapGetters } from 'vuex'
import { formatnewDate } from '@/commonMethods/methods'
import { ref } from 'vue'
import Datepicker from 'vue3-datepicker'
import es from 'date-fns/locale/es'

const useInputStyles = {
  padding: "12px 20px",
  border: "1px solid #00ADCE",
  borderRadius: "0",
  marginRight: "30px",
  width: "calc(100% - 30px)"
}

export default {
  name: 'StudentData',
  components: {
    Multiselect,
    Switch,
    Datepicker
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      form: {
        name: '',
        surnames: '',
        birthday: ref(new Date()),
        imageAuthorized: false,
        allergies: [],
        observations: ''
      },
      isLoading: true,
      errors: {},
      activeStudentId: null,
      useInputStyles,
      personsAuthorized: [],
      dateLocale: es,
      isModalOpen: false
    }
  },
  validations() {
    return {
      form: {
        name: {
          required
        },
        surnames: {
          required
        },
        birthday: {
          required
        },
        imageAuthorized: false,
        allergies: false
      },
    }
  },
  watch: {
    activeStudent: {
      immediate: true,
      handler (newActiveStudent) {
        if(newActiveStudent) {
          this.form = {
            ...newActiveStudent,
            birthday: new Date(`${newActiveStudent.monthOfBirth}/${newActiveStudent.dayOfBirth}/${newActiveStudent.year}`),
            allergies: newActiveStudent.allergies ? newActiveStudent.allergies.map(allergy => allergy.id) : []
          }
          this.personsAuthorized = []
          newActiveStudent.personsAuthorized.forEach(personAuthorized => {
            this.personsAuthorized.push({
              name: personAuthorized.name,
              dni: personAuthorized.dni,
              phone: personAuthorized.phone
            })
          })
        }
      }
    },
    id: {
      immediate: true,
      handler() {
        this.getStudentInfo()
      }
    }
  },
  computed: {
    ...mapGetters('Students', {
      getActiveStudent: 'getActiveStudent',
      getStudentInfoByID: 'getStudentInfoByID',
      allergies: 'getAllergies'
    }),
    activeStudent() {
      return this.getActiveStudent(this.id)
    },
    formAllergies() {
      return this.allergies.map(allergy => ({
        value: allergy.id,
        label: allergy.name
      }))
    }
  },
  methods: {
    ...mapActions('Students', ['setStudentsInfo', 'fetchStudentInfo', 'fetchAllergies']),
    getAllergies() {
      return this.$store.getters['Students/getAllergies']
    },
    getStudentInfo() {
      return this.fetchStudentInfo(this.id)
    },
    addPersonAuthorized() {
      this.personsAuthorized.push({
        name: '',
        dni: '',
        phone: null
      })
    },
    deletePersonAuthorized(idx) {
      this.personsAuthorized.splice(idx, 1)
    },
    editStudentData() {
      if(!this.v$.$invalid) {
        const objStudentInfo = {
          name: this.form.name,
          surnames: this.form.surnames,
          birthday: formatnewDate(this.form.birthday),
          imageAuthorized: this.form.imageAuthorized,
          allergies: this.form.allergies,
          observations: this.form.observations,
          personsAuthorized: this.personsAuthorized
        }
        this.setStudentsInfo({
          id: this.id,
          profile: objStudentInfo
        })
        .then(() => {
          this.$toast.success('Datos guardados correctamente');
        })
        .catch (errors => {
          this.errors = errors
        })
      }
    },
    openModal() {
      if (!this.form.imageAuthorized) {
        this.isModalOpen = true
        this.changeScroll(true)
      }
    },
    closeModal() {
      this.isModalOpen = false
      this.form.imageAuthorized = false
      this.changeScroll(false)
    },
    acceptConditions() {
      this.isModalOpen = false
      this.form.imageAuthorize = true
      this.changeScroll(false)
    },
    changeScroll(status) {
      const body = document.querySelector('body')
      if (status) {
        body.classList.add('scroll-blocked')
      } else {
        body.classList.remove('scroll-blocked')
      }
    }
  },
  async mounted() {
    this.isLoading = true
    await this.fetchAllergies()
    this.isLoading = false
  }
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped lang="scss">
.main {
  position: relative;
}
.is-update {
  text-align: center;
}
.form {
  display: block;
  border-radius: 4px;
  max-width: 1200px;
  &__field-row {
    display: flex;
    flex-wrap: wrap;
    gap: 0 20px;
    width: 100%;
    .form__field-wrapper {
      width: 100%;
      .form__field {
        display: flex;
        flex-direction: column;
      }
      &.form__field-checkbox {
        display: inline-block;
        width: 100%;
        input {
          margin-right: 10px;
        }
        label {
          color: $bg-blue;
        }
      }
    }
  }
  @include tablet {
    &__field-wrapper {
      .input-errors {
        max-width: 300px;
      }
    }
    &__field {
      .form-input {
        min-width: 300px;
      }
    }
    &__field-row {
      justify-content: space-between;
      .form__field-wrapper {
        width: 48%;
      }
    }
  }
}
.new-element {
  max-width: 280px;
}
.form__field-wrapper {
  &.full-name {
    width: 100% !important;
    margin-right: 0 !important;
  }
}
.form__field-row {
  &.person-authorized {
    div {
      &:last-child {
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }
  }
  @media (max-width: 974px) {
    &.person-authorized {
      .form__field-wrapper {
        width: 100%;
      }
    }
  }
}
</style>
