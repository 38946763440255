<template>
  <div class="main">
    <h2 class="subtitle">{{ title }}</h2>
    <form class="form admin__form">
      <div class="form__field-row half">
        <div class="form__field-wrapper">
          <div class="form__field">
            <label class="form__field-label" for="#dateStart">Fecha de festivo</label>
            <datepicker
              v-model="form.date"
              inputFormat="dd/MM/yyyy"
              class="datepicker-wrapper"
              :style="useInputStyles"
              :locale="dateLocale"
              />
          </div>
        </div>
        <div class="form__field-wrapper">
          <div class="form__field">
            <span class="form__field-label" for="#dateStart">Tipo de festivo</span>
            <div class="options">
              <label for="yes">
                <input v-model="form.isRecoverable" type="radio" name="yes" :value="true" id="yes">
                <p>Recuperable</p>
              </label>
              <label for="no">
                <input v-model="form.isRecoverable" type="radio" name="no" :value="false" id="no">
                <p>No recuperable</p>
              </label>
            </div>
          </div>
        </div>
      </div>
      <button
      type="button"
      class="btn btn-save"
      @click="submitForm">Guardar
    </button>
    </form>
  </div>
</template>

<script>
import Datepicker from 'vue3-datepicker'
import es from 'date-fns/locale/es'
import { ref } from 'vue'
import { utcToTimestamp } from '@/commonMethods/methods'
import { apiClient } from '../../services/ApiClient'

const useInputStyles = {
    padding: "14px 20px",
    border: "1px solid #45B8AC",
    borderRadius: "0",
    marginRight: "30px",
    width: "calc(100% - 30px)"
  }

export default {
  name: 'AllergyCreate.vue',
  components: {
    Datepicker
  },

  data() {
    return {
      form: {
        date: ref(new Date()),
        isRecoverable: null
      },
      dateLocale: es,
      useInputStyles,
      utcToTimestamp,
      title: 'Nuevo festivo:'
    }
  },

  methods: {
    async submitForm() {
      const date = new Date(this.form.date)
      this.form = {
        ...this.form,
        date: utcToTimestamp(date),
      }
      try {
        if (this.$route.params.id) {
          await apiClient.patch(`admin/public-holidays/${this.$route.params.id}`, this.form)
          this.$toast.success('Festivo modificado correctamente.')
        } else {
          await apiClient.post('/admin/public-holidays', this.form)
          this.$toast.success('Festivo creado correctamente.')
        } 
        this.$router.push({ name: 'holidays' })
      } catch (error) {
        this.$toast.error('Debes seleccionar una fecha válida.')
      }
    }
  },
  
  async mounted() {
    if (this.$route.params.id) {
      this.title = 'Editar festivo:'
      const { data } = await apiClient.get(`/admin/public-holidays/${this.$route.params.id}`)
      const date = new Date(data.date) * 1000
      this.form = {
        date: ref(date),
        isRecoverable: data.isRecoverable
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .half {
    @include desktop {
      display: flex;
      align-items: center;
    }
  }
  .form {
    .options {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border: 1px solid $newBlue;
      padding: 14px 20px;
      label {
        display: flex;
        align-items: flex-start;
        gap: 4px;
      }
      @include desktop {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 14px;
      }
      p {
        margin: 0;
        display: inline-block;
        // width: 200px;
        text-align: start;
      }
    }
    @include desktop {
      &__field {
        &-row {
          &.half {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;
            .form__field-wrapper {
              display: inline-block;
              vertical-align: top;
              width: calc(50% - 20px);
              &:nth-child(2n-1) {
                margin-right: 10px;
              }
              &:nth-child(2n) {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }
</style>
