<template>
  <main clas="=main extra-payment">
    <h2 class="subtitle">Nuevo pago:</h2>
    <Loading v-if="isLoading" />
    <form v-else action class="form" @submit.prevent="sendManualPayment">
      <div class="form__field-row half">
        <div class="form__field-wrapper">
          <div class="form__field">
            <label class="form__field-label" for="#name">Importe *</label>
            <input
                type="number"
                class="form__field-input extra-payment__input extra-payment__price"
                v-model="v$.form.price.$model"
                id="price"
                placeholder="0,00"
                step="0.01"
                pattern="([0-9]{1,3}).([0-9]{1,2})"
                @blur="onPriceChange($event.target.value)"
                required
            >
          </div>
          <div class="errors errors-input" v-show="v$.form.price.$invalid && v$.form.price.$dirty">
            <p>Por favor, introduce un importe</p>
          </div>
        </div>
        <div class="form__field-wrapper">
          <div class="form__field">
            <label class="form__field-label" for="#subject">Concepto *</label>
            <input
                v-model="v$.form.subject.$model"
                class="form__field-input extra-payment__input"
                type="text"
                id="subject"
                required
            >
          </div>
          <div class="errors errors-input" v-show="v$.form.subject.$invalid && v$.form.subject.$dirty">
            <p>Por favor, introduce un concepto</p>
          </div>
        </div>
      </div>
      <div class="form__field-row half">
        <div class="form__field-wrapper">
          <Select2
              class="extra-payment__select"
              v-model="user"
              :options="tutorsOptions"
              :settings="{  placeholder: 'Selecciona un usuario', width: '100%' }">
          </Select2>

          <div class="errors errors-input" v-show="!user && showUserError">
            <p>Por favor, selecciona un usuario</p>
          </div>
        </div>
        <div class="form__field-wrapper">
          <label class="form__field-label" for="#typeOfPayment">Método de pago *</label>
          <div class="form__field-input extra-payment__radio">
            <div
              v-for="type in typesOfPayments"
              :key="type.id">
              <label class="form__field-label" for="#card">{{type.label}}</label>
              <input
                  type="radio"
                  id="isCardPayment"
                  :value="type.isPaymentCard"
                  name="typeOfPayment"
                  :checked="type.isDafault"
                  @change="selectedTypeOfPayment($event)">
            </div>
          </div>


        </div>
      </div>
      <div class="extra-payment__button">
        <button class="btn btn-save">Enviar pago</button>
      </div>

    </form>
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import Loading from '@/components/Loading'
import Select2 from 'vue3-select2-component'

export default {
  name: 'PaymentExtra.vue',
  setup () {
    return { v$: useVuelidate() }
  },
  components: {
    Loading,
    Select2
  },
  data() {
    return {
      form: {
        price: '',
        subject: ''
      },
      errors: [],
      typesOfPayments: [
        {
          id: 1,
          label: 'Tarjeta',
          isDafault: true,
          isPaymentCard: true
        },
        {
          id: 2,
          label: 'Pago manual',
          isPaymentCard: false
        }
      ],
      isPaymentCard: true,
      user: null,
      showUserError: false,
      isLoading: false,
      tutorsOptions: []
    }
  },
  validations() {
    return {
      form: {
        price: {
          required
        },
        subject: {
          required
        }
      }
    }
  },
  computed: {
    ...mapGetters('Admin', {
      tutors: 'getTutorRedsysList'
    }),
  },
  watch: {
    tutors: {
      immediate: true,
      handler(newTutors) {
        if (newTutors) {
          this.tutorsOptions = newTutors.map(_tutor => ({
            id: _tutor.tutor.id,
            text: `${_tutor.tutor.name} ${_tutor.tutor.surnames}`
          }))
        }
      }
    }
  },
  methods: {
    ...mapActions('Admin', ['fetchTutorRedsysList', 'chargeNewPayment']),
    onPriceChange (value) {
      this.form.price = parseFloat(value).toFixed(2)
    },
    async sendManualPayment() {
      if (!this.v$.$invalid && this.user) {
        const newPayment = {
          ...this.form,
          price: parseFloat(this.form.price),
          isPaymentCard: this.isPaymentCard
        }
        try {
          this.isLoading = true
          await this.chargeNewPayment({ centerId:  this.$route.params.id, tutorId: this.user, payload: newPayment })
          this.$toast.success('Pago realizado correctamente')
          setTimeout(() => {
            this.$router.push({ name: 'payments' })
          }, 3000)
        } catch (error) {
          this.$toast.error('Lo sentimos, no hemos podido realizar el nuevo pago, inténtelo de nuevo')
        } finally {
          this.isLoading = false
        }
      } else {
        this.showUserError = true
      }
    },
    selectedTypeOfPayment(event) {
      this.isPaymentCard = event.target.value === 'true'
    }
  },
  async mounted() {
    this.isLoading = true
    try {
      await this.fetchTutorRedsysList(this.$route.params.id)
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoading = false
    }
  }
}
</script>

<style scoped lang="scss">

.extra-payment {
  &__input {
    color: $newDark;
    &::placeholder {
      color: $newDark;
    }
  }

  &__price {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
  }

  &__radio {
    background-color: $white;
    display: flex;
    flex-direction: column;
  }

  &__select {
    width: 100%;

    @include tablet {
      margin-top: 27px;
    }

    :deep {

      .select2-container--default {
        color: red;
        .select2-selection--single {
          border: 1px solid $newBlue;
          border-radius: 0;
          height: 54px;
          padding: 12px 20px;

          .select2-selection__arrow {
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }

  &__button {
    display: flex;
    justify-content: center;
  }
}

</style>
