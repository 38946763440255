<template>
<div class="enrollment-confirmationOK-wrapper box">
  <div class="enrollment-confirmationOK">
    <img src="../assets/checkOK.png" alt="Check">
    <h2 class="subtitle">¡Todo listo!</h2>
    <p class="text">{{ paymentInfo.studentName }} ya está inscrito en {{ paymentInfo.service }}</p>
    <router-link :to="{ name: 'parents-main-screen' }">
      <button class="btn">Volver al inicio</button>
    </router-link>
    <div class="info-wrapper">
      <img src="../assets/info.png" alt="Inoformación">
      <div class="info">
        <p>Recuerda, la siguiente cuota será de {{ paymentInfo.monthlyFee }} € {{ getDate(paymentInfo.nextPaymentDate) }}</p>
        <p>Puedes cancelar tu matrícula en cualquier momento con 30 días de antelación</p>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { formatDate } from '../commonMethods/methods'

export default {
  name: 'EnrollmentConfirmationOK',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  methods: {
    formatDate,
    ...mapActions('User', ['paymentCheck']),
    getDate(date) {
      if (date) {
        const feeDate = this.formatDate(date)
        return `y la cargaremos en tu tarjeta el día ${feeDate}.`
      }
    }
  },
  computed: {
    ...mapGetters('User', {
      paymentInfo: 'getPaymentCheckInfo'
    })
  },
  async mounted() {
    try {
      await this.paymentCheck(this.id)
    } catch(error) {
      console.log('error', error)
    }
  }
}
</script>

<style scoped lang="scss">
.enrollment-confirmationOK-wrapper {
  padding: 40px 20px;
  .enrollment-confirmationOK {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .btn {
      margin-bottom: 70px;
    }
    .text {
      margin-bottom: 50px;
      font-size: 20px;
    }
    .info-wrapper {
      background-color: $newYellow;
      display: flex;
      padding: 20px 25px;
      border-radius: 10px;
      max-width: 530px;
      img {
        margin-right: 20px;
        margin-top: 8px;
        width: 30px;
        height: 30px;
      }
      .info {
        p {
          margin: 0;
          &:first-child {
            font-weight: 700;
            margin-bottom: 1em;
          }
        }
      }
    }
  }
}
</style>
