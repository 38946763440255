<template>
  <div class="main">
    <form class="form admin__form" action @submit.prevent="updateLeadForm">
      <div class="data-row space-between"><h2 class="subtitle">Editar servicio:</h2>
        <div class="btn-wrapper">
          <button type="submit" class="btn btn-save">Guardar</button>
        </div>
      </div>
      <div class="form__field-row">
        <div class="form__field-wrapper">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.name.$invalid && v$.form.name.$dirty">
              Por favor, introduce un nombre de servicio
            </p>
            <label class="form__field-label" for="#name">Nombre del servicio *</label>
            <input
              v-model="v$.form.name.$model"
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.name.$invalid && v$.form.name.$dirty}"
              type="text"
              id="name"
              required
              @click="removeErrors"
            >
          </div>
          <div class="errors errors-input" v-show="errors.hasOwnProperty('name')">
            <p v-for="(error, key) in errors.name" :key="key">{{ $t(error) }}</p>
          </div>
        </div>
      </div>
    </form>
    <div class="errors" v-for="(error, key) in errors" :key="key">
      <p>{{ $t(error) }}</p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  name: 'ServiceForm',
  props: {
    serviceId: {
      type: String,
      required: true
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      form: {
        name: '',
      },
      errors: {}
    }
  },
  validations() {
    return {
      form: {
        name: {
          required
        }
      }
    }
  },
  watch: {
    activeService: {
      immediate: true,
      handler(newActiveService) {
        if (newActiveService) {
          this.form = newActiveService
        }
      }
    }
  },
  computed: {
    ...mapGetters('Admin', {
      getActiveService: 'getActiveService'
    }),
    activeService () {
      return this.getActiveService(this.serviceId)
    },
  },
  methods: {
    ...mapActions('Admin', ['fetchSelectedService', 'updateService']),
    async updateLeadForm() {
      try {
        const serviceInfo = {
          name: this.form.name
        }
        await this.updateService({ serviceId: this.serviceId, serviceInfo })
        this.$toast.success('Datos enviados correctamente')
        setTimeout(() => {
          this.$router.push({ name: 'services-edit' })
        }, 3000)
      } catch (error) {
        this.errors = error
      }
    }
  },
  async mounted() {
    try {
      await this.fetchSelectedService(this.serviceId)
    } catch (error) {
      this.errors = error
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
