<template>
  <Loading v-if="isLoading" />
  <div v-else>
    <div class="admin-wrapper">
      <SidebarAdmin
        @on-visible-menu="visibleMenu"
      />
      <div class="content" :class="{'content--visible_menu': showSidebar}" >
        <div class="logout-wrapper">
          <p>Te has conectado como <span class="user">{{ getUserInfo.username }}</span> <span @click="logout">Salir</span></p>
        </div>
        <div class="errors" v-for="(error, key) in errors" :key="key">
          <p>{{ $t(error) }}</p>
        </div>
        <router-view></router-view>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import SidebarAdmin from '../components/Admin/SidebarAdmin.vue'
import Loading from '../components/Loading.vue'
import { mapActions, mapGetters } from 'vuex'
import Footer from '../components/Footer.vue'

export default {
  name: 'Admin',
  components: {
    SidebarAdmin,
    Loading,
    Footer
  },
  data() {
    return {
      showSidebar: false,
      isLoading: false,
      errors: null,
      userName: ''
    }
  },
  computed: {
    ...mapGetters('Admin', ['getUserInfo'])
  },
  mounted() {
  },
  methods: {
    ...mapActions('Admin', ['fetchAdmin', 'userInfo']),
    ...mapActions('Auth', {
      logout: 'logout'
    }),
    visibleMenu() {
      this.showSidebar = !this.showSidebar
    }
  },
  beforeMount() {
    this.isLoading = true
    this.fetchAdmin()
    .then(() => {
      this.isLoading = false
    })
    .catch(errors => {
      this.isLoading = true
      this.errors = errors
    })
    this.userInfo()
  }
}

</script>

<style lang="scss">
.admin-wrapper {
  background-color: $newGrey;
  max-width: 1450px;
  margin: 0 auto;
  position: relative;
  .logout-wrapper {
    position: relative;
    text-align: right;
    font-size: 14px;
    font-weight: 700;
    .user {
      text-transform: uppercase;
    }
    span:not(.user) {
      color: $newPink;
      text-transform: uppercase;
      margin-left: 20px;
      cursor: pointer;
    }
  }
}
.content {
  min-height: calc(100vh - 80px);
  padding: 60px 15px 20px;
  transition: all .8s ease;
  .main {
    padding: 15px;
    min-height: calc(100vh - 110px);
    background-color: white;
    border-radius: 22px;
    .subtitle:first-child {
      margin-top: 0;
    }
    .text {
      margin-bottom: 3em;
    }
  }
  @include tablet {
    min-height: calc(100vh - 40px);
    padding: 40px;
    padding-left: 360px;
    .main {
      padding: 30px;
      min-height: calc(100vh - 100px);
    }
  }
}
</style>
