<template>
  <div class="main payment">
    <h2 class="subtitle">Editar pagos:</h2>
    <form action="">
      <ul class="payment__list">
        <li class="form__field-wrapper payment__element">
          <div class="form__field">
            <label
              for="name"
              class="form__field-label">{{labelName}}
            </label>
            <input
              class="form__field-input"
              type="text"
              id="name"
              v-model="v$.form.name.$model"
              disabled>
          </div>
        </li>
        <li class="form__field-wrapper payment__element">
          <div class="form__field">
            <p class="input-errors" v-if="v$.form.price.$invalid && v$.form.price.$dirty">
              Por favor, introduce un precio
            </p>
            <label
              for="precio"
              class="form__field-label">Precio *
            </label>
            <input
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.price.$invalid && v$.form.price.$dirty}"
              type="number"
              id="precio"
              v-model="v$.form.price.$model"
              :disabled="!!(form.error || form.paidAt)"
              @click="removeErrorNegative"
              required>
            <p class="input-errors" v-if="isNegative">
              Por favor, introduce un precio mayor que cero
            </p>
          </div>
        </li>
        <li class="form__field-wrapper payment__element">
          <div class="form__field">
            <label
              for="subject"
              class="form__field-label">Información del pago *
            </label>
            <input
              class="form__field-input"
              :class="{'form__input--invalid': v$.form.subject.$invalid && v$.form.subject.$dirty}"
              type="text"
              id="subject"
              v-model="v$.form.subject.$model"
              :disabled="!!(form.error || form.paidAt)"
              required>
              <p class="input-errors" v-if="v$.form.subject.$invalid && v$.form.subject.$dirty">
                Por favor, introduce un mensaje
              </p>
          </div>
        </li>
        <li class="form__field payment__element">
          <div class="form__field">
            <label
              for="orderAt"
              class="form__field-label">Ordenado
            </label>
            <input
              class="form__field-input"
              type="text"
              id="orderAt"
              v-model="v$.form.orderAt.$model"
              disabled>
          </div>
        </li>
        <li class="form__field payment__element">
          <div class="form__field">
            <label
              for="paidAt"
              class="form__field-label">Pagado
            </label>
            <input
              class="form__field-input"
              type="text"
              id="paidAt"
              v-model="v$.form.paidAt.$model"
              disabled>
          </div>
        </li>
        <li v-if="form.error" class="form__field payment__element">
          <div class="form__field">
            <label
              for="error"
              class="form__field-label">Error
            </label>
            <input
              class="form__field-input"
              type="text"
              id="error"
              v-model="v$.form.error.$model"
              disabled>
          </div>
        </li>
      </ul>
    </form>
    <button
        v-if="form.error"
        class="btn btn-accept"
        @click="acceptPaid">Marcar como pagado
    </button>
    <template v-if="!form.paidAt && !form.error">
      <div
          class="btn-wrapper">
        <button
          class="btn btn-save"
          type="button"
          @click="savePayment">Guardar
        </button>
        <button
          class="btn btn-delete"
          type="button"
          @click="cancelEdition">Cancelar
        </button>
      </div>
    </template>
    <div class="errors" v-for="(error, key) in errors" :key="key">
      <p>{{ $t(error) }}</p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required, numeric } from '@vuelidate/validators'

export default {
  name: 'Payments',
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      form: {
        name: '',
        price: null,
        subject: '',
        orderAt: '',
        paidAt: '',
        error: ''
      },
      labelName: '',
      isNegative: false,
      errors: {}
    }
  },
  validations() {
    return {
      form: {
        name: {
          required
        },
        price: {
          required,
          numeric
        },
        subject: {
          required
        },
        orderAt: {
          required
        },
        paidAt: {},
        error: {}
      }
    }
  },
  computed: {
    ...mapGetters('Admin', {
      getActivePayment: 'getActivePayment'
    }),
    activePayment () {
      return this.getActivePayment(this.$route.params.paymentId)
    },
  },
  watch: {
    activePayment: {
      immediate: true,
      handler(newActivePayment) {
        if (newActivePayment) {
          this.form = {
            ...newActivePayment,
            name: newActivePayment.students.length
              ? newActivePayment.students.map(student => (` ${student}`))
              : newActivePayment.tutor
                ? newActivePayment.tutor.name
                : ''
          }
          this.labelName = newActivePayment.students.length ? 'Estudiantes' : 'Tutor'
        }
      }
    }
  },
  methods: {
    ...mapActions('Admin', ['fetchSelectedPayment', 'updatePayment', 'acceptPayment']),
    cancelEdition() {
      this.$router.push({ name: 'payments' })
    },
    async acceptPaid() {
      this.acceptPayment({ centerId: this.$route.params.id, paymentId: this.$route.params.paymentId })
      .then(() => {
        this.$toast.success('Datos enviados correctamente')
        setTimeout(() => {
          this.$router.push({ name: 'payments' })
        }, 3000)
      })
      .catch(err => {
        this.errors = err
      })
    },
    async savePayment() {
      const price = this.form.price.toString()
      if(price.charAt(0) === '-') {
        this.isNegative = true
      } else {
        if (!this.v$.$invalid) {
          try {
            const payment = {
              price: parseFloat(this.form.price),
              subject: this.form.subject
            }
            await this.updatePayment({
              payment,
              centerId: this.$route.params.id,
              paymentId: this.$route.params.paymentId
            })
            this.$toast.success('Datos guardados correctamente')
            setTimeout(() => {
              this.cancelEdition()
            }, 3000)
          } catch (error) {
            this.errors = error
          }
        }
      }
    },
    removeErrorNegative() {
      this.isNegative = false
    }
  },
  async mounted() {
    try {
      await this.fetchSelectedPayment({ centerId: this.$route.params.id, paymentId: this.$route.params.paymentId })
    } catch (error) {
      console.log('error', error)
    }
  }
}
</script>
<style lang="scss" scoped>
  .payment {
    &__list {
      margin-bottom: 24px;
    }

    &__element {
      margin: 8px 0 18px;
    }
  }
  .payment__list {
    @include tablet {
      flex-direction: row;
      display: flex;
      flex-wrap: wrap;
      .payment__element {
        width: 50%;
        &:nth-child(odd) {
          .form__field-input, .form__field-label {
            margin-right: 30px;
          }
        }
      }
    }
  }
</style>
