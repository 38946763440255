<template>
  <div class="main">
    <Loading v-if="isLoading" />
    <div v-else>
      <div class="csv__header">
        <h2 class="subtitle flex-grow">Matrículas:</h2>
        <button class="tutor new-element compact">
          <svg viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.7758 19.504H19.6398V30.384H11.3838V19.504H0.31175V11.696H11.3838V0.815998H19.6398V11.696H30.7758V19.504Z" fill="#00ADCE"/>
          </svg>
          <router-link :to="{ name: 'enrollment-create' }">
          </router-link>
          Añadir nueva matrícula
        </button>
        <button
          class="btn csv__button"
          type="button"
          @click="downloadCSV">
          <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
            <path d="M22 24h-20v-24h14l6 6v18zm-7-23h-12v22h18v-16h-6v-6zm3 15v1h-12v-1h12zm0-3v1h-12v-1h12zm0-3v1h-12v-1h12zm-2-4h4.586l-4.586-4.586v4.586z"/>
          </svg>
          <span class="csv__button__text">Descargar CSV</span>
        </button>
      </div>

    <div class="enrollments__filters">
      <input
          type="text"
          placeholder="Buscar alumno por nombre"
          v-model="student"
          class="enrollments__filters__text" />
      <div v-if="enrollmentsSearch.length > 0" class="container-btn">
        <h3>{{enrollLabel}}</h3>
        <button @click="enrollFinalizedHandler" class="btn">{{enrollBtnLabel}}</button>
      </div>
    </div>
      <div
          class="form__field-row half payments__filters__dates">
        <div class="form__field-wrapper">
          <div class="form__field form__field--datepicker">
            <label class="form__field-label" for="#price">Fecha inicio</label>
            <datepicker
                v-model="dateFilters.startDate"
                ref="startDatepicker"
                inputFormat="dd/MM/yyyy"
                :style="useInputStyles"
                :locale="dateLocale"
                :clearable="true"
                :upper-limit="dateFilters.endDate"

            />
          </div>
        </div>
        <div class="form__field-wrapper">
          <div class="form__field form__field--datepicker">
            <label class="form__field-label" for="#price">Fecha fin</label>
            <datepicker
                v-model="dateFilters.endDate"
                ref="endDatepicker"
                inputFormat="dd/MM/yyyy"
                :style="useInputStyles"
                :locale="dateLocale"
                :clearable="true"
                :lower-limit="dateFilters.startDate"
            />
          </div>
        </div>
      </div>
      <div class="enrollments-list">
        <Pagination v-if="totalPages > 1" :pages="totalPages"></Pagination>
        <table class="admin__table" v-if="enrollmentsSearch.length || isTableLoading">
          <thead class="admin__table__head">
            <tr>
              <th class="admin__table__head__cell">Nombre alumn@</th>
              <th class="admin__table__head__cell">Curso y nivel</th>
              <th class="admin__table__head__cell">Activa desde</th>
              <th v-if="!isDateEnd" class="admin__table__head__cell">Próximo pago</th>
              <th class="admin__table__head__cell">Precio</th>
              <th class="admin__table__head__cell">Método de pago</th>
              <th v-if="!isDateEnd" class="admin__table__head__cell">Cambiar grupo</th>
              <th class="admin__table__head__cell">Baja</th>
            </tr>
          </thead>
          <tbody class="admin__table__body" v-if="!isTableLoading">
            <tr v-for="(enrollment, idx) in enrollmentsSearch" :key="idx">
              <td class="admin__table__body__cell enrollments__table__cell" @click="showEnrollmentDetail(enrollment.id)"><span class="admin__table__body__cell__header">Nombre alumn@</span> <span class="cell-content">{{ enrollment.student.name }} {{ enrollment.student.surnames }}</span></td>
              <td class="admin__table__body__cell enrollments__table__cell" @click="showEnrollmentDetail(enrollment.id)"><span class="admin__table__body__cell__header">Curso y nivel</span>{{ enrollment.group.service.name }}</td>
              <td class="admin__table__body__cell enrollments__table__cell" @click="showEnrollmentDetail(enrollment.id)"><span class="admin__table__body__cell__header">Activa desde</span>{{ formatDateShort(enrollment.dateStart, false) }}</td>
              <td v-if="!isDateEnd" class="admin__table__body__cell enrollments__table__cell" @click="showEnrollmentDetail(enrollment.id)"><span class="admin__table__body__cell__header">Próximo pago</span>{{enrollment.nextPayment ? formatDateShort(enrollment.nextPayment, false) : '' }}</td>
              <td class="admin__table__body__cell enrollments__table__cell" @click="showEnrollmentDetail(enrollment.id)"><span class="admin__table__body__cell__header">Precio</span>{{ formatPrice(enrollment.priceFee) }}</td>
              <td class="admin__table__body__cell enrollments__table__cell" @click="showEnrollmentDetail(enrollment.id)"><span class="admin__table__body__cell__header">Método de pago</span>{{ getPaymentMethod(enrollment.paymentMethod) }}</td>
              <td v-if="!isDateEnd" class="admin__table__body__cell enrollments__table__cell" @click="showEnrollmentDetail(enrollment.id)"><span class="admin__table__body__cell__header">Cambiar grupo</span>
                <span v-if="!enrollment.dateEnd" class="enrollments__table__icon">
                  <svg @click.stop="enrollmentChangeGroup(enrollment.id, enrollment.student.id)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="m7.5 13a4.5 4.5 0 1 1 4.5-4.5 4.505 4.505 0 0 1 -4.5 4.5zm6.5 11h-13a1 1 0 0 1 -1-1v-.5a7.5 7.5 0 0 1 15 0v.5a1 1 0 0 1 -1 1zm3.5-15a4.5 4.5 0 1 1 4.5-4.5 4.505 4.505 0 0 1 -4.5 4.5zm-1.421 2.021a6.825 6.825 0 0 0 -4.67 2.831 9.537 9.537 0 0 1 4.914 5.148h6.677a1 1 0 0 0 1-1v-.038a7.008 7.008 0 0 0 -7.921-6.941z"/></svg>
                </span>
              </td>
              <td class="admin__table__body__cell enrollments__table__cell" @click="showEnrollmentDetail(enrollment.id)"><span class="admin__table__body__cell__header">Baja</span>
                <span v-if="enrollment.dateEnd" class="cell-dateEnd">{{formatDateShort(enrollment.dateEnd)}}</span>
                <span v-else class="enrollments__table__icon">
                  <svg  @click.stop="enrollmentFinish(enrollment.id)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 16.518l-4.5-4.319 1.396-1.435 3.078 2.937 6.105-6.218 1.421 1.409-7.5 7.626z"/></svg>
                </span>
              </td>
            </tr>
          </tbody>
          <tbody class="admin__table__body" v-else>
          <tr>
            <td class="admin__table__body__cell enrollments__table__cell" colspan="8">
              <div class="message__loading">
                <h2>Results are loading...</h2>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
        <div v-else class="message__noData">
          <div class="message__noData-icon">
            <svg width="13" height="36" viewBox="0 0 13 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.880469 -2.38419e-06H12.1805L10.2805 22.25H2.73047L0.880469 -2.38419e-06ZM6.53047 35.45C4.8638 35.45 3.49714 34.95 2.43047 33.95C1.3638 32.9167 0.830469 31.6667 0.830469 30.2C0.830469 28.7 1.3638 27.4667 2.43047 26.5C3.49714 25.5333 4.8638 25.05 6.53047 25.05C8.19714 25.05 9.54714 25.5333 10.5805 26.5C11.6471 27.4667 12.1805 28.7 12.1805 30.2C12.1805 31.6667 11.6471 32.9167 10.5805 33.95C9.5138 34.95 8.1638 35.45 6.53047 35.45Z" fill="white"/>
            </svg>
          </div>
          <div class="message__noData-text">
            <p>No hay matrículas para mostrar</p>
            <p>Prueba seleccionando otro centro</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Popup :modalTitle="`Finalizar matrícula de ${enrollmentSelected.studentName}`" @onClose="closeModal" @onAccept="finishSelectedEnrollment" v-if="isModalOpen">
    <p class="popup-message">Elige una fecha para la baja de {{enrollmentSelected.studentName}}, o déjala en blanco para que sea efectiva hoy</p>
    <div class="endDatePicker">
      <datepicker
          v-model="selectedDate"
          ref="selectedDatepicker"
          inputFormat="dd/MM/yyyy"
          :style="useInputStyles"
          :locale="dateLocale"
          :clearable="false"
          :lower-limit="minDate"
      />
    </div>
  </Popup>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Loading from '../../Loading.vue'
import {
  formatDateShort,
  formatPrice
} from '@/commonMethods/methods'
import { typeOfPayments } from '@/commonMethods/constants'
import Datepicker from 'vue3-datepicker'
import es from 'date-fns/locale/es'
import Pagination from "@/components/Helpers/Pagination.vue";
import router from "@/router";
import debounce from "lodash.debounce"
import Popup from "@/components/Helpers/Popup.vue";

const useInputStyles = {
  padding: "12px 20px",
  border: "1px solid #00ADCE",
  borderRadius: "0",
  marginRight: "30px",
  width: "calc(100% - 30px)"
}


export default {
  name: 'Tutors',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {
    Popup,
    Pagination,
    Loading,
    Datepicker
  },
  data() {
    return {
      formatDateShort,
      formatPrice,
      student: this.$route.query['search'] || '',
      enrollBtnLabel: '',
      enrollLabel: '',
      showFilters: false,
      isDateEnd: false,
      isLoading: false,
      isTableLoading: false,
      isModalOpen: false,
      enrollmentSelected: null,
      selectedDate: null,
      minDate: new Date(),
      typeOfPayments,
      dateFilters: {
        // startDate: new Date().getTime() - (24*60*60*1000 * 180),
        startDate: this.$route.query['dateStart'] ? Date.parse(this.$route.query['dateStart'].toString()) : null,
        endDate: this.$route.query['dateEnd'] ? Date.parse(this.$route.query['dateEnd'].toString()) : null,
      },
      errors: {},
      useInputStyles,
      dateLocale: es,
    }
  },
  watch: {
    async '$route' (){
      this.isTableLoading = true;
      const _params = { limit: 25, page: 1 };

      for(const param in this.$route.query){
        _params[param] = this.$route.query[param];
      }
      await this.fetchEnrollments({ centerId: this.id, isDateEnd: this.isDateEnd, params: _params })
      this.isTableLoading = false;
    },
    student: debounce(function(searchString){
      if(searchString.length > 0 && searchString.length < 3){
        return;
      }
      const _querySanitizer = {...this.$route.query}
      if(searchString){
        _querySanitizer['search'] = searchString;
      } else {
        delete _querySanitizer['search'];
      }
      router.push({
        name: this.$route.name,
        params: this.$route.params,
        query: {
          ..._querySanitizer,
          page: 1
        }
      })
    }, 500),
    dateFilters: {
      handler(dateFilters) {
        const queryParamSanitizer = {...this.$route.query};
        const dateParams = {};
        if(dateFilters['startDate']) {
          const dateStart = new Date(dateFilters['startDate']);
          dateParams.dateStart = `${dateStart.getFullYear()}-${(dateStart.getMonth() + 1).toString().padStart(2, '0')}-${dateStart.getDate().toString().padStart(2, '0')}`
        } else {
          delete queryParamSanitizer['dateStart'];
        }
        if(dateFilters['endDate']) {
          const dateEnd = new Date(dateFilters['endDate']);
          dateParams.dateEnd = `${dateEnd.getFullYear()}-${(dateEnd.getMonth() + 1).toString().padStart(2, '0')}-${dateEnd.getDate().toString().padStart(2, '0')}`
        } else {
          delete queryParamSanitizer['dateEnd'];
        }

        router.push({
          name: this.$route.name,
          params: this.$route.params,
          query: {
            ...queryParamSanitizer,
            ...dateParams,
            page: 1
          }
        })
      },
      deep: true
    },
    isDateEnd: {
      immediate: true,
      async handler(value) {
        this.isLoading = true;
        this.isTableLoading = true;
        const _params = { limit: 25, page: 1 };

        for(const param in this.$route.query){
          _params[param] = this.$route.query[param];
        }
        try {
          if (value) {
            this.enrollBtnLabel = 'Ver solo activas';
            this.enrollLabel = 'Mostrando matrículas finalizadas';
            await this.fetchEnrollments({ centerId: this.id, isDateEnd: value, params: _params })
          } else {
            this.enrollBtnLabel = 'Ver solo finalizadas';
            this.enrollLabel = 'Mostrando matrículas activas';
            await this.fetchEnrollments({ centerId: this.id, params: _params })
          }
        } finally {
          this.isLoading = false;
          this.isTableLoading = false;
        }
      }
    }
  },
  computed: {
    ...mapGetters('Admin', {
      enrollments: 'getEnrollments'
    }),
    totalPages() {
      return this.enrollments.totalPages;
    },
    enrollmentsSearch() {
      return this.enrollments.content
    }
  },
  methods: {
    ...mapActions('Admin', ['fetchEnrollments', 'updateEnrollmentsFinish', 'fetchCSVToken']),
    async enrollmentFinish(enrollmentId) {
      this.enrollmentSelected = (this.enrollments.content ?? this.enrollments).find((e) => e.id === enrollmentId);
      this.isModalOpen = true;
    },
    enrollFinalizedHandler() {
      this.isDateEnd = !this.isDateEnd
    },
    toogleFilters () {
      this.showFilters = !this.showFilters
    },
    showEnrollmentDetail(enrollmentId) {
      this.$router.push({ name: 'enrollment-detail', params: { id: this.$route.params.id, enrollmentId } })
    },
    enrollmentChangeGroup(enrollmentId, studentId) {
      this.$router.push({ name: 'enrollment-change-group', params: { centerId: this.$route.params.id, enrollmentId, studentId } })
    },
    async downloadCSV() {
      try {
        const response = await this.fetchCSVToken()
        const csv = `${process.env.VUE_APP_API_BASE_URI}/admin/centers/${this.$route.params.id}/enrollments.csv?token=${response.data.token}`
        window.open(csv)
      } catch (error) {
        this.$toast.error('Lo sentimos, no hemos podido descargar el CSV, inténtelo de nuevo');
      }
    },
    getPaymentMethod(type) {
      return this.typeOfPayments.find(_type => _type.id === type).label
    },
    closeModal(){
      this.isModalOpen = false;
      this.selectedDate = null;
      this.enrollmentSelected = null;
    },
    finishSelectedEnrollment(){
      this.updateEnrollmentsFinish({centerId: this.id, enrollmentId: this.enrollmentSelected.id, dateEnd: this.selectedDate })
          .then(() => {
            const _params = { limit: 25, page: 1 };

            for(const param in this.$route.query){
              _params[param] = this.$route.query[param];
            }
            this.fetchEnrollments({ centerId: this.id, params: _params })
            this.$toast.success('Matricula finalizada')
          })
          .catch(errors => {
            this.errors = errors
            this.$toast.error('Este matricula no se puede finalizar')
          })
      this.closeModal()
    }
  }
}
</script>


<style scoped lang="scss">
  .enrollments {
    &__filters {
    align-items: center;
    display: flex;
    margin-bottom: 16px;
    position: relative;
    justify-content: space-between;
    &__text {
      border: 2px solid $newPink;
      border-radius: 16px;
      flex-grow: 1;
      font-size: 12px;
      margin-right: 16px;
      max-width: 400px;
      padding: 8px;

      @include desktop {
        margin-bottom: 0;
        width: 40%;
      }
    }

    &__button {
      height: 34px;
      margin-left: auto;
      padding: 8px 16px;
      transition: border-bottom-left-radius .4s, border-bottom-right-radius .4s;

      &--open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      &__icon {
        fill: $white;
      }

      &:hover {
        svg {
          path {
            fill: $newBlue;
          }
        }
      }
    }

    &__container {
      background-color: white;
      border: 1px solid $newBlue;
      border-radius: 16px;
      border-top-right-radius: 0;
      font-size: 14px;
      margin: 0;
      position: absolute;
      right: 0;
      top: 34px;
      z-index: 1;
    }

    @include desktop {
      flex-direction: row;
    }
  }

    &__error {
      background-color:  lighten(desaturate($newSalmon, 10), 15);
    }

    &__message {
      background-color: $newLightBlue;
      border: 1px solid $newBlue;
      border-radius: 16px;
      font-size: 14px;
      margin-top: 32px;
      padding: 12px;
      text-align: center;
    }
    &__table {
      &__cell {
        cursor: pointer;
        line-height: 20px;
      }

      &__icon {
        display: flex;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
  .container-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    h3 {
      margin: 3px 0;
    }
    .btn {
      font-size: 12px;
      padding: 8px 12px;
      width: 200px;
    }
  }
  .cell-dateEnd {
    font-weight: 100;
  }
  .endDatePicker{
    display: flex;
    justify-content: center;
    *{
      width: 280px
    }
  }
  .popup-message{
    max-width: 500px;
  }
</style>
