<template>
  <Loading v-if="isLoading" />
  <main v-else class="main reports">
    <div class="csv__header">
      <h2 class="subtitle flex-grow">Informes:</h2>
      <button class="tutor new-element compact">
        <svg viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M30.7758 19.504H19.6398V30.384H11.3838V19.504H0.31175V11.696H11.3838V0.815998H19.6398V11.696H30.7758V19.504Z"
              fill="#00ADCE"/>
        </svg>
        <router-link :to="{ name: 'reports-create' }">
        </router-link>
        Añadir nuevo informe
      </button>
    </div>
      <input
        type="text"
        placeholder="Buscar alumno o monitor por nombre"
        v-model="search"
        class="reports__search">
      <div
          class="form__field-row half">
        <div class="form__field-wrapper">
          <div class="form__field form__field--datepicker">
            <label class="form__field-label" for="#price">Fecha inicio</label>
            <datepicker
              v-model="dateFilters.startDate"
              ref="startDatepicker"
              inputFormat="dd/MM/yyyy"
              :style="useInputStyles"
              :locale="dateLocale"
              :clearable="true"
              :upper-limit="dateFilters.endDate"
            />
          </div>
        </div>
        <div class="form__field-wrapper">
          <div class="form__field form__field--datepicker">
            <label class="form__field-label" for="#price">Fecha fin</label>
            <datepicker
              v-model="dateFilters.endDate"
              ref="endDatepicker"
              inputFormat="dd/MM/yyyy"
              :style="useInputStyles"
              :locale="dateLocale"
              :clearable="true"
              :lower-limit="dateFilters.startDate"
            />
          </div>
        </div>
      </div>
      <template v-if="reportSearch.length">
        <table class="admin__table">
          <thead class="admin__table__head">
          <tr>
            <th
              v-for="(column, idx) in tableConfig"
              :key="`columnHeader--${idx}`"
              class="admin__table__head__cell"
              :class="{
                'narrow': ['validated', 'date'].includes(column.key),
                'buttons': column.key === 'validate'
              }">
              <span>{{column.label}}</span>
            </th>
          </tr>
          </thead>
          <tbody class="admin__table__body">
          <tr
            v-for="(report, idx) in reportSearch"
            :key="`report--${idx}`">
            <td
              v-for="(column, idx2) in tableConfig"
              :key="`columnHeader--${idx2}`"
              class="admin__table__body__cell cell-reports"
              :class="{'narrow': ['validated', 'date'].includes(column.key)}"
              @click="showReportDetail(report.id, column.key)">
              <span class="admin__table__body__cell__header">{{ column.label }}</span>
              <img v-if="column.key === 'level'" :src="report.reportLevel.title" alt="level" class="class-image" />
              <div v-if="column.key === 'monitor'">{{report.staffMember.name}} {{report.staffMember.surnames}}</div>
              <div v-if="column.key === 'student'">{{report.student.name}} {{report.student.surnames}}</div>
              <div v-if="column.key === 'date'"><span class="date">{{formatDateShort(report.createdAt)}}</span></div>
              <div v-if="column.key === 'validated'"><span class="date">{{formatDateShort(report.validatedAt)}}</span></div>
              <div v-if="column.key === 'validate'" class="btn" :class="{ validating: !report.validatedAt }">{{configLabel(report.validatedAt)}}</div>
            </td>
          </tr>
          </tbody>
        </table>
      </template>
    <div v-else class="message__noData">
      <div class="message__noData-icon">
        <svg width="13" height="36" viewBox="0 0 13 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.880469 -2.38419e-06H12.1805L10.2805 22.25H2.73047L0.880469 -2.38419e-06ZM6.53047 35.45C4.8638 35.45 3.49714 34.95 2.43047 33.95C1.3638 32.9167 0.830469 31.6667 0.830469 30.2C0.830469 28.7 1.3638 27.4667 2.43047 26.5C3.49714 25.5333 4.8638 25.05 6.53047 25.05C8.19714 25.05 9.54714 25.5333 10.5805 26.5C11.6471 27.4667 12.1805 28.7 12.1805 30.2C12.1805 31.6667 11.6471 32.9167 10.5805 33.95C9.5138 34.95 8.1638 35.45 6.53047 35.45Z" fill="white"/>
        </svg>
      </div>
      <div class="message__noData-text">
        <p>No hay informes para mostrar</p>
        <p>Prueba seleccionando otro centro</p>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { normalizeText, getEndDateTimestamp, getStartDateTimestamp, formatDateShort, emojiLabel, legendLabel } from '@/commonMethods/methods'
import { aspectsToEvaluate } from '@/commonMethods/constants'
import Datepicker from 'vue3-datepicker'
import Loading from '../Loading.vue'
import es from 'date-fns/locale/es'

const useInputStyles = {
  padding: "12px 20px",
  border: "1px solid #00ADCE",
  borderRadius: "0",
  marginRight: "30px",
  width: "calc(100% - 30px)"
}

export default {
  name: 'Reports',
  components: {
    Datepicker,
    Loading
  },
  data() {
     return {
       tableConfig: [
         {
           label: 'Nivel',
           key: 'level'
         },
         {
           label: 'Profesor',
           key: 'monitor',
         },
          {
            label: 'Alumno',
            key: 'student'
          },
          {
           label: 'Creado',
           key: 'date'
         },
          {
           label: 'Enviado',
           key: 'validated'
         }
       ],
       arrayOfIndex: [],
       search: '',
       dateFilters: {
         // startDate: new Date().getTime() - (24*60*60*1000 * 180),
         startDate: null,
         endDate: null
       },
       useInputStyles,
       dateLocale: es,
       aspectsToEvaluate,
       getEndDateTimestamp,
       getStartDateTimestamp,
       emojiLabel,
       legendLabel,
       formatDateShort,
       isLoading: false,
       isValueHidden: false,
       reportIndex: null
     }
  },
  computed: {
    ...mapGetters('AdminCenter', {
      reports: 'getReports',
    }),
    ...mapState('Admin', ['userInfo']),
    reportSearch() {
      return this.reports.filter(report =>
        (
          normalizeText(`${report.student.name} ${report.student.surnames}`).includes(normalizeText(this.search)) ||
          normalizeText(`${report.staffMember.name} ${report.staffMember.surnames}`).includes(normalizeText(this.search))
         ) &&
        (
          this.dateFilters.startDate
            ? getStartDateTimestamp(report.createdAt * 1000) >= getStartDateTimestamp(this.dateFilters.startDate)
            : true
        ) &&
        (
          this.dateFilters.endDate
            ? getEndDateTimestamp(report.createdAt * 1000) <= getEndDateTimestamp(this.dateFilters.endDate)
            : true
        )
      )
    },
  },
  methods: {
    ...mapActions('AdminCenter', ['fetchReports', 'validateReportById']),
    showReportDetail(studentId, key) {
      if (key !== 'validate') return false
      this.$router.push({ name: 'reports-create', params: { studentId: studentId }})
    },
    configLabel(validated) {
      if (this.userInfo.isSuperAdmin || this.userInfo.isSupervisor) {
        return validated ? 'Enviado' : 'Validar'
      } else {
        return 'Modificar'
      }
    }
  },
  async mounted() {
    this.isLoading = true
    try {
      await this.fetchReports(this.$route.params.id)
    } finally {
      this.isLoading = false
    }
    if (this.userInfo.isSuperAdmin || this.userInfo.isSupervisor) {
      this.tableConfig.push({ label: '', key: 'validate' })
    } else {
      this.tableConfig.push({ label: '', key: 'validate' })
    }
    this.arrayOfIndex = Array.from(Array(5).keys())
  }
}
</script>

<style scoped lang="scss">
  .reports {
    .admin {
      &__table {
        &__head{
          overflow-y: scroll;
          &__cell {
            width: 20%;
            &.narrow{
              width: 10%;
            }
            &.buttons{
              width: 21%;
            }
          }
        }
        &__body {
          &__cell {
            cursor: default;
          }
        }
      }
    }
    &__search {
      border: 2px solid $newPink;
      border-radius: 16px;
      flex-grow: 1;
      font-size: 12px;
      margin-right: 16px;
      max-width: 500px;
      padding: 8px;

      @include desktop {
        margin-bottom: 32px;
        width: 45%;
      }
    }
    .evaluation {
      p {
        margin: 0;
        font-size: 14px;
        @include tablet {
          font-size: 18px;
        }
      }
    }
    .student {
      line-height: 16px;
    }
    .btn {
      padding: 20px 10px;
      margin: 10px 0;
      line-height: 0;
      &.validating{
        background: $olive;
        border: 1px solid $olive;
        &:hover{
          color: $olive;
          background-color: white;
        }
      }
    }
    .class-image {
      width: 140px;
      vertical-align: middle;
    }
  }
  .date {
    font-weight: 100;
  }
  @include desktop {
    .cell-reports {
      width: 20%;
      &.narrow{
        width: 10%;
      }
    }
  }
</style>
