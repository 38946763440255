<template>
  <Loading
    v-if="isLoading" />
  <div v-if="student && !isLoading" class="main">
    <h2 class="subtitle">Clases reservadas de {{ student.name }}</h2>
    <p class="text" v-if="!reservedClasses.length">
      {{ student.name }} no tiene clases reservadas.
    </p>
    <table class="admin__table" v-if="reservedClasses.length >= 1">
      <thead class="admin__table__head">
        <tr>
          <th class="admin__table__head__cell">Centro</th>
          <th class="admin__table__head__cell">Grupo</th>
          <th class="admin__table__head__cell">Servicio</th>
          <th class="admin__table__head__cell">Hora</th>
          <th class="admin__table__head__cell">Fecha</th>
          <th class="admin__table__head__cell"></th>
          <th class="admin__table__head__cell"></th>
        </tr>
      </thead>
      <tbody class="admin__table__body">
        <tr v-for="recovered in reservedClasses" :key="recovered.id">
          <td class="admin__table__body__cell"><span class="admin__table__body__cell__header">Centro</span><p>{{recovered.recoveredGroup.center.name}}</p></td>  
          <td class="admin__table__body__cell"><span class="admin__table__body__cell__header">Grupo</span><p>{{recovered.recoveredGroup.name}}</p></td>
          <td class="admin__table__body__cell"><span class="admin__table__body__cell__header">Servicio</span><p>{{recovered.recoveredGroup.service}}</p></td>
          <td class="admin__table__body__cell"><span class="admin__table__body__cell__header">Hora</span><p>{{recovered.recoveredGroup.time}}</p></td>
          <td class="admin__table__body__cell"><span class="admin__table__body__cell__header">Fecha</span><p>{{recovered.recoveredAt}}</p></td>
          <td @click="booking(recovered.id)" class="admin__table__body__cell"><span class="admin__table__body__cell__header"></span><button class="btn">Cambiar clase</button></td>
          <td @click="cancelSession(recovered.id)" class="admin__table__body__cell"><span class="admin__table__body__cell__header"></span><button class="btn">Cancelar sesión</button></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Loading from '@/components/Loading'
import { formatDateShort, reverseDate } from '../commonMethods/methods'
import { apiClient } from '@/services/ApiClient'
import { mapGetters } from 'vuex'

export default {
  name: 'ReservedClass',
  components: {
    Loading
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      reservedClasses: [],
      student: null,
      isLoading: true,
      formatDateShort,
      reverseDate
    }
  },
  watch: {
    
  },
  computed: {
    ...mapGetters('Students', ['getActiveStudent'])
  },
  methods: {
    async cancelSession(id) {
      let checkingAnnulment = confirm('¿Estás seguro que quieres cancelar la reserva?')
      if (checkingAnnulment) {
        this.isLoading = true
        try {
          await apiClient.patch(`/tutor/reserved-sessions/${id}/cancel`)
          const { data } = await apiClient.get(`/tutor/students/${this.id}/reserved-sessions`)
          this.reservedClasses = data
          this.$toast.success('Reserva cancelada correctamente.')
        } catch (error) {
          this.$toast.error('No ha sido posible cancelar la reserva, inténtelo de nuevo.')
        } finally {
          this.isLoading = false
        }
      } 
    },
    async booking(id) {
      this.$router.push({ name: 'recover-class-session', params: { id: this.id, idMissedSession: id, isEditing: true }})
    }
  },
  async mounted() {
    try {
      const { data } = await apiClient.get(`/tutor/students/${this.id}/reserved-sessions`)
      this.reservedClasses = data
      this.student = this.getActiveStudent(this.id)
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoading = false
    }
    
  }
}
</script>

<style scoped lang="scss">
.main {
  .text {
    margin-bottom: 3em;
  }
}

</style>
