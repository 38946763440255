<template>
  <div class="main">
    <Loading v-if="isLoading" />
    <div v-else>
      <form action class="form admin__form" @submit.prevent="changeNewGroup">
        <div class="data-row space-between">
          <h2 class="subtitle">Cambiar grupo:</h2>
          <button type="submit" class="btn btn-save">Guardar</button>
        </div>
        <p>Al cambiar al alumno de grupo se cancelará automáticamente la matrícula previa y se reasignará al correspondiente grupo</p>
        <div class="form__field-row half" style="align-items: flex-end">
          <div class="form__field-wrapper">
            <div class="form__field">
              <label class="form__field-label" for="#studentName">Nombre del alumn@: *</label>
              <input
                  v-model="v$.form.studentName.$model"
                  class="form__field-input"
                  type="text"
                  id="studentName"
                  required
                  disabled
              >
            </div>
          </div>
          <div class="form__field-wrapper">
            <div class="form__field">
              <label class="form__field-label" for="#group">Grupos *</label>
              <select
                  v-model="v$.form.group.$model"
                  class="form__field-input"
                  name="group"
                  id="group"
                  required
              >
                <option v-for="(group,idx) in formGroups" :key="idx" :value="group.value">{{group.label}}</option>
              </select>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/Loading'
import { mapActions, mapGetters } from 'vuex'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

export default {
  name: 'EnrollmentChangeGroup',
  components: {
    Loading
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      isLoading: false,
      form: {
        group: '',
        studentName: ''
      }
    }
  },
  validations() {
    return {
      form: {
        group: {
          required
        },
        studentName: {}
      }
    }
  },
  computed: {
    ...mapGetters('Admin', ['getGroupLists']),
    ...mapGetters('AdminCenter', ['getStudentsByCenter']),
    formGroups() {
      return this.getGroupLists.map(group => ({
        value: group.id,
        label: `${group.name} - Plazas: ${group.availablePlaces}`
      }))
    },
  },
  watch: {
    getStudentsByCenter: {
      immediate: true,
      handler(students) {
        if(students.length) {
          const activeStudent = students.find(student => student.id === parseInt(this.$route.params.studentId))
          this.form.studentName = `${activeStudent.name} ${activeStudent.surnames}`
        }
      }
    }
  },
  methods: {
    ...mapActions('AdminCenter', ['fetchStudents']),
    ...mapActions('Admin', ['fetchGroupList', 'studentChangeGroup']),
    async changeNewGroup() {
      if (!this.v$.$invalid) {
        try {
          const newGroup = {
            enrollment: parseInt(this.$route.params.enrollmentId),
            group: this.form.group
          }
          await this.studentChangeGroup({ centerId: this.$route.params.id, studentId: this.$route.params.studentId, newGroup })
          this.$toast.success('Grupo actualizado correctamente')
          setTimeout(() => {
            this.$router.push({ name: 'enrollments', params: { id: this.$route.params.id } })
          }, 3000)
        } catch (error) {
          this.$toast.error('Lo sentimos, no hemos podido actualizar el grupo correctamente, inténtelo de nuevo')
        }
      }
    }
  },
  async mounted() {
    try {
      this.isLoading = true
      await this.fetchStudents(this.$route.params.id)
      await this.fetchGroupList({ centerId: this.$route.params.id })
      this.isLoading = false
    } catch (error) {
      console.log(error)
    }

  }
}
</script>

<style scoped>

</style>
