import { createStore } from 'vuex'
import Auth from './modules/auth.store'
import Students from './modules/students.store'
import User from './modules/user.store'
import Admin from './modules/admin.store'
import Leads from './modules/leads.store'
import AdminCenter from './modules/admin.center.store'
import Tutors from './modules/admin.tutors.store'
import AdminCamps from './modules/admin.camps.store'
import TutorCamps from './modules/tutor.camps.store'

// import createLogger from 'vuex/dist/logger'
// const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  modules: {
    Auth,
    Students,
    User,
    Admin,
    Leads,
    AdminCenter,
    Tutors,
    AdminCamps,
    TutorCamps
  }
})
