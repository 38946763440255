<template>
  <div class="main">
    <Loading v-if="isLoading" />
    <div v-else-if="!hasContent" class="message__noData">
      <div class="message__noData-icon">
        <svg width="13" height="36" viewBox="0 0 13 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.880469 -2.38419e-06H12.1805L10.2805 22.25H2.73047L0.880469 -2.38419e-06ZM6.53047 35.45C4.8638 35.45 3.49714 34.95 2.43047 33.95C1.3638 32.9167 0.830469 31.6667 0.830469 30.2C0.830469 28.7 1.3638 27.4667 2.43047 26.5C3.49714 25.5333 4.8638 25.05 6.53047 25.05C8.19714 25.05 9.54714 25.5333 10.5805 26.5C11.6471 27.4667 12.1805 28.7 12.1805 30.2C12.1805 31.6667 11.6471 32.9167 10.5805 33.95C9.5138 34.95 8.1638 35.45 6.53047 35.45Z" fill="white"/>
        </svg>
      </div>
      <div class="message__noData-text">
        <p>No hay alumn@s para mostrar en este campamento / taller</p>
        <p>Prueba seleccionando otro campamento / taller</p>
      </div>
    </div>
    <div v-else>
      <div class="csv__header">
        <h2 class="subtitle">Alumn@s del campamento / taller  {{campPeriods[0].camp.name}}:</h2>
        <button
          class="btn csv__button"
          type="button"
          @click="downloadCSV">
          <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
            <path d="M22 24h-20v-24h14l6 6v18zm-7-23h-12v22h18v-16h-6v-6zm3 15v1h-12v-1h12zm0-3v1h-12v-1h12zm0-3v1h-12v-1h12zm-2-4h4.586l-4.586-4.586v4.586z"/>
          </svg>
          <span class="csv__button__text">Descargar CSV</span>
        </button>
      </div>
      <div v-for="(period, idx) in campPeriods" :key="idx">
        <div v-if="period.campHirings.length !== 0" class="camp-students">
          <h4>{{ period.campHirings.length }} alumnos</h4>
          <h3 :class="{ visible: (now >= period.dateStart && now < period.dateEnd) }" @click="e => e.target.classList.toggle('visible')">
            <svg width="15" height="7" viewBox="0 0 15 7" fill="none" xmlns="http://www.w3.org/2000/svg" data-v-5f460ce3=""><path d="M7.5 7L0.138785 0.249999L14.8612 0.25L7.5 7Z" fill="#00ADCE" data-v-5f460ce3=""></path></svg>
            Período {{formatDateShort(period.dateStart)}} - {{formatDateShort(period.dateEnd)}}
          </h3>
          <ul class="camp-students-list">
            <li v-for="(campHiring, index) in period.campHirings" :key="index">
              <h5>{{ campHiring.student.name }} {{ campHiring.student.surnames }} <strong>{{ campHiring.campService.name }}</strong></h5>
              <ul class="basic-data">
                <li class="birthdate">
                  <svg xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 24 24"><path d="M16.441 24h-8.886l-3.555-8h2.999l2 4-.013-4h2.012l1 4 .999-4h2.037l-.037 4 1.999-4h3l-3.555 8zm-5.441-16.941v-2.059h2v2.063c2.783.349 4.844 2.103 5.984 3.937h.016c1.104 0 2 .896 2 2s-.896 2-2 2h-14c-1.104 0-2-.896-2-2 0-1.098.887-1.991 1.983-2 1.131-1.855 3.202-3.604 6.017-3.941zm-2.75 4.441c.414 0 .75.336.75.75s-.336.75-.75.75-.75-.336-.75-.75.336-.75.75-.75zm7.5 0c.414 0 .75.336.75.75s-.336.75-.75.75-.75-.336-.75-.75.336-.75.75-.75zm-3.75-1.5c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm-.847-5.477c-1.897-.621-1.351-3.444.89-4.523.08 1.422 1.957 1.566 1.957 3.002 0 .602-.442 1.274-1.084 1.521.154-.509-.185-1.415-.88-1.809-.702.407-1.064 1.302-.883 1.809z"/></svg>
                  Nacimiento: {{ campHiring.student.dayOfBirth }}/{{ campHiring.student.monthOfBirth }}/{{ campHiring.student.year }}
                </li>
                <li class="allow-pictures" :class="{ disallowed: !campHiring.student.imageAuthorized }">
                  <svg xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 24 24"><path d="M4.348 21.241l4.185-7.249 5.67 9.806c-.714.133-1.45.202-2.203.202-2.907 0-5.575-1.036-7.652-2.759zm18.97-5.247c-1.182 3.345-3.806 6.012-7.124 7.252l-4.187-7.252h11.311zm-14.786-6l-5.656 9.797c-1.793-2.097-2.876-4.819-2.876-7.791 0-.684.057-1.354.167-2.006h8.365zm12.583-5.795c1.798 2.098 2.885 4.824 2.885 7.801 0 .679-.057 1.345-.165 1.994h-8.373l5.653-9.795zm-11.305-3.999c.71-.131 1.442-.2 2.19-.2 2.903 0 5.566 1.033 7.642 2.751l-4.18 7.24-5.652-9.791zm2.19 7.794h-11.314c1.186-3.344 3.812-6.008 7.132-7.244l4.182 7.244z"/></svg>
                  <template v-if="campHiring.student.imageAuthorized">
                    Permite fotografías
                  </template>
                  <template v-else>
                    <strong> No</strong> permite fotografías
                  </template>
                </li>
                <li class="allergies" v-if="campHiring.student.allergies.length > 0">
                  <svg xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 24 24"><path d="M11.415 12.393l1.868-2.289c.425-.544.224-.988-.055-2.165-.205-.871-.044-1.854.572-2.5 1.761-1.844 5.343-5.439 5.343-5.439l.472.37-3.693 4.728.79.617 3.87-4.59.479.373-3.558 4.835.79.618 3.885-4.58.443.347-3.538 4.85.791.617 3.693-4.728.433.338s-2.55 4.36-3.898 6.535c-.479.771-1.425 1.161-2.334 1.167-1.211.007-1.685-.089-2.117.464l-2.281 2.795c2.445 2.962 4.559 5.531 5.573 6.829.771.987.065 2.421-1.198 2.421-.42 0-.853-.171-1.167-.573l-8.36-10.072s-.926.719-1.944 1.518c-3.172-5.184-6.267-11.661-6.267-13.955 0-.128-.034-.924.732-.924.245 0 .493.116.674.344.509.642 5.415 6.513 10.002 12.049m-2.952 3.617l1.953 2.365-4.115 5.055c-.295.378-.736.576-1.182.576-1.198 0-1.991-1.402-1.189-2.428l4.533-5.568z" fill="#030405"/></svg>
                  <strong>Alergias:</strong> <span v-for="(allergies, idx) in campHiring.student.allergies" :key="idx">{{ allergies.name }}</span>
                </li>
                <li class="allergies empty" v-else>
                  <svg xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 24 24"><path d="M11.415 12.393l1.868-2.289c.425-.544.224-.988-.055-2.165-.205-.871-.044-1.854.572-2.5 1.761-1.844 5.343-5.439 5.343-5.439l.472.37-3.693 4.728.79.617 3.87-4.59.479.373-3.558 4.835.79.618 3.885-4.58.443.347-3.538 4.85.791.617 3.693-4.728.433.338s-2.55 4.36-3.898 6.535c-.479.771-1.425 1.161-2.334 1.167-1.211.007-1.685-.089-2.117.464l-2.281 2.795c2.445 2.962 4.559 5.531 5.573 6.829.771.987.065 2.421-1.198 2.421-.42 0-.853-.171-1.167-.573l-8.36-10.072s-.926.719-1.944 1.518c-3.172-5.184-6.267-11.661-6.267-13.955 0-.128-.034-.924.732-.924.245 0 .493.116.674.344.509.642 5.415 6.513 10.002 12.049m-2.952 3.617l1.953 2.365-4.115 5.055c-.295.378-.736.576-1.182.576-1.198 0-1.991-1.402-1.189-2.428l4.533-5.568z" fill="#030405"/></svg>
                  Sin alergias.
                </li>
                <template v-if="campHiring.responseToCampOptions.length !== 0">
                  <li v-for="(option, i) in authorizationOptions" :key="i" class="options">
                    {{ option }}: <span class="option-value">{{ campHiring.responseToCampOptions[option] ? 'Sí' : 'No' }}</span>
                  </li>
                </template>
              </ul>
              <button @click="openModal('authorized', campHiring.student.id)">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M10.644 17.08c2.866-.662 4.539-1.241 3.246-3.682-3.932-7.427-1.042-11.398 3.111-11.398 4.235 0 7.054 4.124 3.11 11.398-1.332 2.455.437 3.034 3.242 3.682 2.483.574 2.647 1.787 2.647 3.889v1.031h-18c0-2.745-.22-4.258 2.644-4.92zm-12.644 4.92h7.809c-.035-8.177 3.436-5.313 3.436-11.127 0-2.511-1.639-3.873-3.748-3.873-3.115 0-5.282 2.979-2.333 8.549.969 1.83-1.031 2.265-3.181 2.761-1.862.43-1.983 1.34-1.983 2.917v.773z"/></svg>
                {{ campHiring.personsAuthorized.length > 0 ? "Ver " + (campHiring.personsAuthorized.length + 1) + " personas autorizadas" : "Ver 1 persona autorizada" }}
              </button>
              <button @click="openModal('payments', campHiring.student.id)" v-if="campHiring.payments.length > 0">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 8v-2c0-1.104.896-2 2-2h20c1.104 0 2 .896 2 2v2h-24zm24 3v7c0 1.104-.896 2-2 2h-20c-1.104 0-2-.896-2-2v-7h24zm-15 5h-6v1h6v-1zm3-2h-9v1h9v-1zm9 0h-3v1h3v-1z"/></svg>
                {{ campHiring.payments.length > 1 ? "Ver " + campHiring.payments.length + " pagos" : "Ver 1 pago" }}
              </button>
              <button class="disabled" v-else>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 8v-2c0-1.104.896-2 2-2h20c1.104 0 2 .896 2 2v2h-24zm24 3v7c0 1.104-.896 2-2 2h-20c-1.104 0-2-.896-2-2v-7h24zm-15 5h-6v1h6v-1zm3-2h-9v1h9v-1zm9 0h-3v1h3v-1z"/></svg>
                No hay pagos
              </button>
              <button @click="openModal('observations', campHiring.student.id)" v-if="campHiring.student.observations">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 0l-12 12 12 12 12-12-12-12zm-1 6h2v8h-2v-8zm1 12.25c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z"/></svg>
                Ver observaciones
              </button>
              <button class="disabled" v-else>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 0l-12 12 12 12 12-12-12-12zm-1 6h2v8h-2v-8zm1 12.25c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z"/></svg>
                Sin observaciones
              </button>
              <button  @click="openModal('annotations', campHiring.student.id, campHiring.annotations)">
                <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="800px" height="800px" viewBox="0 0 24 24" data-name="Layer 1"><path d="M16,14H8a1,1,0,0,0,0,2h8a1,1,0,0,0,0-2Zm0-4H10a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2Zm4-6H17V3a1,1,0,0,0-2,0V4H13V3a1,1,0,0,0-2,0V4H9V3A1,1,0,0,0,7,3V4H4A1,1,0,0,0,3,5V19a3,3,0,0,0,3,3H18a3,3,0,0,0,3-3V5A1,1,0,0,0,20,4ZM19,19a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V6H7V7A1,1,0,0,0,9,7V6h2V7a1,1,0,0,0,2,0V6h2V7a1,1,0,0,0,2,0V6h2Z"/></svg>
                Notas privadas
              </button>
              <router-link :to="{ name: 'student-detail', params: { id: id, studentId: campHiring.student.id } }">
                <button>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z"/></svg>
                  Ver ficha completa
                </button>
              </router-link>
              <button @click="deleteStudentFromCamp(campHiring.student.id, period.id)" class="delete-student-btn">Eliminar alumn@ del campamento</button>
              <div @click.self="closeModal()" class="camp-detail-modal-overlay" :class="{ visible: isModalOpen &&  currentStudentId === campHiring.student.id}">
                <div class="camp-detail-modal" :class="{'scrolling-table': currentType === 'payments'} ">
                  <svg 
                    @click="closeModal()" 
                    xmlns="http://www.w3.org/2000/svg" 
                    viewBox="0 0 24 24">
                    <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" fill="#00ADCE" />
                  </svg>
                  <div class="modal-content">
                    <div :class="[{'wrapper': currentType === 'authorized' }, {'hidden': currentType !== 'authorized'}]" id="authorized">
                      <h4>Personas autorizadas</h4>
                      <h5>Tutor principal:</h5>
                      <span>
                        {{ campHiring.student.tutor.name }} 
                        {{ campHiring.student.tutor.surnames }}
                        (Teléfono principal: {{ campHiring.student.tutor.phone }}
                        <template v-if="campHiring.student.tutor.phoneSecondary">, Tlfn. alternativo: {{ campHiring.student.tutor.phoneSecondary }}</template>)
                      </span>
                    </div>
                    <div :class="[{'wrapper': currentType === 'observations' }, {'hidden': currentType !== 'observations'}]" id="observations">
                      <h4>Notas del alumno</h4>
                      <span>{{ campHiring.student.observations }}</span>
                    </div>
                    <div :class="[{'wrapper': currentType === 'payments'}, {'hidden': currentType !== 'payments'}]" id="payments">
                      <h4>Relación de pagos</h4>
                      <table class="admin__table">
                        <thead class="admin__table__head">
                          <td class="admin__table__head__cell">Fecha</td>
                          <td class="admin__table__head__cell">Importe</td>
                          <td class="admin__table__head__cell">Concepto</td>
                          <td class="admin__table__head__cell">Pagado</td>
                        </thead>
                        <tbody v-for="(payment, idxPayment) in campHiring.payments" :key="payment.id" class="admin__table__body">
                          <tr>
                            <td class="admin__table__body__cell"> <span class="admin__table__body__cell__header">Fecha</span><span class="value">{{ formatDate(payment.orderAt) }}</span></td>
                            <td class="admin__table__body__cell"> <span class="admin__table__body__cell__header">Importe</span> <span class="value">{{ formatPrice(payment.price) }}</span></td>
                            <td class="admin__table__body__cell"> <span class="admin__table__body__cell__header">Concepto</span> <span class="value subject">{{ payment.subject }}</span></td>
                            <td class="admin__table__body__cell" :class="{'last-child': idxPayment !== campHiring.payments.length - 1}">
                              <span class="admin__table__body__cell__header">Pagado</span>
                              <span v-if="payment.paidAt" class="value">{{ formatDate(payment.paidAt) }}</span>
                              <strong v-else class="red">Pendiente</strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div :class="[{'wrapper': currentType === 'annotations'}, {'hidden': currentType !== 'annotations'}]" id="annotations">
                      <h4>Anotaciones</h4>
                      <textarea 
                        name="" 
                        :id="campHiring.student.id" 
                        cols="30" 
                        rows="10" 
                        class="textarea" 
                        v-model="annotations"
                        >
                      </textarea> 
                      <div class="container-btn">
                        <button @click="updateStudentNote(campHiring.student.id, annotations)" 
                        class="btn" 
                        id="btn">{{btnLabel}}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Loading from '../../Loading.vue'
import { formatDateShort, formatDate, formatPrice } from '@/commonMethods/methods'
import { apiClient } from '../../../services/ApiClient'

export default {
  name: 'CampsStudentsList',
  components: {
    Loading
  },
  props: {
    id: {
      type: String,
      required: true
    },
    campId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      isModalOpen: false,
      campPeriods: [],
      authorizationOptions: [],
      currentType: '',
      currentStudentId: '',
      annotations: '',
      btnLabel: 'Guardar',
      formatDateShort,
      formatDate,
      formatPrice
    }
  },
  computed: {
    hasContent() {
      let result = false
      this.campPeriods.forEach(element => {
        if(element.campHirings.length > 0) {
          result = true
        }
      })
      return result
    },
    now() {
      return new Date().getTime() / 1000
    }
  },
  methods: {
    ...mapActions('AdminCamps', ['fetchCampsStudents']),
    ...mapActions('Admin',['fetchCSVToken']),
    openModal(type, studentId, annotations) {
      this.currentType = type
      this.currentStudentId = studentId
      this.annotations = annotations
      this.isModalOpen = true
    },
    closeModal() {
      this.isModalOpen = false
      this.currentStudentId = 0
      this.annotations = ''
      this.currentType = ''
    }, 
    async updateStudentNote(student) {
      this.btnLabel = 'Guardando'
      try {
        await apiClient.post(`/admin/centers/${this.id}/camps/${this.campId}/camp-hirings/annotations`, {
          student,
          annotations: this.annotations
        })
        this.campPeriods = await this.fetchCampsStudents({centerId: this.id, campId: this.campId})
        this.$toast.success('Nota guardada correctamente.')
      } catch (error) {
        this.$toast.error('Ha ocurrido un error, inténtalo de nuevo.')
      } finally {
        this.btnLabel = 'Guardar'
        this.isModalOpen = false
      }
    },
    async downloadCSV() {
      try {
        const response = await this.fetchCSVToken()
        const csv = `${process.env.VUE_APP_API_BASE_URI}/admin/centers/${this.id}/camp/${this.campId}/camp-hirings.csv?token=${response.data.token}`
        window.open(csv)
      } catch (error) {
        this.$toast.error('Lo sentimos, no hemos podido descargar el CSV, inténtelo de nuevo');
      }
    },
    async deleteStudentFromCamp(studentId, campPeriodId) {
      if (confirm('¿Estás seguro de que quieres eliminar a este alumn@ del campamento?')) {
        this.isLoading = true
        try {
          await apiClient.delete(`/admin/centers/${this.id}/camps/${this.campId}/camp-hirings/camp-periods/${campPeriodId}/students/${studentId}/delete`)
          const response = await this.fetchCampsStudents({centerId: this.id, campId: this.campId})

          response.forEach(resp => resp.campHirings.forEach(camp => this.authorizationOptions = Object.keys(camp.responseToCampOptions)))
          this.campPeriods = response

          this.$toast.success('Alumn@ eliminado correctamente.')

          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          })
        } catch (error) {
          this.$toast.error('Ha ocurrido un error, inténtelo de nuevo.')
        } finally {
          this.isLoading = false
        }
      }
    }
  },
  async mounted() {
    this.isLoading = true
    await this.fetchCampsStudents({centerId: this.id, campId: this.campId})
    .then(response => {
      response.forEach(resp => resp.campHirings.forEach(camp => this.authorizationOptions = Object.keys(camp.responseToCampOptions)))
      this.campPeriods = response
    })
    .catch(errors => {
      console.log('errors', errors)
    })
    .finally(() => {
      this.isLoading = false
    })
  }
}
</script>
<style lang="scss" scoped>

.camp-students {
  padding: 20px;
  margin-bottom: 30px;
  background-color: $newSoftBlue;
  border-radius: 18px;
  width: calc(100% - 60px);
  h3 {
    color: $newBlue;
    margin: 0;
    cursor: pointer;
    svg {
      position: relative;
      top: -2px;
      transition: all .2s linear;
      margin-right: 5px;
    }
    &.visible {
      &+ul.camp-students-list {
        display: inline-block;
      }
      svg {
        transform: rotate(-90deg);
      }
    }
  }
  h4 {
    float: right;
    margin-top: 0;
    pointer-events: none;
  }
  ul.camp-students-list {
    display: none;
    margin: 0;
    width: 100%;
    text-align: left;
    &>li {
      border-bottom: 1px solid $newBlue;
      padding-bottom: 20px;
      margin-bottom: 30px;
      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
      }
      h5 {
        font-size: 20px;
        font-weight: 700;
        margin: 0;
        strong {
          color: $newPink;
          font-size: 14px;
          display: inline-block;
          margin-left: 10px;
          text-transform: uppercase;
        }
      }
      p {
        margin: 0 0 8px 0;
      }
      span {
        &:not(:last-child) {
          &::after {
            content: ' '
          }
        }
      }


      ul.basic-data {
        margin: 15px 0 20px;
        padding: 0;
        list-style: none;
        li {
          display: inline-block;
          margin: 0;
          margin-right: 20px;
          font-size: 14px;
          svg {
            width: 16px;
            height: 16px;
            vertical-align: middle;
            position: relative;
            top: -2px;
            margin-right: 5px;
            display: inline-block;
            path {
              fill: $newBlue;
            }
          }
          .option-value {
            font-weight: 600;
          }
        }
        li:nth-child(5) {
          margin-top: 10px;
        }
      }
      button {
        border-radius: 12px;
        border: 1px solid $newBlue;
        background-color: white;
        padding: 4px 14px;
        display: inline-block;
        margin: 6px;
        margin-bottom: 0;
        cursor: pointer;
        svg {
          width: 12px;
          height: 12px;
          display: inline-block;
          margin-right: 6px;
          position: relative;
          top: 0;
          vertical-align: middle;
        }
        &.disabled {
          opacity: .4;
          pointer-events: none;
          cursor: not-allowed;
        }
        &:last-child {
          background-color: $newBlue;
          color: white;
          font-weight: bold;
          svg {
            path {
              fill: white;
            }
          }
        }
      }
      .delete-student-btn {
        border: 1px solid $error;
        color: $error;
        margin-top: 16px;
      }
    }
    .options {
      margin-top: 100px;
    }
    .annotations {
      font-size: 14px;
      margin: 10px;
      margin-left: 0;
      margin-bottom: 20px;
      &__title {
        font-weight: 600;
      }
    }
  }
}
.camp-detail-modal-overlay {
  display: none;
  &.visible {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(black, .1);
    z-index: 10;
    // cursor: pointer;
  }
  .camp-detail-modal {
    background: white;
    border-radius: 20px;
    max-width: 700px;
    margin: 0 30px;
    padding: 25px;
    height: auto;
    overflow: hidden;
    width: 100%;
    &>svg {
      float: right;
      cursor: pointer;
      width: 24px;
      height: 24px;
      margin-bottom: -25px;
    }
    .modal-content {
      .wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        h4 {
        font-size: 18px;
        margin: 0;
        margin-bottom: 1rem;
      }
        h5 {
          margin-bottom: .5rem;
          font-size: 16px;
        }
        p {
          margin: 0;
          line-height: 1.8;
          white-space: pre;
        }
        strong.red {
          color: red;
        }
        table {
          margin: 30px auto;
        }
        thead td {
          cursor: auto;
          text-align: center;
          font-weight: bold;
          padding: 5px 20px;
        }
        tbody td {
          cursor: auto;
          text-align: center;
          padding: 5px 20px;
          @include desktop {
            padding: 16px 20px;
          }
          .value {
            font-weight: 100;
          }
          .subject {
            line-height: 20px;
            text-align: end;
            width: 250px;
          }
        }
        .textarea {
          border: 1px solid $newBlue;
          margin-bottom: 10px;
          margin: 10px 0;
          resize: none;
          padding-left: 2px;
          width: 100%;
        }
        .container-btn {
          display: flex;
          justify-content: center;
          width: 100%;
          button {
            padding: 10px 30px;
            border-radius: 16px;
          }
        }
      }
    }
  }
  .scrolling-table {
    height: 600px;
    overflow: scroll;
    @include desktop {
      height: auto;
      overflow: hidden;
    }
  }
}
.hidden {
  display: none;
}
.last-child {
  border-bottom: 2px solid #45B8AC;
  @include desktop {
    border-bottom: 0;
  }
}
</style>
