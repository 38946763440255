<template>
  <div class="main">
    <h2 class="subtitle">Contacta con nosotros</h2>
    <p class="text">Si tienes cualquier duda o sugerencia no dudes en contactar con nosotros</p>
    <form action class="form" @submit.prevent="contact">

      <div class="form__field-row">
        <div class="form__field-wrapper">
          <div class="form__field">
            <label class="form__field-label" for="#subject">Asunto *</label>
            <input
              v-model="v$.form.subject.$model"
              class="form__field-input"
              type="text"
              id="subject"
              required
              placeholder=""
            >
          </div>
        </div>
      </div>

      <div class="form__field-wrapper">
        <div class="form__field">
          <label class="form__field-label" for="#comment">Comentarios *</label>
          <textarea
            v-model="v$.form.comment.$model"
            placeholder=""
            rows="8"
            no-resize
            class="form__field-input text-area"
            required
          >
          </textarea>
        </div>
      </div>

      <div class="form__field-wrapper">
        <div class="form__field">
          <label class="form__field-label" for="center">Centros *</label>
          <Select2
              class="select2"
              v-model="v$.form.center.$model"
              :options="formCenters"
              :settings="{  placeholder: 'Selecciona un centro', width: '100%' }">
          </Select2>
        </div>
      </div>

      <button class="btn">Enviar</button>

      <div class="errors" v-for="(error, key) in errors" :key="key">
        <p>{{ $t(error) }}</p>
      </div>

    </form>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { mapActions, mapGetters } from 'vuex'
import Select2 from 'vue3-select2-component'
import { required } from '@vuelidate/validators'

export default {
  name: 'ContactUs',
  components: {
    Select2
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      form: {
        subject: '',
        comment: '',
        center: ''
      },
      errors: {}
    }
  },
  computed: {
    ...mapGetters('Students', {
      centers: 'getTutorCenters'
    }),
    formCenters() {
      return this.centers.map(center => ({
        id: center.id,
        text: center.name
      }))
    }
  },
  validations() {
    return {
      form: {
        subject: {
          required
        },
        comment: {
          required
        },
        center: {
          required
        }
      }
    }
  },
  methods: {
    ...mapActions('User', ['tutorSendContactEmail']),
    ...mapActions('Students', ['fetchCenters']),
    async contact() {
      if (!this.v$.$invalid) {
        this.tutorSendContactEmail(this.form)
          .then(() => {
            this.$router.push({ name: 'parents-main-screen', params: { id: this.id } })
            this.$toast.success('Datos enviados correctamente');
          })
          .catch(errors => {
            this.errors = errors
          })
      }
    }
  },
  async mounted() {
    await this.fetchCenters()
  }
}
</script>

<style scoped lang="scss">
.form {
  display: block;
  border-radius: 4px;
  max-width: 1200px;
  .field-passwords {
    background-color: $bg-table-header;
    padding: 1em 2em;
    margin-bottom: 3em;
  }
  &__field-row {
    width: 100%;
    &.bank-account {
      .form__field-wrapper {
        width: 100%;
      }
    }
    .form__field-wrapper {
      width: 100%;
      .form__field {
        display: flex;
        flex-direction: column;
        .text-area {
          resize: none;
        }
      }
    }
  }
  @include tablet {
    &__field-wrapper {
      .input-errors {
        max-width: 300px;
      }
    }
    &__field {
      .form-input {
        min-width: 300px;
      }
    }
    &__field-row {
      justify-content: space-between;
      .form__field-wrapper {
        width: 48%;
      }
    }
  }
}

.select2 {
  width: 100%;

  :deep {

    .select2-container--default {
      color: red;
      .select2-selection--single {
        border: 1px solid $newBlue;
        border-radius: 0;
        height: 41px;
        padding: 8px 12px;
        text-align: left;

        .select2-selection__arrow {
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}
</style>
