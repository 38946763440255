<template>
  <div class="menu" :class="[visibleMenu ? 'visible_menu' : '']">
    <a href="#" class="menu__on" :class="[visibleMenu ? 'visible_menu' : '']" id="menu__on" @click="toggleClass()">
      <span></span>
      <span></span>
      <span></span>
    </a>
    <div class="menu__logo-wrapper">
      <router-link :to="{ name: 'admin-main-screen' }" @click="closeOnClick">
        <img class="menu__logo" alt="Vue logo" src="../../assets/logo.png">
      </router-link>
    </div>
    <div class="menu_box">
      <div v-if="activeCenter" class="menu__centers-list">
        <div class="menu__centers-list-item" @click="toogleCenter" :class="{'open': open}">{{ activeCenter ? activeCenter.name : 'Centros' }}
          <svg width="15" height="7" viewBox="0 0 15 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.5 7L0.138785 0.249999L14.8612 0.25L7.5 7Z" fill="white"/>
          </svg>
        </div>
        <ul v-if="open" class="admin__area admin__area__center">
          <li
            v-for="(center, idx) in centers"
            :key="`center--${idx}`"
            class="admin__area-item admin__area-item__center"
            :class="{'admin__area-item--selected': center.id === activeCenter.id }"
            @click="changeCenter(center.id)">
            {{center.name}}
          </li>
        </ul>
        <ul class="admin__area">
          <li class="admin__area-item">
            <router-link :to="{ name: 'groups', params: { id: activeCenter.id } }" @click="closeOnClick">Grupos</router-link>
          </li>
          <li class="admin__area-item">
            <router-link :to="{ name: 'sessions', params: { id: activeCenter.id } }" @click="closeOnClick">Sesiones</router-link>
          </li>
          <li class="admin__area-item">
            <router-link :to="{ name: 'students', params: { id: activeCenter.id } }" @click="closeOnClick">Alumn@s</router-link>
          </li>
          <li class="admin__area-item" v-if="getUserInfo.isSupervisor">
            <router-link :to="{ name: 'enrollments', params: { id: activeCenter.id } }" @click="closeOnClick">Matrículas</router-link>
          </li>
          <li class="admin__area-item" v-if="getUserInfo.isSupervisor">
            <router-link :to="{ name: 'enrollments-payment-error', params: { id: activeCenter.id } }" @click="closeOnClick">Matrículas en error</router-link>
          </li>
          <li class="admin__area-item" v-if="getUserInfo.isSupervisor">
            <router-link :to="{ name: 'payments', params: { id: activeCenter.id } }" @click="closeOnClick">Historial de pagos</router-link>
          </li>
          <li class="admin__area-item" v-if="getUserInfo.isSupervisor">
            <router-link :to="{ name: 'teachers', params: { id: activeCenter.id } }" @click="closeOnClick">Profesores</router-link>
          </li>
          <li class="admin__area-item" v-if="getUserInfo.isSupervisor">
            <router-link :to="{ name: 'leads', params: { id: activeCenter.id } }" @click="closeOnClick">Leads</router-link>
          </li>
          <li class="admin__area-item" v-if="getUserInfo.isSupervisor">
            <router-link :to="{ name: 'tutors', params: { id: activeCenter.id } }" @click="closeOnClick">Tutores</router-link>
          </li>
          <li class="admin__area-item" v-if="getUserInfo.isSupervisor">
            <router-link :to="{ name: 'camps', params: { id: activeCenter.id } }" @click="closeOnClick">Campamentos / Talleres</router-link>
          </li>
          <li class="admin__area-item" v-if="getUserInfo.isSupervisor">
            <router-link :to="{ name: 'payment-methods', params: { id: activeCenter.id } }" @click="closeOnClick">Métodos de pago</router-link>
          </li>
          <li class="admin__area-item">
            <router-link :to="{ name: 'reports', params: { id: activeCenter.id } }" @click="closeOnClick">Informes</router-link>
          </li>
          <li class="admin__area-item">
            <router-link :to="{ name: 'academic-year-change', params: { id: activeCenter.id } }" @click="closeOnClick">Cambio de curso escolar</router-link>
          </li>
        </ul>
        <ul class="admin__area" v-if="activeCenter == null" :class="[hiddenCenters ? 'hidden' : '']">
          <li class="admin__area-item" v-for="(item) in getCentersList" :key="item.id">
            <router-link :to="{ name: 'groups', params: { id: item.id } }" @click="closeOnClick">{{ item.name }}</router-link>
          </li>
        </ul>
      </div>
      <div v-else class="menu__centers-list">
        <div class="menu__centers-list-item" @click="toogleCenter" :class="{'open': open}">Selecciona centro
          <svg width="15" height="7" viewBox="0 0 15 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.5 7L0.138785 0.249999L14.8612 0.25L7.5 7Z" fill="white"/>
          </svg>
        </div>
        <ul v-if="open" class="admin__area admin__area__center">
          <li
            v-for="(center, idx) in centers"
            :key="`center--${idx}`"
            class="admin__area-item admin__area-item__center"
            @click="changeCenter(center.id)">
            {{center.name}}
          </li>
        </ul>
      </div>
      <ul class="center__area" v-if="getUserInfo.isSuperAdmin">
        <li class="center__area-item">
          <router-link :to="{ name: 'centers' }" @click="closeOnClick">Editar centros</router-link>
        </li>
        <li class="center__area-item">
          <router-link :to="{ name: 'services-edit' }" @click="closeOnClick">Editar servicios</router-link>
        </li>
        <li class="center__area-item">
          <router-link :to="{ name: 'allergies' }" @click="closeOnClick">Editar alergias</router-link>
        </li>
        <li class="center__area-item">
          <router-link :to="{ name: 'holidays' }" @click="closeOnClick">Editar festivos</router-link>
        </li>
        <li class="center__area-item">
          <router-link :to="{ name: 'students-without-center' }" @click="closeOnClick">Ver alumnos sin centro</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'Sidebar',
  props: {
    id: {
      type: String
    }
  },
  data() {
    return {
      visibleMenu: false,
      open: false,
      hidden: true,
      isOpenCenters: false,
      hiddenCenters: true
    }
  },
  computed: {
    ...mapState(['Admin']),
    ...mapGetters('Admin', {
      returnActiveCenter: 'getActiveCenter',
      centers: 'getCentersList'
    }),
    ...mapGetters('Admin', ['getCentersList', 'getUserInfo']),
    activeCenter() {
      return this.returnActiveCenter(this.$route.params.id)
    }
  },
  methods: {
    ...mapActions('Auth', {
      logout: 'logout',
      userInfo: 'userInfo'
    }),
    ...mapActions('Admin', ['fetchCenters']),
    toggleClass(){
      this.visibleMenu = !this.visibleMenu;
      this.$emit('on-visible-menu');
    },
    closeOnClick() {
      if(window.innerWidth < 768) {
        this.visibleMenu = false;
      }
    },
    sidebar() {
      if(window.innerWidth >= 768) {
        this.$emit('on-visible-menu');
        this.visibleMenu = true;
      } else {
        this.visibleMenu = false;
      }
    },
    openCenter() {
      this.open = !this.open
      this.hidden = !this.hidden
    },
    openCenters() {
      this.isOpenCenters =! this.isOpenCenters
      this.hiddenCenters =! this.hiddenCenters
    },
    toogleCenter() {
      this.open = !this.open
    },
    changeCenter(centerId) {
      this.$router.push({ name: 'groups', params: { id: centerId } })
      this.open = false
    }
  },
  async mounted() {
    this.sidebar()
    try {
      await this.fetchCenters()
    } catch (error) {
      console.log('error', error)
    }
  }
}
</script>

<style scoped lang="scss">
.menu {
  width: 100%;
  position: absolute;
  z-index: 5;
  top: 0;
  bottom: 0;
  left: -100%;
  display: flex;
  flex-direction: column;
  transition: all .8s ease;
  background-color: $white;
  &.visible_menu {
    overflow-y: auto;
    left: 0;
  }
  .menu_box {
    background-color: $white;
    margin: 20px;
    padding: 20px;
    border-radius: 22px;

    .center__area {
      .center__area-item {
        margin: 20px;
        a {
          text-decoration: none;
          color: $black;
        }
      }
    }
  }
  .menu__on {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(60px);
    transition: all .8s ease;
    display: inline-block;
    margin: 20px;
    width: 30px;
    height: 20px;
    z-index: 2;
    span {
      width: 100%;
      height: 4px;
      border-radius: 4px;
      display: block;
      position: absolute;
      background-color: $bg-green;
      transition: all .8s ease;
      transform-origin : 0px 100%;
      &:nth-child(2) {
        top: calc(50% - 2px);
        background-color: $bg-red;
      }
      &:nth-child(3) {
        bottom: 0;
        background-color: $bg-blue;
      }
    }
    &.visible_menu {
      transform: translate(0);
      span {
        &:nth-child(1) {
          transform: rotate(45deg) translate(-2px, 1px);
        }
        &:nth-child(2) {
          transform: translate(-50px);
          opacity: 0;
        }
        &:nth-child(3) {
          transform: rotate(-45deg) translate(-3px, 3px);
        }
      }
    }
    @include small-desktop {
      display: none !important;
    }
  }
  .menu__logo-wrapper {
    display: flex;
    position: relative;
    margin-top: 30px;
    a {
      margin: 0 auto;
      .menu__logo {
        margin: 20px auto;
        width: 180px;
        height: auto;
      }
    }
  }

  .menu__centers-list {
    .menu__centers-list-item {
      margin: 0;
      padding: 3px 30px;
      background-color: $newBlue;
      border-radius: 16px;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-decoration: none;
      color: $white;
      font-weight: 700;
      height: 46px;
      @include disable-selection;
      svg {
        transition: transform .3s ease;
      }
      &.open {
        border-radius: 16px 16px 0 0;
        svg {
          transform: rotate(-180deg);
        }
      }
    }
    .admin__area {
      border-bottom: 1px solid $newLightBlue;
      .admin__area-item {
        margin: 20px;
        cursor: pointer;
        a {
          text-decoration: none;
          color: $black;
          &.router-link-active {
            font-weight: 700;
          }
        }

        &--selected {
          color: $black;
          font-weight: 700;
        }
      }
      .admin__area-item__center {
        &:first-child {
          margin-top: 0;
          padding-top: 20px;
        }
      }
      &.hidden {
        display: none;
      }
    }
    .admin__area__center {
      background-color: #d5f2f8;
      border: 1px solid $newBlue;
      border-radius: 0 0 16px 16px;
      margin-top: 0;
    }
  }
  @include tablet {
    width: 340px;
    left: 0 !important;
    background-color: transparent;
    &.open_default {
      overflow-y: auto;
      left: 0;
    }
  }
}
</style>
