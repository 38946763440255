<template>
  <div class="content__popup__wrapper">
    <div class="content__popup">
      <div class="content__popup__title" :class="{'compact' : compact }">
        <h2>{{modalTitle}}</h2>
      </div>
      <img src="../../assets/missed_newBlue.svg" alt="close-popup" @click="$emit('onClose', false)" class="content__popup__close-image">
      <div class="content__popup__text">

        <slot/>
      </div>
      <div class="content__popup__buttons" :class="{'compact' : compact }">
        <button class="btn content__popup__button btn-cancel" @click="!disabled ? $emit('onClose', false): null">Cancelar</button>
        <button class="btn content__popup__button compact" :class="{'disabled' : !valid, 'btn-save': valid}" @click="!disabled && valid ? $emit('onAccept', false) : null">Aceptar</button>
      </div>
    </div>
  </div>
</template>
<script setup>
  defineEmits(['onClose', 'onAccept'])
</script>
<script>
 export default {
   name: 'Popup',
   props: {
     buttons: {
       type: Boolean,
       default: false
     },
     modalTitle: {
       type: String,
       default: ''
     },
     disabled: {
       type: Boolean,
       default: false
     },
     valid: {
       type: Boolean,
       default: true
     },
     compact: {
       type: Boolean,
       default: false
     }
   }
 }
</script>
<style scoped lang="scss">
.content {
  &__popup {
    max-width: unset;
    min-width: 25%;
    &__wrapper {
      background-color: rgba($bg-btn, 0.7);
      bottom: 0;
      height: 100vh;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      z-index: 5;
    }
    &__close-image {
      height: 28px;
      padding: 16px;
      position: absolute;
      right: 0;
      top: 0;
      width: 20px;
    }
    &__title {
      H2 {
        margin: 0;
      }
      &.compact{
        margin-bottom: 10px;
      }
      margin-bottom: 50px;
    }
    &__text {
      margin-top: 0;
      &--header {
        margin-top: 24px;
      }
    }
    &__buttons {
      display: flex;
      justify-content: flex-end;
      gap: 16px;
      padding-top: 20px;
      margin-top: 58px;

      &.compact{
        margin-top: 18px;
      }
    }
    &__button{
      padding: 8px 20px;
      width: unset;
    }
    &__body {
      padding-top: 20px;
    }
    .description {
      padding-top: 20px;
      text-align: center;
      p {
        text-align: start;
      }
    }
    .exit {

      a {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  @include tablet {
    min-height: calc(100vh - 40px);
    padding: 20px;
    padding-left: 360px;
  }
}
</style>
