import { apiClient } from '@/services/ApiClient'

const state = {
  tutors: [],
  tutor: {}
}

const actions = {
  async fetchTutors( { commit }, obj) {
    const { centerId, params } = obj
    const queryParams = {...params};
    let queryString = '';
    for(const param of Object.keys(queryParams)){
      queryString+= (queryString === '' ? '?' : '&');
      queryString+=`${param}=${queryParams[param]}`;
    }
    await apiClient.get(`/admin/centers/${centerId}/tutors${queryString}`)
    .then(response => {
      commit('SET_TUTORS', response.data);
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },
  async deleteTutor( { commit } , {centerId, tutorId}) {
    await apiClient.delete(`/admin/centers/${centerId}/tutors/${tutorId}`)
    .then(() => {
      commit('DELETE_TUTOR', tutorId)
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },
  async fetchTutor( { commit } , { centerId, tutorId }) {
    await apiClient.get(`/admin/centers/${centerId}/tutors/${tutorId}`)
    .then(response => {
      commit('SET_TUTOR', {...response.data, center: response.data.center?.id})
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },
  async updateTutor(commit, { centerId, tutorId, infoUpdateTutor }) {
    await apiClient.patch(`/admin/centers/${centerId}/tutors/${tutorId}`, infoUpdateTutor)
    .then(() => {
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },
  async registerTutor(commit, { centerId, infoCreateTutor }) {
    await apiClient.post(`/admin/centers/${centerId}/tutors`, infoCreateTutor)
    .then(() => {
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },
}

const mutations = {
  SET_TUTORS(state, tutors) {
    state.tutors = tutors
  },
  SET_TUTOR(state, tutor) {
    state.tutor = tutor
  },
  DELETE_TUTOR(state, tutorId) {
    const tutorIndex = state.tutors.findIndex(tutor => tutor.id === tutorId)
    state.tutors.splice(tutorIndex, 1)
  },
}

const getters = {
  getTutors: (state) => state.tutors,
  getTutor: (state) => state.tutor,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
